import React from "react";
import {
  Modal,
  Box,
  Grid,
  Typography,
  Stack,
  IconButton,
} from "@mui/material";
import Button from "../Button";
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 600,
  bgcolor: "background.paper",
  boxShadow: 24,
  borderRadius: "12px",
};

const ConfirmationModal = ({ children, isOpen, title, deletableDataName, dataContentName, handleClose, onConfirm, onCancel, isButtonLoading = false, subList = {}, type = "" }) => {
  return (
    <>
      <Modal
        open={isOpen}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Box display={"flex"} justifyContent={"space-between"} alignItems={"center"} py={2} px={3} borderBottom="1px solid #E2E4EC">
            <Typography variant="h6">Delete {title}</Typography>
            <IconButton
              aria-label="Close"
              size="small"
              color="secondary"
              onClick={onCancel}
              height={24}
              width={24}
              sx={{ 'svg': { fontSize: 22, } }}
            >
              <CloseRoundedIcon />
            </IconButton>
          </Box>
          {children ? children :
            <Grid item p={3}>
              <Stack spacing={2}>
                {type === 'holiday' ? <Typography
                  color="dark.800"
                  fontSize={16}
                  fontWeight={400}
                  sx={{ lineHeight: "26px" }}
                >
                  Are you sure, You want to{" "}
                  <span
                    style={{
                      color: "#D32F2F",
                      fontWeight: "700",
                    }}
                  >
                    delete
                  </span>{" the holiday of "}
                  <b>{deletableDataName}</b> ?
                </Typography> :
                  <Typography
                    color="dark.800"
                    fontSize={16}
                    fontWeight={400}
                    sx={{ lineHeight: "26px" }}
                  >
                    Are you sure, You want to{" "}
                    <span
                      style={{
                        color: "#D32F2F",
                        fontWeight: "700",
                      }}
                    >
                      delete
                    </span>{" "}
                    <b>{deletableDataName}</b>{" "}{dataContentName} ?
                  </Typography>}
                {Object.keys(subList)?.length ? <Typography
                  color="dark.800"
                  fontSize={16}
                  fontWeight={400}
                  sx={{ lineHeight: "26px" }}
                >
                  {subList}
                </Typography> : null}
              </Stack>
            </Grid>}

          <Grid item py={2} px={3} borderTop="1px solid #E2E4EC">
            <Stack spacing={1} direction="row">
              <Button
                variant="contained"
                color="error"
                size="medium"
                fontWeight="500"
                sx={{ color: "white", textTransform: "none", boxShadow: 'none !important' }}
                onClick={onConfirm}
                isButtonLoading={isButtonLoading}
                disabled={isButtonLoading}
              >
                Delete
              </Button>
              <Button
                variant="text"
                color="secondary"
                sx={{ textTransform: "none" }}
                size="medium"
                fontWeight="500"
                onClick={onCancel}
                disabled={isButtonLoading}
              >
                Cancel
              </Button>
            </Stack>
          </Grid>
        </Box>
      </Modal>
    </>
  )
}

export default ConfirmationModal