import React from 'react'
import DatePicker from 'react-multi-date-picker';
import DatePanel from 'react-multi-date-picker/plugins/date_panel';
import Toolbar from 'react-multi-date-picker/plugins/toolbar';
import { LEAVE_DURATION_TYPE, WEEKDAYS } from '../../constants/default-values';
import moment from 'moment';
import Button from '../Button';
import AddCircleRoundedIcon from "@mui/icons-material/AddCircleRounded";
import { useSelector } from 'react-redux';
import useAuthentication from '../../hook/useAuthentication';
import { styled } from '@mui/styles';
import { Box, Tooltip, tooltipClasses, Typography } from '@mui/material';
import { titleCase } from '../../utils/validation';

const HtmlTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
        backgroundColor: '#444444',
        maxWidth: 220,
        padding: "7px 12px",
    },
}));

const MultipleDatePickerWithButton = ({ selectedDates, setSelectedDates, onChange, onClose, ignoreDates, extraDisableDates = [], ...rest }) => {
    const { getCurrentUser } = useAuthentication();
    const currentUser = getCurrentUser();

    const { ignoreLeaveDates, requestDays } = useSelector((state) => state?.leaveData);

    const getIgnoreDatesArray = () => {
        let array = [];
        if (ignoreDates?.length) {
            array = ignoreDates;
        } else {
            array = ignoreLeaveDates;
        }
        if (extraDisableDates?.length) {
            array = Array.from(new Set([...extraDisableDates, ...ignoreLeaveDates]));
        }
        return array;
    }

    const getLeaveType = (leaveDetails) => {
        // Leave has leave_date key and WFH / timing request has date key
        if (leaveDetails?.leave_duration !== Object.keys(LEAVE_DURATION_TYPE)[2]) {
            return `${Object.keys(leaveDetails)?.includes('leave_date') ? titleCase(leaveDetails?.type?.split(" ")?.[0]) : titleCase(leaveDetails?.type)} ${LEAVE_DURATION_TYPE[leaveDetails?.leave_duration]} ${Object.keys(leaveDetails)?.includes('leave_date') ? 'Leave' : ''}`
        } else {
            return `${titleCase(leaveDetails?.type)}`
        }
    }

    const isdotType = (requestDate, key) => {
        return requestDate?.find(data => Object.keys(data)?.includes(key))
    }

    const isIncludeTwoRequest = (requestDate) => {
        return requestDate?.length > 1;
    }

    return (
        <DatePicker
            value={selectedDates}
            onChange={(dates) => onChange(dates)}
            onClose={onClose}
            plugins={[
                <DatePanel markFocused />,
                <Toolbar
                    position="bottom"
                    names={{
                        today: "Today",
                        deselect: "Deselect",
                        close: "Select",
                    }}
                />,
            ]}
            format="DD/MM/YYYY"
            weekDays={WEEKDAYS}
            headerOrder={["MONTH_YEAR", "LEFT_BUTTON", "RIGHT_BUTTON"]}
            mapDays={({ date }) => {
                // let isPastDate = moment(new Date(date)).add(1, 'd').isBefore(new Date());
                let isPastDate = moment(new Date(date)).isBefore(currentUser?.organization?.createdAt || `01/01/${new Date().getFullYear()}`);

                let isIgnoredDate = getIgnoreDatesArray()?.some((ignoreDate) => {
                    let date1 = moment(ignoreDate, 'DD/MM/YYYY').format("DD/MM/YYYY");
                    let date2 = moment(new Date(date)).format("DD/MM/YYYY");
                    return date1 === date2;
                })
                let isRequestDate = requestDays?.filter((requestDetails) => {
                    let date1 = moment(requestDetails?.leave_date ?? requestDetails?.date).format("DD/MM/YYYY");
                    let date2 = moment(new Date(date)).format("DD/MM/YYYY");

                    return date1 === date2;
                });
                // if (isPastDate || isIgnoredDate) {
                //     return {
                //         disabled: true,
                //         style: { color: "#ccc" },
                //     };
                // }
                if (isPastDate || isIgnoredDate || !!isRequestDate?.length) {
                    return {
                        disabled: (isPastDate || isIgnoredDate) ? true : (!!isRequestDate?.length && isRequestDate?.leave_duration === "full") ? true : false,
                        style: { color: (isPastDate || isIgnoredDate) ? "#ccc" : (!!isRequestDate?.length && isRequestDate?.leave_duration === "full") ? "#ccc" : "#000" },
                        children: (
                            <>
                                {!!isRequestDate?.length ? <HtmlTooltip arrow
                                    title={
                                        <React.Fragment>
                                            <Box display={'flex'} flexDirection={'column'} justifyContent={'space-between'} alignItems={'center'}>
                                                {isdotType(isRequestDate, "leave_date") ? <Typography variant="body1" color={"white"} fontSize={14} fontWeight={500} lineHeight={"21px"}>{getLeaveType(isRequestDate?.find(data => Object.keys(data)?.includes('leave_date')))}</Typography> : null}
                                                {isdotType(isRequestDate, "date") ? <Typography variant="body1" color={"white"} fontSize={14} fontWeight={500} lineHeight={"21px"}>{getLeaveType(isRequestDate?.find(data => Object.keys(data)?.includes('date')))}</Typography> : null}
                                            </Box>
                                        </React.Fragment>
                                    }
                                >
                                    <Box>
                                        <Typography variant="caption" className="sd leave-type">{new Date(isRequestDate[0]?.leave_date ?? isRequestDate[0]?.date)?.getDate()}</Typography>
                                        <Box display={'flex'} justifyContent={'space-between'} alignItems={'center'} mx={0}>
                                            {isdotType(isRequestDate, "leave_date") ? <Box mr={1} width={6} height={6} minWidth={6} bgcolor={isRequestDate?.find(data => Object.keys(data)?.includes('leave_date'))?.type?.toLowerCase() === "casual leave" ? "#12B495" : "#D32F2F"} borderRadius={5} margin={"auto"} position={"absolute"} bottom={"2.5px"} left={isIncludeTwoRequest(isRequestDate) ? -7 : 0} right={0}></Box> : null}
                                            {isdotType(isRequestDate, "date") ? <Box mr={1} width={6} height={6} minWidth={6} bgcolor={"#047FE0"} borderRadius={5} margin={"auto"} position={"absolute"} bottom={"2.5px"} left={isIncludeTwoRequest(isRequestDate) ? 7 : 0} right={0}></Box> : null}
                                        </Box>
                                    </Box>
                                </HtmlTooltip> : <Typography variant="caption" className="sd leave-type">{new Date(date)?.getDate()}</Typography>}
                            </>
                        ),
                    };
                }
            }}
            sort
            highlightToday
            render={
                <Button variant="text" startIcon={<AddCircleRoundedIcon />} sx={{ textTransform: 'none', }} >
                    Add New
                </Button>
            }
            {...rest}
        />
    )
}

export default MultipleDatePickerWithButton