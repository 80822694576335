import { configureStore } from '@reduxjs/toolkit';
import { thunk } from 'redux-thunk';
import userReducer from './slices/userSlice';
import notificationReducer from './slices/notificationSlice';
import leaveDataSlice from './slices/leaveDataSlice';
import nightModeSlice from './slices/nightModeSlice';
import repoteeSlice from './slices/repoteeDataSlice';
import activityActionDataSlice from './slices/activityActionDataSlice';
import leaveApplicationSlice from './slices/leaveApplicationSlice';

export const store = configureStore({
        reducer: {
                user: userReducer,
                notification: notificationReducer,
                leaveData: leaveDataSlice,
                nightMode: nightModeSlice,
                repoteeData: repoteeSlice,
                activityActionData: activityActionDataSlice,
                leaveApplicationData: leaveApplicationSlice,
        },
        middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(thunk),
});
