import { Box, Grid, Typography, styled } from '@mui/material';
import React from 'react'
import { getInputId, getMaxSize } from '../../../../utils';
import TextEditor from '../../../../components/TextEditor';
import Button from '../../../../components/Button';
import { useNavigate } from 'react-router-dom';
import Input from '../../../../components/Input';
import { VALID_IMAGE_TYPE } from '../../../../constants/default-values';
import { useAlert } from '../../../../hook/useAlert';
import dummyImg from '../../../../assets/images/img-upload.png';

const VisuallyHiddenInput = styled("input")({
    clip: "rect(0 0 0 0)",
    clipPath: "inset(50%)",
    height: 1,
    overflow: "hidden",
    position: "absolute",
    bottom: 0,
    left: 0,
    whiteSpace: "nowrap",
    width: 1,
});
const TEMPLATE_IMAGE_SIZE = 1 * 1024 * 1024;

const FormTemplate = ({ formik, loading, isCreate }) => {
    const showAlert = useAlert();
    const navigate = useNavigate();

    const handlePreviewImage = (file, fieldName) => {
        if (typeof file === "string") {
            return file;
        } else {
            if (file) {
                if (VALID_IMAGE_TYPE.includes(file.type)) {
                    if (file.size <= TEMPLATE_IMAGE_SIZE) {
                        const previewURL = URL.createObjectURL(file);
                        return previewURL;
                    } else {
                        showAlert(`File size exceeds ${getMaxSize(TEMPLATE_IMAGE_SIZE)}MB limit. Please choose a smaller file.`, "error");
                        formik.setFieldValue(fieldName, null);
                    }
                } else {
                    showAlert('Only JPEG and PNG file types are allowed.', "error");
                    formik.setFieldValue(fieldName, null);
                }
            }
        };

    };

    return (
        <form onSubmit={formik.handleSubmit} className="step-form-1" noValidate>
            <Box
                direction="row"
                display="flex"
                flexWrap="wrap"
                width={"860px"}
                maxWidth="100%"
            >
                <Box
                    sx={{
                        display: "grid",
                        gap: 4,
                        gridTemplateColumns: "repeat(5, 1fr)",
                    }}
                    mb={2}
                    width={"100%"}
                    p={4}
                    bgcolor={"#F8F8F8"}
                >
                    <Grid sx={{ gridColumnStart: '1', gridColumnEnd: '4' }}>
                        <Input
                            id={getInputId("subject")}
                            label="Subject"
                            variant="standard"
                            placeholder="Subject"
                            type="text"
                            name={"subject"}
                            fullWidth
                            error={
                                formik.touched.subject &&
                                Boolean(formik.errors.subject)
                            }
                            helperText={
                                formik.touched.subject && formik.errors.subject
                            }
                            {...formik.getFieldProps("subject")}
                        />
                    </Grid>
                    <Grid sx={{ gridColumnStart: '4', gridColumnEnd: '7' }}>
                        <Input
                            id={getInputId("sub_title")}
                            label="Technology Title"
                            variant="standard"
                            placeholder="Technology Title"
                            type="text"
                            name={"sub_title"}
                            fullWidth
                            error={
                                formik.touched.sub_title &&
                                Boolean(formik.errors.sub_title)
                            }
                            helperText={
                                formik.touched.sub_title && formik.errors.sub_title
                            }
                            {...formik.getFieldProps("sub_title")}
                        />
                    </Grid>
                </Box>
                <Box
                    mb={2}
                    width={"100%"}
                    p={3}
                    bgcolor={"#F8F8F8"}
                >
                    <Box
                        sx={{
                            display: "grid",
                            gap: 4,
                            gridTemplateColumns: "repeat(5, 1fr)",
                        }} mb={4}
                    >
                        <Grid sx={{ gridColumnStart: '1', gridColumnEnd: '4' }}>
                            <Box display={"flex"} flexDirection={"column"} gap={4}>
                                <Input
                                    id={getInputId("template-title")}
                                    label="Main Blog Title"
                                    variant="standard"
                                    isCapitalizeFirstLatter={true}
                                    placeholder="Main Blog Title"
                                    type="text"
                                    name={"title"}
                                    fullWidth
                                    error={
                                        formik.touched.title &&
                                        Boolean(formik.errors.title)
                                    }
                                    helperText={
                                        formik.touched.title && formik.errors.title
                                    }
                                    {...formik.getFieldProps("title")}
                                />
                                <Input
                                    id={getInputId("read_more_link")}
                                    label="Main Blog Read More Link"
                                    variant="standard"
                                    placeholder="Main Blog Read More Link"
                                    type="text"
                                    name={"read_more_link"}
                                    fullWidth
                                    error={
                                        formik.touched.read_more_link &&
                                        Boolean(formik.errors.read_more_link)
                                    }
                                    helperText={
                                        formik.touched.read_more_link && formik.errors.read_more_link
                                    }
                                    {...formik.getFieldProps("read_more_link")}
                                />
                            </Box>
                        </Grid>
                        <Grid sx={{ gridColumnStart: '4', gridColumnEnd: '7' }}>
                            <Typography color={"dark.800"} fontSize={13} fontWeight={400} lineHeight={"22px"} letterSpacing={"0.15px"} mb={1}>
                                Main Blog Banner*
                            </Typography>
                            <Grid display="flex" alignItems="center">
                                <Box display={"flex"} alignItems={"center"} gap={2.5}>
                                    <Box maxWidth={180} width={"100%"} flex={1}>
                                        {!!formik.values.banner_img ?
                                            <img
                                                alt={"Banner Img"}
                                                src={handlePreviewImage(formik.values.banner_img, "banner_img")}
                                                className="imgcolver"
                                            /> :
                                            <img src={dummyImg} style={{ width: '100%' }} alt='banner_img' />
                                        }
                                    </Box>
                                </Box>
                                <Button
                                    component="label"
                                    variant="text"
                                    color="primary"
                                    fontSize={13}
                                    sx={{ textTransform: "none", cursor: "pointer", ml: "20px" }}
                                >
                                    <label for="banner_img" style={{ cursor: "pointer" }}>{isCreate ? "Upload" : "Change"}</label>
                                    <VisuallyHiddenInput
                                        accept="image/*"
                                        id="banner_img"
                                        type="file"
                                        variant="standard"
                                        name='banner_img'
                                        onChange={(event) => formik.setFieldValue('banner_img', event.target.files[0])}
                                    />
                                </Button>
                            </Grid>
                            {formik.touched.banner_img && !!formik.errors.banner_img?.length ?
                                <Typography
                                    variant="body2"
                                    fontWeight={400}
                                    color={'error'}
                                    sx={{ lineHeight: "21px", letterSpacing: "0.15", mt: 0.5 }}
                                >
                                    {formik.errors.banner_img}
                                </Typography>
                                : null}
                        </Grid>
                    </Box>
                    <Grid>
                        <Typography
                            variant="body2"
                            color={"dark.800"}
                            fontSize={14}
                            fontWeight={400}
                            lineHeight={"21px"}
                            letterSpacing={"0.15px"}
                            mb={0.5}
                        >
                            Main Blog Body*
                        </Typography>
                        <TextEditor
                            value={formik.values.body}
                            onChange={(value) => formik.setFieldValue('body', value)}
                        />
                        {formik.touched.body && !!formik.errors.body?.length ?
                            <Typography
                                variant="body2"
                                fontWeight={400}
                                color={'error'}
                                sx={{ lineHeight: "21px", letterSpacing: "0.15", mb: 0.5 }}
                            >
                                {formik.errors.body}
                            </Typography>
                            : null}
                    </Grid>
                </Box>
                <Box
                    sx={{
                        display: "grid",
                        gap: 4,
                        gridTemplateColumns: "repeat(5, 1fr)",
                    }}
                    mb={2}
                    width={"100%"}
                    p={3}
                    bgcolor={"#F8F8F8"}
                >
                    <Grid sx={{ gridColumnStart: '1', gridColumnEnd: '4' }}>
                        <Box display={"flex"} flexDirection={"column"} gap={4}>
                            <Input
                                id={getInputId("footer_title")}
                                label="Footer Title"
                                variant="standard"
                                placeholder="Footer Title"
                                type="text"
                                name={"footer_title"}
                                fullWidth
                                error={
                                    formik.touched.footer_title &&
                                    Boolean(formik.errors.footer_title)
                                }
                                helperText={
                                    formik.touched.footer_title && formik.errors.footer_title
                                }
                                {...formik.getFieldProps("footer_title")}
                            />
                            <Input
                                id={getInputId("footer_link")}
                                label="Footer Link"
                                variant="standard"
                                placeholder="Footer Link"
                                type="text"
                                name={"footer_link"}
                                fullWidth
                                error={
                                    formik.touched.footer_link &&
                                    Boolean(formik.errors.footer_link)
                                }
                                helperText={
                                    formik.touched.footer_link && formik.errors.footer_link
                                }
                                {...formik.getFieldProps("footer_link")}
                            />
                        </Box>
                    </Grid>
                    <Grid sx={{ gridColumnStart: '4', gridColumnEnd: '7' }}>
                        <Typography color={"dark.800"} fontSize={13} fontWeight={400} lineHeight={"22px"} letterSpacing={"0.15px"} mb={1}>
                            Footer Image*
                        </Typography>
                        <Grid display="flex" alignItems="center">
                            <Box display={"flex"} alignItems={"center"} gap={2.5}>
                                <Box maxWidth={180} width={"100%"} flex={1}>
                                    {!!formik.values.footer_banner ?
                                        <img
                                            alt={"Banner Img"}
                                            src={handlePreviewImage(formik.values.footer_banner, "footer_banner")}
                                            className="imgcolver"
                                        /> :
                                        <img src={dummyImg} style={{ width: '100%' }} alt='footer_banner' />
                                    }
                                </Box>
                            </Box>
                            <Button
                                component="label"
                                variant="text"
                                color="primary"
                                fontSize={13}
                                sx={{ textTransform: "none", cursor: "pointer", ml: "20px" }}
                            >
                                <label for="footer_banner" style={{ cursor: "pointer" }}>{isCreate ? "Upload" : "Change"}</label>
                                <VisuallyHiddenInput
                                    accept="image/*"
                                    id="footer_banner"
                                    type="file"
                                    variant="standard"
                                    name='footer_banner'
                                    onChange={(event) => formik.setFieldValue('footer_banner', event.target.files[0])}
                                />
                            </Button>
                        </Grid>
                        {formik.touched.footer_banner && !!formik.errors.footer_banner?.length ?
                            <Typography
                                variant="body2"
                                fontWeight={400}
                                color={'error'}
                                sx={{ lineHeight: "21px", letterSpacing: "0.15", mt: 0.5 }}
                            >
                                {formik.errors.footer_banner}
                            </Typography>
                            : null}
                    </Grid>
                </Box>
                <Box
                    width={"100%"}
                    p={3}
                    bgcolor={"#F8F8F8"}
                    sx={{
                        display: "grid",
                        gap: 4,
                        gridTemplateColumns: "repeat(5, 1fr)",
                    }}
                >
                    <Grid sx={{ gridColumnStart: '1', gridColumnEnd: '4' }}>
                        <Box display={"flex"} flexDirection={"column"} gap={4}>
                            <Input
                                id={getInputId("other_blog_title")}
                                label="Other Blog Title"
                                variant="standard"
                                placeholder="Other Blog Title"
                                type="text"
                                name={"other_blog_title"}
                                fullWidth
                                error={
                                    formik.touched.other_blog_title &&
                                    Boolean(formik.errors.other_blog_title)
                                }
                                helperText={
                                    formik.touched.other_blog_title && formik.errors.other_blog_title
                                }
                                {...formik.getFieldProps("other_blog_title")}
                            />
                            <Input
                                id={getInputId("other_blog_intro")}
                                label="Other Blog Intro"
                                variant="standard"
                                placeholder="Other Blog Intro"
                                type="text"
                                name={"other_blog_intro"}
                                fullWidth
                                error={
                                    formik.touched.other_blog_intro &&
                                    Boolean(formik.errors.other_blog_intro)
                                }
                                helperText={
                                    formik.touched.other_blog_intro && formik.errors.other_blog_intro
                                }
                                {...formik.getFieldProps("other_blog_intro")}
                            />
                            <Input
                                id={getInputId("other_blog_link")}
                                label="Other Blog Link"
                                variant="standard"
                                placeholder="Other Blog Link"
                                type="text"
                                name={"other_blog_link"}
                                fullWidth
                                error={
                                    formik.touched.other_blog_link &&
                                    Boolean(formik.errors.other_blog_link)
                                }
                                helperText={
                                    formik.touched.other_blog_link && formik.errors.other_blog_link
                                }
                                {...formik.getFieldProps("other_blog_link")}
                            />
                        </Box>
                    </Grid>
                    <Grid sx={{ gridColumnStart: '4', gridColumnEnd: '7' }}>
                        <Typography color={"dark.800"} fontSize={13} fontWeight={400} lineHeight={"22px"} letterSpacing={"0.15px"} mb={1}>
                            Other Blog Image*
                        </Typography>
                        <Grid display="flex" alignItems="center">
                            <Box display={"flex"} alignItems={"center"} gap={2.5}>
                                <Box maxWidth={180} width={"100%"} flex={1}>
                                    {!!formik.values.other_blog_img ?
                                        <img
                                            alt={"Banner Img"}
                                            src={handlePreviewImage(formik.values.other_blog_img, "other_blog_img")}
                                            className="imgcolver"
                                        /> :
                                        <img src={dummyImg} style={{ width: '100%' }} alt='other_blog_img' />
                                    }
                                </Box>
                            </Box>
                            <Button
                                component="label"
                                variant="text"
                                color="primary"
                                fontSize={13}
                                sx={{ textTransform: "none", cursor: "pointer", ml: "20px" }}
                            >
                                <label for="other_blog_img" style={{ cursor: "pointer" }}>{isCreate ? "Upload" : "Change"}</label>
                                <VisuallyHiddenInput
                                    accept="image/*"
                                    id="other_blog_img"
                                    type="file"
                                    variant="standard"
                                    name='other_blog_img'
                                    onChange={(event) => formik.setFieldValue('other_blog_img', event.target.files[0])}
                                />
                            </Button>
                        </Grid>
                        {formik.touched.other_blog_img && !!formik.errors.other_blog_img?.length ?
                            <Typography
                                variant="body2"
                                fontWeight={400}
                                color={'error'}
                                sx={{ lineHeight: "21px", letterSpacing: "0.15", mt: 0.5 }}
                            >
                                {formik.errors.other_blog_img}
                            </Typography>
                            : null}
                    </Grid>
                </Box>
            </Box>
            <Box
                display="flex"
                justifyContent="space-between"
                alignItems="center"
                mt={4}
            >
                <Box
                    display="flex"
                    justifyContent="flex-start"
                    alignItems="center"
                    flexBasis="100%"
                    gap={2}
                >
                    <Button
                        variant="contained"
                        size="large"
                        type="submit"
                        sx={{
                            boxShadow: "0",
                            fontSize: "15px",
                            textTransform: "none",
                            color: "white",
                            "&:hover": { boxShadow: "0" },
                        }}
                        disabled={loading.formSubmitting}
                        isButtonLoading={loading.formSubmitting}
                    >
                        {isCreate ? "Create" : "Update"}
                    </Button>
                    <Button
                        variant="text"
                        color="secondary"
                        sx={{ textTransform: "none" }}
                        size="medium"
                        fontWeight="500"
                        onClick={() => navigate(-1)}
                        disabled={loading.pageLoading}
                    >
                        Cancel
                    </Button>
                </Box>
            </Box>
        </form>
    )
}

export default FormTemplate;