import { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import React from 'react';
import { Checkbox, Divider, Drawer, FormControlLabel, Grid, IconButton, List, ListItem, Menu, MenuItem, Stack, Switch, Tooltip, Typography, styled, tooltipClasses } from '@mui/material'
import API from "../../../../axios/api";
import axiosInstance from "../../../../axios";
import CircularLoader from '../../../../components/CircularLoader';
import { usePageTitle } from '../../../../hook/useTitle';
import { useAlert } from '../../../../hook/useAlert';
import ConfirmationModal from '../../../../components/ConfirmationModal';
import Button from '../../../../components/Button';
import URLS from '../../../../routes/urls';
import { useNavigate } from 'react-router-dom';
// import EditIcon from "@mui/icons-material/Edit";
import EmptyPage from '../../../../components/EmptyPage';
import CloseIcon from "@mui/icons-material/Close";
// import BackgroundLetterAvatars from '../../../../components/BackgroundLetterAvatars';
import { getApplyDateTime, getFullName, getInputId } from '../../../../utils';
import ICONS from '../../../../constants/icons';
import moment from 'moment';
import SearchBoxInput from '../../../../components/SearchBoxInput';
import SendIcon from '@mui/icons-material/Send';
import useDebounce from '../../../../hook/useDebounce';
import InfiniteScroll from 'react-infinite-scroll-component';
import SearchRoundedIcon from '@mui/icons-material/SearchRounded';


const HtmlTooltip = styled(({ className, ...props }) => (
    <Tooltip arrow {...props} classes={{ popper: className }} />
))(() => ({
    [`& .${tooltipClasses.tooltip}`]: {
        padding: "8px 12px",
        borderRadius: "5px",
        background: "#444444",
    },
}));

const EmailTemplates = () => {
    const navigate = useNavigate();
    const showAlert = useAlert();
    const { setPageTitle } = usePageTitle();
    const [loading, setLoading] = useState({
        formSubmitting: false,
        pageLoading: true,
        dataLoading: false,
        sidebarLoading: false,
    });
    const [searchString, setSearchString] = useState("");
    const debouncedSearchTerm = useDebounce(searchString);
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [openMenuIndex, setOpenMenuIndex] = useState(null);
    const open = Boolean(anchorEl);
    const [templateData, setTemplateData] = useState([]);
    const [contactsForMailList, setContactsForMailList] = useState([]);
    const [count, setCount] = useState(0);
    const [isDeleteIsOpen, setIsDeleteIsOpen] = useState(false);
    const [deletedTemplateData, setDeletedTemplateData] = useState(null);
    const [showSideBarForSendMail, setShowSideBarForSendMail] = useState(false);
    const [contactMailFormData, setContactMailFormData] = useState({
        recipeintIdsList: [],
        templateId: null,
        templateName: ""
    })
    const [isOnlyNew, setIsOnlyNew] = useState(false)
    const [pageBeenRendered, setPageBeenRendered] = useState(false);
    const [params, setParams] = useState({
        limit: 100,
        offSet: 0,
    });
    const [hasMore, setHasMore] = useState(true);
    const [isClearedSearched, setIsClearedSearched] = useState(false);

    setPageTitle("CRM - Templates");

    useEffect(() => {
        getAllTemplateList();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (pageBeenRendered) {
            if (!searchString?.length && !contactsForMailList?.length) {
                setIsClearedSearched(true)
            } else {
                setIsClearedSearched(false)
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [searchString])

    useEffect(() => {
        if (pageBeenRendered) {
            if (contactMailFormData?.templateId) {
                getAllContactList(contactMailFormData?.templateId, 0, true)
                setParams(prev => ({
                    ...prev, offSet: 0
                }))
                if (!debouncedSearchTerm?.trim()?.length) {
                    setIsClearedSearched(true)
                } else {
                    setIsClearedSearched(false)
                }
            }
        }
        setPageBeenRendered(true)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [debouncedSearchTerm]);

    const getAllTemplateList = async () => {
        try {
            setLoading((prev) => ({ ...prev, dataLoading: true }));
            const response = await axiosInstance.post(
                API.getAllTemplateList
            );

            if (response?.status === 200) {
                setTemplateData(response?.data?.data);
            } else {
                setTemplateData([]);
            }
            setLoading((prev) => ({ ...prev, dataLoading: false, pageLoading: false }));
        } catch (error) {
            console.error(error);
            setLoading((prev) => ({ ...prev, dataLoading: false, pageLoading: false }));
        }
    };

    const getAllContactList = async (templateId, updatedOffset = params?.offSet, isSearch = false, isOnlyNewData = isOnlyNew, isCheckChange = false) => {
        try {
            setLoading((prev) => ({ ...prev, sidebarLoading: (updatedOffset !== 0) ? false : true }));
            let body = {
                "search": searchString?.trim(),
                "newRecord": isOnlyNewData,
                "limit": params?.limit,
                "page": updatedOffset
            }
            const response = await axiosInstance.post(
                API.getAllContactsListByTemplateId(templateId), body
            );
            if (response?.status === 200) {
                if (isSearch || isCheckChange) {
                    setCount(response?.data?.data?.count ?? 0)
                    setContactsForMailList(response?.data?.data?.rows ?? []);
                    setIsClearedSearched(false);
                }
                else {
                    setCount(response?.data?.data?.count ?? 0)
                    setContactsForMailList(prev => [...prev, ...(response?.data?.data?.rows || [])]);
                    setIsClearedSearched(false);
                }

                if (response?.data?.data?.rows?.length < params?.limit) setHasMore(false)
                else setHasMore(true)
            } else {
                setContactsForMailList([]);
            }
            setLoading((prev) => ({ ...prev, sidebarLoading: false }));
        } catch (error) {
            console.error(error);
            setLoading((prev) => ({ ...prev, sidebarLoading: false }));
        }
    };

    const handleSubmitDelete = async () => {
        try {
            setLoading((prev) => ({ ...prev, formSubmitting: true }));
            const response = await axiosInstance.delete(API.deleteContact(deletedTemplateData?.id));
            if (response?.status === 200) {
                setTemplateData(prev => prev.filter((contact) => contact?.id !== deletedTemplateData?.id));
                setDeletedTemplateData(null);
                setIsDeleteIsOpen(false);
                showAlert(response?.data?.message)
            }
            setLoading((prev) => ({ ...prev, formSubmitting: false }));
        } catch (error) {
            console.error("Error:", error);
            setLoading((prev) => ({ ...prev, formSubmitting: false }));
        }
    };

    const handleSendEmailToContacts = async (receiveTestMail, templateId = null) => {
        try {
            let body = { ...contactMailFormData, receiveTestMail };
            if (templateId) {
                body.templateId = templateId
            }
            setLoading(prev => ({ ...prev, formSubmitting: true }));
            const response = await axiosInstance.post(API.sendEmailTemplateToContacts, body);
            if (response?.status === 200) {
                showAlert(response?.data?.message);
                setShowSideBarForSendMail(false);
                setContactMailFormData({
                    recipeintIdsList: [],
                    templateId: null,
                    templateName: ""
                });

                setContactsForMailList([]);
                setCount(0);
                setParams({
                    limit: 100,
                    offSet: 0,
                })
                setIsOnlyNew(false);
                setHasMore(true);
            } else {
                showAlert(response?.data?.message, "error");
            }
            setLoading(prev => ({ ...prev, formSubmitting: false }));
        } catch (error) {
            console.error(error);
            setLoading(prev => ({ ...prev, formSubmitting: false }));
        }
    };

    const handleRecieveEmails = async (templateId, templateName) => {
        setContactMailFormData(prev => ({ ...prev, templateId, templateName }));
        handleSendEmailToContacts(true, templateId);
    };

    const handleClick = (event, index) => {
        setAnchorEl(event.currentTarget);
        setOpenMenuIndex(index)
    };

    const handleClose = () => {
        setAnchorEl(null);
        setOpenMenuIndex(null)
    };
    const handleSearch = (value) => {
        setSearchString(value);
    };

    const handleChangeNew = (e) => {
        setIsOnlyNew(e.target.checked);
        getAllContactList(contactMailFormData?.templateId, 0, false, e.target.checked, true);
        setParams((prev) => ({
            ...prev,
            offSet: 0,
        }));
    }

    const geMoreContactList = () => {
        if (contactMailFormData?.templateId) {
            let updatedOffset = params.offSet + 1
            setParams((prev) => ({
                ...prev,
                offSet: updatedOffset,
            }));
            getAllContactList(contactMailFormData?.templateId, updatedOffset);
        }
    }

    const DrawerList = (
        <Box
            sx={{ width: 420 }}
            display={"flex"}
            flexDirection={"column"}
            overflow={"hidden"}
            role="presentation"
            flex={1}
        >
            <Grid
                item
                p={1.5}
                display="flex"
                justifyContent="space-between"
                alignItems="center"
                sx={{ top: "0", zIndex: "1" }}
            >
                <Box display={"flex"} flexDirection={"column"}>
                    <Typography
                        color="dark.800"
                        fontSize={16}
                        fontWeight={500}
                        sx={{ lineHeight: "25px" }}
                    >
                        Select Contacts
                    </Typography>
                    <Typography
                        color="dark.800"
                        fontSize={16}
                        fontWeight={500}
                        sx={{ lineHeight: "25px" }}
                    >
                        to send <b>{contactMailFormData?.templateName}</b>
                    </Typography>
                </Box>
                <Grid item>
                    <IconButton
                        aria-label="delete"
                        size="small"
                        className="edit-btn"
                        onClick={() => {
                            setShowSideBarForSendMail(false);
                            setContactMailFormData({
                                recipeintIdsList: [],
                                templateId: null,
                                templateName: ""
                            });
                            setContactsForMailList([]);
                            setCount(0);
                            setParams({
                                limit: 100,
                                offSet: 0,
                            })
                            setIsOnlyNew(false);
                            setHasMore(true);
                        }}
                    >
                        <CloseIcon fontSize="small" />
                    </IconButton>
                </Grid>
            </Grid>
            <Divider />
            {(contactsForMailList?.length || searchString?.trim()?.length || debouncedSearchTerm?.trim()?.length || !isOnlyNew || loading?.sidebarLoading) ?
                <>
                    <Box py={1.5} px={2}>
                        <Box position={"relative"} mb={2}
                            sx={{
                                "svg": { color: 'rgba(0, 0, 0, 0.54)', position: 'absolute', left: '0px', top: '3px' },
                                '> div': { width: '100%' },
                                'input': { paddingLeft: '32px' },
                            }}
                        >
                            <SearchRoundedIcon />
                            <SearchBoxInput
                                label=""
                                variant="standard"
                                placeholder="Search Contacts"
                                handleSearch={handleSearch}
                                value={searchString}
                                isUseDebounce={false}
                                isOverwritePlaceholder={true}
                                disabled={(loading?.sidebarLoading || !contactsForMailList?.length) && !(searchString?.trim()?.length || debouncedSearchTerm?.trim()?.length)}
                            />
                        </Box>
                        <Grid
                            item
                            display="flex"
                            justifyContent="space-between"
                            alignItems="center"
                            sx={{ top: "0", zIndex: "1" }}
                        >
                            <FormControlLabel

                                sx={{
                                    margin: 0, padding: 0,
                                    ".MuiCheckbox-root": {
                                        padding: '4px',
                                    },
                                    ".MuiFormControlLabel-label": {
                                        color: 'rgba(38, 38, 38, 0.87)',
                                        fontWeight: '600',
                                        fontSize: '14px',
                                        lineHeight: '18px',
                                        letterSpacing: '0.15px',
                                        marginLeft: "8px",
                                    },
                                }}

                                id={getInputId("select-all-contacts")}
                                name="select-all-contacts"
                                control={<Checkbox size="small" />}
                                checked={contactsForMailList.length !== 0 &&
                                    (contactMailFormData.recipeintIdsList.length === contactsForMailList.length || contactsForMailList.length < contactMailFormData.recipeintIdsList.length) &&
                                    contactsForMailList?.every(data => contactMailFormData?.recipeintIdsList?.includes(data?.id))}
                                label="Select All"
                                disabled={!contactsForMailList.length}
                                onChange={(event, checked) => {
                                    if (checked) {
                                        const selectedContacts = [];
                                        contactsForMailList.forEach((user) => selectedContacts.push(user?.id));
                                        setContactMailFormData(prev => ({ ...prev, recipeintIdsList: [...new Set([...prev?.recipeintIdsList, ...selectedContacts])] }))
                                    } else {
                                        setContactMailFormData(prev => ({ ...prev, recipeintIdsList: prev?.recipeintIdsList?.filter(id => !(contactsForMailList?.map((data => data?.id)))?.includes(id)) }))
                                    }
                                }}
                            />
                            <Stack direction="row" spacing={{ xs: 1.5, lg: 2 }}>
                                <FormControlLabel control={<Switch
                                    name="onlyNew"
                                    id={getInputId("onlyNew")}
                                    size="small"
                                    checked={isOnlyNew}
                                    onChange={(e) => handleChangeNew(e)}
                                    disabled={(!contactsForMailList.length && isOnlyNew) || loading?.sidebarLoading}
                                />}
                                    label="Only New"
                                    sx={{
                                        ".MuiFormControlLabel-label": {
                                            color: 'rgba(38, 38, 38, 0.87)',
                                            fontWeight: '600',
                                            fontSize: '14px',
                                            lineHeight: '18px',
                                            letterSpacing: '0.15px',
                                        }
                                    }}
                                />
                            </Stack>
                        </Grid>
                    </Box>
                    <Divider />
                </> :
                null
            }
            <Box flex={1} overflow="hidden" display="flex" flexDirection={"column"} width="100%" sx={{
                '.infinite-scroll-component__outerdiv': {
                    display: 'flex',
                    overflow: 'hidden',
                    flex: '1',
                    width: '100%',
                },
                'ul': {
                    border: "1px solid #E0E0E0",
                    borderRadius: '3px',
                    'li:last-child': {
                        borderBottom: 'none',
                    }
                }
            }}>
                <InfiniteScroll
                    dataLength={contactsForMailList?.length} // Total number of contacts loaded so far
                    next={geMoreContactList}
                    hasMore={hasMore}
                    style={{
                        scrollBehavior: "smooth",
                        width: '100%',
                        flex: 1,
                        overflow: 'auto',
                    }}
                    height={"100%"}
                    endMessage={contactsForMailList?.length === count && contactsForMailList?.length > 0 ?
                        <Typography p={2.5} fontSize={12} fontWeight={500} color={"dark.800"} textAlign={"center"}>
                            All Contacts have been loaded.
                        </Typography> : null}
                >
                    {loading?.sidebarLoading ?
                        <CircularLoader height="500px" />
                        :
                        <>
                            <Box>
                                {contactsForMailList?.length > 0 ? (
                                    contactsForMailList?.map((userData, index) => (
                                        <List sx={{ p: 0, border: 'none !important' }} key={index}>
                                            <ListItem
                                                sx={{
                                                    padding: "8px 16px",
                                                    background: `${contactMailFormData.recipeintIdsList?.includes(userData?.id) ? "#047FE014" : "transparent"}`
                                                }}
                                            >
                                                <FormControlLabel
                                                    id={getInputId("mail-check")}
                                                    name="mail-check"
                                                    sx={{
                                                        margin: 0, padding: 0,
                                                        height: 28,
                                                        width: 28,
                                                        minWidth: 28,
                                                        ".MuiCheckbox-root": {
                                                            padding: '4px',
                                                            '~ span': {
                                                                '&:before': {
                                                                    position: 'absolute',
                                                                    inset: 0,
                                                                    background: 'rgba(4, 127, 224, 0.08)',
                                                                    content: '""',
                                                                    opacity: '0',
                                                                    visibility: 'hidden',
                                                                    pointerEvents: 'none',
                                                                    transition: 'all 0.25s ease-in-out',
                                                                }
                                                            }
                                                        },
                                                        ".Mui-checked": {
                                                            '~ span': {
                                                                '&:before': {
                                                                    opacity: '1',
                                                                    visibility: 'visible',
                                                                }
                                                            }
                                                        }
                                                    }}
                                                    control={<Checkbox size="small" />}
                                                    checked={contactMailFormData.recipeintIdsList?.includes(userData?.id)}
                                                    label=""
                                                    onChange={(event, checked) => {
                                                        if (checked) {
                                                            setContactMailFormData(prev => ({ ...prev, recipeintIdsList: [...prev.recipeintIdsList, userData?.id] }))
                                                        } else {
                                                            setContactMailFormData(prev => ({ ...prev, recipeintIdsList: prev.recipeintIdsList.filter(id => id !== userData?.id) }))
                                                        }
                                                    }}
                                                />
                                                {/* <BackgroundLetterAvatars
                                                    user={userData}
                                                    sx={{ width: 30, height: 30, fontSize: "16px" }}
                                                /> */}
                                                <Grid item ml={1} flex={1} overflow={"hidden"}>
                                                    <Box display={"flex"} gap={"4px"} overflow={"hidden"}>
                                                        <Typography
                                                            color="dark.800"
                                                            fontSize={14}
                                                            fontWeight={500}
                                                            lineHeight={"20px"}
                                                            letterSpacing={"0.17px"}
                                                            sx={{
                                                                lineHeight: "22px",
                                                                whiteSpace: "nowrap",
                                                                overflow: "hidden",
                                                                textOverflow: "ellipsis",
                                                            }}>
                                                            {getFullName(userData)}
                                                        </Typography>
                                                        {userData?.TransactionCounts?.[0]?.count ? <HtmlTooltip
                                                            title={
                                                                <Typography fontSize={14} fontWeight={500} lineHeight={'21px'} textAlign={"center"}>Last sent on {getApplyDateTime(userData?.TransactionCounts?.[0]?.last_updated_date ?? userData?.TransactionCounts?.[0]?.updatedAt, "hh:mm A, DD/MM/YYYY")}</Typography>
                                                            }>
                                                            <Typography
                                                                display={"inline-flex"}
                                                                alignItems={"center"}
                                                                fontSize={12}
                                                                fontWeight={500}
                                                                fontStyle={"italic"}
                                                                lineHeight={"17px"}
                                                                letterSpacing={"0.17px"}
                                                                whiteSpace={"nowrap"}
                                                                color={"#047FE0"}>
                                                                - {userData?.TransactionCounts?.[0]?.count ?? 0}{" "}{userData?.TransactionCounts?.[0]?.count > 1 ? 'times' : 'time'}
                                                            </Typography>
                                                        </HtmlTooltip> : null}
                                                    </Box>
                                                    <Typography
                                                        fontSize={12}
                                                        fontWeight={400}
                                                        color="dark.800"
                                                        lineHeight={"17px"}
                                                        letterSpacing={"0.17px"}
                                                    >{userData?.email}</Typography>
                                                </Grid>
                                            </ListItem>
                                        </List>
                                    ))
                                ) : (
                                    // <EmptyPage
                                    //     title="No users found"
                                    //     isButtonShow={false}
                                    // />
                                    (searchString?.trim()?.length || debouncedSearchTerm?.trim()?.length || !isOnlyNew || loading?.sidebarLoading || isClearedSearched) ?
                                        <EmptyPage
                                            title="No contacts found"
                                            isButtonShow={false}
                                        />
                                        :
                                        <Box py={"80px"} textAlign={"center"}>
                                            <Box width={95} minWidth={95} maxWidth={95} margin={"0px auto 10px"}>
                                                {ICONS.NoContactEmapty}
                                            </Box>
                                            <Typography color={"dark.800"} fontSize={13} fontWeight={500} lineHeight={"18px"} letterSpacing={"0.17px"} mb={2}>No Contacts Added</Typography>
                                            <Button
                                                variant="contained"
                                                color="primary"
                                                size="medium" sx={{ boxShadow: 'none', textTransform: 'none' }}
                                                onClick={() => navigate(URLS.CampaignsContactList)}>
                                                Create Contact
                                            </Button>
                                        </Box>
                                )}
                            </Box>
                        </>
                    }
                </InfiniteScroll>
                <Divider />
                {contactsForMailList?.length ? <Grid
                    item
                    py={1.5}
                    px={2}
                    display="flex"
                    justifyContent="space-between"
                    alignItems="center"
                >
                    <Typography fontSize={16} fontWeight={500} color={"dark.800"}>{contactMailFormData?.recipeintIdsList?.length} Selected</Typography>
                    <Button
                        startIcon={<SendIcon />}
                        variant='contained'
                        color='primary'
                        size="medium"
                        sx={{ color: 'white', textTransform: 'none', boxShadow: 'none !important' }}
                        onClick={() => handleSendEmailToContacts(false)}
                        disabled={!contactMailFormData.recipeintIdsList.length}
                    >

                        Send Email
                    </Button>
                </Grid> : null}
            </Box>
        </Box>
    );

    return (
        <Box width="100%" bgcolor="white" display="flex" flexDirection="column">
            <Box p={{ xs: 2, lg: 3, xl: 4 }} py={{ xs: 3, lg: 3, xl: 4 }} sx={{ background: '#ffffff' }} flex={1} overflow="auto">
                {(loading?.userRoleLoading || loading?.years || loading.pageLoading) ?
                    <CircularLoader height="500px" /> :
                    (templateData?.length ?
                        <Box >
                            <Grid item display="flex" justifyContent="space-between" alignItems="center" gap={1} pb={2}>
                                <Typography fontSize={16} fontWeight={400} lineHeight={'22.88px'} letterSpacing={'0.17px'}>{templateData?.length} Templates</Typography>
                                <Stack direction="row" spacing={{ xs: 1.5, lg: 2 }}>
                                    <Button
                                        variant='contained'
                                        color='primary'
                                        size="small"
                                        sx={{ color: 'white', textTransform: 'none', boxShadow: 'none !important' }}
                                        onClick={() => navigate(URLS.CreateEmailTemplate)}
                                    >

                                        Create Template
                                    </Button>
                                </Stack>
                            </Grid>
                            <Paper sx={{ width: '100%', borderRadius: '0px', boxShadow: 'none', border: "1px solid #E0E0E0" }}>
                                <TableContainer>
                                    <Table
                                        sx={{ minWidth: 750 }}
                                        aria-labelledby="tableTitle"
                                        size={'small'}
                                    >
                                        <TableHead>
                                            <TableRow sx={{
                                                whiteSpace: 'nowrap',
                                                'th': {
                                                    color: "dark.800",
                                                    fontWeight: 500,
                                                    fontSize: 14,
                                                    lineHeight: "24px",
                                                    letterSpacing: "0.17px",
                                                    bgcolor: "bgColor",
                                                    padding: "5px 16px",
                                                    textAlign: "left",
                                                    background: "rgba(245, 248, 250, 1)",
                                                }
                                            }}>
                                                <TableCell sx={{ width: '80px', maxWidth: '80px', minWidth: '80px', paddingRight: '0px !important' }}>#</TableCell>
                                                <TableCell sx={{ minWidth: '300px' }}>Title</TableCell>
                                                <TableCell sx={{ width: '300px', maxWidth: '300px', minWidth: '300px' }}>Sub Title</TableCell>
                                                <TableCell sx={{ width: '320px', maxWidth: '320px', minWidth: '320px' }}>Created By</TableCell>
                                                <TableCell sx={{ textAlign: 'center', width: '48px', maxWidth: '48px', minWidth: '48px' }}></TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody
                                            sx={{
                                                'td': {
                                                    padding: "7px 16px",
                                                }
                                            }}
                                        >
                                            {templateData?.map((template, index) => {
                                                return (
                                                    <React.Fragment key={index}>
                                                        <TableRow
                                                            hover
                                                            role="checkbox"
                                                            tabIndex={-1}
                                                            sx={{ cursor: 'pointer' }}
                                                        >
                                                            <TableCell>
                                                                <Typography variant='body1' fontSize={14} fontWeight={400} color="dark.800" lineHeight={"20px"} letterSpacing={"0.17px"}>{index + 1}</Typography>
                                                            </TableCell>
                                                            <TableCell>
                                                                <Typography variant='body1' fontSize={14} fontWeight={400} color="dark.800" lineHeight={"20px"} letterSpacing={"0.17px"}>{template?.title}</Typography>
                                                            </TableCell>
                                                            <TableCell>
                                                                <Typography variant='body1' fontSize={14} fontWeight={400} color="dark.800" lineHeight={"20px"} letterSpacing={"0.17px"}>{template?.sub_title}</Typography>
                                                            </TableCell>
                                                            <TableCell>
                                                                <Typography variant='body1' fontSize={14} fontWeight={400} color="dark.800" lineHeight={"20px"} letterSpacing={"0.17px"}>{template?.User?.name ? `${template?.User?.name} (${moment(template?.createdAt).format("hh:mm A, DD/MM/YYYY")})` : "-"}</Typography>
                                                            </TableCell>
                                                            <TableCell sx={{ textAlign: 'center !important' }}>
                                                                {/* <IconButton
                                                                    aria-label="edit"
                                                                    size="small"
                                                                    color="secondary"
                                                                    onClick={() => navigate(`${URLS.UpdateEmailTemplate}/${template?.id}`)}
                                                                >
                                                                    <EditIcon fontSize="small" />
                                                                </IconButton> */}
                                                                <IconButton
                                                                    id="basic-button"
                                                                    aria-controls={open ? 'basic-menu' : undefined}
                                                                    aria-haspopup="true"
                                                                    aria-expanded={open ? 'true' : undefined}
                                                                    onClick={(e) => handleClick(e, template?.id)}
                                                                    sx={{
                                                                        height: "30px",
                                                                        width: "30px",
                                                                        padding: "0px",
                                                                        borderRadius: "4px",
                                                                    }}
                                                                >
                                                                    <i
                                                                        style={{
                                                                            height: "20px",
                                                                            width: "20px",
                                                                            display: "flex",
                                                                        }}
                                                                    >
                                                                        {ICONS.DotsHorizontal}
                                                                    </i>
                                                                </IconButton>
                                                                <Menu
                                                                    id="basic-menu"
                                                                    anchorEl={anchorEl}
                                                                    open={open && openMenuIndex === template?.id}
                                                                    onClose={handleClose}
                                                                    MenuListProps={{
                                                                        'aria-labelledby': 'basic-button',
                                                                    }}
                                                                >
                                                                    <MenuItem onClick={() => navigate(`${URLS.UpdateEmailTemplate}/${template?.id}`)}>Edit</MenuItem>
                                                                    <MenuItem onClick={() => { handleClose(); getAllContactList(template?.id); setShowSideBarForSendMail(true); setContactMailFormData(prev => ({ ...prev, templateId: template?.id, templateName: template?.title })); }}>Send Mail</MenuItem>
                                                                    <MenuItem onClick={() => handleRecieveEmails(template?.id, template?.name)}>Recieve Test Mail</MenuItem>
                                                                </Menu>
                                                            </TableCell>
                                                        </TableRow>
                                                    </React.Fragment>)
                                            })}
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            </Paper>
                        </Box> :
                        <Box bgcolor={"#FAFBFF"} height={151} display={"flex"} flexDirection={"column"} justifyContent={"center"} alignItems={"center"}>
                            <Typography color={"#262626"} mb={"16px"} fontSize={16} fontWeight={400}>No Email Templates created yet</Typography>
                            <Button
                                variant='contained'
                                color='primary'
                                size="medium"
                                sx={{ color: 'white', textTransform: 'none', boxShadow: 'none !important' }}
                                onClick={() => navigate(URLS.CreateEmailTemplate)}
                            >

                                Create Template
                            </Button>
                        </Box>)
                }
            </Box>
            <Drawer open={showSideBarForSendMail} anchor="right" onClose={() => { }}>
                {DrawerList}
            </Drawer>
            <ConfirmationModal
                isOpen={isDeleteIsOpen}
                title={'Template'}
                deletableDataName={deletedTemplateData?.name}
                dataContentName={'Template'}
                handleClose={() => setIsDeleteIsOpen(false)}
                onConfirm={handleSubmitDelete}
                onCancel={() => setIsDeleteIsOpen(false)}
                isButtonLoading={loading.formSubmitting}
                subList={<span>This will remove <b>{deletedTemplateData?.name}</b> from your contact list.</span>}
            />
        </Box>
    );
};

export default EmailTemplates;