/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import API from "../../../../../axios/api";
import axiosInstance from "../../../../../axios";
import useAuthentication from "../../../../../hook/useAuthentication";
import CircularLoader from "../../../../../components/CircularLoader";
import { Box } from "@mui/material";
import ApplyWFHRequestDetails from "./ApplyWFHRequestDetails";
import ApplyWFHRequestSummary from "./ApplyWFHRequest";
import { specialRequestList } from "../../../../../utils";
import { usePageTitle } from "../../../../../hook/useTitle";
import { getAllHolidayData } from "../../../../../redux/slices/leaveDataSlice";
import { useDispatch, useSelector } from "react-redux";

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          {children}
        </Box>
      )}
    </div>
  );
}

const ApplyWFHRequest = () => {
  let initialLeaveData = {
    static_id: null,
    informed_authorities: [],
    wfh_days: [],
    attachments: [],
    description: "",
    apply_date: new Date(),
  };
  const { getCurrentUser } = useAuthentication();
  const currentUser = getCurrentUser();
  const dispatch = useDispatch();
  const { setPageTitle } = usePageTitle();
  const { holidaySettingData } = useSelector((state) => state?.leaveData);
  const [isAppliedLeave, setIsAppliedLeave] = useState(false);
  const [leaveData, setLeaveData] = useState(initialLeaveData);
  const [leaveSnapshot, setLeaveSnapshot] = useState(null);
  const [errors, setErrors] = useState({});
  const [organizationUserList, setOrganizationUserList] = useState([]);
  const [isLoading, setIsLoading] = useState({
    leaveType: currentUser?.organization?.allow_special_credits ?? false,
    users: true,
  })
  const [isAutoDeleted, setIsAutoDeleted] = useState(false);
  const [activeRequestType, setActiveRequestType] = useState([]);
  const [renewalSettingData, setRenewalSettingData] = useState({
    setting_end_date: null,
    setting_start_date: null,
    year: null
  });

  const [halfRequestData, setHalfRequestData] = useState([]);

  useEffect(() => {
    if (!!holidaySettingData?.halfDayLeave?.length || !!holidaySettingData?.halfDayDates?.length) {
      setHalfRequestData([...holidaySettingData?.halfDayLeave, ...holidaySettingData?.halfDayDates?.map(wfhData => {
        return {
          ...wfhData,
          leave_date: wfhData?.date
        }
      })])
    }
  }, [holidaySettingData])

  setPageTitle("Apply WFH / Timing Request");

  const getUserLeaveTypeData = async () => {
    try {
      setIsLoading((prev) => ({ ...prev, leaveType: true }))
      const response = await axiosInstance.get(
        API.getUserSpecialCredits(currentUser?.id)
      );
      if (response.status === 200) {
        setActiveRequestType(response?.data?.result);
        setRenewalSettingData({
          setting_end_date: response?.data?.orgRenewalSetting?.setting_end_date,
          setting_start_date: response?.data?.orgRenewalSetting?.setting_start_date,
          year: response?.data?.orgRenewalSetting?.year
        });
        setIsLoading((prev) => ({ ...prev, leaveType: false }))
      } else {
        setIsLoading((prev) => ({ ...prev, leaveType: false }))
      }
    } catch (error) {
      console.error(error);
      setIsLoading((prev) => ({ ...prev, leaveType: false }))
    }
  };

  const getUserLeaveBalance = async () => {
    try {
      setIsLoading((prev) => ({ ...prev, spanLoading: true }))
      const response = await axiosInstance.get(API.getLeaveBalance(currentUser?.id));
      if (response.status === 200) {
        setLeaveSnapshot(response?.data?.result);
        setIsLoading((prev) => ({ ...prev, spanLoading: false }))
      } else {
        setIsLoading((prev) => ({ ...prev, spanLoading: false }))
      }
    } catch (error) {
      console.error(error);
      setIsLoading((prev) => ({ ...prev, spanLoading: false }))
    }
  };

  const getUserSettingData = async () => {
    try {
      setIsLoading((prev) => ({ ...prev, users: true }))
      const response = await axiosInstance.get(API.organisationUserWithInformationAuthority);
      if (response.status === 200) {
        let reporting_authorities = response?.data?.data?.reporting_authorities;
        let second_reporting_authorities = response?.data?.data?.second_reporting_authorities;
        let defaultUsers = response?.data?.data?.defaultUsers;
        let users = [];
        if (reporting_authorities && reporting_authorities?.length > 0) {
          users.push(...reporting_authorities);
        }
        if (second_reporting_authorities && second_reporting_authorities?.length > 0) {
          users.push(...second_reporting_authorities);
        }
        if (users?.length <= 0) {
          users.push(...defaultUsers);
        }
        setOrganizationUserList(users || []);
        setIsLoading((prev) => ({ ...prev, users: false }))
      } else {
        setIsLoading((prev) => ({ ...prev, users: false }))
      }
    } catch (error) {
      console.error(error);
      setIsLoading((prev) => ({ ...prev, users: false }))
    }
  };

  const getYears = async () => {
    try {
      setIsLoading((prev) => ({ ...prev, holiday: true }))
      const response = await axiosInstance.get(
        `${API.getAllHolidaysYear(currentUser?.organization_id)}`
      );
      if (response.status === 200) {
        let sortedYear = response?.data?.result?.sort((a, b) =>
          a?.year?.toString()?.localeCompare(b?.year?.toString())
        );
        let activeYear = sortedYear?.find(yearData => yearData?.active);
        dispatch(getAllHolidayData({
          organization_id: currentUser?.organization_id,
          year_Id: activeYear?.id || sortedYear[0]?.id,
          user_id: currentUser?.id
        }))
        setIsLoading((prev) => ({ ...prev, holiday: false }))
      }
    } catch (error) {
      console.error(error);
      setIsLoading((prev) => ({ ...prev, holiday: false }))
    }
  }

  useEffect(() => {
    if (currentUser?.id) {
      if (currentUser?.organization?.allow_special_credits) getUserLeaveTypeData();
      getUserSettingData();
      getYears();
      getUserLeaveBalance();
    }
  }, []);

  return ((isLoading?.leaveType || isLoading?.users) ?
    <CircularLoader height="500px" /> :
    <>
      <Box width="100%" bgcolor="white" display="flex" flexDirection="column">
        {/* <Box sx={{ borderBottom: 1, borderColor: "divider", backgroundColor: 'white', position: 'sticky', top: 0, zIndex: 5 }}>
          <Tabs
            value={1}
            aria-label="basic tabs example"
            onChange={(event, tab) => {
              if (tab === 0) {
                navigate(URLS.WorkFromHome)
              }
            }}
          >
            <Tab label="Others’ WFH Requests" {...a11yProps(0)} />
            <Tab label="My WFH Requests" {...a11yProps(1)} />
          </Tabs>
        </Box> */}
        <Box bgcolor="secondary"
          flex={1}
          overflow="auto"
          sx={{
            ".tabspanel > .MuiBox-root": {
              padding: "0px",
            }
          }}
        >
          <CustomTabPanel value={1} index={0} className="tabspanel">
          </CustomTabPanel>
          <CustomTabPanel value={1} index={1} className="tabspanel">
            <Box sx={{
              // padding: "24px! important"
            }}>
              {isAppliedLeave ? (
                <ApplyWFHRequestDetails
                  setIsAppliedLeave={setIsAppliedLeave}
                  setLeaveData={setLeaveData}
                  leaveData={leaveData}
                  setErrors={setErrors}
                  errors={errors}
                  userLeaveTypes={specialRequestList}
                  organizationUserList={organizationUserList}
                  currentUser={currentUser}
                  setIsAutoDeleted={setIsAutoDeleted}
                  isAutoDeleted={isAutoDeleted}
                  activeRequestType={activeRequestType}
                  leaveSnapshot={leaveSnapshot}
                  renewalSettingData={renewalSettingData}
                  setHalfRequestData={setHalfRequestData}
                  halfRequestData={halfRequestData}
                />
              ) : (
                <ApplyWFHRequestSummary
                  setIsAppliedLeave={setIsAppliedLeave}
                  setLeaveData={setLeaveData}
                  leaveData={leaveData}
                  setErrors={setErrors}
                  errors={errors}
                  userLeaveTypes={specialRequestList}
                  organizationUserList={organizationUserList}
                  setIsAutoDeleted={setIsAutoDeleted}
                  isAutoDeleted={isAutoDeleted}
                  activeRequestType={activeRequestType}
                  renewalSettingData={renewalSettingData}
                  setHalfRequestData={setHalfRequestData}
                  halfRequestData={halfRequestData}
                />
              )}
            </Box>
          </CustomTabPanel>
        </Box>
      </Box>
    </>
  );
};

export default ApplyWFHRequest;
