import { ReactComponent as Dashboard } from "../assets/images/Dashboard.svg";
import { ReactComponent as Projects } from "../assets/images/Projects.svg";
import { ReactComponent as Leaves } from "../assets/images/Leaves.svg";
import { ReactComponent as Request } from "../assets/images/Request.svg";
import { ReactComponent as Users } from "../assets/images/Users.svg";
import { ReactComponent as Holidays } from "../assets/images/Holidays.svg";
import { ReactComponent as Administrator } from "../assets/images/Administrator.svg";
import { ReactComponent as RightArrow } from "../assets/images/RightArrow.svg";
import { ReactComponent as LeftArrow } from "../assets/images/LeftArrow.svg";
import { ReactComponent as DownArrow } from "../assets/images/DownArrow.svg";
import { ReactComponent as DummyPhoto } from "../assets/images/dummy-photo.svg";
import { ReactComponent as LoginBgRight } from "../assets/images/logo-bg-right.svg";
import { ReactComponent as Logo } from "../assets/images/logo.svg";
import { ReactComponent as LoginBg } from "../assets/images/login-bg.svg";
import { ReactComponent as BallIcon } from "../assets/images/bell-icon.svg";
import { ReactComponent as WhiteLogo } from "../assets/images/logo-white.svg";
import { ReactComponent as MenuIcon } from "../assets/images/menu-icon.svg";
import { ReactComponent as Logout } from "../assets/images/Logout.svg";
import { ReactComponent as User } from "../assets/images/user.svg";
import { ReactComponent as Other } from "../assets/images/other.svg";
import { ReactComponent as SickLeave } from "../assets/images/Sick Leave.svg";
import { ReactComponent as SystemGenerated } from "../assets/images/System Generated.svg";
import { ReactComponent as SystemGeneratedLeave } from "../assets/images/SystemGenLeave.svg";
import { ReactComponent as ChevronLineSmall } from "../assets/images/chevron-line-icon.svg";
import { ReactComponent as Attachment } from "../assets/images/Attachment.svg";
import { ReactComponent as DotsVertical } from "../assets/images/Dots-Vertical.svg";
import { ReactComponent as Info } from "../assets/images/Info.svg";
import { ReactComponent as Message } from "../assets/images/Message.svg";
import { ReactComponent as SummarizeIcon } from "../assets/images/SummarizeIcon.svg";
import { ReactComponent as ReportIcon } from "../assets/images/Report.svg";
import { ReactComponent as LeaveChairIcon } from "../assets/images/leave-chair.svg";
import { ReactComponent as CakeIcon } from "../assets/images/cake.svg";
import { ReactComponent as PhoneIcon } from "../assets/images/phone.svg";
import { ReactComponent as TeamMeetingIcon } from "../assets/images/team-meeting.svg";
import { ReactComponent as MealIcon } from "../assets/images/meal.svg";
import { ReactComponent as TeaBreakIcon } from "../assets/images/tea-break.svg";
import { ReactComponent as PDFIcon } from "../assets/images/pdf.svg";
import { ReactComponent as AddIcon } from "../assets/images/add-circle.svg";
import { ReactComponent as CheckIcon } from "../assets/images/check-icon.svg";
import { ReactComponent as CrossIcon } from "../assets/images/cross-icon.svg";
import { ReactComponent as ExternalLinkIcon } from "../assets/images/external-link-icon.svg";
import { ReactComponent as RepoteesIcon } from "../assets/images/repotees-icon.svg";
import { ReactComponent as DotsHorizontal } from "../assets/images/Dots-Horizontal.svg";
import { ReactComponent as StarFillIcon } from "../assets/images/star-fill.svg";
import { ReactComponent as StarLineIcon } from "../assets/images/star-line.svg";
import { ReactComponent as DesktopIcon } from "../assets/images/desktop-icon.svg";
import { ReactComponent as AiIcon } from "../assets/images/ai-generated-icon.svg";
import { ReactComponent as Close } from "../assets/images/Close.svg";
import { ReactComponent as LockIcon } from "../assets/images/lock-icon.svg";
import { ReactComponent as ApprovedIcon } from "../assets/images/approved-icon.svg";
import { ReactComponent as PendingIcon } from "../assets/images/pending-icon.svg";
import { ReactComponent as RemoteIcon } from "../assets/images/RemoteIcon.svg";
import { ReactComponent as OfficeIcon } from "../assets/images/OfficeIcon.svg";
import { ReactComponent as TillNowClockIcon } from "../assets/images/till-now-clock.svg";
import { ReactComponent as NoContact } from "../assets/images/NoContact.svg";
import { ReactComponent as NoContactEmapty } from "../assets/images/no-contact.svg";
import { ReactComponent as CreditOver } from "../assets/images/credit-over.svg";

const ICONS = {
    Dashboard: <Dashboard />,
    Projects: <Projects />,
    Leaves: <Leaves />,
    Request: <Request />,
    Users: <Users />,
    Holidays: <Holidays />,
    Administrator: <Administrator />,
    RightArrow: <RightArrow />,
    LeftArrow: <LeftArrow />,
    DownArrow: <DownArrow />,
    DummyPhoto: <DummyPhoto />,
    LoginBgRight: <LoginBgRight />,
    Logo: <Logo />,
    LoginBg: <LoginBg />,
    BallIcon: <BallIcon />,
    WhiteLogo: <WhiteLogo />,
    MenuIcon: <MenuIcon />,
    Logout: <Logout />,
    User: <User />,
    Other: <Other />,
    SickLeave: <SickLeave />,
    SystemGenerated: <SystemGenerated />,
    SystemGeneratedLeave: <SystemGeneratedLeave />,
    Close: <Close />,
    ChevronLineSmall: <ChevronLineSmall />,
    Attachment: <Attachment />,
    DotsVertical: <DotsVertical />,
    Info: <Info />,
    SummarizeIcon: <SummarizeIcon />,
    ReportIcon: <ReportIcon />,
    Message: <Message />,
    LeaveChairIcon: <LeaveChairIcon />,
    CakeIcon: <CakeIcon />,
    PhoneIcon: <PhoneIcon />,
    TeamMeetingIcon: <TeamMeetingIcon />,
    MealIcon: <MealIcon />,
    TeaBreakIcon: <TeaBreakIcon />,
    PDFIcon: <PDFIcon />,
    AddIcon: <AddIcon />,
    CheckIcon: <CheckIcon />,
    CrossIcon: <CrossIcon />,
    ExternalLinkIcon: <ExternalLinkIcon />,
    RepoteesIcon: <RepoteesIcon />,
    DotsHorizontal: <DotsHorizontal />,
    StarFillIcon: <StarFillIcon />,
    StarLineIcon: <StarLineIcon />,
    DesktopIcon: <DesktopIcon />,
    AiIcon: <AiIcon />,
    LockIcon: <LockIcon />,
    ApprovedIcon: <ApprovedIcon />,
    PendingIcon: <PendingIcon />,

    RemoteIcon: <RemoteIcon />,
    OfficeIcon: <OfficeIcon />,
    NoContact: <NoContact />,
    TillNowClockIcon: <TillNowClockIcon />,
    NoContactEmapty: <NoContactEmapty />,

    CreditOver: <CreditOver />
}

export default ICONS;