import React, { useEffect, useState } from "react";
import API from "../../../axios/api";
import axiosInstance from "../../../axios";
import { Box, Link, Stack, Typography } from "@mui/material";
import ICONS from "../../../constants/icons";
import { Link as RouteLink } from "react-router-dom";
import URLS from "../../../routes/urls";
import useAuthentication from "../../../hook/useAuthentication";
import { ON_BOARDING_STATUS } from "../../../constants/default-values";

const SystemGeneratedLeaveNotification = () => {
    const [leaveData, setLeaveData] = useState([]);
    const [isShowNotification, setIsShowNotification] = useState(false);
    const { getCurrentUser, isAuthenticated } = useAuthentication();
    const currentUser = getCurrentUser();

    useEffect(() => {
        if (isAuthenticated() && currentUser?.user_type !== "super_admin" && currentUser?.organization?.onboarding_status === ON_BOARDING_STATUS.completed) {
            fetchSystemGeneratedLeaveData();
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const fetchSystemGeneratedLeaveData = async () => {
        try {
            const response = await axiosInstance.get(API.getSystemGeneratedLeaveList);
            if (response.status === 200) {
                const data = response?.data?.data;
                setLeaveData(data?.filter((value, index, array) => value?.is_auto_generated));
                setIsShowNotification(data?.length > 0);
            }
        } catch (error) {
            console.error("Error fetching system generated leave data:", error);
        }
    };
    const getLeaveCount = (data) => {
        let totalCount = data?.reduce((previousValue, currentValue) => {
            return previousValue + currentValue?.days
        }, 0);
        return totalCount;
    };

    return (isShowNotification ?
        <Box pb={2}>
            <Stack spacing={2} direction={"row"} justifyContent={"space-between"} alignItems={"center"} sx={{ width: "100%", backgroundColor: "#047FE01A", borderRadius: "4px", p: "13px" }}>
                <Box>
                    <Typography fontSize={14} fontWeight={400} color={"#3F5C76"}>System generated <b>{getLeaveCount(leaveData)} Casual leaves </b>due to your unavailability. Please check in details.</Typography>
                </Box>
                <Stack direction={"row"} justifyContent={"space-between"} alignItems={"center"} spacing={2}>
                    <Link
                        component={RouteLink}
                        to={`${URLS.Leaves}?view=list&tabValue=1&groupBy=Status&status=past`}
                        sx={{ fontSize: '13px', fontWeight: 500, whiteSpace: 'nowrap', cursor: 'pointer', textDecoration: "none", color: "primary.main" }}
                    >
                        Go to leaves
                    </Link>
                    <Box sx={{ cursor: "pointer" }} onClick={() => setIsShowNotification(!isShowNotification)}>
                        {ICONS.Close}
                    </Box>
                </Stack>
            </Stack>
        </Box>
        : null);
};

export default SystemGeneratedLeaveNotification;
