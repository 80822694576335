import React, { useState } from "react";
import { usePageTitle } from "../../../../hook/useTitle";
import {
  Box,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormHelperText,
  Grid,
  MenuItem,
  Radio,
  RadioGroup,
  Typography,
} from "@mui/material";
import Input from "../../../../components/Input";
import { convertDateFromFormat, getFormattedDate, titleCase } from "../../../../utils";
import Button from "../../../../components/Button";
import { PROJECT_STATUS } from "../../../../constants/default-values";
import API from "../../../../axios/api";
import useAuthentication from "../../../../hook/useAuthentication";
import axiosInstance from "../../../../axios";
import { useAlert } from "../../../../hook/useAlert";
import SingleDatePicker from "../../../../components/SingleDatePicker";
import CalendarTodayIcon from "@mui/icons-material/CalendarToday";
import { checkProjectUniqueName } from "../../../../axios/service";
import { useNavigate } from "react-router-dom";
import moment from "moment";
import { useSelector } from "react-redux";

const CustomDatePicker = ({
  value,
  onChange,
  label,
  minDate,
  maxDate,
  onOpen,
  onClose,
  error = null,
  ...rest
}) => {
  return (
    <Grid
      item
      flex={1}
      sx={{
        input: {
          width: "100%",
          height: 30,
          border: "none !important",
          boxShadow: "none !important",
          borderRadius: "0px",
          paddingBottom: "6px",
          borderBottom: "1px solid rgba(0, 0, 0, 0.42) !important",
          margin: "0",
          position: "relative",
          background: "transparent",
          color: "dark.800",
          fontSize: 16,
          fontWeight: 400,
          lineHeight: "24px",
          letterSpacing: "0.15px",
          paddingLeft: "0px",
        },
        "input:hover": {
          borderBottomColor: "#333333 !important",
        },
        "input:focus": {
          borderBottomColor: "#047FE0 !important",
        },
        ".rmdp-container": {
          minWidth: "100px",
        },
      }}
    >
      <div
        style={{
          position: "absolute",
          right: "0px",
          top: "20px",
          marginTop: "0px",
        }}
      >
        <CalendarTodayIcon
          sx={{
            fontSize: 20,
            marginBottom: "-5px",
            marginLeft: "2px",
            color: "rgba(0, 0, 0, 0.54)",
          }}
        />
      </div>
      <Typography
        id="demo-simple-select-standard-label"
        fontSize={12}
        fontWeight={400}
        color="dark.600"
        sx={{
          width: "100%",
          letterSpacing: "0.15px",
          marginTop: "-3px",
          marginBottom: "2px",
        }}
      >
        {titleCase(label)}
      </Typography>
      <SingleDatePicker
        value={value}
        onChange={onChange}
        isMultiple={false}
        onOpen={onOpen}
        minDate={minDate}
        maxDate={maxDate}
        onClose={onClose}
        {...rest}
      />
      {!!error ? (
        <Typography sx={{ color: "red.main", fontSize: "12px" }}>{error}</Typography>
      ) : null}
    </Grid>
  );
};

const ProjectDetails = ({ projectDetail, setProjectDetails }) => {
  const { setPageTitle } = usePageTitle();
  const { getCurrentUser } = useAuthentication();
  const navigate = useNavigate();
  const showAlert = useAlert();
  const currentUser = getCurrentUser();
  setPageTitle("Projects");
  const [errors, setErrors] = useState({});
  const [isLoading, setIsLoading] = useState({
    formSubmitting: false,
  });
  const { ignoreLeaveDates } = useSelector((state) => state?.leaveData);

  const [projectData, setProjectData] = useState({
    ...projectDetail,
    start_date: projectDetail?.start_date !== null ? new Date(moment(projectDetail?.start_date, "YYYY-MM-DD")) : null,
  });
  const [optionalProjectData, setOptionalProjectData] = useState({
    ...projectDetail,
    start_date: projectDetail?.start_date !== null ? new Date(moment(projectDetail?.start_date, "YYYY-MM-DD")) : null,
  });

  const getIgnoreDates = () => {
    let array = [];
    if (!!ignoreLeaveDates?.leaves?.length) {
      array.push(...ignoreLeaveDates?.leaves);
    }
    if (!!ignoreLeaveDates?.custom_holidays?.length) {
      array.push(...ignoreLeaveDates?.custom_holidays?.map(value => value?.date));
    }
    if (!!ignoreLeaveDates?.festival_holidays?.length) {
      array.push(...ignoreLeaveDates?.festival_holidays?.map(value => value?.date));
    }
    if (!!ignoreLeaveDates?.saturdayHolidays?.length) {
      array.push(...ignoreLeaveDates?.saturdayHolidays?.map(value => value?.date));
    }
    if (!!ignoreLeaveDates?.sundayHolidays?.length) {
      array.push(...ignoreLeaveDates?.sundayHolidays?.map(value => value?.date));
    }
    if (!!ignoreLeaveDates?.inactive_holidays?.length) {
      array.push(...ignoreLeaveDates?.inactive_holidays?.map(value => value?.date));
    }
    if (!!ignoreLeaveDates?.disabledDates?.length) {
      array.push(...ignoreLeaveDates?.disabledDates?.map(value => convertDateFromFormat(value)));
    }
    return Array.from(new Set(array));
  }

  const validateProjectData = (projectData) => {
    let error = { ...errors };
    if (!projectData?.title?.length) {
      error["title"] = "Required";
    }
    if (!projectData?.start_date && PROJECT_STATUS['Not Started'] !== projectData?.status) {
      error["start_date"] = "Required";
    }
    if (!projectData?.short_code?.length) {
      error["short_code"] = "Required";
    }
    if (!projectData?.description?.length) {
      error["description"] = "Required";
    }
    setErrors(error);
    return Object?.values(error)?.some((value) => value?.length);
  };

  const handleChange = (event) => {
    let { name, value } = event.target;

    if (name === "type") {
      setProjectData((prev) => ({
        ...prev,
        [name]: value
      }));
    } else if (name === "short_code") {
      if (value.length < 2) {
        setErrors((prev) => ({
          ...prev,
          [name]: "Short code must be at least 2 characters.",
        }));
      } else if (value.length > 6) {
        setErrors((prev) => ({
          ...prev,
          [name]: "Short code must not exceed 6 characters.",
        }));
      } else {
        setErrors((prev) => ({
          ...prev,
          [name]: "",
        }));
      }
      setProjectData((prev) => ({
        ...prev,
        [name]: value,
      }));
    }
    else {
      setProjectData((prev) => ({
        ...prev,
        [name]: value,
      }));
      setErrors((prev) => ({
        ...prev,
        [name]: !value ? "Required" : "",
        start_date: name === 'status' ? PROJECT_STATUS['Not Started'] === value ? '' : prev?.start_date ?? '' : prev?.start_date ?? ''
      }));
    }
  };

  const handleBlur = async (e) => {
    let { name, value } = e.target;
    if (name === "short_code") {
      if (value.length < 2) {
        setErrors((prev) => ({
          ...prev,
          [name]: "Short code must be at least 2 characters.",
        }));
      } else if (value.length > 6) {
        setErrors((prev) => ({
          ...prev,
          [name]: "Short code must not exceed 6 characters.",
        }));
      } else {
        setErrors((prev) => ({
          ...prev,
          [name]: "",
        }));
      }
    } else {
      setErrors((prev) => ({
        ...prev,
        [name]: !value ? "Required" : "",
      }));
    }
  };

  const isValidDateFormat = (date) => {
    return new Date(date).toString() !== "Invalid date";
  };

  const handleChangeProjectstart_date = (date, name) => {
    setProjectData((prev) => ({
      ...prev,
      [name]: isValidDateFormat(date) ? new Date(date) : null,
    }));
    setErrors((prev) => ({
      ...prev,
      [name]: !isValidDateFormat(date) && PROJECT_STATUS['Not Started'] !== projectData?.status ? "Required" : "",
    }));
  };

  const handleSubmit = async (event) => {
    try {
      event.preventDefault();
      if (!validateProjectData(projectData)) {
        setIsLoading((prev) => ({ ...prev, formSubmitting: true }));
        let payload = {
          title: projectData?.title,
          type: projectData?.type,
          status: projectData?.status,
          short_code: projectData?.short_code,
          is_private: projectData?.is_private,
          description: projectData?.description,
          org_id: currentUser?.organization_id,
          start_date: getFormattedDate(projectData?.start_date, "YYYY-MM-DD"),
        };
        if (PROJECT_STATUS['Not Started'] === projectData?.status && payload?.start_date === "Invalid date") {
          delete payload["start_date"]
        }
        const response = await axiosInstance.put(
          API.updateProject(projectData?.id),
          payload
        );
        if (response?.status === 200) {
          showAlert(response.data?.message);
          setProjectDetails((prev) => ({ ...projectData }));
          setOptionalProjectData((prev) => ({ ...prev, title: projectData?.title }))
        }
        setIsLoading((prev) => ({ ...prev, formSubmitting: false }));
      }
    } catch (error) {
      setIsLoading((prev) => ({ ...prev, formSubmitting: false }));
      console.error(error);
    }
  };

  const handleCheckUniqueProjectName = async (title, id) => {
    try {
      if (title !== optionalProjectData?.title) {
        const response = await checkProjectUniqueName(title, id);
        if (response?.isExist) {
          setErrors((errors) => ({
            ...errors,
            title: "Project name is already exist, choose another name.",
          }));
          return true;
        }
        return false;
      }
    } catch (error) {
      console.error(error);
    }
  }

  return (
    <Box width="100%" bgcolor="white" display="flex" flexDirection="column">
      <Box
        p={{ xs: 3, lg: 4, xl: 4 }}
        py={{ xs: 3, lg: 4, xl: 4 }}
        sx={{ background: "#ffffff" }}
        // overflow="auto"
        className="project-detail-box"
      >
        <Grid
          container
          display={"flex"}
          justifyContent={"space-between"}
          alignItems={"flex-start"}
          mb={2}
        >
          <Grid item>
            <Typography
              variant="h6"
              fontSize={20}
              fontWeight={400}
              color="dark.800"
              sx={{ lineHeight: "30px", letterSpacing: "0.15px" }}
            >
              Project details
            </Typography>
          </Grid>
        </Grid>
        <form onSubmit={handleSubmit} className="step-form-1">
          <Box
            direction="row"
            display="flex"
            flexWrap="wrap"
            width={780}
            maxWidth="100%"
          >
            <Box
              sx={{
                display: "grid",
                gap: 4,
                gridTemplateColumns: "repeat(2, 1fr)",
              }}
              mb={4}
              width={"100%"}
            >
              <Grid item>
                <Input
                  id="title"
                  label="Project Name"
                  variant="standard"
                  placeholder="Project Name"
                  type="text"
                  name={"title"}
                  fullWidth
                  required={false}
                  value={titleCase(projectData?.title)}
                  onBlur={(e) => { handleBlur(e); handleCheckUniqueProjectName(e?.target?.value, projectData?.id) }}
                  onChange={handleChange}
                  error={!!errors?.title}
                  helperText={errors?.title}
                />
              </Grid>

              <Grid item>
                <Input
                  id="short_code"
                  label="Short Code"
                  variant="standard"
                  placeholder="Short Code"
                  type="text"
                  name={"short_code"}
                  fullWidth
                  required={false}
                  value={titleCase(projectData?.short_code)}
                  onBlur={handleBlur}
                  onChange={handleChange}
                  error={!!errors?.short_code}
                  helperText={errors?.short_code}
                />
              </Grid>

              <Grid item>
                <Input
                  id="status"
                  name={"status"}
                  select
                  type="select"
                  label="Status"
                  defaultValue="In-Office"
                  variant="standard"
                  sx={{ width: "100%" }}
                  onChange={handleChange}
                  value={projectData?.status}
                  required={false}
                >
                  {Object.entries(PROJECT_STATUS)?.map((option) => (
                    <MenuItem key={option[0]} value={option[1]}>
                      {option[0]}
                    </MenuItem>
                  ))}
                </Input>
              </Grid>
              <Grid item>
                <Grid item width={"100%"} position={"relative"}>
                  <CustomDatePicker
                    ignoreDates={getIgnoreDates() ?? []}
                    value={projectData?.start_date ? projectData?.start_date : null}
                    onChange={(date) =>
                      handleChangeProjectstart_date(date, "start_date")
                    }
                    label={PROJECT_STATUS['Not Started'] === projectData?.status ? "Start Date (optional)" : "Start Date"}
                    error={errors?.start_date}
                    placeholder={'Select date'}
                    maxDate={moment(projectData?.start_date, "YYYY-MM-DD").subtract(1, "days").toDate()}
                  />
                </Grid>
              </Grid>

              <Grid item>
                <Typography
                  color="text.dark"
                  fontSize={14}
                  sx={{ lineHeight: "24px" }}
                >
                  Type
                </Typography>
                <FormControl component="fieldset">
                  <RadioGroup
                    row
                    name="type"
                    defaultValue="fixed"
                    value={projectData?.type}
                    onChange={handleChange}
                  >
                    <FormControlLabel
                      value="fixed"
                      control={<Radio size="small" />}
                      label="Fixed"
                      sx={{ color: "dark.800" }}
                    />
                    <FormControlLabel
                      value="dedicated"
                      control={<Radio size="small" />}
                      label="Dedicated"
                      sx={{ color: "dark.800" }}
                    />
                  </RadioGroup>
                  <FormHelperText
                    error={false}
                    sx={{ p: 0, m: 0 }}
                  ></FormHelperText>
                </FormControl>
              </Grid>

              <Grid item>
                <Typography
                  color="text.dark"
                  fontSize={14}
                  sx={{ lineHeight: "24px" }}
                >
                  Access
                </Typography>
                <Box display={"flex"} alignItems={"center"}>
                  <FormControlLabel
                    id="is_private"
                    name="is_private"
                    value="end"
                    control={
                      <Checkbox
                        checked={projectData?.is_private}
                        size="small"
                      />
                    }
                    label="Private"
                    labelPlacement="end"
                    sx={{
                      ".MuiCheckbox-sizeMedium": {
                        minWidth: "42px",
                      },
                    }}
                    // disabled={!isEditable || userData?.is_general_user || userData?.is_primary || isDisabledCheckBox(userData, "is_admin") || false}
                    onChange={(event, checked) =>
                      setProjectData((prev) => ({
                        ...prev,
                        is_private: checked,
                      }))
                    }
                  />
                  <Typography
                    color="text.dark"
                    fontSize={12}
                    fontWeight={400}
                    lineHeight={"18px"}
                    letterSpacing={"0.15px"}
                  >
                    (Access only to assigned team members)
                  </Typography>
                </Box>
              </Grid>
            </Box>
            <Grid width={"100%"} maxWidth={"100%"}>
              <Input
                id="description"
                name="description"
                label="Description"
                variant="standard"
                placeholder="description"
                type="text"
                fullWidth
                required={false}
                onChange={(e) => handleChange(e, "description")}
                onBlur={handleBlur}
                multiline={true}
                value={projectData?.description}
                error={!!errors?.["description"]}
                helperText={errors?.["description"]}
              />
            </Grid>
          </Box>
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            mt={6}
          >
            <Box
              display="flex"
              justifyContent="flex-start"
              alignItems="center"
              flexBasis="100%"
              gap={2}
            >
              <Button
                variant="contained"
                size="large"
                type="submit"
                sx={{
                  boxShadow: "0",
                  fontSize: "15px",
                  textTransform: "none",
                  color: "white",
                  "&:hover": { boxShadow: "0" },
                }}
                disabled={isLoading.formSubmitting || Boolean(
                  Object.values(errors)?.some((value) => !!value?.length)
                )}
                isButtonLoading={isLoading.formSubmitting}
              >
                Update
              </Button>
              <Button
                variant="text"
                color="secondary"
                sx={{ textTransform: "none" }}
                size="medium"
                fontWeight="500"
                onClick={() => navigate(-1)}
                disabled={isLoading.formSubmitting}
              >
                Cancel
              </Button>
            </Box>
          </Box>
        </form>
      </Box>
    </Box>
  );
};

export default ProjectDetails;
