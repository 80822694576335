/* eslint-disable react-hooks/exhaustive-deps */
import React, { forwardRef, useEffect, useImperativeHandle, useState } from "react";
import {
  Box,
  Checkbox,
  FormControlLabel,
  Grid,
  Tab,
  Tabs,
  Typography,
  Tooltip,
  MenuItem,
  FormControl,
  Radio,
  RadioGroup,
  FormHelperText,
  Stack,
  tooltipClasses,
} from "@mui/material";
import Button from "../../../../components/Button";
import { ON_BOARDING_STEPS } from "../../../../constants/default-values";
import axiosInstance from "../../../../axios";
import API from "../../../../axios/api";
import { useAlert } from "../../../../hook/useAlert";
import URLS from "../../../../routes/urls";
import { useNavigate } from "react-router-dom";
import LeaveTypeForm from "./LeaveTypeForm";
import { generateUniqueId, getMissingKeys, validateObject, isValidDays, titleCase, sortArrayByKey, secondsToTime, timeToHours } from "../../../../utils";
import CircularLoader from "../../../../components/CircularLoader";
import Input from "../../../../components/Input";
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import { usePageTitle } from "../../../../hook/useTitle";
import { setUserOnBoardingStep } from "../../../../redux/slices/userSlice";
import { useDispatch } from "react-redux";
import { styled } from "@mui/styles";

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          {children}
        </Box>
      )}
    </div>
  );
}

const constantTypes = {
  work_type_settings: [],
  leaveTypes: [],
  specialRequestTypes: [],
};

const LeaveSettingStep = forwardRef(({ setOnBoardingStep, currentUser, isEditField, setIsEditField, handleShowSaveModal }, ref) => {
  const showAlert = useAlert();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { setPageTitle } = usePageTitle();
  const [tabValue, setTabValue] = useState(0);
  const [formData, setFormData] = useState({});
  const [initialData, setInitialData] = useState({});
  const [loading, setLoading] = useState({
    formSubmitting: false,
    pageLoading: false,
  });
  const [constantType, setConstantType] = useState(constantTypes);
  const [errors, setErrors] = useState([]);
  const [isAnyError, setIsAnyError] = useState(false);
  let initialLeaveTypeData = {
    id: null,
    leave_type_id: null,
    monthly_days: null,
    advance_days: null,
    max_leave_encashment: null,
  };
  let months = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"]
  setPageTitle("Configure Leaves Management");

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading((prev) => ({ ...prev, pageLoading: true }));
        const [
          leaveSettingResponse,
          workTypesResponse,
          leaveTypesResponse,
          specialRequestTypesResponse,
        ] = await Promise.all([
          currentUser?.organization_id
            ? axiosInstance.get(
              API.organisationLeaveSettings(currentUser.organization_id)
            )
            : null,
          axiosInstance.get(API.getOrganisationWorkTypes),
          axiosInstance.get(API.getLeaveTypes),
          axiosInstance.get(API.getLeaveSpecialRequests),
        ]);

        if (workTypesResponse.status === 200) {
          let workTypeData = workTypesResponse?.data.data;
          workTypeData?.sort((a, b) =>
            a?.name?.localeCompare(b?.name)
          );
          setConstantType((prev) => ({
            ...prev,
            work_type_settings: workTypeData?.map(data => ({ ...data, isChecked: false, is_changed: false, canDelete: false, external: data?.external })),
          }));
        } else {
          showAlert(workTypesResponse.data?.message, "error");
        }

        if (leaveTypesResponse.status === 200) {
          setConstantType((prev) => ({
            ...prev,
            leaveTypes: leaveTypesResponse.data.data,
          }));
        } else {
          showAlert(leaveTypesResponse.data?.message, "error");
        }

        if (specialRequestTypesResponse.status === 200) {
          setConstantType((prev) => ({
            ...prev,
            specialRequestTypes: specialRequestTypesResponse.data.data,
          }));
        } else {
          showAlert(specialRequestTypesResponse.data?.message, "error");
        }

        if (leaveSettingResponse && leaveSettingResponse.status === 200) {
          setInitialData(leaveSettingResponse.data.data);
          setFormData(leaveSettingResponse.data.data);
          handleCreateDataWorkType(
            leaveSettingResponse.data.data,
            workTypesResponse.data.data,
            leaveTypesResponse.data.data,
            specialRequestTypesResponse.data.data
          );
        } else if (leaveSettingResponse) {
          showAlert(leaveSettingResponse.data?.message, "error");
        }
        setLoading((prev) => ({ ...prev, pageLoading: false }));
      } catch (error) {
        console.error(error);
        setLoading((prev) => ({ ...prev, pageLoading: false }));
      }
    };

    fetchData();
  }, [currentUser?.organization_id]);

  useImperativeHandle(ref, () => ({
    handleSubmitLeaveSetting: () => handleSubmitLeaveSetting(ON_BOARDING_STEPS.step1)
  }));

  const handleCreateDataWorkType = (
    initialData,
    workType,
    leaveType,
    specialRequest
  ) => {
    let constantWorkType = [];

    let errorObject = [];
    const updatedFormData = {
      ...initialData,
      leave_frequency: initialData?.leave_frequency || "monthly",
      isLeaveSettings: initialData?.isLeaveSettings || false,
      allow_special_credits: initialData?.allow_special_credits || false,
      work_type_settings: initialData?.work_type_settings?.map((workTypeData, index) => {
        // let getInitialWorkTypeData = initialData?.work_type_settings?.find((value) => value?.name === workTypeData?.name)
        // constantWorkType.push({ id: workTypeData?.id, name: workTypeData?.name, isChecked: true });
        constantWorkType.push({ id: workTypeData?.id, name: workTypeData?.name, is_changed: false, isChecked: workTypeData?.active, canDelete: workTypeData?.canDelete, isActive: workTypeData?.active, external: workTypeData?.external });
        let leaves = workTypeData?.leave_type_settings?.filter((leave, index) => leave?.leave_type_id)?.map((leave, index) => {
          return {
            ...leave,
            monthly_days: initialData?.leave_frequency === "monthly" ? leave?.monthly_days * 12 : leave?.monthly_days,
            is_visible: true,
            unique_id: generateUniqueId(),
            is_changed: false,
          }
        });
        let spacialReq = workTypeData?.special_requests?.filter((leave, index) => !!leave?.allotment)?.map((leave, index) => {
          return {
            ...leave,
            is_visible: true,
            unique_id: generateUniqueId(),
            is_changed: false,
          }
        });
        if (leaves?.length < leaveType?.length && leaves?.length > 0) {
          leaves.push({
            ...initialLeaveTypeData,
            is_visible: true,
            unique_id: generateUniqueId(),
          });
        } else if (leaves?.length === 0) {
          leaves.push({
            ...initialLeaveTypeData,
            is_visible: true,
            unique_id: generateUniqueId(),
          });
        }
        if (spacialReq?.length < specialRequest?.length && spacialReq?.length > 0) {
          spacialReq.push({
            id: null,
            name: null,
            allotment: null,
            is_visible: true,
            unique_id: generateUniqueId(),
            is_changed: false,
          });
        } else if (spacialReq?.length === 0) {
          spacialReq.push({
            id: null,
            name: null,
            allotment: null,
            is_visible: true,
            unique_id: generateUniqueId(),
            is_changed: false,
          });
        }
        if (workTypeData?.active) {
          errorObject.push({
            id: workTypeData?.id,
            name: workTypeData?.name,
            max_monthly_leave_for_dd: !!workTypeData?.max_monthly_leave_for_dd ? "" : "Required",
            leave_type_settings: leaves?.map((value, index, array) => {
              return {
                id: value?.id,
                leave_type_id: !!value?.leave_type_id || (index !== 0 && array.length - 1) ? "" : "Required",
                monthly_days: !!value?.monthly_days || (index !== 0 && array.length - 1) ? "" : "Required",
                advance_days: value?.advance_days >= 0 || (index !== 0 && array.length - 1) ? "" : "Required",
                max_leave_encashment: value?.max_leave_encashment >= 0 || (index !== 0 && array.length - 1) ? "" : "Required",
                is_visible: true,
                unique_id: generateUniqueId(),
              }
            }),
            special_requests: spacialReq?.map((value, index, array) => {
              return {
                id: value?.id,
                name: !!value?.name || (index !== 0 && array.length - 1) ? "" : array?.length === 1 ? "" : "Required",
                allotment: !!value?.allotment || (index !== 0 && array.length - 1) ? "" : array?.length === 1 ? "" : "Required",
                is_visible: true,
                unique_id: generateUniqueId(),
              }
            }),

          })
        } else {
          errorObject.push({
            id: workTypeData?.id,
            name: workTypeData?.name,
            max_monthly_leave_for_dd: "",
            leave_type_settings: leaves?.map((value, index, array) => {
              return {
                id: null,
                leave_type_id: "",
                monthly_days: "",
                advance_days: "",
                max_leave_encashment: "",
                is_visible: false,
                unique_id: generateUniqueId(),
              }
            }),
            special_requests: spacialReq?.map((value, index, array) => {
              return {
                id: null,
                name: "",
                allotment: "",
                is_visible: false,
                unique_id: generateUniqueId(),
              }
            }),
          })
        }
        return {
          ...workTypeData,
          leave_type_settings: leaves,
          special_requests: spacialReq,
          min_working_hrs_half_day: workTypeData?.min_working_hrs_half_day || null,
          min_working_hrs_full_day: workTypeData?.min_working_hrs_full_day || null,
          is_changed: false
        }
      }),
    };
    errorObject.push({
      "max_absent_allowed": "",
    });
    errorObject.push({
      "renewal_month": "",
    });
    errorObject.push({
      "work_type_settings": "",
    });
    errorObject.push({
      "mandatoryWorkTypeNotInclude": "",
    });

    let sortedWorkType = sortArrayByKey(updatedFormData.work_type_settings, "asc", "createdAt");
    updatedFormData.work_type_settings = sortedWorkType;
    const orderArray = [...constantWorkType].map((value) => value?.name);
    const orderMap = Object.fromEntries(orderArray.map((name, index) => [name, index]));

    let updatedOrder = [...constantWorkType].sort((a, b) => {
      if (a.isChecked === false) return 1;
      if (b.isChecked === false) return -1;
      return (orderMap[a.name] || Infinity) - (orderMap[b.name] || Infinity);
    });
    setFormData(updatedFormData);
    setConstantType((prev) => ({ ...prev, work_type_settings: updatedOrder }));
    setErrors(errorObject);
  };

  const handleChangeTab = (event, newValue) => {
    setTabValue(newValue);
  };

  const handlePreviousStep = () => {
    if (isEditField) {
      handleShowSaveModal();
    } else {
      navigate(`${URLS.onBoarding}/${ON_BOARDING_STEPS.step1}`, {
        replace: true,
      });
      setOnBoardingStep(ON_BOARDING_STEPS.step1);
      dispatch(setUserOnBoardingStep(ON_BOARDING_STEPS.step1));
    }
  };

  const handleSkipStep = async () => {
    try {
      const response = await axiosInstance.put(
        API.skipStepForOnBoarding(currentUser?.organization_id, "leave_setting"));
      if (response.status === 200) {
        navigate(`${URLS.onBoarding}/${ON_BOARDING_STEPS.step3}`, {
          replace: true,
        });
        setOnBoardingStep(ON_BOARDING_STEPS.step3);
        dispatch(setUserOnBoardingStep(ON_BOARDING_STEPS.step3));
      }
    } catch (error) {
      console.error(error)
    }
  };

  const handleChangeCheckBox = (event, checked, tabType) => {
    let workTypeSettingsIndex = errors?.findIndex((value) => Object.keys(value).includes("work_type_settings"));
    let updatedErrors = [...errors];
    if (["in-office", "remote"]?.includes(tabType?.toLowerCase())) {
      let isMandatoryWorkTypeIncludeIndex = errors?.findIndex((value) => Object.keys(value).includes("mandatoryWorkTypeNotInclude"));
      updatedErrors[isMandatoryWorkTypeIncludeIndex]["mandatoryWorkTypeNotInclude"] = "";
    }
    updatedErrors[workTypeSettingsIndex]["work_type_settings"] = "";
    setIsAnyError(false);
    setErrors(updatedErrors);

    const updatedWorkTypes = [...constantType.work_type_settings];
    const findIndex = updatedWorkTypes.findIndex(
      (tab) => tab.name === tabType
    );
    if (findIndex !== -1) {
      updatedWorkTypes[findIndex] = {
        ...updatedWorkTypes[findIndex],
        isChecked: checked,
        is_changed: true,
        isDeleted: updatedWorkTypes[findIndex]?.isActive ? !checked : false
      };
    }
    // let isEveryUnchecked = updatedWorkTypes?.every(type => type?.isChecked === false)
    // if (isEveryUnchecked) {
    //   setFormData((prev) => ({ ...prev, allow_special_credits: false }));
    // }
    setConstantType((prev) => {
      return { ...prev, work_type_settings: updatedWorkTypes };
    });
  };

  const handleChange = (e, value) => {
    let name = e.target.name;

    setFormData((prev) => ({
      ...prev,
      [name]: value || e.target.value,
    }));
  };

  const handleSubmitLeaveSetting = async (redirectStep) => {
    try {
      setLoading((prev) => ({ ...prev, formSubmitting: true }));
      let updatedErrors = [...errors];
      let errorsTab = [];
      let globalError = [];

      // validate max_absent_allowed and renewal_month
      let maxAbsentAllowedIndex = errors?.findIndex((value) => Object.keys(value).includes("max_absent_allowed"));
      let renewalMonthIndex = errors?.findIndex((value) => Object.keys(value).includes("renewal_month"));
      let workTypeSettingsIndex = errors?.findIndex((value) => Object.keys(value).includes("work_type_settings"));
      let isMandatoryWorkTypeIncludeIndex = errors?.findIndex((value) => Object.keys(value).includes("mandatoryWorkTypeNotInclude"));
      if (!formData?.max_absent_allowed && formData?.max_absent_allowed !== 0) {
        updatedErrors[maxAbsentAllowedIndex]["max_absent_allowed"] = "Required";
        globalError.push('max_absent_allowed')
      } else {
        updatedErrors[maxAbsentAllowedIndex]["max_absent_allowed"] = "";
      }
      if (!formData?.renewal_month) {
        updatedErrors[renewalMonthIndex]["renewal_month"] = "Required";
        globalError.push('renewal_month')
      } else {
        updatedErrors[renewalMonthIndex]["renewal_month"] = "";
      }
      let isChecked = constantType?.work_type_settings?.some(item => item?.isChecked);
      if (isChecked) {
        updatedErrors[workTypeSettingsIndex]["work_type_settings"] = "";
        // validate tab data
        constantType?.work_type_settings?.forEach((value, parentIndex) => {
          if (value?.isChecked === true) {
            let isErrorInTab = false;
            let workTypeIndex = formData?.work_type_settings?.findIndex((_value) => _value?.id === value?.id);
            const errorWorkTypeIndex = errors?.findIndex((_value) => _value?.name === value?.name);
            let workTypeData = formData?.work_type_settings[workTypeIndex];
            if (!workTypeData["max_monthly_leave_for_dd"] && workTypeData["max_monthly_leave_for_dd"] !== 0) {
              updatedErrors[workTypeIndex]["max_monthly_leave_for_dd"] = "Required";
              isErrorInTab = true;
            }
            if (formData?.enable_sys_generated_leave) {
              let min_working_hrs_half_day = workTypeData?.min_working_hrs_half_day;
              let min_working_hrs_full_day = workTypeData?.min_working_hrs_full_day;
              if (!min_working_hrs_half_day || min_working_hrs_half_day === 0) {
                updatedErrors[errorWorkTypeIndex]["min_working_hrs_half_day"] = "Required";
                isErrorInTab = true;
              } else {
                let halfDayTime = secondsToTime(workTypeData?.min_working_hrs_half_day);
                if (halfDayTime && halfDayTime !== 'Invalid date' && halfDayTime !== '00:00') {
                  const halfDayTotalHours = timeToHours(halfDayTime);
                  if (halfDayTotalHours > 10) {
                    updatedErrors[errorWorkTypeIndex]['min_working_hrs_half_day'] = "Time must be less than 10 hours";
                    isErrorInTab = true;
                  } else {
                    updatedErrors[errorWorkTypeIndex]['min_working_hrs_half_day'] = "";
                  }
                } else {
                  updatedErrors[errorWorkTypeIndex]['min_working_hrs_half_day'] = "Required";
                  isErrorInTab = true;
                }
              }

              if (!min_working_hrs_full_day || min_working_hrs_full_day === 0) {
                updatedErrors[errorWorkTypeIndex]["min_working_hrs_full_day"] = "Required";
                isErrorInTab = true;
              } else {
                let fullDayTime = secondsToTime(workTypeData?.min_working_hrs_full_day);
                if (fullDayTime && fullDayTime !== 'Invalid date' && fullDayTime !== '00:00') {
                  const fullDayTotalHours = timeToHours(fullDayTime);
                  if (fullDayTotalHours > 10) {
                    updatedErrors[errorWorkTypeIndex]['min_working_hrs_full_day'] = "Time must be less than 10 hours";
                    isErrorInTab = true;
                  } else {
                    updatedErrors[errorWorkTypeIndex]['min_working_hrs_full_day'] = "";
                  }
                } else {
                  updatedErrors[errorWorkTypeIndex]['min_working_hrs_full_day'] = "Required";
                  isErrorInTab = true;
                }
              }

              if (workTypeData?.min_working_hrs_half_day && workTypeData?.min_working_hrs_full_day) {
                // Cross-validation logic
                let halfDayTime = secondsToTime(workTypeData?.min_working_hrs_half_day);
                let fullDayTime = secondsToTime(workTypeData?.min_working_hrs_full_day);

                if (halfDayTime && fullDayTime && halfDayTime !== 'Invalid date' && fullDayTime !== 'Invalid date') {
                  const halfDayTotalHours = timeToHours(halfDayTime);
                  const fullDayTotalHours = timeToHours(fullDayTime);

                  if (halfDayTotalHours >= fullDayTotalHours) {
                    updatedErrors[errorWorkTypeIndex]['min_working_hrs_half_day'] = "Half day time must be less than full day time";
                    updatedErrors[errorWorkTypeIndex]['min_working_hrs_full_day'] = "Full day time must be greater than half day time";
                    isErrorInTab = true;
                  } else {
                    updatedErrors[errorWorkTypeIndex]['min_working_hrs_half_day'] = "";
                    updatedErrors[errorWorkTypeIndex]['min_working_hrs_full_day'] = "";
                  }
                }
              }
            }

            let errorsForLeaveTypeSettings = workTypeData?.leave_type_settings?.map((leave, leaveIndex) => {
              let error = {};
              if (leaveIndex === 0) {
                error = getMissingKeys(leave, formData?.leave_frequency);
              } else {
                let isShowError = Object.values(getMissingKeys(leave, formData?.leave_frequency)).filter((value) => !!value).length !== ['leave_type_id', 'monthly_days', 'advance_days', 'max_leave_encashment'].length;
                error = isShowError ? getMissingKeys(leave, formData?.leave_frequency) : {
                  'leave_type_id': "",
                  'monthly_days': "",
                  'advance_days': "",
                  'max_leave_encashment': "",
                };
              }
              return error;
            });
            let filteredErrors2 = [];
            let isErrorSet = false;
            let isErrorSet2 = false;
            if (!!formData?.allow_special_credits) {
              let filteredList = workTypeData?.special_requests?.filter(value => !!value?.name || !!value?.allotment);
              let errorsForSpecialRequests = filteredList?.map((leave, leaveIndex, array) => {
                let error = {};
                if (leaveIndex === 0) {
                  error = getMissingKeys(leave, formData?.leave_frequency);
                } else {
                  let isShowError = Object.values(getMissingKeys(leave, formData?.leave_frequency)).filter((value) => !!value).length !== ['name', 'allotment'].length;
                  let object = isShowError ? getMissingKeys(leave, formData?.leave_frequency) : {
                    'name': "",
                    'allotment': "",
                  };
                  error = object;
                }
                return error;
              });

              let blankLength = filteredList?.filter((value) => !value?.id && !value?.allotment && !value?.name).length
              let isDeletedLength = filteredList?.filter((value) => value?.is_deleted).length

              if (errorsForSpecialRequests?.length === 2) {
                let isAnyValueIsDeleted = filteredList.some((value) => value?.is_deleted);
                if (isAnyValueIsDeleted) {
                  isErrorSet = true;
                }
              }

              if (filteredList?.length === (blankLength + isDeletedLength)) {
                isErrorSet2 = true;
              }

              filteredErrors2 = errorsForSpecialRequests?.filter((value) => !!Object.values(value)?.filter((value) => !!value)?.length);
              updatedErrors[workTypeIndex]["special_requests"] = (isErrorSet || isErrorSet2) ? [...errorsForSpecialRequests.slice(0, errorsForSpecialRequests?.length - 1), { ...errorsForSpecialRequests[errorsForSpecialRequests?.length - 1], name: "Required", allotment: "Required" }] : errorsForSpecialRequests;
            }

            let filteredErrors = errorsForLeaveTypeSettings?.filter((value) => !!Object.values(value)?.filter((value) => !!value)?.length);
            updatedErrors[workTypeIndex]["leave_type_settings"] = errorsForLeaveTypeSettings;

            if (isErrorInTab || Boolean(filteredErrors?.length) || Boolean(filteredErrors2?.length) || isErrorSet || isErrorSet2) {
              errorsTab.push(parentIndex)
              isErrorInTab = false;
              isErrorSet = false;
              isErrorSet2 = false;
            }
          }
        });
        let isMandatoryWorkTypeInclude = constantType?.work_type_settings?.some(item => item?.isChecked && ["in-office", "remote"]?.includes(item?.name?.toLowerCase()));
        if (!isMandatoryWorkTypeInclude) {
          updatedErrors[isMandatoryWorkTypeIncludeIndex]["mandatoryWorkTypeNotInclude"] = "Required";
          globalError.push('mandatoryWorkTypeNotInclude')
        }
        setIsAnyError(false);
        setErrors(updatedErrors);
      } else {
        updatedErrors[workTypeSettingsIndex]["work_type_settings"] = "Required";
        globalError.push('work_type_settings');
        updatedErrors[isMandatoryWorkTypeIncludeIndex]["mandatoryWorkTypeNotInclude"] = "";
      }

      if (!errorsTab?.length && !globalError?.length) {
        setIsAnyError(false);
        let body = {
          id: formData?.id,
          name: formData?.name,
          leave_frequency: formData?.leave_frequency,
          max_absent_allowed: formData?.max_absent_allowed,
          renewal_month: formData?.renewal_month,
          allow_special_credits: formData?.allow_special_credits,
          enable_sys_generated_leave: formData?.enable_sys_generated_leave,
          work_type_settings:
            constantType?.work_type_settings?.filter(workTypeDetails => workTypeDetails?.isChecked || workTypeDetails?.isDeleted)?.map((work_type, index) => {
              let isChangeData = work_type?.is_changed;
              let workTypeData = formData?.work_type_settings?.find((value) => value?.id === work_type?.id);

              let getLeavesTypeSettings = () => {
                let deletedLeave;
                let initialWorkTypeData = initialData?.work_type_settings?.find((value) => value?.id === work_type?.id)?.leave_type_settings;
                let array = workTypeData?.leave_type_settings?.filter(leave => (!!leave?.leave_type_id && !!leave?.monthly_days && (!!leave?.advance_days || leave?.advance_days === 0) && (!!leave?.max_leave_encashment || leave?.max_leave_encashment === 0)))?.map((leave) => {
                  deletedLeave = initialWorkTypeData?.filter(initialObj => !workTypeData?.leave_type_settings?.some(updatedObj => updatedObj?.id === initialObj?.id));
                  let object = {
                    leave_type_id: leave?.leave_type_id,
                    monthly_days: leave?.monthly_days,
                    advance_days: leave?.advance_days,
                    max_leave_encashment: leave?.max_leave_encashment,
                    is_changed: leave?.is_changed,
                  }
                  if (leave?.id != null) {
                    object['id'] = leave?.id;
                  }
                  if (leave?.is_changed && leave?.id) {
                    object['is_changed'] = true;
                    isChangeData = true;
                  } else {
                    delete object['is_changed'];
                  }
                  if (leave?.is_deleted) {
                    object['is_deleted'] = true;
                    delete object['is_changed'];
                    isChangeData = true;
                  }
                  return object;
                });
                if (!!deletedLeave && Object.keys(deletedLeave)) {
                  deletedLeave.forEach((value) => {
                    array.push({ ...value, is_deleted: true, });
                  })
                }
                return array;
              };
              let getSpecialRequests = () => {
                let array = workTypeData?.special_requests?.map((leave) => {
                  let object = {
                    name: leave?.name,
                    allotment: leave?.allotment,
                    is_changed: leave?.is_changed,
                  }
                  if (leave?.id && formData?.allow_special_credits === false) {
                    object['is_deleted'] = true;
                    isChangeData = true;
                    delete object['is_changed'];
                  }
                  if (leave?.id != null) {
                    object['id'] = leave?.id;
                  }
                  if (leave?.is_changed && leave?.id) {
                    object['is_changed'] = true;
                    isChangeData = true;
                  } else {
                    delete object['is_changed'];
                  }
                  if (leave?.is_deleted) {
                    object['is_deleted'] = true;
                    isChangeData = true;
                    delete object['is_changed'];
                  }
                  return object;
                });
                let updatedArray = array?.filter(value => !!value?.allotment);
                return updatedArray;
              }
              let object = {
                name: workTypeData?.name,
                max_monthly_leave_for_dd: workTypeData?.max_monthly_leave_for_dd,
                leave_type_settings: getLeavesTypeSettings(),
                special_requests: getSpecialRequests(),
                min_working_hrs_half_day: workTypeData?.min_working_hrs_half_day,
                min_working_hrs_full_day: workTypeData?.min_working_hrs_full_day,
                is_changed: isChangeData,
                active: work_type?.isChecked,
                external: workTypeData?.external
              }
              if (workTypeData?.id) {
                object['id'] = workTypeData?.id;
              }
              if (work_type?.isDeleted) {
                object['is_deleted'] = true;
                object['is_inactive'] = true;
              }
              // if (object?.id === null && object?.is_deleted === true)
              //   return null;
              // else
              return object;
            }).filter(value => !!value),
        }
        try {
          const response = await axiosInstance.put(
            API.organisationLeaveSettings(currentUser?.organization_id),
            body
          );
          if (response.status === 200) {
            showAlert(response.data?.message, "success");
            navigate(`${URLS.onBoarding}/${redirectStep}`, {
              replace: true,
            });
            setOnBoardingStep(redirectStep);
            dispatch(setUserOnBoardingStep(redirectStep));
            return true;
          } else {
            showAlert(response?.response.data.message, "error");
          }
          setLoading((prev) => ({ ...prev, formSubmitting: false }));
          setIsAnyError(false);
        } catch (error) {
          console.error(error)
          setLoading((prev) => ({ ...prev, formSubmitting: false }));
          setIsAnyError(false);
        }
      } else {
        setIsAnyError(true);
        setTabValue(globalError?.length > 0 ? 0 : errorsTab[0]);
      }
      setLoading((prev) => ({ ...prev, formSubmitting: false }));
      return false;
    } catch (error) {
      console.error(error)
      setLoading((prev) => ({ ...prev, formSubmitting: false }));
    }
  };

  const findIndexWithUid = (array, unique_id) => {
    return array.findIndex((data) => data?.unique_id === unique_id);
  };

  // const findIndexWorkType = formData?.work_type_settings?.findIndex(
  //   (type, index) => type?.id === constantType?.work_type_settings?.find(constantData => constantData?.id === type?.id)?.id
  // );
  // const workTypeData = formData?.work_type_settings?.[findIndexWorkType];

  const handleReleaseFrequencyChange = (e) => {
    setIsEditField(true);
    handleChange(e);
    const { value } = e.target;
    const updatedErrors = [...errors];
    formData?.work_type_settings?.map((workTypeData, index) => {
      let isActiveWorkType = constantType?.work_type_settings?.find(data => data?.id === workTypeData?.id)?.isChecked;
      if (isActiveWorkType) {
        updatedErrors[index]?.leave_type_settings?.forEach(
          (leaveTypeData, index, array) => {
            let leaveIndex = findIndexWithUid(array, leaveTypeData?.unique_id);
            const monthlyDays =
              workTypeData["leave_type_settings"][leaveIndex]["monthly_days"];
            if (value === "monthly") {
              updatedErrors[index]["leave_type_settings"][leaveIndex][
                "monthly_days"
              ] = Boolean(monthlyDays)
                  ? isValidDays(monthlyDays)
                    ? ""
                    : "Please enter valid days which is divisible by 12 or 6"
                  : "";
            } else {
              updatedErrors[index]["leave_type_settings"][leaveIndex][
                "monthly_days"
              ] = "";
            }
          }
        );
      }
      return null
    })

    // updatedErrors[findIndexWorkType]?.leave_type_settings?.forEach(
    //   (leaveTypeData, index, array) => {
    //     let leaveIndex = findIndexWithUid(array, leaveTypeData?.unique_id);
    //     const monthlyDays =
    //       workTypeData["leave_type_settings"][leaveIndex]["monthly_days"];
    //     if (value === "monthly") {
    //       updatedErrors[findIndexWorkType]["leave_type_settings"][leaveIndex][
    //         "monthly_days"
    //       ] = Boolean(monthlyDays)
    //           ? isValidDays(monthlyDays)
    //             ? ""
    //             : "Please enter valid days which is divisible by 12 or 6"
    //           : "";
    //     } else {
    //       updatedErrors[findIndexWorkType]["leave_type_settings"][leaveIndex][
    //         "monthly_days"
    //       ] = "";
    //     }
    //   }
    // );
    setIsAnyError(false);
    setErrors(updatedErrors);
  };

  let maxAbsentAllowedIndex = errors?.findIndex((value) =>
    Object.keys(value).includes("max_absent_allowed")
  );

  let renewalMonthIndex = errors?.findIndex((value) =>
    Object.keys(value).includes("renewal_month")
  );

  const handleMaxAbsentChange = (e) => {
    setIsEditField(true);
    handleChange(e, parseInt(e.target.value));
    const updatedErrors = [...errors];
    const maxAbsentError = (!e.target.value && e.target.value !== 0) ? "Required" : "";

    updatedErrors[maxAbsentAllowedIndex]["max_absent_allowed"] = maxAbsentError;
    setIsAnyError(false)
    setErrors(updatedErrors);
  };

  const handleRenewalMonthChange = (e) => {
    setIsEditField(true);
    let name = e.target.name;

    setFormData((prev) => ({
      ...prev,
      [name]: e.target.value,
    }));

    const updatedErrors = [...errors];
    const renewalMonthError = !e.target.value ? "Required" : "";

    updatedErrors[renewalMonthIndex]["renewal_month"] = renewalMonthError;
    setIsAnyError(false)
    setErrors(updatedErrors);
  }

  const handleChangeSystemCheckBox = (e, checked) => {
    setFormData((prev) => ({
      ...prev,
      enable_sys_generated_leave: checked
    }));
    if (!checked) {
      const updatedErrors = [...errors];
      updatedErrors?.forEach((value, index) => {
        if (value?.min_working_hrs_full_day) {
          updatedErrors[index]["min_working_hrs_full_day"] = "";
        }
        if (value?.min_working_hrs_half_day) {
          updatedErrors[index]["min_working_hrs_half_day"] = "";
        }
      })
      setErrors(updatedErrors)
    }
  }

  const handleChangeCreditCheckBox = (e) => {
    setIsEditField(true);
    // Functional update for immutability
    setFormData((prevFormData) => {
      let getSpecialRequests = (workTypeSetting) => {
        let updatedLeaves = workTypeSetting?.special_requests?.map(value => {
          if (e.target.checked === false) {
            if (!!value?.id) {
              return { ...value, is_deleted: true };
            }
            else {
              return null;
            }
          }
          return value;
        })
        let filteredData = updatedLeaves?.filter((value, index, array) => !!value);
        let lastData = filteredData[filteredData?.length - 1];
        if (filteredData?.length === 0) {
          filteredData.push({
            id: null,
            allotment: null,
            name: null,
            is_visible: true,
            unique_id: generateUniqueId()
          })
        } else if (filteredData?.length >= 2 && !!lastData?.allotment && !!lastData?.name) {
          filteredData.push({
            id: null,
            allotment: null,
            name: null,
            is_visible: true,
            unique_id: generateUniqueId()
          })
        }
        return filteredData;
      };

      return {
        ...prevFormData,
        allow_special_credits: e.target.checked,
        work_type_settings: prevFormData?.work_type_settings?.map((workTypeSetting) => ({
          ...workTypeSetting,
          special_requests: getSpecialRequests(workTypeSetting),
        }))
      }
    });
  };

  const HtmlTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} classes={{ popper: className }} />
  ))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: '#444444',
      color: '#ffffff',
      maxWidth: 310,
      padding: 12,
    },
  }));

  return (loading.pageLoading ?
    <Box display={"flex"} alignItems={"center"} justifyContent={"center"} width={"500px"}>
      <CircularLoader />
    </Box> :
    <Box display="flex" flexDirection="column" sx={{ width: '100%' }}>
      <Stack spacing={4} sx={{ flexBasis: "100%" }}>
        <Typography color="secondary.800" fontSize={20}>
          Create a Organisation (Step 2 of 5)
        </Typography>
        <Stack spacing={2}>
          <Grid display={"flex"}>
            <Typography
              color="secondary"
              fontSize={{ xs: 28, lg: 32 }}
              lineHeight={{ xs: "38px", lg: "44px" }}>
              Configure Leaves management for {titleCase(formData?.name)}
            </Typography>
          </Grid>
          <Typography variant="body2" color="dark.800">
            Setup your leave configuration for your organisation.
            {/* You can still update these settings from My leave settings later. */}
          </Typography>
          <Grid container sx={{ maxWidth: '850px' }}>
            <Grid item mr={3} flex={1}>
              <Input
                id="renewal-month"
                select
                type="select"
                label="Select Yearly Renewal Month"
                name="renewal_month"
                variant="standard"
                sx={{ width: "100%" }}
                onChange={(e) => handleRenewalMonthChange(e)}
                onBlur={(e) => handleRenewalMonthChange(e)}
                value={formData?.renewal_month || ""}
                error={!!errors?.[renewalMonthIndex]?.['renewal_month']}
                helperText={errors?.[renewalMonthIndex]?.['renewal_month']}
                required={true}
                disabled={!!initialData?.renewal_month}
              >
                {months?.map(
                  (month, index) => (
                    <MenuItem
                      key={index}
                      value={month}
                    >
                      {titleCase(month)}
                    </MenuItem>
                  )
                )}
              </Input>
            </Grid>
            <Grid item flex={1}>
              <Input
                id="max-absent"
                name="max_absent_allowed"
                label="Max Absent (%)"
                variant="standard"
                type="number"
                isDays={true}
                inputProps={{ min: 0, max: 100 }}
                min={0}
                max={100}
                sx={{ width: "100%" }}
                // InputLabelProps={{
                //   shrink: !!formData?.max_absent_allowed || formData?.max_absent_allowed === 0,
                // }}
                onChange={(e) => handleMaxAbsentChange(e)}
                onBlur={(e) => handleMaxAbsentChange(e)}
                value={formData?.max_absent_allowed}
                error={!!errors?.[maxAbsentAllowedIndex]?.["max_absent_allowed"]}
                helperText={errors?.[maxAbsentAllowedIndex]?.["max_absent_allowed"]}
              />
              <Box>

                <HtmlTooltip
                  arrow
                  title={
                    <React.Fragment>
                      <Typography sx={{ fontSize: '13px !important' }} textAlign={"center"}>% of team allowed to be Absent on any given day</Typography>
                    </React.Fragment>
                  }
                >
                  <Typography
                    display="inline-block"
                    color="primary"
                    fontSize={12}
                    sx={{ cursor: "pointer" }}
                  >
                    Help
                  </Typography>
                </HtmlTooltip>
              </Box>
            </Grid>
          </Grid>
        </Stack>
        <Grid item>
          <Typography color="text.dark" fontSize={14} mb={1} sx={{ lineHeight: '24px' }}>
            Leave Availability Frequency
          </Typography>
          <FormControl component="fieldset">
            <RadioGroup
              row
              aria-labelledby="demo-row-radio-buttons-group-label"
              name="leave_frequency"
              defaultValue="monthly"
              value={formData?.leave_frequency}
              onChange={handleReleaseFrequencyChange}
            >
              <FormControlLabel
                value="monthly"
                control={<Radio />}
                label="Monthly"
                sx={{ color: "dark.800" }}
                disabled={!!initialData?.leave_frequency}
              />
              <FormControlLabel
                value="yearly"
                control={<Radio />}
                label="Annually"
                sx={{ color: "dark.800" }}
                disabled={!!initialData?.leave_frequency}
              />
            </RadioGroup>
            <FormHelperText error={false} sx={{ p: 0, m: 0 }}></FormHelperText>
          </FormControl>
          <Box>
            <Typography color="dark.600" fontSize={12}>
              Set frequency of adding leaves into the leave balance.
            </Typography>
            <Typography color="dark.600" fontSize={12}>
              With <b>Monthly</b> option, candidate will receive{" "}
              <Tooltip title="monthly" arrow>
                <Typography
                  display="inline-block"
                  fontSize={12}
                  sx={{ textDecoration: "underline", cursor: "pointer" }}
                >
                  monthly
                </Typography>
              </Tooltip>{" "}
              leaves at the beginning of every month throughout the year based on
              Yearly Leave set below.
            </Typography>
            <Typography color="dark.600" fontSize={12}>
              With <b>Annually</b> option, candidate will receive all the yearly
              leaves at the beginning of the Year.
            </Typography>
          </Box>
        </Grid>
        <Stack spacing={1}>
          <Box display="flex" alignItems="center" mb={1}>
            <Typography color="text.dark" fontSize={14} mr={1} sx={{ lineHeight: '24px' }}>
              System Generated Leaves
            </Typography>
            <HtmlTooltip arrow placement="top"
              title={
                <React.Fragment>
                  <Typography color={"white"} textAlign={"center"} fontSize={16} fontWeight={500} lineHeight={"24px"}>System will automatically creates a leave upon unavailability for the user.</Typography>
                </React.Fragment>
              }
            >
              <i style={{ width: '18px', height: '18px' }}><HelpOutlineIcon sx={{ fontSize: '18px', color: 'secondary.main' }} /></i>
            </HtmlTooltip>
          </Box>
          <Box mb={1}>
            <Typography color="dark.600" fontSize={12}>
              System generated leaves will be enabled for all work type team members like In-office, Remote, Hybrid.
            </Typography>
            <Typography color="dark.600" fontSize={12} >
              This setting can also be maintained at individual work-type as well as specific user level.
            </Typography>
          </Box>
          <FormControlLabel
            id='enable_sys_generated_leave'
            name='enable_sys_generated_leave'
            label={`Enable System Generated Leaves for all work-types`}
            labelPlacement="end"
            control={<Checkbox checked={formData?.enable_sys_generated_leave}
            // disabled={!constantType.work_type_settings.filter(workType => !!workType?.isChecked)?.length}
            />}
            onChange={handleChangeSystemCheckBox}
          />
        </Stack>
        {/* Special Credits */}
        <Stack spacing={1}>
          <Grid display="flex" alignItems="center" mb={1}>
            <Typography color="text.dark" fontSize={14} mr={1} sx={{ lineHeight: '24px' }}>
              Special Credits
            </Typography>
            <i style={{ width: '18px', height: '18px' }}><HelpOutlineIcon sx={{ fontSize: '18px', color: 'secondary.main' }} /></i>
          </Grid>
          <Typography color="dark.600" fontSize={12}>
            Special credit will be enable for all work type team members like In-office, Remote, Hybrid.
          </Typography>
          <FormControlLabel
            id='allow_special_credits'
            name='allow_special_credits'
            label={`Enable Special credits feature`}
            labelPlacement="end"
            control={<Checkbox checked={formData?.allow_special_credits}
            // disabled={!constantType.work_type_settings.filter(workType => !!workType?.isChecked)?.length}
            />}
            onChange={(e) =>
              handleChangeCreditCheckBox(e)
            }
          />
        </Stack>
        <Box sx={{ width: "100%" }}>
          <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
            <Tabs
              value={tabValue}
              onChange={handleChangeTab}
              aria-label="basic tabs example"
            >
              {constantType.work_type_settings.map(
                (work_type_settings, index) => (
                  <Tab label={work_type_settings.name} key={index} {...a11yProps(index)} />
                )
              )}
            </Tabs>
          </Box>
          {constantType.work_type_settings.map((work_type_settings, index) => (
            <CustomTabPanel value={tabValue} index={index} key={index}>
              <Box mx={-0.5}>
                <Box display={'flex'} justifyContent={'space-between'}>
                  <FormControlLabel
                    name="work_type_settings"
                    id={`work_type_settings-${index}`}
                    value="end"
                    control={<Checkbox checked={work_type_settings?.isChecked} />}
                    label={`Yes, I want to set ${work_type_settings.name} Leave Settings for this organisation.`}
                    labelPlacement="end"
                    onChange={(e, checked) =>
                      handleChangeCheckBox(e, checked, work_type_settings.name)
                    }
                    sx={{
                      marginBottom: '16px',
                      '.MuiCheckbox-sizeMedium': {
                        minWidth: '42px'
                      }
                    }}
                    disabled={!work_type_settings?.canDelete}
                  />
                  <Box display={'flex'} alignItems={'center'}>
                    <Typography color="dark.600" fontSize={13} lineHeight={'21.58px'} letterSpacing={'0.4px'}>
                      Type:
                    </Typography>
                    <Typography color={"primary"} fontSize={"13px"} fontWeight={"500"} border={"1px solid #047FE0"} borderRadius={"4px"} padding={"2px 9px"} lineHeight={"22px"} letterSpacing={"0.46px"} sx={{ mx: '10px', fontStyle: 'italic' }} >
                      {work_type_settings?.external ? "External" : "Internal"}
                    </Typography>
                    <HtmlTooltip arrow placement="top"
                      title={
                        <Typography fontSize={{ xs: 12, lg: 14 }}>Indicates the nature of worktype, specified whether it is Internal or External.</Typography>
                      }>
                      <i
                        style={{
                          width: "18px",
                          height: "18px",
                          display: "inline-block",
                          verticalAlign: "middle",
                          color: "#3F5C76",
                        }}
                      >
                        <i style={{ width: '18px', height: '18px' }}><HelpOutlineIcon sx={{ fontSize: '18px', color: 'secondary.main' }} /></i>
                      </i>
                    </HtmlTooltip>
                  </Box>
                </Box>
                {!!(errors[errors?.findIndex(value => value && Object?.keys(value)?.includes('work_type_settings'))]?.work_type_settings) ? <Typography fontSize={"12px"} sx={{ color: "red.main" }}> Please select at least one work type</Typography> : null}
                {work_type_settings?.isChecked ? (
                  <LeaveTypeForm
                    work_type_settings={work_type_settings}
                    handleChange={handleChange}
                    formData={formData}
                    errors={errors}
                    setErrors={setErrors}
                    setIsAnyError={setIsAnyError}
                    setFormData={setFormData}
                    constantType={constantType}
                    setIsEditField={setIsEditField}
                  />
                ) : null}
              </Box>
            </CustomTabPanel>
          ))}
        </Box>
        {!!(errors[errors?.findIndex(value => value && Object?.keys(value)?.includes('mandatoryWorkTypeNotInclude'))]?.mandatoryWorkTypeNotInclude) ? <Typography fontSize={"14px"} color={"error.main"}> You should enable at least one worktype from In-office or Remote worktype.</Typography> : null}

        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          mt={4}
        >
          <Button
            variant="text"
            color="secondary"
            sx={{ fontSize: "15px", textTransform: "none" }}
            onClick={handlePreviousStep}
          >
            Previous
          </Button>
          <Box
            display="flex"
            justifyContent="flex-end"
            alignItems="center"
            flexBasis="100%"
          >
            {validateObject(initialData) ?
              <Button
                variant="text"
                color="secondary"
                sx={{ fontSize: "15px", textTransform: "none" }}
                onClick={handleSkipStep}
              >
                Skip
              </Button>
              : null}
            <Button
              variant="contained"
              color="primary"
              size="large"
              sx={{
                boxShadow: "0",
                color: "white",
                fontSize: "15px",
                textTransform: "none",
                marginLeft: "20px",
                "&:hover": { boxShadow: "0" },
              }}
              type="button"
              onClick={() => handleSubmitLeaveSetting(ON_BOARDING_STEPS.step3)}
              isButtonLoading={loading?.formSubmitting}
              disabled={loading?.formSubmitting || isAnyError}
            >
              Continue
            </Button>
          </Box>
        </Box>
      </Stack>
    </Box>
  );
});

export default LeaveSettingStep;