import React from 'react'
import { LocalizationProvider, MobileTimePicker } from '@mui/x-date-pickers';
import dayjs from 'dayjs';
import moment from 'moment';
import { titleCase } from '../../utils';
import { DemoItem } from '@mui/x-date-pickers/internals/demo';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import ScheduleIcon from '@mui/icons-material/Schedule';
import { Typography } from '@mui/material';

const CustomTimePickerForUser = ({ value, onChange, label, onOpen, onClose = () => { }, error = null, isMaxTime = false, ...rest }) => {
    let getMaxTime = () => {
        let value = moment(new Date()).format("HH:mm:ss");
        let [hh, mm, ss] = value.split(':').map(Number);
        return dayjs().set('hour', hh).set('minute', mm).set('second', ss);
    }

    return <LocalizationProvider dateAdapter={AdapterDayjs}>
        <DemoItem label={titleCase(label)}
            sx={{
                position: 'relative',
                '.MuiTypography-body2': {
                    fontSize: 12,
                    fontWeight: 400,
                    color: 'dark.600',
                    width: "100%",
                    letterSpacing: "0.15px",
                    marginTop: "-3px",
                    marginBottom: "2px",
                },
                '.MuiFormControl-root': {
                    margin: '0',
                },
                'fieldset': {
                    border: 'none',
                },
                '.MuiOutlinedInput-root': {
                    padding: '0px',
                },
                'input': {
                    width: "100%",
                    height: 30,
                    border: "none !important",
                    boxShadow: "none !important",
                    borderRadius: "0px",
                    paddingBottom: "6px",
                    borderBottom:
                        "1px solid rgba(0, 0, 0, 0.42) !important",
                    margin: "0",
                    position: "relative",
                    background: "transparent",
                    color: "dark.800",
                    fontSize: 16,
                    fontWeight: 400,
                    lineHeight: "24px",
                    letterSpacing: "0.15px",
                    padding: '0px 20px 0px 0px'
                },
                "input:hover": {
                    borderBottomColor:
                        "#333333 !important",
                },
                "input:focus": {
                    borderBottomColor:
                        "#047FE0 !important",
                },
            }}>
            <div style={{ position: 'absolute', right: '0px', top: '20px', marginTop: '0px' }}>
                <ScheduleIcon
                    sx={{
                        fontSize: 20,
                        color: 'rgba(0, 0, 0, 0.56)',
                        marginBottom: '-5px',
                        marginLeft: '2px',
                    }}
                />
            </div>
            <MobileTimePicker
                value={dayjs(value)}
                onChange={onChange}
                onOpen={onOpen}
                onClose={onClose}
                openTo="hours"
                maxTime={isMaxTime ? getMaxTime() : null}
                {...rest}
            />
            {!!error ? <Typography sx={{ color: "red.main", fontSize: "12px" }}>{error}</Typography> : null}
        </DemoItem>
    </LocalizationProvider>
}

export default CustomTimePickerForUser