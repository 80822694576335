import moment from "moment";
import React, { useEffect, useRef } from "react";
import MultiDatePicker from "react-multi-date-picker";
import { LEAVE_DURATION_TYPE, WEEKDAYS } from "../../constants/default-values";
import { getInputId, titleCase } from "../../utils";
import { styled } from "@mui/styles";
import { Box, Tooltip, tooltipClasses, Typography } from "@mui/material";
import DatePanel from "react-multi-date-picker/plugins/date_panel";

const HtmlTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
        backgroundColor: '#444444',
        maxWidth: 220,
        padding: "7px 12px",
    },
}));

const OtherUserSingleDatePicker = ({
    value,
    onChange,
    ignoreDates = [],
    isMultiple = false,
    disabled = false,
    className,
    onOpen = () => { },
    isOnlyAllowCurrentYearDates = false,
    style,
    extraDisableDates = [],
    isEnableAllDate = false,
    ignoreLeaveDates = [],
    requestDays = [],
    userCreatedAt = new Date(),
    ...rest
}) => {
    let ignoreDatesArray = [];
    if (ignoreDates?.length) {
        ignoreDatesArray = ignoreDates;
    }
    if (ignoreLeaveDates?.length) {
        ignoreDatesArray = [...ignoreDatesArray, ...ignoreLeaveDates];
    }
    if (extraDisableDates?.length) {
        ignoreDatesArray = Array.from(new Set([...extraDisableDates, ...ignoreLeaveDates]));
    }
    const inputRef = useRef(null);

    useEffect(() => {
        const handleKeyPress = (e) => {
            e.preventDefault();
        };

        const inputElement = inputRef.current;
        if (inputElement) {
            inputElement.addEventListener('keypress', handleKeyPress);
            inputElement.addEventListener('keydown', handleKeyPress);
            inputElement.addEventListener('keyup', handleKeyPress);
        }

        return () => {
            if (inputElement) {
                inputElement.removeEventListener('keypress', handleKeyPress);
                inputElement.removeEventListener('keydown', handleKeyPress);
                inputElement.removeEventListener('keyup', handleKeyPress);
            }
        };
    }, []);

    const getLeaveType = (leaveDetails) => {
        // Leave has leave_date key and WFH / timing request has date key
        if (leaveDetails?.leave_duration !== Object.keys(LEAVE_DURATION_TYPE)[2]) {
            return `${Object.keys(leaveDetails)?.includes('leave_date') ? titleCase(leaveDetails?.type?.split(" ")?.[0]) : titleCase(leaveDetails?.type)} ${LEAVE_DURATION_TYPE[leaveDetails?.leave_duration]} ${Object.keys(leaveDetails)?.includes('leave_date') ? 'Leave' : ''}`
        } else {
            return `${titleCase(leaveDetails?.type)}`
        }
    }

    const isdotType = (requestDate, key) => {
        return requestDate?.find(data => Object.keys(data)?.includes(key))
    }

    const isIncludeTwoRequest = (requestDate) => {
        return requestDate?.length > 1;
    }

    return (
        <>
            <MultiDatePicker
                id={getInputId("date")}
                style={style}
                ref={inputRef}
                onOpen={onOpen}
                disabled={disabled}
                value={value}
                onChange={onChange}
                plugins={[<DatePanel markFocused />]}
                multiple={isMultiple}
                format="DD/MM/YYYY"
                weekDays={WEEKDAYS}
                headerOrder={["MONTH_YEAR", "LEFT_BUTTON", "RIGHT_BUTTON"]}
                mapDays={({ date }) => {
                    let isPastDate = isEnableAllDate ? false : moment(new Date(date)).isBefore(moment(userCreatedAt ?? `01/01/${new Date().getFullYear()}`), "day");

                    let isIgnoredDate = ignoreDatesArray?.some((ignoreDate) => {
                        let date1 = moment(ignoreDate, 'DD/MM/YYYY').format("DD/MM/YYYY");
                        let date2 = moment(new Date(date)).format("DD/MM/YYYY");
                        return date1 === date2;
                    })
                    let isRequestDate = requestDays?.filter((requestDetails) => {
                        let date1 = moment(requestDetails?.leave_date ?? requestDetails?.date).format("DD/MM/YYYY");
                        let date2 = moment(new Date(date)).format("DD/MM/YYYY");

                        return date1 === date2;
                    });

                    if (isOnlyAllowCurrentYearDates) {
                        let isPastDate = moment(new Date(date)).isBefore(new Date(rest?.minDate));
                        let isFutureDate = moment(new Date(date)).subtract(1, 'day').isAfter(new Date(rest?.maxDate));
                        if (isPastDate || isFutureDate || isIgnoredDate || !!isRequestDate?.length) {
                            return {
                                disabled: (isPastDate || isIgnoredDate || isFutureDate) ? true : (!!isRequestDate && isRequestDate?.leave_duration === "full") ? true : false,
                                style: { color: (isPastDate || isIgnoredDate || isFutureDate) ? "#ccc" : (!!isRequestDate && isRequestDate?.leave_duration === "full") ? "#ccc" : "#000" },
                                children: (
                                    <>
                                        {!!isRequestDate?.length ? <HtmlTooltip arrow
                                            title={
                                                <React.Fragment>
                                                    <Box display={'flex'} flexDirection={'column'} justifyContent={'space-between'} alignItems={'center'}>
                                                        {isdotType(isRequestDate, "leave_date") ? <Typography variant="body1" color={"white"} fontSize={14} fontWeight={500} lineHeight={"21px"}>{getLeaveType(isRequestDate?.find(data => Object.keys(data)?.includes('leave_date')))}</Typography> : null}
                                                        {isdotType(isRequestDate, "date") ? <Typography variant="body1" color={"white"} fontSize={14} fontWeight={500} lineHeight={"21px"}>{getLeaveType(isRequestDate?.find(data => Object.keys(data)?.includes('date')))}</Typography> : null}
                                                    </Box>
                                                </React.Fragment>
                                            }
                                        >
                                            <Box>
                                                <Typography variant="caption" className="sd leave-type">{new Date(isRequestDate[0]?.leave_date ?? isRequestDate[0]?.date)?.getDate()}</Typography>
                                                <Box display={'flex'} justifyContent={'space-between'} alignItems={'center'} mx={0}>
                                                    {isdotType(isRequestDate, "leave_date") ? <Box mr={1} width={6} height={6} minWidth={6} bgcolor={isRequestDate?.find(data => Object.keys(data)?.includes('leave_date'))?.type?.toLowerCase() === "casual leave" ? "#12B495" : "#D32F2F"} borderRadius={5} margin={"auto"} position={"absolute"} bottom={"2.5px"} left={isIncludeTwoRequest(isRequestDate) ? -7 : 0} right={0}></Box> : null}
                                                    {isdotType(isRequestDate, "date") ? <Box mr={1} width={6} height={6} minWidth={6} bgcolor={"#047FE0"} borderRadius={5} margin={"auto"} position={"absolute"} bottom={"2.5px"} left={isIncludeTwoRequest(isRequestDate) ? 7 : 0} right={0}></Box> : null}
                                                </Box>
                                            </Box>
                                        </HtmlTooltip> : <Typography variant="caption" className="sd leave-type">{new Date(date)?.getDate()}</Typography>}
                                    </>
                                ),
                            };
                        }
                    } else {
                        if (isIgnoredDate || isPastDate || !!isRequestDate?.length) {
                            return {
                                disabled: (isPastDate || isIgnoredDate) ? true : (!!isRequestDate && isRequestDate?.leave_duration === "full") ? true : false,
                                style: { color: (isPastDate || isIgnoredDate) ? "#ccc" : (!!isRequestDate && isRequestDate?.leave_duration === "full") ? "#ccc" : "#000" },
                                children: (
                                    <>
                                        {!!isRequestDate?.length ? <HtmlTooltip arrow
                                            title={
                                                <React.Fragment>
                                                    <Box display={'flex'} flexDirection={'column'} justifyContent={'space-between'} alignItems={'center'}>
                                                        {isdotType(isRequestDate, "leave_date") ? <Typography variant="body1" color={"white"} fontSize={14} fontWeight={500} lineHeight={"21px"}>{getLeaveType(isRequestDate?.find(data => Object.keys(data)?.includes('leave_date')))}</Typography> : null}
                                                        {isdotType(isRequestDate, "date") ? <Typography variant="body1" color={"white"} fontSize={14} fontWeight={500} lineHeight={"21px"}>{getLeaveType(isRequestDate?.find(data => Object.keys(data)?.includes('date')))}</Typography> : null}
                                                    </Box>
                                                </React.Fragment>
                                            }
                                        >
                                            <Box>
                                                <Typography variant="caption" className="sd leave-type">{new Date(isRequestDate[0]?.leave_date ?? isRequestDate[0]?.date)?.getDate()}</Typography>
                                                <Box display={'flex'} justifyContent={'space-between'} alignItems={'center'} mx={0}>
                                                    {isdotType(isRequestDate, "leave_date") ? <Box mr={1} width={6} height={6} minWidth={6} bgcolor={isRequestDate?.find(data => Object.keys(data)?.includes('leave_date'))?.type?.toLowerCase() === "casual leave" ? "#12B495" : "#D32F2F"} borderRadius={5} margin={"auto"} position={"absolute"} bottom={"2.5px"} left={isIncludeTwoRequest(isRequestDate) ? -7 : 0} right={0}></Box> : null}
                                                    {isdotType(isRequestDate, "date") ? <Box mr={1} width={6} height={6} minWidth={6} bgcolor={"#047FE0"} borderRadius={5} margin={"auto"} position={"absolute"} bottom={"2.5px"} left={isIncludeTwoRequest(isRequestDate) ? 7 : 0} right={0}></Box> : null}
                                                </Box>
                                            </Box>
                                        </HtmlTooltip> : <Typography variant="caption" className="sd leave-type">{new Date(date)?.getDate()}</Typography>}
                                    </>
                                ),
                            };
                        }
                    }
                }}
                {...rest}
                className={"test-test"}
            />
        </>
    );
};

export default OtherUserSingleDatePicker;
