export const keysWithActualValues = ["api", "date", "msg", 'entries_to_create', "allow_continue_session", "is_remote"];
export const keysToSkip = ["entries_to_create", "allow_continue_session", "msg", "message", "last_activity", "activity_id", "activity"];

export const createDefaultObject = (obj) => {
    function setDefaults(value, key) {
        if (Array.isArray(value)) {
            return value.map(item => setDefaults(item, key));
        } else if (value && typeof value === 'object') {
            const newObj = {};
            for (const [k, val] of Object.entries(value)) {
                newObj[k] = setDefaults(val, k);
            }
            return newObj;
        }
        return keysWithActualValues.includes(key) ? obj[key] : null;
    }

    return setDefaults(obj, null);
}

export const validateLogoutModelData = (object) => {
    const isEmpty = (value) => {
        if (value === null || value === undefined) return true;
        if (typeof value === 'string' && value.trim() === '') return true;
        if (Array.isArray(value) && value.length === 0) return true;
        return false;
    };

    const validate = (obj, skippedKeys) => {
        const errors = {};

        if (typeof obj !== 'object' || obj === null) {
            errors._error = 'Invalid object';
            return errors;
        }

        for (const key in obj) {
            if (Object.hasOwn(obj, key)) {
                const value = obj[key];

                if (skippedKeys.includes(key)) {
                    continue;
                }

                if (isEmpty(value)) {
                    errors[key] = 'Required';
                } else if (typeof value === 'object') {
                    const nestedErrors = validate(value, skippedKeys);
                    if (Object.keys(nestedErrors).length > 0) {
                        errors[key] = nestedErrors;
                    }
                }
            }
        }

        return errors;
    };

    const errors = validate(object, keysToSkip);

    const cleanErrors = (err) => {
        for (const key in err) {
            if (Object.hasOwn(err, key)) {
                if (typeof err[key] === 'object' && Object.keys(err[key]).length === 0) {
                    delete err[key];
                } else if (typeof err[key] === 'object') {
                    cleanErrors(err[key]);
                }
            }
        }
    };

    cleanErrors(errors);
    return errors;
}
