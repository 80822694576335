import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    leaveApplicationData: {},
    conflictApplication: [],
    conflictDateArray: [],
    allLeaveDays: [],
    isHalfToFull: false,
    allApplicationData: {}
};

const leaveApplicationSlice = createSlice({
    name: 'leaveApplicationData',
    initialState,
    reducers: {
        setLeaveApplicationData: (state, action) => {
            let payload = action.payload;
            if (!!payload) {
                state.allApplicationData = payload;
            }
            if (!!payload?.leaveApplicationData) {
                state.leaveApplicationData = payload?.leaveApplicationData;
            }
            if (!!payload?.conflictApplication) {
                state.conflictApplication = payload.conflictApplication;
            }
            if (!!payload?.conflictDateArray) {
                state.conflictDateArray = payload.conflictDateArray;
            }
            if (!!payload?.allLeaveDays) {
                state.allLeaveDays = payload.allLeaveDays;
            }
            if (!!payload?.isHalfToFull) {
                state.isHalfToFull = payload.isHalfToFull;
            }
        },
        clearLeaveApplicationData: (state) => {
            state.allApplicationData = {};
            state.leaveApplicationData = {};
            state.conflictApplication = [];
            state.conflictDateArray = [];
            state.allLeaveDays = [];
            state.isHalfToFull = false;
        }
    },
});

export const { setLeaveApplicationData, clearLeaveApplicationData } = leaveApplicationSlice.actions;
export default leaveApplicationSlice.reducer;