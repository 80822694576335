import { Box, FormControl, Grid, InputLabel, MenuItem, Paper, Select, Stack, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TableSortLabel, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react'
import { LEAVE_REPORT_DURATION_LIST } from '../../../../constants/default-values';
import { getApplicationNumber, getApplyDateTime, getDayName, getFormattedDate, getInputId, getLeaveStatus, sortArrayByKey, updateSystemGeneratedLeaveStatus } from '../../../../utils';
import BackgroundLetterAvatars from '../../../../components/BackgroundLetterAvatars';
import ICONS from '../../../../constants/icons';
import useAuthentication from '../../../../hook/useAuthentication';
import axiosInstance from '../../../../axios';
import API from '../../../../axios/api';
import CircularLoader from '../../../../components/CircularLoader';
import { useNavigate } from 'react-router-dom';
import URLS from '../../../../routes/urls';
import TableRowSkeleton from '../../../../components/TableRowSkeleton';
import { visuallyHidden } from '@mui/utils';

const LeaveReport = () => {
  const { getCurrentUser } = useAuthentication();
  const currentUser = getCurrentUser();
  const navigate = useNavigate();
  const [duration, setDuration] = useState(Object.values(LEAVE_REPORT_DURATION_LIST)?.[1] || "");
  const [selectedMonth, setSelectedMonth] = useState("");
  const [sortType, setSortType] = useState("asc");
  const [orderBy, setOrderBy] = useState("user");
  const [yearsData, setYearsData] = useState({
    activeYearMonthData: [],
    renewal_month: "",
  });
  const [leaveReportData, setLeaveReportData] = useState([]);
  const [optionalReportLeaveData, setOptionalReportLeaveData] = useState([]);
  const [firstRender, setFirstRender] = useState(true);

  const [isLoading, setIsLoading] = useState({
    pageLoading: true,
    monthDataLoading: false,
  });
  const [params, setParams] = useState({
    sortField: "user",
    sortOrder: "asc",
  });

  let monthNames = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"]

  useEffect(() => {
    if (currentUser?.organization_id)
      getAllYearData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  useEffect(() => {
    if (!firstRender) {
      getMonthlyLeaveReport();
      setSortType("asc");
      setOrderBy("user");
    }
    setFirstRender(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [duration, selectedMonth])

  useEffect(() => {
    if (optionalReportLeaveData?.length)
      getFilteredData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params])

  const getFilteredData = () => {
    let keyName = orderBy === 'user' ? "User?.name" : orderBy === 'type' ? "UserLeavesTypeSetting?.org_leave_type?.LeaveType?.name" : "status"
    let sortedArray = sortArrayByKey(optionalReportLeaveData, sortType, keyName);
    setLeaveReportData(updateSystemGeneratedLeaveStatus(sortedArray))
  }

  const convertDateString = () => {
    if (duration === Object.values(LEAVE_REPORT_DURATION_LIST)[0]) {
      return getFormattedDate(new Date(), "YYYY-MM-DD")
    } else {
      const [month, year] = selectedMonth.split(' - ');
      let monthNumber = (monthNames.findIndex(monthData => monthData === month) + 1)?.toString().padStart(2, '0');
      return `${new Date().getFullYear()?.toString()?.slice(0, 2)}${year}-${monthNumber}-01`;
    }
  }

  const getMonthlyLeaveReport = async () => {
    try {
      setIsLoading(prev => ({ ...prev, monthDataLoading: true }))
      let payload = duration === Object.values(LEAVE_REPORT_DURATION_LIST)[1] ? {
        date: convertDateString(),
      } : {}
      const response = await axiosInstance.post(API.getLeaveReportList, payload);
      let sortedData = sortArrayByKey(response?.data?.data, 'asc', "User?.name")
      setLeaveReportData(updateSystemGeneratedLeaveStatus(sortedData));
      setOptionalReportLeaveData(response?.data?.data);
      setIsLoading(prev => ({ ...prev, monthDataLoading: false }))
    } catch (error) {
      console.error(error);
      setIsLoading(prev => ({ ...prev, monthDataLoading: false }))
    }
  }

  const generateMonthYearArray = (startMonth, startYear) => {
    const result = [];
    let monthIndex = monthNames.indexOf(startMonth);
    let year = startYear;

    for (let i = 0; i < 12; i++) {
      const shortYear = year?.toString().slice(-2);
      result.push(`${monthNames[monthIndex]} - ${shortYear}`);

      monthIndex++;

      if (monthIndex > 11) {
        monthIndex = 0;
        year++;
      }
    }
    return result;
  }

  const getCurrentMonthAndYear = (date) => {
    let month = monthNames[new Date(date)?.getMonth()];
    let year = new Date(date).getFullYear()?.toString()?.slice(2);
    return `${month} - ${year}`;
  }

  const getAllYearData = async () => {
    try {
      const config = {
        params: {
          renewalMonth: true
        },
      };
      setIsLoading((prev) => ({ ...prev, pageLoading: true }));
      const response = await axiosInstance.get(API.getHolidayYears(currentUser?.organization_id), config);
      if (response.status === 200) {
        let allMonths = generateMonthYearArray(monthNames[new Date(response?.data?.result?.find((yearData) => yearData?.active)?.setting_start_date)?.getMonth()],
          new Date(response?.data?.result?.find((yearData) => yearData?.active)?.setting_start_date)?.getFullYear());
        setYearsData({
          activeYearMonthData: allMonths,
          renewal_month: monthNames[new Date(response?.data?.result?.find((yearData) => yearData?.active)?.setting_start_date)?.getMonth()],
          activeYearData: response?.data?.result?.find((yearData) => yearData?.active)
        });
        let currentMonth = getCurrentMonthAndYear(response?.data?.result?.find((yearData) => yearData?.active)?.setting_start_date);
        setSelectedMonth(currentMonth)

        setIsLoading((prev) => ({ ...prev, pageLoading: false }));
      } else {
        setIsLoading((prev) => ({ ...prev, pageLoading: false }));
      }
    } catch (error) {
      console.error(error);
      setIsLoading((prev) => ({ ...prev, pageLoading: false }));
    }
  };

  const handleChangeDuration = (event) => {
    setDuration(event.target.value)
  }

  const handleChangeMonth = (event) => {
    setSelectedMonth(event?.target?.value);
  }

  const handleSortClick = (property) => {
    const isAsc = (orderBy === property) ? (sortType === 'asc') ? true : false : true;
    setSortType(isAsc ? 'desc' : 'asc');
    setParams((prev) => ({ ...prev, sortOrder: isAsc ? 'desc' : 'asc', sortField: property }))
    setOrderBy(property);
  }

  const getSelectedMonthString = () => {
    let [month, year] = selectedMonth?.split(" - ");
    return `${month} - ${new Date().getFullYear()?.toString()?.slice(0, 2)}${year}`
  }

  const IsDisableOption = (monthYear) => {
    let monthName = monthNames[new Date(currentUser?.organization?.createdAt)?.getMonth()];
    let [month, year] = monthYear?.split(" - ");
    let activeYear = `${new Date(yearsData?.activeYearData?.setting_end_date)?.getFullYear()?.toString()?.slice(0, 2)}${year}`
    let orgCreateYear = new Date(currentUser?.organization?.createdAt)?.getFullYear()?.toString();

    if (orgCreateYear === activeYear) {
      let orgCreateMonthIndex = yearsData?.activeYearMonthData?.findIndex(monthData => {
        let [month] = monthData?.split(" - ");
        return month === monthName
      });
      let currentMonthIndex = yearsData?.activeYearMonthData?.findIndex(monthData => {
        let [currentMonth] = monthData?.split(" - ");
        return currentMonth === month
      });
      return (currentMonthIndex < orgCreateMonthIndex) && (yearsData?.activeYearData?.year?.toString() === orgCreateYear)
    }
    return false
  }

  const handleLeaveClick = (leave) => {
    let { user_id, id } = leave;
    navigate(`${URLS.OtherLeaveDetails}/${user_id}/${id}`);
  };

  return (isLoading.pageLoading ?
    <CircularLoader height="500px" /> :
    <Box sx={{ padding: { sm: '16px !important', lg: '24px !important', xl: '32px !important' }, width: '100%', overflow: 'hidden', height: '100%', display: 'flex', flexDirection: 'column' }}>
      <Stack spacing={{ xs: 2, lg: 3 }}>
        <Grid container alignItems="flex-start">
          <Grid item display="flex" alignItems="flex-end">
            <FormControl
              variant="standard"
              sx={{ mr: { xs: 2, lg: 3, xl: 4 }, minWidth: { xs: 160, md: 180 }, maxWidth: { xs: 160, md: 180 } }}>
              <InputLabel htmlFor={getInputId("duration", 0)}>
                Duration
              </InputLabel>
              <Select
                id='duration'
                name='duration'
                value={duration || ""}
                onChange={(e) => handleChangeDuration(e)}
                label="Duration"
                inputProps={{
                  id: getInputId("duration", 0),
                }}
              >
                {Object.values(LEAVE_REPORT_DURATION_LIST)?.map(
                  (name, index) => (
                    <MenuItem key={index} value={name}>
                      {name}
                    </MenuItem>
                  )
                )}
              </Select>
            </FormControl>
          </Grid>
          {duration === Object.values(LEAVE_REPORT_DURATION_LIST)?.[1] ? <Grid item display="flex" alignItems="flex-end">
            <FormControl
              variant="standard"
              sx={{ mr: { xs: 2, lg: 3, xl: 4 }, minWidth: { xs: 160, md: 180 }, maxWidth: { xs: 160, md: 180 } }}>
              <InputLabel htmlFor={getInputId("month", 0)}>
                Select Month
              </InputLabel>
              <Select
                id='month'
                value={selectedMonth || ""}
                onChange={(e) => handleChangeMonth(e)}
                label="Select Month"
                inputProps={{
                  id: getInputId("month", 0),
                }}
              >
                {yearsData?.activeYearMonthData?.map(
                  (name, index) => (
                    <MenuItem disabled={IsDisableOption(name)} key={index} value={name}>
                      {name}
                    </MenuItem>
                  )
                )}
              </Select>
            </FormControl>
          </Grid> : null}
        </Grid>
      </Stack>
      {duration === Object.values(LEAVE_REPORT_DURATION_LIST)?.[1] ?
        <Box pt={{ xs: 2, lg: 2, xl: 3 }}>
          <Typography color="secondary" fontSize={18} fontWeight={600} lineHeight={'27px'} letterSpacing={'0.15px'}>
            {getSelectedMonthString()}
          </Typography>
        </Box>
        : null}
      <Box pt={{ xs: 3, lg: 3, xl: 4 }} overflow="hidden"
        sx={{
          maxHeight: '100%',
          '> div': {
            height: '100%',
            display: 'flex',
          }
        }}
      >
        <Paper
          sx={{
            border: "1px solid #E0E0E0",
            borderRadius: "3px",
            boxShadow: 'none',
          }}>
          <TableContainer>
            <Table
              sx={{ minWidth: 750 }}
              aria-labelledby="tableTitle"
              size={'medium'}
            >
              <TableHead>
                <TableRow sx={{
                  whiteSpace: 'nowrap',
                  'th': {
                    bgcolor: "bgColor",
                    color: "dark.800",
                    fontWeight: 500,
                    fontSize: 14,
                    lineHeight: "24px",
                    letterSpacing: "0.17px",
                    padding: "5px 16px",
                    textAlign: "left",
                    background: "rgba(245, 248, 250, 1)",
                    position: 'sticky',
                    top: '0px',
                    boxShadow: 'inset 0px -1px 0px #e0e0e0',
                    zIndex: '1',
                    borderBottom: 'none',
                  }
                }}>
                  <TableCell width={"180px"} sx={{ minWidth: '180px' }}>
                    Date
                  </TableCell>
                  <TableCell sx={{ minWidth: '230px' }}>
                    <TableSortLabel
                      active={orderBy === "user"}
                      direction={orderBy === "user" ? sortType : "asc"}
                      onClick={() => handleSortClick("user")}
                    >
                      User
                      {orderBy === 'user' ? (
                        <Box component="span" sx={visuallyHidden}>
                          {params?.sortOrder === 'desc' ? 'sorted descending' : 'sorted ascending'}
                        </Box>
                      ) : null}
                    </TableSortLabel>
                  </TableCell>
                  <TableCell width={"120px"} sx={{ minWidth: '120px' }}>
                    <TableSortLabel
                      active={orderBy === "type"}
                      direction={orderBy === "type" ? sortType : "asc"}
                      onClick={() => handleSortClick("type")}
                    >
                      Type
                      {orderBy === 'type' ? (
                        <Box component="span" sx={visuallyHidden}>
                          {params?.sortOrder === 'desc' ? 'sorted descending' : 'sorted ascending'}
                        </Box>
                      ) : null}
                    </TableSortLabel>
                  </TableCell>
                  <TableCell width={"120px"} sx={{ minWidth: '120px' }}>
                    Leave
                  </TableCell>
                  <TableCell width={"120px"} sx={{ minWidth: '120px' }}>
                    Full / Half
                  </TableCell>
                  <TableCell width={"190px"} sx={{ minWidth: '190px' }}>
                    Apply
                  </TableCell>
                  <TableCell width={"120px"} sx={{ minWidth: '120px' }}>
                    <TableSortLabel
                      active={orderBy === "status"}
                      direction={orderBy === "status" ? sortType : "asc"}
                      onClick={() => handleSortClick("status")}
                    >
                      Status
                      {orderBy === 'status' ? (
                        <Box component="span" sx={visuallyHidden}>
                          {params?.sortOrder === 'desc' ? 'sorted descending' : 'sorted ascending'}
                        </Box>
                      ) : null}
                    </TableSortLabel>
                  </TableCell>
                  {/* <TableCell width={"65px"} sx={{ minWidth: '65px' }}></TableCell> */}
                </TableRow>
              </TableHead>
              <TableBody
                sx={{
                  "tr:hover": {
                    background: "rgba(247, 247, 247, 1)",
                  },
                  td: {
                    color: "dark.800",
                    fontWeight: 400,
                    fontSize: 14,
                    lineHeight: "20px",
                    letterSpacing: "0.17px",
                    padding: "7px 16px",
                    textAlign: "left",
                    height: "44px",
                  },
                }}
              >
                {isLoading?.monthDataLoading ?
                  <TableRowSkeleton rows={5} cols={7} /> :
                  leaveReportData?.length ? (
                    leaveReportData.map((listData, index) => (
                      listData?.leave_days?.map((leaveData, index) => (
                        <TableRow hover index={1} key={index} sx={{ cursor: 'pointer' }} onClick={() => handleLeaveClick(listData)}>
                          <TableCell width={"180px"}>
                            {/* <TableCell><TableCell>03</TableCell>/10/2024</TableCell> */}
                            <Typography color="dark.800" fontWeight={400} fontSize={14} lineHeight={"20px"} letterSpacing={"0.17px"} display={"inline-block"} component={'div'}><Typography fontWeight={600} fontSize={16} lineHeight={"22px"} display={"inline"}>{getFormattedDate(leaveData?.leave_date)?.split('/').slice(0, 1)}</Typography>/{getFormattedDate(leaveData?.leave_date)?.split('/').slice(1).join('/')}</Typography>
                            {/* {getFormattedDate(leaveData?.leave_date)?.split('/').} */}
                            <Typography color="dark.500" fontWeight={500} fontSize={12} ml={0.5} display="inline-block">
                              {`(${getDayName(getFormattedDate(leaveData?.leave_date))?.slice(0, 3)?.toUpperCase()})`}
                            </Typography>
                          </TableCell>
                          <TableCell>
                            <Box display={'flex'} alignItems={'center'}>
                              <BackgroundLetterAvatars
                                user={listData?.User || {}}
                                sx={{ width: 30, height: 30, mr: 1, fontSize: "13px", fontWeight: '500', }}
                              />
                              <Typography fontSize={14} fontWeight={500} sx={{ lineHeight: '22px', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }} flex={1}>{listData?.User?.name}</Typography>
                            </Box>
                          </TableCell>
                          <TableCell width={"120px"}>
                            <Stack direction={'row'} gap={0.5} alignItems={'center'} justifyItems={'center'}>
                              <Typography color="dark.800" fontWeight={400} fontSize={14} lineHeight={"21px"} >
                                {listData?.UserLeavesTypeSetting?.org_leave_type?.LeaveType?.name?.split(" ")?.[0]}
                              </Typography>
                              <Typography color="dark.800" fontWeight={400} fontSize={14} lineHeight={"21px"} >
                                {listData?.is_auto_generated ?
                                  " (System)"
                                  : null}
                              </Typography>
                            </Stack>
                          </TableCell>
                          <TableCell width={"120px"}>
                            <Box display={'inline-flex'} alignItems={'center'}
                            // sx={{
                            //   cursor: 'pointer',
                            //   '&:hover': {
                            //     color: "primary.main"
                            //   },
                            //   '&:hover p': {
                            //     color: "primary.main",
                            //     textDecoration: "underline",
                            //   },
                            //   '&:hover i': {
                            //     opacity: "1 !important",
                            //   },
                            // }}
                            // onClick={() => handleLeaveClick(listData)}
                            >
                              <Typography fontSize={14} fontWeight={500} mr={"5px"}>
                                {getApplicationNumber(listData?.application_num)}
                              </Typography>
                              <i style={{ width: '14px', height: '14px', opacity: '0' }}>
                                {ICONS.ExternalLinkIcon}
                              </i>
                            </Box>
                          </TableCell>
                          <TableCell width={"120px"}>
                            {leaveData?.leave_duration !== "full" ? leaveData?.leave_duration === "first_half" ? `1st half` : `2nd half` : `Full-day`}
                          </TableCell>
                          <TableCell width={"190px"}>
                            {getApplyDateTime(listData?.createdAt || listData?.apply_date, "DD/MM/YYYY hh:mm a")}
                          </TableCell>
                          <TableCell width={"120px"}>
                            {getLeaveStatus(listData?.status, "chip")}
                          </TableCell>
                          {/* <TableCell width={"65px"}>
                          <IconButton
                            aria-label="more"
                            aria-haspopup="true"
                            // onClick={handleClick()}
                            sx={{
                              height: "30px",
                              width: "30px",
                              padding: "0px",
                              borderRadius: "4px",
                            }}
                            id="basic-button"
                          // aria-controls={open ? 'basic-menu' : ""}
                          // aria-expanded={open ? 'true' : "false"}
                          >
                            <i
                              style={{
                                height: "20px",
                                width: "20px",
                                display: "flex",
                              }}
                            >
                              {ICONS.DotsHorizontal}
                            </i>
                          </IconButton>
                        </TableCell> */}
                        </TableRow>
                      ))
                    ))
                  ) : <TableRow
                    style={{
                      height: 53,
                    }}
                  >
                    <TableCell
                      colSpan={7}
                      align="center"
                      sx={{ padding: "12px 16px;" }}
                    >
                      <Typography
                        color={"secondary"}
                        display={"flex"}
                        alignItems={"center"}
                        justifyContent={"center"}
                        fontSize={13}
                        sx={{ opacity: "0.5", height: "200px" }}
                      >
                        No Leave Available
                      </Typography>
                    </TableCell>
                  </TableRow>}
              </TableBody>
            </Table>
          </TableContainer>
        </Paper>
      </Box>
    </Box>
  )
}

export default LeaveReport