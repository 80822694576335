/* eslint-disable react-hooks/exhaustive-deps */
import {
  Box,
  Button,
  Chip,
  Divider,
  Grid,
  List,
  ListItem,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TableRow,
  Tooltip,
  Typography,
  tooltipClasses,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import ICONS from "../../../../constants/icons";
import CheckCircleRoundedIcon from "@mui/icons-material/CheckCircleRounded";
import { styled } from "@mui/styles";
import CustomModal from "../../../../components/CustomModal";
import TextEditor from "../../../../components/TextEditor";
import FileDropZone from "../../../../components/FileDropZone";
import { useAlert } from "../../../../hook/useAlert";
import axiosInstance from "../../../../axios";
import API from "../../../../axios/api";
import CircularLoader from "../../../../components/CircularLoader";
import { LEAVE_DURATION_TYPE, LEAVE_STATUS } from "../../../../constants/default-values";
import moment from "moment";
import { getApplicationNumber, getApplyDateTime, getDayName, getFormattedDate, getFullName, getLeaveDateArray, getLeaveStatus, showInformationAuthority, sortArrayByKey, titleCase, updateSystemGeneratedLeaveApplicationStatus } from "../../../../utils";
import ErrorOutlineRoundedIcon from "@mui/icons-material/ErrorOutlineRounded";
import CommentRoundedIcon from '@mui/icons-material/CommentRounded';
import { useNavigate, useParams } from "react-router-dom";
import EmptyPage from "../../../../components/EmptyPage";
import { usePageTitle } from "../../../../hook/useTitle";
import BackgroundLetterAvatars from "../../../../components/BackgroundLetterAvatars";
import UserRoleChip from "../../../../components/UserRoleChip";
import useAuthentication from "../../../../hook/useAuthentication";
import URLS from "../../../../routes/urls";
import Breadcrumb from "../../../../components/Breadcrumb";
import DesktopMacRoundedIcon from '@mui/icons-material/DesktopMacRounded';
import CancelRoundedIcon from '@mui/icons-material/CancelRounded';
import CancelPresentationOutlinedIcon from '@mui/icons-material/CancelPresentationOutlined';
import PageNotFound from "../../../PageNotFound";
import useQuery from "../../../../hook/useQuery";
import secureLocalStorage from "react-secure-storage";

const HtmlTooltip = styled(({ className, ...props }) => (
  <Tooltip arrow {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    padding: "16px",
    borderRadius: "5px",
    background: "#444444",
  },
}));

const OthersLeaveDetails = () => {
  const showAlert = useAlert();
  const navigate = useNavigate();
  const { getCurrentUser } = useAuthentication();
  const currentUser = getCurrentUser();
  const params = useParams();
  const { getQueryParams } = useQuery();
  const previousPage = getQueryParams('previousPage')
  const { setPageTitle } = usePageTitle();
  const initialModalDetails = {
    isShow: false,
    type: null,
    comment: "",
  };

  const [modalDetails, setModalDetails] = useState(initialModalDetails);
  const [leaveDetails, setLeaveDetails] = useState(null);
  const [leaveSnapshot, setLeaveSnapshot] = useState(null);
  const [isLoading, setIsLoading] = useState({
    leaveDetails: true,
    modalButtonLoading: false
  })
  const [repotee, setReportee] = useState([]);
  const isRepotee = secureLocalStorage.getItem("isReportee");

  setPageTitle("Other's Leave Details");

  useEffect(() => {
    if (currentUser?.id) {
      if (Number(currentUser?.id) !== Number(params?.userId)) {
        getUserSettingData(params?.userId, params?.appId);
      } else {
        navigate(URLS.AccessDenied)
      }
    }
  }, [params])

  useEffect(() => {
    if (isRepotee) {
      getUsersRepoteesData()
    }
  }, [])

  const getTotalCreditUsedCount = (leave_array) => {
    let totalCreditUsed = 0;
    leave_array?.forEach((leave) => {
      totalCreditUsed += leave?.credit_used || 0;
    });
    return totalCreditUsed || 0;
  };

  const handleOpen = (type) => {
    setModalDetails({
      isShow: true,
      type,
    });
  };

  const getApplyDays = (leave_days) => {
    let count = leave_days?.reduce((previousValue, currentValue, currentIndex, array) => {
      let paidCount = currentValue?.paid || 0;
      let unPaidCount = currentValue?.unpaid || 0
      previousValue.paidCount = previousValue.paidCount + paidCount;
      previousValue.unPaidCount = previousValue.unPaidCount + unPaidCount;
      previousValue.totalCount = previousValue.paidCount + previousValue.unPaidCount;
      previousValue.totalDays = array?.length || 0
      return previousValue;
    }, { paidCount: 0, unPaidCount: 0, totalCount: 0, totalDays: 0, });
    return count;
  }

  const handleSubmitLeaveApplication = async (status, comment) => {
    try {
      setIsLoading((prev) => ({ ...prev, modalButtonLoading: true }))
      let updatedComment = comment?.trim()?.replace(/\s{2,}/g, " ");
      let payload = {
        "processed_description": updatedComment,
        "status": leaveDetails?.status,
        "action": status
      };

      const response = await axiosInstance.put(
        API.updateLeaveStatus(params?.userId, params?.appId),
        payload,
      );
      if (response.status === 200) {
        showAlert(response?.data.message);
        setIsLoading((prev) => ({ ...prev, modalButtonLoading: false }))
        navigate(`${URLS.Leaves}?view=list&tabValue=0&groupBy=Status&status=active`);
        // navigate(-1);
      }
      else {
        showAlert(response?.response?.data.message, "error");
        setIsLoading((prev) => ({ ...prev, modalButtonLoading: false }))
        // navigate(-1);
      }
    } catch (error) {
      console.error("Error:", error);
      showAlert(error?.response?.data.message, "error");
      setIsLoading((prev) => ({ ...prev, modalButtonLoading: false }))
      // navigate(-1);
    }
  };

  const handleApprove = () => {
    let status = modalDetails?.type;
    if (!!modalDetails?.comment && modalDetails?.comment?.trim()?.length) {
      handleSubmitLeaveApplication(status, modalDetails?.comment)
      setModalDetails(initialModalDetails);
    } else {
      setModalDetails((prev) => ({ ...prev, error: "Required" }));
    }
  };

  const handleCancel = () => {
    setModalDetails(initialModalDetails);
  };

  const getUserSettingData = async (user_id, id) => {
    try {
      const response = await axiosInstance.get(API.getLeaveById(user_id, id));
      const response2 = await axiosInstance.get(API.getLeaveBalance(user_id));
      if (response2.status === 200) {
        setLeaveSnapshot(response2?.data?.result);
      }
      if (response.status === 200) {
        setLeaveDetails(updateSystemGeneratedLeaveApplicationStatus(response?.data?.result) || {})
        setIsLoading((prev) => ({ ...prev, leaveDetails: false }));
      } else {
        setIsLoading((prev) => ({ ...prev, leaveDetails: false }));
      }
    } catch (error) {
      console.error(error);
      setIsLoading((prev) => ({ ...prev, leaveDetails: false }));
    }
  };

  const getRemainingCreditsData = () => {
    return leaveSnapshot?.find((data) => Object.keys(data)?.includes('specialCredits'))
  }

  const getMatchData = (leaveDetail) => {
    let usedSpecialRequest = [...leaveDetail?.specialRequest];
    const isExistRule = (creditListData) => {
      return usedSpecialRequest?.includes(creditListData?.id)
    }

    return (
      <>
        <HtmlTooltip
          title={
            <React.Fragment>
              <Typography color="white" mb="0">
                {/* {leaveDetail?.match === 'fully' ? 'Match' : leaveDetail?.match === "true" ?
                  `${leaveDetail?.specialRequest?.length} credit will be used` : 'Request Status'} */}
                Request Status
              </Typography>
              <List
                sx={{
                  padding: 0,
                  // marginLeft: '25px',
                  li: {
                    listStyle: "decimal",
                    display: "list-item",
                    paddingLeft: '5px',
                    fontSize: '12px',
                    fontWeight: '500',
                    lineHeight: '17px',
                    paddingBottom: '0px',
                    marginBottom: '0px'
                  },
                  'li:last-child': {
                    marginBottom: '0px'
                  }
                }}
              >
                {leaveDetail?.match === 'fully' ?
                  getRemainingCreditsData()?.specialCreditsList?.length ?
                    getRemainingCreditsData()?.specialCreditsList?.filter(data => data?.leave_credit)?.map((creditListData, index) => (
                      <ListItem sx={{ listStyle: 'none !important', paddingLeft: '0 !important', 'svg': { display: 'block', } }} key={index}>
                        <i style={{ background: '#ffffff', height: '18px', width: '18px', borderRadius: '50%', marginRight: '10px', display: 'inline-block', verticalAlign: 'Top' }}>
                          {ICONS.CheckIcon}
                        </i>{titleCase(creditListData?.name)}</ListItem>
                    )) : null : getRemainingCreditsData()?.specialCreditsList?.filter(data => data?.leave_credit)?.map((creditListData, index) => (
                      <ListItem sx={{ listStyle: 'none !important', paddingLeft: '0 !important', 'svg': { display: 'block', } }} key={index}>
                        <i style={{ background: '#ffffff', height: '18px', width: '18px', borderRadius: '50%', marginRight: '10px', display: 'inline-block', verticalAlign: 'Top' }}>
                          {isExistRule(creditListData) ? ICONS.CrossIcon : ICONS.CheckIcon}
                        </i>{titleCase(creditListData?.name)}</ListItem>
                    ))}
              </List>
            </React.Fragment>
          }
        >
          <i
            style={{
              height: 20,
              width: 20,
              display: "inline-block",
              verticalAlign: "middle",
            }}
          >
            {leaveDetail?.match === 'fully' ? <CheckCircleRoundedIcon
              color="primary"
              sx={{ fontSize: 20 }}
            /> : <ErrorOutlineRoundedIcon
              color={leaveDetail?.match === "true" ? "primary" : "error"}
              sx={{ fontSize: 20 }}
            />}
          </i>
        </HtmlTooltip>
      </>
    )
  }

  const checkApplicationEnable = (leaveData, isEdit) => {
    const minLeaveDate = leaveData?.leave_days.reduce((min, leave) => {
      const leaveDate = new Date(leave.leave_date);
      return leaveDate < min ? leaveDate : min;
    }, new Date(leaveData?.leave_days[0].leave_date));

    const today = new Date();
    today.setHours(0, 0, 0, 0);

    const result = isEdit ? today >= minLeaveDate : today <= minLeaveDate;
    return result;
  }

  const getUsersRepoteesData = async () => {
    try {
      const response = await axiosInstance.get(API.getRepoteesListByUserId)
      if (response?.status === 200) {
        if (response.data?.userData) {
          setReportee(response.data?.userData)
        }
      }
    } catch (error) {
      console.error(error);
    }
  }

  const isShowButton = (leaveData) => {
    let isAdmin = currentUser?.is_admin;
    if (isAdmin) {
      return true
    } else {
      return checkApplicationEnable(leaveData) || (isRepotee && leaveDetails?.is_auto_generated && leaveDetails?.status === LEAVE_STATUS.Pending && !!repotee?.find(userData => userData?.id === leaveDetails?.user_id))
    }
  }

  const handleClickBack = () => {
    switch (previousPage) {
      case 'notification':
        navigate(URLS.AllNotifications)
        break;
      case 'dashboard':
        navigate(URLS.Dashboard)
        break;
      default:
        navigate(-1);
        break;
    }
  }

  const getPreviousPageName = () => {
    return previousPage === 'notification' ? 'Notifications' : previousPage === 'dashboard' ? 'Dashboard' : 'Other’s Leaves'
  }

  const handleEditLeave = () => {
    navigate(`${URLS.EditOthersLeaveApplication}/${leaveDetails?.user_id}/${leaveDetails?.id}`)
  }

  return (
    isLoading?.leaveDetails ?
      <CircularLoader height="600px" /> :
      !leaveDetails ?
        <PageNotFound showSideBar={false} /> :
        <Grid width="100%" display={"flex"} flexDirection={"column"}>
          <Breadcrumb isBack={true} title={getPreviousPageName()}
            pageTitle={'Leave Detail'}
            isPathShow={false}
            onBackClick={() => handleClickBack()} />

          <Box p={{ xs: 2, lg: 3, xl: 4 }} py={{ xs: 3, lg: 3, xl: 4 }} width="100%" flex={1} overflow="auto">
            <Box pb={1} display={"flex"} alignItems={"center"} justifyContent={"space-between"}>
              <Typography
                color="dark.800"
                variant="title"
                fontSize={21}
                fontWeight={600}
                lineHeight={"31px"}
                letterSpacing={"0.17px"}
                mr={2}
              >
                {getApplicationNumber(leaveDetails?.application_num)}
              </Typography>
              <Box display="flex" alignItems="center">
                <Typography color="dark.800" fontSize={14} fontWeight={500} lineHeight={"24px"} letterSpacing={"0.15px"} pr={1}>{leaveDetails?.is_auto_generated ? 'Generated for' : 'Applied by'}</Typography>
                <Box display="flex" alignItems="center" pr={1} overflow="hidden">
                  <BackgroundLetterAvatars
                    user={leaveDetails?.userData}
                    src={leaveDetails?.userData?.profile_img || "image.png"}
                    sx={{ width: 32, height: 32, fontSize: '100%' }}
                  />
                  <Box ml={1} display="flex" overflow="hidden">
                    <Box display="flex" alignItems={"center"}>
                      <Typography
                        variant="body1"
                        color="dark.800"
                        fontSize={16}
                        fontWeight={500}
                        whiteSpace="nowrap"
                        overflow="hidden"
                        textOverflow="ellipsis"
                        lineHeight={"24px"}
                        letterSpacing={"0.17px"}
                        mr={1}
                      >
                        {getFullName(leaveDetails?.userData)}
                      </Typography>
                      {leaveDetails?.userData?.is_dedicated_developer ?
                        <Chip
                          label="Dedicated"
                          color="secondary"
                          size="small"
                          variant="outlined"
                        /> : null}
                    </Box>
                    <Box>
                      <UserRoleChip size="medium" label={titleCase(leaveDetails?.userData?.user_role) || "-"} />
                    </Box>
                  </Box>
                </Box>
                {/* <Link href="#" color="blue.primary" fontSize={14} fontWeight={500} lineHeight={"24px"} letterSpacing={"0.15px"} ml={1}>See details</Link> */}
              </Box>
            </Box>
            <Box px={3} pt={3} pb={2} mb={2} borderRadius={'4px'} sx={{ background: "#F5F8FA" }}>
              <Box display="flex" alignItems="center" justifyContent="space-between" mb={1}>
                <Box display="flex" alignItems="center" flex={"1"}>
                  {leaveDetails?.is_auto_generated ?
                    <Box sx={{ maxWidth: '34px', width: '34', height: '34px', flex: '0 0 34px', background: '#3F5C76', borderRadius: '100%' }} mr={1} display={"flex"} alignItems={"center"} justifyContent={"center"}>
                      <DesktopMacRoundedIcon sx={{ fontSize: '18px', color: '#ffffff' }} />
                    </Box> : null}
                  {getLeaveStatus(leaveDetails?.status, "chip", "medium")}
                  <Typography color="dark.800" fontSize={22} fontWeight={500} lineHeight={"33px"} letterSpacing={"0.17px"}>{leaveDetails?.is_auto_generated ? "System Generated" : null} {leaveDetails?.is_auto_generated ? `(${titleCase(leaveDetails?.leave_type)})` : titleCase(leaveDetails?.leave_type)}</Typography>
                </Box>
                {/* <Button variant="outlined" color="secondary" fontSize={14} lineHeight={"24px"} fontWeight={500}
                  sx={{
                    textTransform: 'none',
                    'svg': {
                      width: '22px',
                      height: '22px',
                      maxWidth: '22px',
                      mr: 1,
                    }
                  }}
                >
                  {ICONS.AiIcon}
                  AI Insights</Button> */}
              </Box>
              <Box mb={1.5}>
                <Typography
                  color="dark.800"
                  fontSize={14}
                  fontWeight={500}
                  lineHeight={"24px"}
                  letterSpacing={"0.15px"}
                  component={"div"}
                >
                  <Typography
                    fontSize={14} fontWeight={700} lineHeight={"24px"} letterSpacing={"0.15px"}
                    display={"inline-block"}
                    mr={0.5}
                  >
                    Informed to:
                  </Typography>
                  {showInformationAuthority(leaveDetails?.informed_authorities) || "-"}
                </Typography>
              </Box>
              <Divider component={"div"} />
              <Grid container mt={1.5} width={"100%"} display={"flex"} flexWrap={"nowrap"} gap={6}>
                <Grid item>
                  <Typography variant="body1" display={"block"} mb={0.5} color={"dark.800"} fontSize={14} fontWeight={700} lineHeight={"21px"} letterSpacing={"0.15px"}>{leaveDetails?.is_auto_generated ? "Generated on" : "Applied on"}</Typography>
                  <Typography color={"dark.800"} fontSize={14} fontWeight={500} lineHeight={"24px"} letterSpacing={"0.15px"}>{getApplyDateTime(leaveDetails?.createdAt || leaveDetails?.apply_date)}</Typography>
                </Grid>
                {leaveDetails?.updated_by?.length > 0 ?
                  <Grid item>
                    <Typography variant="body1" display={"block"} mb={0.5} color={"dark.800"} fontSize={14} fontWeight={700} lineHeight={"21px"} letterSpacing={"0.15px"}>Updated</Typography>
                    <Typography color={"dark.800"} fontSize={14} fontWeight={500} lineHeight={"24px"} letterSpacing={"0.15px"}>
                      <b>{showInformationAuthority(leaveDetails?.updated_by)}</b> at ({getApplyDateTime(leaveDetails?.updated_date)})
                    </Typography>
                  </Grid>
                  : null}
                {leaveDetails?.status?.toLowerCase() === "pending" ?
                  <Grid item>
                    <Typography color={"dark.800"} fontSize={14} fontWeight={500} lineHeight={"24px"} letterSpacing={"0.15px"} fontStyle={"italic"}>Waiting for approval...</Typography>
                  </Grid> :
                  <Grid item>
                    <Typography variant="body1" display={"flex"} alignItems={"center"} mb={0.5} color={"dark.800"} fontSize={14} fontWeight={700} lineHeight={"21px"} letterSpacing={"0.15px"} component={"div"}>
                      {leaveDetails?.status?.toLowerCase() === LEAVE_STATUS.Approved ?
                        <CheckCircleRoundedIcon sx={{ color: '#12B495', marginRight: '6px', fontSize: '20px' }} /> :
                        leaveDetails?.status?.toLowerCase() === LEAVE_STATUS.Cancelled ?
                          <CancelPresentationOutlinedIcon sx={{ color: '#3F5C76', marginRight: '6px', fontSize: '20px' }} /> :
                          <CancelRoundedIcon sx={{ color: '#D32F2F', marginRight: '6px', fontSize: '20px' }} />
                      }
                      {leaveDetails?.status?.toLowerCase() === LEAVE_STATUS.Approved ? "Approved by" :
                        leaveDetails?.status?.toLowerCase() === LEAVE_STATUS.Cancelled ? "Cancelled by" :
                          "Rejected by"}
                    </Typography>
                    <Typography
                      color="dark.800" fontSize={14} fontWeight={500} lineHeight={"24px"} letterSpacing={"0.15px"} mb={0.5} component={"div"}>
                      <Typography fontSize={14} fontWeight={600} lineHeight={"24px"} letterSpacing={"0.15px"} display={"inline-block"} mr={0.5}>
                        {showInformationAuthority(leaveDetails?.processed_by) || "-"}
                      </Typography>
                      (at {leaveDetails?.process_date ? getApplyDateTime(leaveDetails?.process_date) : "-"})
                    </Typography>
                    <Box display={"flex"}>
                      <Typography color={"text.primary"} display={"flex"} fontSize={13} fontWeight={500} lineHeight={"24px"} letterSpacing={"0.15px"} component={"div"}>
                        <CommentRoundedIcon sx={{ color: '#3F5C76', fontSize: '20px', margin: '4px 8px 0px 4px' }} mr={1.5} />
                        {titleCase(leaveDetails?.processed_description)}
                      </Typography>
                    </Box>
                  </Grid>}
              </Grid>
            </Box>
            <Box mb={3}>
              <Stack spacing={2}>
                <Box>
                  <TableContainer
                    component={Paper}
                    sx={{ border: "1px solid #E0E0E0", boxShadow: "none" }}
                  >
                    <Table sx={{ minWidth: 560 }} aria-label="simple table">
                      <TableHead sx={{ background: "rgba(0, 0, 0, 0.01)" }}>
                        <TableRow
                          sx={{
                            th: {
                              color: "dark.800",
                              fontWeight: 500,
                              fontSize: 14,
                              lineHeight: "24px",
                              letterSpacing: "0.15px",
                              padding: "6px 16px",
                              whiteSpace: "nowrap",
                            },
                          }}
                        >
                          <TableCell>Date</TableCell>
                          <TableCell>Day</TableCell>
                          <TableCell>Duration</TableCell>
                          {leaveDetails?.status === LEAVE_STATUS.Approved ? <TableCell width={160} align="center">
                            <span style={{ width: '38px', display: 'inline-block', textAlign: 'right', marginRight: '4px' }}>Paid</span>|
                            <span style={{ width: '50px', display: 'inline-block', marginLeft: '4px' }}>Unpaid</span>
                          </TableCell> : null}
                          {leaveDetails?.allow_special_credits ?
                            <>
                              <TableCell width={120}>
                                Match
                                <HtmlTooltip
                                  title={
                                    <React.Fragment>
                                      <Typography color="white" mb="0">
                                        Match
                                      </Typography>
                                      <List
                                        sx={{
                                          padding: 0,
                                          marginLeft: '15px',
                                          li: {
                                            listStyle: "decimal",
                                            display: "list-item",
                                            paddingLeft: '5px',
                                            fontSize: '12px',
                                            fontWeight: '500',
                                            lineHeight: '17px',
                                            paddingBottom: '0px',
                                            marginBottom: '8px'
                                          },
                                          'li:last-child': {
                                            marginBottom: '4px'
                                          }
                                        }}
                                      >
                                        <ListItem>Applying Leave exceeding minimum days required before applying particular leave.</ListItem>
                                        <ListItem>Over lapping max absent allowed for organization rule.</ListItem>
                                        <ListItem>Exceeding max club leave allowed.</ListItem>
                                        <ListItem>Unpaid leaves</ListItem>
                                      </List>
                                    </React.Fragment>
                                  }
                                >
                                  <i
                                    style={{
                                      width: "18px",
                                      height: "18px",
                                      display: "inline-block",
                                      verticalAlign: "middle",
                                      marginLeft: "3px",
                                      color: "#3F5C76",
                                    }}
                                  >
                                    {ICONS.Info}
                                  </i>
                                </HtmlTooltip>
                              </TableCell>
                              <TableCell width={110}>Credits</TableCell>
                            </> : null
                          }
                        </TableRow>
                      </TableHead>
                      <TableBody
                      // sx={{
                      //   "tr:last-child td": {
                      //     border: 0,
                      //   },
                      // }}
                      >
                        {sortArrayByKey(leaveDetails?.leave_days, 'asc', 'leave_date')?.map((leave, index) => (
                          <TableRow
                            key={index}
                            sx={{
                              td: {
                                color: "dark.800",
                                fontWeight: 400,
                                fontSize: 14,
                                lineHeight: "24px",
                                letterSpacing: "0.15px",
                                padding: "7px 16px",
                                border: 0,
                                borderBottom: "1px solid rgba(0, 0, 0, 0.12)",
                              },
                            }}
                          >
                            <TableCell>{getFormattedDate(leave?.leave_date)}</TableCell>
                            <TableCell>{getDayName(getFormattedDate(leave?.leave_date))}</TableCell>
                            <TableCell>{leave?.leave_duration !== "full" ? leave?.leave_duration === "first_half" ? `1st Half ${leave?.in_time ? (`Come at ${moment(leave?.in_time, 'HH:mm:ss').format('h:mm A')}`) : ''}` : `2nd Half ${leave?.out_time ? (`Leave at ${moment(leave?.out_time, 'HH:mm:ss').format('h:mm A')}`) : ''}` : LEAVE_DURATION_TYPE[leave?.leave_duration]}</TableCell>
                            {leaveDetails?.status === LEAVE_STATUS.Approved ? <TableCell width={160} align="center">
                              {leaveDetails?.not_in_current_year ? null : <Grid display="flex" justifyContent="center">
                                <Typography sx={{ width: '50px', display: 'inline-block', textAlign: 'right', marginRight: '4px' }}>{leave?.paid || 0} |</Typography>
                                <Typography
                                  color="error.main"
                                  fontWeight={700}
                                  sx={{ width: '50px', display: 'inline-block', textAlign: 'left', marginLeft: '4px' }}
                                >
                                  {leave?.unpaid || 0}
                                </Typography>
                              </Grid>}
                            </TableCell> : null}
                            {leaveDetails?.allow_special_credits ? <TableCell width={120}>
                              {leaveDetails?.not_in_current_year ? null : getMatchData(leave)}
                            </TableCell>
                              : null}
                            {leaveDetails?.allow_special_credits ?
                              <TableCell
                                width={110}
                                color="dark.800"
                                fontWeight={400}
                                fontSize={14}
                                lineheight="24px"
                                letterSpacing="0.15px"
                              >
                                {leaveDetails?.not_in_current_year ? null : leave?.credit_used > 0 ? leave?.credit_used : null}
                              </TableCell> : null}
                          </TableRow>
                        ))}
                        {leaveDetails?.not_in_current_year ? null :
                          <TableRow
                            sx={{
                              td: {
                                padding: "10px 16px",
                                border: 0,
                                borderBottom: "1px solid rgba(0, 0, 0, 0.12)",
                              },
                            }}
                          >
                            <TableCell colSpan={3}></TableCell>
                            {leaveDetails?.status === LEAVE_STATUS.Approved ? <TableCell width={160} align="center">
                              <Grid display="flex" justifyContent="center">
                                <Typography sx={{ width: '50px', display: 'inline-block', textAlign: 'right', marginRight: '4px' }}>{getApplyDays(leaveDetails?.leave_days)?.paidCount || 0} |</Typography>
                                <Typography
                                  color="error.main"
                                  fontWeight={700}
                                  sx={{ width: '50px', display: 'inline-block', textAlign: 'left', marginLeft: "4px" }}
                                >
                                  {getApplyDays(leaveDetails?.leave_days)?.unPaidCount || 0}
                                </Typography>
                              </Grid>
                            </TableCell> : null}
                            {leaveDetails?.allow_special_credits ?
                              <>
                                <TableCell></TableCell>
                                <TableCell>
                                  <Typography
                                    color="error.main"
                                    fontWeight={600}
                                    fontSize={14}
                                    sx={{ marginLeft: "0px", lineHeight: '24px' }}
                                  >
                                    {getTotalCreditUsedCount(leaveDetails?.leave_days)}{" "}
                                    Credit Applied
                                  </Typography>
                                </TableCell>
                              </> : null}
                          </TableRow>}
                      </TableBody>
                      {leaveDetails?.not_in_current_year ? null :
                        <TableFooter>
                          <TableRow sx={{
                            'td': {
                              padding: 0,
                              borderBottom: 'none',
                            }
                          }}>
                            <TableCell colSpan={3}></TableCell>
                            {leaveDetails?.status === LEAVE_STATUS.Approved ? <TableCell>
                              <Grid
                                item
                                width={160}
                                sx={{
                                  background: "#DEF3FF",
                                  padding: "10px 16px",
                                  margin: "auto",
                                }}
                              >
                                <Typography
                                  color="dark.800"
                                  variant="body2"
                                  lineHeight="24px"
                                  letterSpacing="0.17px"
                                  fontWeight={600}
                                >
                                  Remaining {leaveDetails?.leave_type?.split(" ")?.map(word => word[0])?.join("")}:{" "}
                                  {leaveDetails?.remaining_days || 0}
                                </Typography>
                              </Grid>
                            </TableCell> : null}
                            <TableCell></TableCell>
                            {leaveDetails?.allow_special_credits ?
                              <TableCell>
                                <Grid
                                  item
                                  width={200}
                                  sx={{ background: "#FFF6DF", padding: "10px 16px" }}
                                >
                                  <Typography
                                    color="dark.800"
                                    variant="body2"
                                    lineHeight="24px"
                                    letterSpacing="0.17px"
                                    fontWeight={600}
                                  >
                                    Remaining Credits: {leaveDetails?.remaining_credits || 0}
                                  </Typography>
                                </Grid>
                              </TableCell> : null}
                          </TableRow>
                        </TableFooter>}
                    </Table>
                  </TableContainer>
                </Box>
              </Stack>
            </Box>
            <Box mb={2}>
              <Typography
                variant="body1"
                fontWeight={500}
                sx={{ marginBottom: "4px", letterSpacing: "0.17px" }}
              >
                Reason:
              </Typography>
              {!!leaveDetails?.description && leaveDetails?.description !== "<p><br></p>" ?
                <Box
                  style={{
                    padding: "16px",
                    borderRadius: "3px",
                    background: "#F7F7F7",
                  }}
                  className="reason-text-view"
                >
                  <TextEditor
                    value={leaveDetails?.description}
                    readOnly={true}
                    onChange={() => { }}
                  />
                </Box> :
                <EmptyPage height={92} isButtonShow={false} title={"No Reason"} />
              }
            </Box>
            <Box mb={2}>
              <Typography
                variant="body1"
                fontWeight={500}
                sx={{ marginBottom: "4px", letterSpacing: "0.17px" }}
              >
                Attachments:
              </Typography>
              {!!leaveDetails?.attachment_references?.length ?
                <div
                  style={{
                    padding: "16px",
                    borderRadius: "3px",
                    background: "#F7F7F7",
                  }}
                >
                  <FileDropZone
                    readOnly={true}
                    fileURLs={leaveDetails?.attachment_references}
                  />
                </div> :
                <EmptyPage height={92} isButtonShow={false} title={"No Attachments"} />
              }
            </Box>
            <Box mt={2}>
              <Stack
                spacing={1}
                direction="row"
                alignItems="center"
                justifyContent="center"
              >
                {(currentUser?.is_admin && [LEAVE_STATUS.Approved, LEAVE_STATUS.Pending]?.includes(leaveDetails?.status) && checkApplicationEnable(leaveDetails, true)) ?
                  <Button
                    variant="contained"
                    color="primary"
                    size="medium"
                    sx={{ color: "white", textTransform: "none", boxShadow: 'none !important' }}
                    onClick={() => handleEditLeave()}
                  >
                    Edit
                  </Button>
                  : null}
                {leaveDetails?.status === LEAVE_STATUS.Pending && isShowButton(leaveDetails) ?
                  <>
                    {!leaveDetails?.isSystemGeneratedLeave ? <>
                      <Button
                        variant="contained"
                        color="greentc"
                        size="medium"
                        sx={{ color: "white", textTransform: "none", boxShadow: 'none !important' }}
                        onClick={() => handleOpen(LEAVE_STATUS.Approved)}
                      >
                        Approve
                      </Button>
                      <Button
                        variant="contained"
                        color="error"
                        size="medium"
                        sx={{ color: "white", textTransform: "none", boxShadow: 'none !important' }}
                        onClick={() => handleOpen(LEAVE_STATUS.Rejected)}
                      >
                        Reject
                      </Button>
                    </> : null}
                    <Button
                      variant="contained"
                      color="secondary"
                      size="medium"
                      sx={{ color: "white", textTransform: "none", boxShadow: 'none !important' }}
                      onClick={() => handleOpen(LEAVE_STATUS.Cancelled)}
                    >
                      Cancel Leave
                    </Button>
                  </>
                  : null
                }
                {([LEAVE_STATUS.Approved, LEAVE_STATUS.Rejected]?.includes(leaveDetails?.status) && isShowButton(leaveDetails)) ?
                  <Button
                    variant="contained"
                    color="secondary"
                    size="medium"
                    sx={{ color: "white", textTransform: "none", boxShadow: 'none !important' }}
                    onClick={() => handleOpen(LEAVE_STATUS.Cancelled)}
                  >
                    Cancel Leave
                  </Button>
                  : null}
              </Stack>
            </Box>
            <CustomModal
              actionType={modalDetails.type}
              title={modalDetails.type === LEAVE_STATUS.Approved ? "Confirm Approval" : modalDetails.type === LEAVE_STATUS.Rejected ? "Confirm Rejection" : modalDetails.type === LEAVE_STATUS.Cancelled ? "Confirm Cancellation" : ""}
              leaveType={leaveDetails?.is_auto_generated ? `System Generated ${leaveDetails?.leave_type}` : leaveDetails?.leave_type}
              // date={getLeaveDateArray(leaveDetails?.leave_days || [], 'leave_date')?.map((date) => moment(date, 'DD/MM/YYYY').format("Do MMMM (dddd)"))}
              date={getLeaveDateArray(leaveDetails?.leave_days || [], 'leave_date')?.map((date) => moment(date).format("Do MMMM (dddd)"))}
              userData={{
                profileImage: leaveDetails?.userData?.profile_img,
                userName: getFullName(leaveDetails?.userData),
                role: titleCase(leaveDetails?.userData?.user_role),
                is_dedicated_developer: leaveDetails?.userData?.is_dedicated_developer,
              }}
              applyDays={getApplyDays(leaveDetails?.leave_days)?.totalDays}
              comment={modalDetails?.comment}
              commentError={modalDetails?.error}
              handleComment={(e) => {
                setModalDetails((prev) => ({ ...prev, comment: e.target.value, error: e.target.value ? "" : "Required" }))
              }}
              onApprove={handleApprove}
              onCancel={handleCancel}
              isButtonLoading={isLoading?.modalButtonLoading}
              submitButtonContent={modalDetails.type === LEAVE_STATUS.Cancelled ? 'Cancel Leave' : ''}
              isLeave={true}
              isReportingUser={true}
              leaveSnapshotData={leaveSnapshot?.find(snapData => snapData?.leave_type?.toLowerCase() === leaveDetails?.leave_type?.toLowerCase())}
            />
          </Box>
        </Grid>
  )
}

export default OthersLeaveDetails