/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import React from 'react';
import { FormControl, Grid, InputLabel, MenuItem, Select, Typography } from '@mui/material'
import API from "../../../../axios/api";
import axiosInstance from "../../../../axios";
import CircularLoader from '../../../../components/CircularLoader';
import { usePageTitle } from '../../../../hook/useTitle';
import TablePagination from '../../../../components/TablePagination';
import { titleCase } from '../../../../utils';
import Button from '../../../../components/Button';
import MonthSelector from '../../../../components/MonthSelector';
import moment from 'moment';

const EmailTransactions = () => {
    const { setPageTitle } = usePageTitle();
    const [loading, setLoading] = useState({
        transactionDataLoading: false,
        pageLoading: true,
        emailTemplateLoading: false
    });
    const [transactionsData, setTransactionsData] = useState([]);
    const [TransationCount, setTransactionCount] = useState(0);
    const [templateData, setTemplateData] = useState([]);
    setPageTitle("CRM - Contacts");

    const [queryParams, setQueryParams] = useState({
        page: 0,
        limit: 100,
        campaign_template_id: "",
        email_sent: "all",
        date: moment(new Date()).format('YYYY-MM-DD'),
    });

    useEffect(() => {
        getAllTemplateList();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const getAllTemplateList = async () => {
        try {
            setLoading((prev) => ({ ...prev, emailTemplateLoading: true }));
            const response = await axiosInstance.post(
                API.getAllTemplateList
            );

            if (response?.status === 200) {
                setTemplateData(response?.data?.data);
            } else {
                setTemplateData([]);
            }
            setLoading((prev) => ({ ...prev, emailTemplateLoading: false }));
        } catch (error) {
            console.error(error);
            setLoading((prev) => ({ ...prev, emailTemplateLoading: false }));
        }
    };

    useEffect(() => {
        getAllTransactionList();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [queryParams]);

    const getAllTransactionList = async () => {
        try {
            setLoading(prev => ({
                ...prev,
                transactionDataLoading: true,
            }));
            const response = await axiosInstance.post(
                API.getAllEmailTransaction,
                queryParams
            );

            if (response?.status === 200) {
                setTransactionsData(response?.data?.data?.rows ?? []);
                setTransactionCount(response?.data?.dataExists);
            } else {
                setTransactionsData([]);
            }
            setLoading(prev => ({
                ...prev,
                transactionDataLoading: false,
                pageLoading: false
            }));
        } catch (error) {
            console.error(error);
            setLoading(prev => ({
                ...prev,
                transactionDataLoading: false,
                pageLoading: false
            }));
        }
    };

    const handleChangePage = (event, newPage) => {
        setQueryParams((prev) => ({
            ...prev,
            page: newPage,
        }));
    };

    const handleChangeRowsPerPage = (event) => {
        setQueryParams((prev) => ({
            ...prev,
            limit: event.target.value,
            page: 0,
        }));
    };

    const handleClearFilter = () => {
        setQueryParams({
            page: 0,
            limit: 25,
            campaign_template_id: "",
            date: new Date(),
            email_sent: "all"
        });
    };

    return (
        <Box width="100%" bgcolor="white" display="flex" flexDirection="column">
            <Box p={{ xs: 2, lg: 3, xl: 4 }} py={{ xs: 3, lg: 3, xl: 4 }} sx={{ background: '#ffffff' }} flex={1} overflow="auto">
                {loading?.pageLoading || loading?.emailTemplateLoading ?
                    <CircularLoader height="500px" /> :
                    <Box>
                        {TransationCount ? <Grid mb={1.5} sx={{
                            display: "flex",
                            alignItems: "flex-end",
                            justifyContent: "flex-start",
                            gap: 2,
                        }}>
                            <Grid item sx={{ width: { xs: 180, lg:220, xl: 264 } }}>
                                <FormControl variant="standard" sx={{ width: "100%" }}>
                                    <InputLabel htmlFor="campaign_template_id" shrink={true}>Email Template</InputLabel>
                                    <Select
                                        inputProps={{ id: "campaign_template_id" }}
                                        id="campaign_template_id"
                                        value={queryParams?.campaign_template_id}
                                        onChange={(event, ...rest) => {
                                            setQueryParams((prev) => ({ ...prev, campaign_template_id: event?.target?.value }))
                                        }}
                                        name="campaign_template_id"
                                        label="Select Email Template"
                                        sx={{
                                            ".MuiSelect-select": {
                                                fontSize: 14,
                                                fontWeight: 400,
                                                color: "dark.800",
                                            },
                                        }}
                                        displayEmpty
                                    >
                                        <MenuItem value="">
                                            All
                                        </MenuItem>
                                        {templateData?.map((item) => (
                                            <MenuItem key={item?.id} value={item?.id}>
                                                {titleCase(item?.title)}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            </Grid>
                            <Grid item sx={{ width: { xs: 180, lg:220, xl: 264 } }}>
                                <FormControl variant="standard" sx={{ width: "100%" }}>
                                    <InputLabel htmlFor="email_sent">Email status</InputLabel>
                                    <Select
                                        inputProps={{ id: "email_sent" }}
                                        id="email_sent"
                                        value={queryParams?.email_sent}
                                        onChange={(event, ...rest) => {
                                            setQueryParams((prev) => ({ ...prev, email_sent: event?.target?.value }))
                                        }}
                                        name="email_sent"
                                        label="Select Email status"
                                        sx={{
                                            ".MuiSelect-select": {
                                                fontSize: 14,
                                                fontWeight: 400,
                                                color: "dark.800",
                                            },
                                        }}
                                    >
                                        {[
                                            { name: "All", value: "all" },
                                            { name: "Delivered", value: "true" },
                                            { name: "Not Delivered", value: "false" }
                                        ]?.map((item, index) => (
                                            <MenuItem key={item?.id} value={item?.value}>
                                                {titleCase(item?.name)}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            </Grid>
                            <Grid item sx={{ width: { xs: 180, lg:220, xl: 264 } }}>
                                <FormControl variant="standard" sx={{ width: "100%" }}>
                                    <MonthSelector
                                        label="Month"
                                        value={queryParams?.date}
                                        onChange={(selectedMonth) => setQueryParams((prev) => ({ ...prev, date: new Date(selectedMonth) }))}
                                    />
                                </FormControl>
                            </Grid>
                            <Grid item>
                                <Button variant='text' color='primary'
                                    size="small"
                                    sx={{ marginTop: '16px !important', textTransform: 'none' }}
                                    onClick={() => handleClearFilter()}>Clear Filters</Button>
                            </Grid>
                        </Grid> : null}
                        {(transactionsData?.length && TransationCount) ? <Paper sx={{ width: '100%', borderRadius: '0px', boxShadow: 'none', border: "1px solid #E0E0E0" }}>
                            <TableContainer>
                                <Table
                                    sx={{ minWidth: 750 }}
                                    aria-labelledby="tableTitle"
                                    size={'small'}
                                >
                                    <TableHead>
                                        <TableRow sx={{
                                            whiteSpace: 'nowrap',
                                            'th': {
                                                color: "dark.800",
                                                fontWeight: 500,
                                                fontSize: 14,
                                                lineHeight: "24px",
                                                letterSpacing: "0.17px",
                                                bgcolor: "bgColor",
                                                padding: "5px 16px",
                                                textAlign: "left",
                                                background: "rgba(245, 248, 250, 1)",
                                            }
                                        }}>
                                            <TableCell>Name</TableCell>
                                            <TableCell>Email</TableCell>
                                            <TableCell>Email Template</TableCell>
                                            <TableCell>Send on</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody
                                        sx={{
                                            'td': {
                                                padding: "12px 16px",
                                            }
                                        }}
                                    >
                                        {loading.transactionDataLoading ?
                                            <CircularLoader variant="table" rows={queryParams.limit} cols={4} /> :
                                            transactionsData?.map((transaction, index) => {
                                                return (
                                                    <React.Fragment key={index}>
                                                        <TableRow
                                                            hover
                                                            role="checkbox"
                                                            tabIndex={-1}
                                                        >
                                                            <TableCell>
                                                                <Typography variant='body1' fontSize={14} fontWeight={400} color="dark.800" lineHeight="20px" letterSpacing={"0.17px"}>{transaction?.CampaignRecipient?.name}</Typography>
                                                            </TableCell>
                                                            <TableCell>
                                                                <Typography variant='body1' fontSize={14} fontWeight={400} color="dark.800" lineHeight="20px" letterSpacing={"0.17px"}>{transaction?.CampaignRecipient?.email}</Typography>
                                                            </TableCell>
                                                            <TableCell>
                                                                <Typography variant='body1' fontSize={14} fontWeight={400} color="dark.800" lineHeight="20px" letterSpacing={"0.17px"}>{transaction?.CampaignTemplate?.title}</Typography>
                                                            </TableCell>
                                                            <TableCell>
                                                                <Typography variant='body1' fontSize={14} fontWeight={400} color="dark.800" lineHeight="20px" letterSpacing={"0.17px"}>{moment(transaction?.updatedAt).format("hh:mm A, DD/MM/YYYY")}</Typography>
                                                                {transaction?.email_sent ? null : <Typography variant='body1' fontSize={13} fontWeight={600} color="#D32F2F" lineHeight="20px" letterSpacing={"0.17px"}>- Not Delivered</Typography>}
                                                            </TableCell>
                                                        </TableRow>
                                                    </React.Fragment>)
                                            })}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                            <TablePagination
                                count={TransationCount}
                                rowsPerPageOptions={[100, 200, 500]}
                                rowsPerPage={queryParams.limit}
                                page={queryParams.page}
                                onPageChange={handleChangePage}
                                onRowsPerPageChange={handleChangeRowsPerPage}
                            />
                        </Paper> :
                            (TransationCount ?
                                <Box bgcolor={"#FAFBFF"} height={151} display={"flex"} flexDirection={'column'} justifyContent={"center"} alignItems={"center"}>
                                    <Typography color={"#262626"} fontSize={16} fontWeight={400}>No Transactions found</Typography>
                                    <Button variant='text' color='primary'
                                        size="small"
                                        sx={{ marginTop: '16px !important', textTransform: 'none' }}
                                        onClick={() => handleClearFilter()}>Clear Filters</Button>
                                </Box>
                                : <Box bgcolor={"#FAFBFF"} height={151} display={"flex"} justifyContent={"center"} alignItems={"center"}>
                                    <Typography color={"#262626"} fontSize={16} fontWeight={400}>No Transactions made yet</Typography>
                                </Box>)
                        }
                    </Box>
                }
            </Box>
        </Box>
    );
};

export default EmailTransactions;