/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { Box, FormControl, FormControlLabel, FormLabel, Grid, Link, Modal, Radio, RadioGroup, Stack, Typography } from "@mui/material";
import { Link as RouterLink, useNavigate } from "react-router-dom";
import Button from "../../components/Button";
import URLS from "../../routes/urls";
import PublicFooter from "../../components/PublicFooter";
import Input from "../../components/Input";
import axiosInstance from "../../axios";
import API from "../../axios/api";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useAlert } from "../../hook/useAlert";
import { useDispatch } from "react-redux";
import useAuthentication from "../../hook/useAuthentication";
import ICONS from "../../constants/icons";
import { usePageTitle } from "../../hook/useTitle";
import LogoutModal from "../../components/LogoutModal";
import moment from "moment";
import { setUserDetails } from "../../redux/slices/userSlice";
import { createDefaultObject, validateLogoutModelData } from "../../components/LogoutModal/utils-functions";
import { ON_BOARDING_STATUS } from "../../constants/default-values";
import secureLocalStorage from "react-secure-storage";
import { getInputId, setIsReportee, titleCase, sortArrayByKey } from "../../utils";
import { setAllActivityData, setIsActionData } from "../../redux/slices/activityActionDataSlice";
import { setIsApiReponseFailed, setIsRepoteeData } from "../../redux/slices/repoteeDataSlice";
import { DateObject } from "react-multi-date-picker";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 600,
  bgcolor: "background.paper",
  boxShadow: 24,
  borderRadius: "12px",
};

const Login = () => {
  const errorMessage = secureLocalStorage.getItem("errorMessage");
  const dispatch = useDispatch();
  const showAlert = useAlert();
  const navigate = useNavigate();
  const { defaultRoute } = useAuthentication();
  const { setPageTitle } = usePageTitle();
  setPageTitle("Login");
  const [isLoading, setIsLoading] = useState(false);
  let initialModalValues = {
    isShow: false,
    isButtonLoading: false,
    date: null,
    msg: null,
    time: null,
    email: null,
    last_activity: null,
  }
  const [logoutModelDetails, setLogoutModelDetails] = useState(initialModalValues);
  const [errors, setErrors] = useState({});
  const [isShowLoginConfirmModal, setIsShowLoginConfirmModal] = useState({
    isShow: false,
    isButtonLoading: false,
    user_type: null,
    login_from: "",
    login_details: null
  });

  const showMessage = () => {
    if (errorMessage && errorMessage?.length > 0) {
      showAlert(errorMessage, "error");
      secureLocalStorage.removeItem("errorMessage");
    }
  };

  useEffect(() => {
    showMessage();
  }, [])

  const getAllActivityData = async (user) => {
    try {
      const response = await axiosInstance.get(
        API.getAllActivityByWorkType(user?.org_work_type_setting_id)
      );
      if (response.status === 200) {
        if (response?.data?.data?.length > 0) {
          let sortedActivity = sortArrayByKey(response?.data?.data, 'asc', 'title')
          dispatch(setAllActivityData(sortedActivity || []));
        }
      }
    } catch (error) {
      console.error(error);
    }
  };

  const getSummaryReport = async (user) => {
    try {
      let body = {
        userData: {
          id: user?.id
        },
        startDate: new DateObject().toFirstOfMonth(),
        endDate: new DateObject().toDate()
      };
      const response = await axiosInstance.post(API.getUserSummary, body);
      if (response.status === 200) {
        dispatch(setIsActionData(response?.data?.activity || []));
      }
    } catch (error) {
      console.error(error);
    }
  }

  const getUsersRepoteesData = async () => {
    try {
      const response = await axiosInstance.get(API.getRepoteesListByUserId)
      if (response?.status === 200) {
        if (response.data?.userData) {
          secureLocalStorage.setItem("isReportee", response.data?.userData?.length > 0)
        }
        dispatch(setIsRepoteeData(response.data?.userData));
        dispatch(setIsApiReponseFailed(false));
      }
    } catch (error) {
      console.error(error);
      dispatch(setIsApiReponseFailed(true));
    }
  }

  const formik = useFormik({
    initialValues: {
      email: "",
      password: "",
      login_from: ""
    },
    validationSchema: Yup.object({
      email: Yup.string().email("Invalid email address").required("Required"),
      password: Yup.string()
        .min(8, "password must be at least 8 characters")
        .required("Required"),
      login_from: Yup.string().required("Please select work mode type"),
    }),
    onSubmit: async (values) => {
      try {
        setIsLoading(true);
        let payload = { ...values };
        if (payload?.login_from) {
          payload['is_remote'] = payload?.login_from === "remote" ? true : false;
          delete payload['login_from'];
        }
        const response = await axiosInstance.post(API.login, payload);
        if (response.status === 200) {
          let ask_login_details = response.data?.ask_login_details;
          if (ask_login_details?.hasOwnProperty("last_day") || ask_login_details?.hasOwnProperty("checkout") || ask_login_details?.hasOwnProperty("current_day")) {
            setIsLoading(false);
            const defaultObject = {
              ...createDefaultObject(response.data?.ask_login_details),
              date: response.data?.ask_login_details?.date,
              time: moment(response.data?.ask_login_details?.date).format("hh:mm a"),
            };

            setLogoutModelDetails({
              isShow: true,
              ...defaultObject,
              email: values?.email,
              last_activity: defaultObject?.msg?.toString()?.split("from")[1]?.replace("b", "")?.replace("<>", "") || null
            });
          } else {
            let user = response?.data?.user;
            let organization = response?.data?.organization;

            if (response.data.message?.includes("deactivated")) {
              showAlert(response.data.message, "warning");
            } else {
              let currentUser = response.data.user;
              let onboarding_status = response.data.organization?.onboarding_status;
              secureLocalStorage.setItem("isReportee", response?.data?.user?.is_reportee)
              let currentUserWorkType = organization?.work_type_settings?.find((workType) => workType?.id === user?.org_work_type_setting_id);
              dispatch(setUserDetails({
                user: { ...response?.data?.user, organization: response?.data?.organization, onboarding_status: response?.data?.organization?.onboarding_status },
                token: response?.data?.token,
                sessionBackupToken: response?.data?.token,
                organization: response?.data?.organization
              }));
              if (!(currentUserWorkType?.name?.toLowerCase())?.includes(values?.login_from?.toLowerCase()) && currentUserWorkType?.name?.toLowerCase() === 'remote') {
                setIsShowLoginConfirmModal({
                  isShow: true,
                  isButtonLoading: false,
                  user_type: currentUserWorkType?.name,
                  login_from: values?.login_from,
                  login_details: response,
                  token: response?.data?.token,
                })
              } else {
                if (currentUser?.user_type !== "super_admin" && onboarding_status === ON_BOARDING_STATUS.completed) {
                  getAllActivityData(currentUser);
                  getSummaryReport(currentUser)
                  getUsersRepoteesData();
                  setIsReportee(currentUser);
                }
                setIsLoading(false);
                showAlert(response.data.message);
                navigate({ pathname: defaultRoute() });
              }
            }
          }
        } else {
          showAlert(response?.response?.data?.message || "Something went wrong.", "error");
          setIsLoading(false);
        }
      } catch (error) {
        console.error("Error:", error);
        showAlert(error?.response?.data?.message || "Something went wrong.", "error");
        setIsLoading(false);
      }
    },
  });

  // const handleCloseLoginConfirmModal = () => {
  //   setIsShowLoginConfirmModal({
  //     isShow: false,
  //     isButtonLoading: false,
  //     user_type: null,
  //     login_from: "office",
  //     login_details: null
  //   });
  // };

  const handleApproveLogin = async () => {
    let response = isShowLoginConfirmModal?.login_details;
    let currentUser = response.data?.user;
    let onboarding_status = response.data?.organization?.onboarding_status;
    if (currentUser?.user_type !== "super_admin" && onboarding_status === ON_BOARDING_STATUS.completed) {
      getAllActivityData(currentUser);
      getSummaryReport(currentUser)
      getUsersRepoteesData();
      setIsReportee(currentUser);
    }
    dispatch(setUserDetails({
      user: { ...response?.data?.user, organization: response?.data?.organization, onboarding_status: response?.data?.organization?.onboarding_status },
      token: response?.data?.token,
      sessionBackupToken: response?.data?.token,
      organization: response?.data?.organization
    }));
    setIsLoading(false);
    showAlert(response.data.message);
    navigate({ pathname: defaultRoute() });
  }

  const handleOnSubmit = async () => {
    try {
      setLogoutModelDetails((prev) => ({ ...prev, isButtonLoading: true }));
      let payload = {
        email: logoutModelDetails?.email,
        ask_login_details: { ...logoutModelDetails },
      };
      delete payload.ask_login_details.email;
      delete payload.ask_login_details.msg;
      delete payload.ask_login_details.isShow;
      delete payload.ask_login_details.isButtonLoading;
      delete payload.ask_login_details.time;

      let errors = validateLogoutModelData(payload);
      setErrors(errors?.ask_login_details);
      if (!Object.values(errors)?.filter((value) => !!value)?.length > 0) {
        const response = await axiosInstance.post(API.addOverTime, payload);
        if (response.status === 200) {
          let user = response?.data?.user;
          let organization = response?.data?.organization;

          secureLocalStorage.setItem("isReportee", response?.data?.user?.is_reportee)
          let currentUserWorkType = organization?.work_type_settings?.find((workType) => workType?.id === user?.org_work_type_setting_id);
          dispatch(setUserDetails({
            user: response.data.user,
            token: response.data.token,
            sessionBackupToken: response?.data?.token,
            organization: response.data.organization
          }));

          if (!(currentUserWorkType?.name?.toLowerCase())?.includes(formik.values?.login_from?.toLowerCase()) && currentUserWorkType?.name?.toLowerCase() === 'remote') {
            setLogoutModelDetails((prev) => ({ ...prev, isShow: false, isButtonLoading: false }));
            setIsShowLoginConfirmModal({
              isShow: true,
              isButtonLoading: false,
              user_type: currentUserWorkType?.name,
              login_from: formik.values.login_from,
              login_details: response,
              token: response?.data?.token,
            })
          } else {
            setIsLoading(false);
            showAlert(response.data.message);
            navigate({ pathname: defaultRoute() });
            let currentUser = response.data.user;
            let onboarding_status = response.data.organization?.onboarding_status;
            if (currentUser?.user_type !== "super_admin" && onboarding_status === ON_BOARDING_STATUS.completed) {
              getAllActivityData(response.data.user);
              getSummaryReport(response.data.user)
              getUsersRepoteesData();
              setIsReportee(response.data.user);
            }
            setLogoutModelDetails((prev) => ({ ...prev, isButtonLoading: false }));
          }
        } else {
          setLogoutModelDetails((prev) => ({ ...prev, isButtonLoading: false }));
        }
      } else {
        setLogoutModelDetails((prev) => ({ ...prev, isButtonLoading: false }));
      }
    } catch (error) {
      console.error(error)
      showAlert(error?.response?.data?.message, "error");
      setLogoutModelDetails((prev) => ({ ...prev, isButtonLoading: false }));
    }
  }

  const handleDeclineLogin = async () => {
    try {
      let currentUser = isShowLoginConfirmModal?.login_details?.data?.user;
      let onboarding_status = isShowLoginConfirmModal?.login_details?.data?.organization?.onboarding_status;
      let response = await axiosInstance.put(API.declineLogin(currentUser?.id));
      if (response.status === 200) {
        if (currentUser?.user_type !== "super_admin" && onboarding_status === ON_BOARDING_STATUS.completed) {
          getAllActivityData(currentUser);
          getSummaryReport(currentUser)
          getUsersRepoteesData();
          setIsReportee(currentUser);
        }
        navigate({ pathname: defaultRoute() });
        showAlert(response?.data?.message);
        setIsLoading(false);
      }
    } catch (error) {
      console.error(error);
    }
    finally {
      setIsShowLoginConfirmModal({
        isShow: false,
        isButtonLoading: false,
        user_type: null,
        login_from: "office",
        login_details: null
      });
    }
  }

  return (
    <Box
      sx={{ minHeight: "100vh", position: "relative", background: "#FAFBFF" }}
    >
      <Box
        sx={{
          position: "absolute",
          filter: "blur(18px)",
          bottom: 0,
          top: 0,
          display: "flex",
          alignItems: "center",
        }}
      >
        <i style={{ height: "calc(100vh - 175px)", display: 'flex', alignItems: 'center' }}>{ICONS.LoginBg}</i>
      </Box>
      <Grid
        container
        direction="column"
        justifyContent="flex-end"
        position="relative"
        zIndex="1"
        sx={{ minHeight: "100vh" }}
      >
        <Grid sx={{ flex: 1, display: "flex", paddingTop: "50px" }}>
          <Grid
            item
            xs={12}
            container
            justifyContent="center"
            alignItems="center"
            flexDirection="column"
          // sx={{ minHeight: { xs: 'calc(100vh - 134px)', md: 'calc(100vh - 112px)' } }}
          >
            <Grid item>
              <i style={{ display: 'block', }}>{ICONS.Logo}</i>
            </Grid>
            <Grid item sx={{ width: "100%" }}>
              <Box
                sx={{
                  maxWidth: { xs: 400, lg: 500 },
                  "& > *": {
                    flexGrow: 1,
                    flexBasis: "50%",
                  },
                  width: "100%",
                  margin: "20px auto 55px",
                  boxShadow: '0px 31px 95px rgba(0, 0, 0, 0.12)',
                  borderRadius: "14px",
                  background: "white",
                  overflow: 'hidden',
                }}
              >
                <Box
                  sx={{ p: { xs: 2, sm: 3, md: 4, xl: 5 }, width: "100%" }}
                  boxShadow={1}
                >
                  <form onSubmit={formik.handleSubmit}>
                    <Stack spacing={3} alignItems="flex-start">
                      <Typography
                        variant="h5"
                        fontWeight="bold"
                        color="secondary"
                      >
                        Sign in
                      </Typography>
                      <Input
                        id={getInputId("email")}
                        name="email"
                        label="Email address"
                        variant="standard"
                        type="email"
                        sx={{ width: "100%" }}
                        placeholder={"email address"}
                        {...formik.getFieldProps("email")}
                        error={
                          formik.touched.email && Boolean(formik.errors.email)
                        }
                        helperText={formik.touched.email && formik.errors.email}
                      />
                      <Input
                        id={getInputId("password")}
                        name="password"
                        label="Password"
                        variant="standard"
                        type="password"
                        sx={{ width: "100%" }}
                        placeholder={"password"}
                        {...formik.getFieldProps("password")}
                        onChange={(e) =>
                          formik.setFieldValue("password", e.target.value.trim())
                        }
                        error={
                          formik.touched.password &&
                          Boolean(formik.errors.password)
                        }
                        helperText={
                          formik.touched.password && formik.errors.password
                        }
                      />
                      <Box width={"100%"}>
                        <FormControl sx={{ width: "100%", display: "flex", alignItems: "center", flexDirection: "row", gap: 2 }}>
                          <FormLabel required={true} id="sign_in_from" fontSize={14} fontWeight={500} lineHeight={"24px"} letterSpacing={"0.15"} sx={{ color: "#262626 !important" }}>
                            From
                          </FormLabel>
                          <RadioGroup
                            aria-labelledby="sign_in_from"
                            name="login_from"
                            value={formik.values.login_from}
                            onChange={formik.handleChange}
                            sx={{ flexDirection: "row", gap: 2, flex: '1' }}
                          >
                            <FormControlLabel
                              value="office"
                              control={<Radio size='small' sx={{ padding: '4px' }} />}
                              label={
                                <Box sx={{ display: "flex", alignItems: "flex-start", flexDirection: 'column', gap: '4px' }}>
                                  <i style={{ marginRight: "8px", height: '24px', width: '24px', maxWidth: '24px' }}>{ICONS.RemoteIcon}</i>
                                  <Typography color={"secondary"} fontSize={15} fontWeight={500} lineHeight={"26px"}>Office</Typography>
                                </Box>
                              }
                              sx={{
                                padding: "6px 6px 6px 12px",
                                border: formik.values.login_from === "office" ? "2px solid #047FE0" : "2px solid rgba(63, 92, 118, 0.1)",
                                borderRadius: "4px",
                                margin: '0px',
                                flex: '1',
                                justifyContent: 'space-between',
                                flexDirection: 'row-reverse',
                                alignItems: 'flex-start'
                              }}
                            />
                            <FormControlLabel
                              value="remote"
                              control={<Radio size='small' sx={{ padding: '4px' }} />}
                              label={
                                <Box sx={{ display: "flex", alignItems: "flex-start", flexDirection: 'column', gap: '4px' }}>
                                  <i style={{ marginRight: "8px", height: '24px', width: '24px', maxWidth: '24px' }}>{ICONS.OfficeIcon}</i>
                                  <Typography color={"secondary"} fontSize={15} fontWeight={500} lineHeight={"26px"}>Remote</Typography>
                                </Box>
                              }
                              sx={{
                                padding: "6px 6px 6px 12px",
                                border: formik.values.login_from === "remote" ? "2px solid #047FE0" : "2px solid rgba(63, 92, 118, 0.1)",
                                borderRadius: "4px",
                                margin: '0px',
                                flex: '1',
                                justifyContent: 'space-between',
                                flexDirection: 'row-reverse',
                                alignItems: 'flex-start'
                              }}
                            />
                          </RadioGroup>
                        </FormControl>
                        {formik.touched.login_from && formik.errors.login_from ? (
                          <Typography fontSize="12px" sx={{ color: "red.main", marginTop: 1 }}>
                            {formik.errors.login_from}
                          </Typography>
                        ) : null}
                      </Box>
                      <Button
                        type="submit"
                        size="large"
                        sx={{ color: "#ffffff", width: "100%", boxShadow: 'none !important', textTransform: 'none' }}
                        isButtonLoading={isLoading}
                        disabled={!formik.isValid || !formik.values.login_from}
                      >
                        Sign in {!!formik.values.login_from ? formik.values.login_from === "remote" ? "from Remote" : "from Office" : null}
                      </Button>
                      <Grid
                        className="text-center"
                        sx={{ width: "100%", textAlign: "center" }}
                      >
                        <Link
                          variant="body2"
                          component={RouterLink}
                          to={URLS.ForgotPassword}
                          color="primary"
                          sx={{ textDecoration: "none", cursor: "pointer" }}
                        >
                          Forgot Password?
                        </Link>
                      </Grid>
                    </Stack>
                  </form>
                </Box>
              </Box>
            </Grid>
          </Grid>
        </Grid>
        <PublicFooter />
      </Grid>
      <LogoutModal
        actionType={"ask_login_details"}
        onApprove={handleOnSubmit}
        title={`Logout time for ${moment(logoutModelDetails?.date).format("DD/MM/YYYY (dddd)")}`}
        isShow={logoutModelDetails.isShow}
        isButtonLoading={logoutModelDetails.isButtonLoading}
        isShowCancelButton={false}
        errors={errors}
        setErrors={setErrors}
        logoutModelDetails={logoutModelDetails}
        setLogoutModelDetails={setLogoutModelDetails}
      />
      <Modal
        open={isShowLoginConfirmModal.isShow}
        onClose={() => { }}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Grid item py={2} px={3} borderBottom="1px solid #E2E4EC">
            <Typography variant="h6" color={"dark.800"} fontSize={20} fontWeight={500} lineHeight={"32px"} letterSpacing={"0.15px"}>Login Confirmation</Typography>
          </Grid>
          <Grid item p={3} pb={0} mb={4}>
            <Typography
              color="dark.main"
              fontSize={16}
              fontWeight={500}
              lineHeight={"24px"}
              letterSpacing={"0.15px"}
            >
              Your work type is configured as a <b>{titleCase(isShowLoginConfirmModal?.user_type)}</b>. Are you sure you want to log in from the <b>{titleCase(isShowLoginConfirmModal?.login_from)}</b> instead?
            </Typography>
            {/* Your user type is <b>{titleCase(isShowLoginConfirmModal?.user_type)}</b> but you are trying to sign in from <b>{titleCase(isShowLoginConfirmModal?.login_from)}</b>? */}
          </Grid>
          <Grid item pb={2} px={3}>
            <Stack spacing={2} direction="row" width={"100%"}>
              <Button
                variant="outlined"
                onClick={handleApproveLogin}
                isButtonLoading={isShowLoginConfirmModal.isButtonLoading}
                borderRadius={"4px"}
                sx={{
                  textTransform: "none", boxShadow: 'none !important',
                  padding: '16px !important',
                  border: '2px solid #047FE0',
                  justifyContent: 'flex-start',
                  flex: '1',
                  '&:hover': {
                    border: '2px solid #047FE0',
                  },
                  'svg': {
                    width: '100%',
                    height: '100%'
                  },
                }}
              >
                <i style={{ marginRight: "8px", height: '30px', width: '30px', maxWidth: '30px' }}>{ICONS.RemoteIcon}</i>
                <Typography color={"secondary"} fontSize={16} fontWeight={500} lineHeight={"26px"}>Login <b>from Office</b></Typography>
              </Button>
              <Button
                variant="outlined"
                onClick={handleDeclineLogin}
                disabled={isShowLoginConfirmModal.isButtonLoading}
                borderRadius={"4px"}
                sx={{
                  textTransform: "none",
                  padding: '16px !important',
                  border: '2px solid rgba(63, 92, 118, 0.1)',
                  justifyContent: 'flex-start',
                  flex: '1',
                  '&:hover': {
                    border: '2px solid #047FE0',
                  },
                  'svg': {
                    width: '100%',
                    height: '100%'
                  },
                }}
              >
                <i style={{ marginRight: "8px", height: '30px', width: '30px', maxWidth: '30px' }}>{ICONS.OfficeIcon}</i>
                <Typography color={"secondary"} fontSize={16} fontWeight={500} lineHeight={"26px"}>Login <b>from Remote</b></Typography>
              </Button>
            </Stack>
          </Grid>
        </Box>
      </Modal>
    </Box>
  );
};

export default Login;