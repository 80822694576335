import { Box, Grid, List, ListItem, Stack } from '@mui/material';
import React, { useState } from 'react';
import Breadcrumb from '../../../components/Breadcrumb';
import { useNavigate } from 'react-router-dom';
import CircularLoader from '../../../components/CircularLoader';
import Input from '../../../components/Input';
import Button from '../../../components/Button';
import axiosInstance from '../../../axios';
import API from '../../../axios/api';
import { useAlert } from '../../../hook/useAlert';
import { useDispatch, useSelector } from 'react-redux';
import URLS from '../../../routes/urls';
import { clearStorage } from '../../../redux/slices/userSlice';

const ChangePassword = () => {
    const navigator = useNavigate();
    const { user: currentUser } = useSelector((state) => state.user);
    const showAlert = useAlert();
    const dispatch = useDispatch();
    const [loading, setLoading] = useState({
        pageLoading: false,
        formSubmitting: false
    });
    let initialFormData = {
        old_password: "",
        new_password: "",
        confirm_password: "",
    }
    const [formData, setFormData] = useState(initialFormData);
    const [errors, setErrors] = useState(initialFormData);

    const validateFormData = (formData) => {
        let isValid = true;
        const newErrors = {};

        if (!formData.old_password) {
            newErrors.old_password = "Required";
            isValid = false;
        }

        if (!formData.new_password) {
            newErrors.new_password = "Required";
            isValid = false;
        } else {
            const passwordErrors = validatePassword(formData.new_password);
            if (passwordErrors?.length > 0) {
                newErrors.new_password = passwordErrors;
                isValid = false;
            } else if (formData.new_password === formData.old_password) {
                newErrors.new_password = "New password cannot be the same as old password";
                isValid = false;
            }
        }

        if (!formData.confirm_password) {
            newErrors.confirm_password = "Required";
            isValid = false;
        } else if (formData.new_password !== formData.confirm_password) {
            newErrors.confirm_password = "Passwords do not match";
            isValid = false;
        }

        setErrors(prev => ({ ...prev, ...newErrors }));
        return isValid;
    }

    const validatePassword = (password) => {
        const errors = [];
        if (password.length < 8) {
            errors.push("Must be at least 8 characters.");
        }
        if (!/[A-Z]/.test(password)) {
            errors.push("Must include at least one uppercase letter.");
        }
        if (!/[a-z]/.test(password)) {
            errors.push("Must include at least one lowercase letter.");
        }
        if (!/[0-9]/.test(password)) {
            errors.push("Must include at least one number.");
        }
        if (!/[!@#$%^&*(),.?":{}|<>]/.test(password)) {
            errors.push("Must include at least one special character like @, #, $, etc.");
        }
        return errors;
    }

    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(prev => ({ ...prev, formSubmitting: true }));

        if (!validateFormData(formData)) {
            setLoading(prev => ({ ...prev, formSubmitting: false }));
            return;
        } else {
            try {
                const body = {
                    "currentPassword": formData?.old_password,
                    "newPassword": formData?.new_password,
                    "email": currentUser?.email,
                };
                const response = await axiosInstance.post(API.changePassword, body);
                if (response.status === 200) {
                    setLoading(prev => ({ ...prev, formSubmitting: false }));
                    setFormData(initialFormData);
                    const logoutResponse = await axiosInstance.post(API.logout(currentUser?.id));
                    if (logoutResponse.status === 200) {
                        showAlert("Password changed successfully, you have to login again.", "success");
                        dispatch(clearStorage());
                        navigator(URLS.Login, { replace: true });
                    } else {
                        showAlert(logoutResponse.data.message, "error");
                    }
                } else {
                    setLoading(prev => ({ ...prev, formSubmitting: false }));
                    showAlert(response.data.message, "error");
                    setFormData(initialFormData);
                }
            } catch (error) {
                console.error('Error changing password:', error);
                setFormData(initialFormData);
            } finally {
                setLoading(prev => ({ ...prev, formSubmitting: false }));
                setFormData(initialFormData);
            }
        }
    }

    const handleBlur = (e) => {
        let { name, value } = e.target;
        setErrors(prev => ({
            ...prev,
            [name]: !value ? "Required" : "",
        }));
    }

    const handleChange = (e) => {
        handleBlur(e);
        let { name, value } = e.target;
        setFormData(prev => ({
            ...prev,
            [name]: value
        }));
    }

    const handleClickBack = () => {
        navigator(-1);
    };

    const isError = () => {
        return Object.values(errors).some(err => err !== "");
    }

    return (
        <Box width="100%">
            <Breadcrumb isBack={true} onBackClick={() => handleClickBack()} pageTitle={"Change Password"} title={"My Profile"} />
            <Box p={{ xs: 2, lg: 3, xl: 4 }} py={{ xs: 3, lg: 3, xl: 4 }}>
                <Stack spacing={3}>
                    <Grid container justifyContent="space-between" alignItems="center">
                        {loading.pageLoading ? <CircularLoader height="500px" /> :
                            <form onSubmit={handleSubmit} className="step-form-1">
                                <Stack spacing={2} sx={{ flexBasis: "100%" }} width={350}>
                                    <Input
                                        id="old_password"
                                        name={"old_password"}
                                        label="Old Password*"
                                        variant="standard"
                                        placeholder="Enter Old Password"
                                        type="password"
                                        fullWidth
                                        required={false}
                                        value={formData?.old_password}
                                        onBlur={handleBlur}
                                        onChange={handleChange}
                                        error={!!errors?.old_password}
                                        helperText={errors?.old_password}
                                    />
                                    <Box>
                                        <Input
                                            id="new_password"
                                            name={"new_password"}
                                            label="New Password*"
                                            variant="standard"
                                            placeholder="Enter New Password"
                                            type="password"
                                            fullWidth
                                            required={false}
                                            value={formData?.new_password}
                                            onBlur={handleBlur}
                                            onChange={handleChange}
                                            error={!!errors?.new_password}
                                            helperText={typeof errors?.new_password === "string" ? errors?.new_password : null}
                                        />
                                        {typeof errors?.new_password !== "string" && errors?.new_password?.length > 0 ?
                                            <List sx={{ padding: 0, margin: 0, color: "red.main", fontSize: "12px", mt: "3px" }}>
                                                {errors?.new_password?.map((error, index) => (
                                                    <ListItem sx={{ padding: 0, margin: 0 }} key={index}>{error}</ListItem>
                                                ))}
                                            </List>
                                            : null}
                                    </Box>
                                    <Input
                                        id="confirm_password"
                                        name={"confirm_password"}
                                        label="Confirm Password*"
                                        variant="standard"
                                        placeholder="Enter Confirm Password"
                                        type="password"
                                        fullWidth
                                        required={false}
                                        value={formData?.confirm_password}
                                        onBlur={handleBlur}
                                        onChange={handleChange}
                                        error={!!errors?.confirm_password}
                                        helperText={errors?.confirm_password}
                                    />
                                </Stack>
                                <Box
                                    display="flex"
                                    justifyContent="space-between"
                                    alignItems="center"
                                    mt={4}
                                >
                                    <Box
                                        display="flex"
                                        justifyContent="flex-start"
                                        alignItems="center"
                                        flexBasis="100%"
                                        gap={2}
                                    >
                                        <Button
                                            variant="contained"
                                            size="large"
                                            type="submit"
                                            sx={{
                                                boxShadow: "0",
                                                fontSize: "15px",
                                                textTransform: "none",
                                                color: "white",
                                                "&:hover": { boxShadow: "0" },
                                            }}
                                            disabled={isError()}
                                            isButtonLoading={loading.formSubmitting}
                                        >
                                            Change
                                        </Button>
                                        <Button
                                            variant="text"
                                            color="secondary"
                                            size="large"
                                            sx={{
                                                boxShadow: "0",
                                                fontSize: "15px",
                                                textTransform: "none",
                                                "&:hover": { boxShadow: "0" },
                                            }}
                                            onClick={handleClickBack}
                                        >
                                            Cancel
                                        </Button>
                                    </Box>
                                </Box>
                            </form>
                        }
                    </Grid>
                </Stack>
            </Box>
        </Box>
    );
}

export default ChangePassword;
