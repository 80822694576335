/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import {
    Card,
    Container,
    Grid,
    TableSortLabel,
} from "@mui/material";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import EditIcon from "@mui/icons-material/Edit";
import Button from "../../../components/Button";
import URLS from "../../../routes/urls.js";
import { useNavigate } from "react-router-dom";
import API from "../../../axios/api";
import axiosInstance from "../../../axios";
import Switch from "../../../components/Switch";
import SearchBoxInput from "../../../components/SearchBoxInput/index.js";
import { useAlert } from "../../../hook/useAlert";
import Highlighter from "../../../components/Highlighter/index.js";
import useDebounce from "../../../hook/useDebounce.js";
import CircularLoader from "../../../components/CircularLoader/index.js";
import { getInputId, sortArrayByKey, titleCase } from "../../../utils/index.js";
import { usePageTitle } from "../../../hook/useTitle.js";
import TablePagination from "../../../components/TablePagination/index.jsx";

const AdminDashboardModule = () => {
    const navigate = useNavigate();
    const showAlert = useAlert();
    const { setPageTitle } = usePageTitle();
    const [moduleList, setModuleList] = useState([]);
    const [optionalModuleList, setOptionalModuleList] = useState([]);
    const [totalModuleCount, setTotalModuleCount] = useState(0);
    const [queryParams, setQueryParams] = useState({
        search: "",
        active: true,
    });
    const [searchString, setSearchString] = useState("");
    const debouncedSearchTerm = useDebounce(searchString);
    const [loading, setLoading] = useState({
        pageLoading: true,
    })
    const [pageBeenRendered, setPageBeenRendered] = useState(false);

    setPageTitle("Dashboard");

    useEffect(() => {
        if (pageBeenRendered) {
            setQueryParams((prev) => ({
                ...prev,
                search: debouncedSearchTerm,
            }));
            if (debouncedSearchTerm?.trim()?.length) {
                let searchedArray = optionalModuleList?.map((moduleData) => {
                    if (moduleData?.name?.toLowerCase()?.includes(debouncedSearchTerm?.toLowerCase())) {
                        if (moduleData?.Modules?.length) {
                            let searchedChild = moduleData?.Modules?.filter(childModuleData => childModuleData?.name?.toLowerCase()?.includes(debouncedSearchTerm?.toLowerCase()))
                            if (searchedChild?.length) {
                                return { ...moduleData, "Modules": searchedChild }
                            }
                        }
                        return moduleData;
                    } else {
                        if (moduleData?.Modules?.length) {
                            let searchedChild = moduleData?.Modules?.filter(childModuleData => childModuleData?.name?.toLowerCase()?.includes(debouncedSearchTerm?.toLowerCase()))
                            if (searchedChild?.length) {
                                return { ...moduleData, "Modules": searchedChild }
                            }
                            return false;
                        }
                    }
                    return false;
                })?.filter(data => !!data);

                setModuleList(searchedArray)
            } else {
                setModuleList(optionalModuleList)
            }
        }
        setPageBeenRendered(true)
    }, [debouncedSearchTerm]);

    const getAllModuleData = async () => {
        try {
            // setLoading((prev) => ({ ...prev, pageLoading: true }));
            const response = await axiosInstance.get(API.getAllModule);
            if (response.status === 200) {
                if (response.data?.data?.length) {
                    let sortedArray = sortArrayByKey(response.data?.data, queryParams?.sort, "name")
                    setModuleList(sortedArray);
                    setOptionalModuleList(sortedArray);
                    setTotalModuleCount(sortedArray?.length);
                } else {
                    setModuleList([]);
                }
            }
            setLoading((prev) => ({ ...prev, pageLoading: false }));
        } catch (error) {
            console.error(error);
            setLoading((prev) => ({ ...prev, pageLoading: false }));
        }
    };

    useEffect(() => {
        getAllModuleData();
    }, []);

    const handleOnChangeActiveStatus = async (e, index, moduleId, moduleData, childId = null, childIndex = null) => {
        let statusValue = e?.target?.checked;
        let body = {
            name: moduleData?.name,
            active: statusValue,
            module_type_id: childId ? moduleData?.module_type_id : null
        }

        if (!childId) delete body.module_type_id;
        const response = await axiosInstance.put(API.updateModule(childId ?? moduleId), body);

        if (response.status === 200) {
            const updatedModuleList = [...moduleList];
            if (childIndex !== null) updatedModuleList[index]['Modules'][childIndex]['active'] = statusValue;
            else updatedModuleList[index]['active'] = statusValue;
            setModuleList(updatedModuleList);

            setOptionalModuleList((prev) => prev?.map((moduleData) => {
                if (moduleData?.id === moduleId) {
                    if (childIndex !== null) {
                        return {
                            ...moduleData, Modules: moduleData?.Modules?.map(childModuleData => {
                                return childModuleData?.id === childId ? { ...childModuleData, active: statusValue } : childModuleData
                            })
                        }
                    }
                    return { ...moduleData, active: statusValue }
                }
                return moduleData
            }))
            showAlert(response?.data?.message, "success");
        } else {
            showAlert("something went wrong", "error");
        }
    };

    const handleChangePage = (event, newPage) => {
        setQueryParams((prev) => ({
            ...prev,
            page: newPage,
        }));
    };

    const handleChangeRowsPerPage = (event) => {
        setQueryParams((prev) => ({
            ...prev,
            pageSize: parseInt(event.target.value, 10),
            page: 0,
        }));
    };

    const handleSortByName = () => {
        setQueryParams((prev) => ({
            ...prev,
            sort: prev.sort === "asc" ? "desc" : "asc",
        }));
        let sortedArray = sortArrayByKey(optionalModuleList, queryParams?.sort === "asc" ? "desc" : "asc", "name")
        setModuleList(sortedArray);
    };

    const handleSearch = (value) => {
        setSearchString(value);
    };

    return (
        <Box sx={{ marginTop: "-190px" }} position="relative" zIndex={1}>
            <Container>
                <Typography variant="h6" color="white" marginBottom="12px">
                    Modules
                </Typography>
                <Card sx={{ bgcolor: "bgColor", boxShadow: '0px 31px 95px rgba(0, 0, 0, 0.12)' }}>
                    <Box>
                        <Box p={"26px"}>
                            <Grid
                                justifyContent="space-between"
                                alignItems="center"
                                display="flex"
                            >
                                <Grid item>
                                    <SearchBoxInput
                                        label="Search"
                                        variant="standard"
                                        placeholder="a Module"
                                        handleSearch={handleSearch}
                                        value={searchString}
                                        isUseDebounce={false}
                                    />
                                </Grid>
                                <Grid item>
                                    <Button
                                        color="primary"
                                        size="small"
                                        sx={{
                                            boxShadow: 0,
                                            color: "white",
                                            "&:hover": { boxShadow: 0 },
                                            height: "36px",
                                            textTransform: "capitalize",
                                        }}
                                        onClick={() => navigate(URLS.AddModule)}
                                    >
                                        Create
                                    </Button>
                                </Grid>
                            </Grid>
                        </Box>
                        <Paper sx={{ width: "100%", overflow: "hidden", boxShadow: 0 }}>
                            <TableContainer sx={{ maxHeight: 440 }}>
                                <Table stickyHeader aria-label="sticky table">
                                    <TableHead>
                                        <TableRow>
                                            <TableCell
                                                sx={{ bgcolor: "bgColor", padding: "8px 16px" }}
                                            >
                                                <TableSortLabel
                                                    active={true}
                                                    direction={queryParams.sort}
                                                    onClick={() => { moduleList?.length && handleSortByName() }}
                                                >
                                                    Modules
                                                </TableSortLabel>
                                            </TableCell>
                                            <TableCell
                                                sx={{ bgcolor: "bgColor", padding: "8px 16px" }}
                                            >
                                                Type
                                            </TableCell>
                                            <TableCell
                                                sx={{ bgcolor: "bgColor", padding: "8px 16px", width: '100px', minWidth: '100px', maxWidth: '100px' }}
                                            >
                                                Status
                                            </TableCell>
                                            <TableCell
                                                sx={{ bgcolor: "bgColor", padding: "8px 16px", width: '60px', minWidth: '60px', maxWidth: '60px' }}
                                            ></TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody
                                        sx={{
                                            'td': {
                                                lineHeight: 'normal',
                                            }
                                        }}
                                    >
                                        {loading.pageLoading ?
                                            <CircularLoader variant="table" rows={queryParams.pageSize} cols={4} />
                                            :
                                            moduleList?.length ? (
                                                moduleList?.map((moduleData, index) => {
                                                    return (
                                                        <React.Fragment key={index}>
                                                            <TableRow
                                                                hover
                                                                sx={{
                                                                    "&:hover": {
                                                                        background: "rgba(0, 0, 0, 0.12)",
                                                                        ".edit-btn": {
                                                                            opacity: "1",
                                                                            visibility: "visible",
                                                                        },
                                                                    },
                                                                }}
                                                                key={index}
                                                            >
                                                                <TableCell colSpan={2} sx={{ padding: "8px 16px" }}>
                                                                    {/* {titleCase(moduleData?.name)} */}
                                                                    <Highlighter
                                                                        value={titleCase(moduleData?.name)}
                                                                        term={searchString}
                                                                        highlightClassName={"highlighted"}
                                                                    />
                                                                </TableCell>
                                                                <TableCell sx={{ padding: "8px 12px" }}>
                                                                    <Switch
                                                                        id={getInputId("status", moduleData?.id)}
                                                                        name="status"
                                                                        size="small"
                                                                        checked={moduleData?.active || false}
                                                                        onChange={(e) =>
                                                                            handleOnChangeActiveStatus(
                                                                                e,
                                                                                index,
                                                                                moduleData?.id,
                                                                                moduleData
                                                                            )
                                                                        }
                                                                    />
                                                                </TableCell>
                                                                <TableCell sx={{ padding: "8px 12px" }}>
                                                                    <IconButton
                                                                        aria-label="delete"
                                                                        size="small"
                                                                        sx={{
                                                                            opacity: "0",
                                                                            visibility: "hidden",
                                                                            transition: 1,
                                                                        }}
                                                                        className="edit-btn"
                                                                        onClick={() =>
                                                                            navigate({
                                                                                pathname: `${URLS.EditModule}/${moduleData?.id}/true`,
                                                                            })
                                                                        }
                                                                    >
                                                                        <EditIcon fontSize="small" />
                                                                    </IconButton>
                                                                </TableCell>
                                                            </TableRow>
                                                            {
                                                                moduleData?.Modules?.length ? (
                                                                    sortArrayByKey(moduleData?.Modules, queryParams?.sort, "name")?.map((chilModuleData, childIndex) => (
                                                                        <TableRow
                                                                            hover
                                                                            sx={{
                                                                                "&:hover": {
                                                                                    background: "rgba(0, 0, 0, 0.12)",
                                                                                    ".edit-btn": {
                                                                                        opacity: "1",
                                                                                        visibility: "visible",
                                                                                    },
                                                                                },
                                                                            }}
                                                                            key={childIndex}
                                                                        >
                                                                            <TableCell sx={{ padding: "8px 16px 8px 40px" }} width={'400px'}>
                                                                                <Typography
                                                                                    variant="caption"
                                                                                    mr={1}
                                                                                    color="dark.800"
                                                                                    fontSize={13}
                                                                                    sx={{
                                                                                        textTransform: "capitalize",
                                                                                        fontWeight: "500",
                                                                                        display: "inline-block",
                                                                                    }}
                                                                                >
                                                                                    <Highlighter
                                                                                        value={titleCase(chilModuleData?.name)}
                                                                                        term={searchString}
                                                                                        highlightClassName={"highlighted"}
                                                                                    />
                                                                                </Typography>
                                                                            </TableCell>
                                                                            <TableCell sx={{ padding: "8px 16px" }}>
                                                                                <Grid item>
                                                                                    <Grid item>
                                                                                        {titleCase(moduleData?.name)}
                                                                                    </Grid>
                                                                                </Grid>
                                                                            </TableCell>
                                                                            <TableCell sx={{ padding: "8px 12px" }}>
                                                                                <Switch
                                                                                    id={getInputId("status", chilModuleData?.id)}
                                                                                    name="status"
                                                                                    size="small"
                                                                                    checked={chilModuleData?.active || false}
                                                                                    onChange={(e) =>
                                                                                        handleOnChangeActiveStatus(
                                                                                            e,
                                                                                            index,
                                                                                            moduleData?.id,
                                                                                            chilModuleData,
                                                                                            chilModuleData?.id,
                                                                                            childIndex,
                                                                                        )
                                                                                    }
                                                                                />
                                                                            </TableCell>
                                                                            <TableCell sx={{ padding: "8px 12px" }}>
                                                                                <IconButton
                                                                                    aria-label="delete"
                                                                                    size="small"
                                                                                    sx={{
                                                                                        opacity: "0",
                                                                                        visibility: "hidden",
                                                                                        transition: 1,
                                                                                    }}
                                                                                    className="edit-btn"
                                                                                    onClick={() =>
                                                                                        navigate({
                                                                                            pathname: `${URLS.EditModule}/${chilModuleData?.id}/false`,
                                                                                        })
                                                                                    }
                                                                                >
                                                                                    <EditIcon fontSize="small" />
                                                                                </IconButton>
                                                                            </TableCell>
                                                                        </TableRow>
                                                                    ))
                                                                ) : null
                                                            }
                                                        </React.Fragment>
                                                    );
                                                })
                                            ) : (
                                                <TableRow>
                                                    <TableCell
                                                        colSpan={4}
                                                        align="center"
                                                        sx={{ padding: "12px 16px;" }}
                                                    >
                                                        <Typography
                                                            color={"secondary"}
                                                            display={"flex"}
                                                            alignItems={"center"}
                                                            justifyContent={"center"}
                                                            fontSize={13}
                                                            sx={{ opacity: "0.5", height: "236px" }}
                                                        >
                                                            No Modules
                                                        </Typography>
                                                    </TableCell>
                                                </TableRow>
                                            )}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                            <TablePagination
                                rowsPerPageOptions={[10, 25, 50]}
                                count={totalModuleCount}
                                rowsPerPage={queryParams.pageSize}
                                page={queryParams.page}
                                onPageChange={handleChangePage}
                                onRowsPerPageChange={handleChangeRowsPerPage}
                            />
                        </Paper>
                    </Box>
                </Card>
            </Container>
        </Box>
    )
}

export default AdminDashboardModule