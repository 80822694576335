const URLS = {
  Login: "/login",
  AccessDenied: "/page-not-found",
  TermsAndConditions: "/terms-and-conditions",
  PrivacyPolicy: "/privacy-policy",
  CAPrivacyNotice: "/ca-privacy-notice",
  AdminDashboard: "/admin/dashboard/organisation",
  AdminProfile: "/admin/profile",
  AddOrganisation: "/admin/organisation/add-organisation",
  EditOrganisation: "/admin/organisation/edit-organisation",
  AdminDashboardModule: "/admin/dashboard/module",
  AddModule: "/admin/module/add-module",
  EditModule: "/admin/module/edit-module",
  ForgotPassword: "/forgot-password",
  ResetPassword: "/reset-password/:reset_password_token",
  onBoarding: "/on-borading",
  InCompleteOnBoarding: "/incomplete-onboard-process",
  Dashboard: "/dashboard",
  Projects: "/projects",
  CreateProject: "/projects/create",
  UpdateProject: "/projects/update",
  Leaves: "/request/leaves",
  ApplyLeave: "/request/leaves/leave/apply",
  OtherLeaveDetails: "/request/leaves/other-leave",
  ViewMyLeaveApplication: "/request/leaves/my-leave/view",
  EditMyLeaveApplication: "/request/leaves/my-leave/edit",
  EditOthersLeaveApplication: "/request/leaves/other-leave/edit",
  OtherWfhApplicationDetails: "/request/work-from-home/other-wfh-application",
  EditOthersWfhApplication: "/request/work-from-home/other-wfh-application/edit",
  ViewMyWfhApplication: "/request/work-from-home/my-wfh-application/view",
  EditMyWfhApplication: "/request/work-from-home/my-wfh-application/edit",
  WorkFromHome: "/request/work-from-home",
  ApplyWorkFromHome: "/request/work-from-home/apply",
  Inventory: "/request/inventory",
  Users: "/users",
  Reports: "/reports",
  InOutReport: "/report/in-out-report",
  LeaveReport: "/report/leave-report",
  UserDetails: "/users/user-details",
  CreateUser: "/users/create-user",
  Holidays: "/holidays",
  Profile: "/profile",
  ChangePassword: "/profile/change-password",
  AdministratorOrganisation: "/administrator/organisation",
  AdministratorLeaves: "/administrator/leaves",
  AdministratorHolidays: "/administrator/holidays",
  AdministratorHolidaysCreate: "/administrator/holidays/create",
  AdministratorRoles: "/administrator/roles",
  AdministratorProjectRoles: "/administrator/project-roles",
  AdministratorActivity: "/administrator/activity",
  AdministratorCreateActivity: "/administrator/activity/create",
  AdministratorUpdateActivity: "/administrator/activity/update",
  AdministratorYears: "/administrator/years",
  AdministratorWorkType: "/administrator/workt-type",
  AllNotifications: "/all-notifications",
  Repotees: "/repotees",
  EmailTemplates: "/CRM/templates",
  EmailTransactions: "/CRM/transactions",
  CreateEmailTemplate: "/CRM/templates/create",
  UpdateEmailTemplate: "/CRM/templates/update",
  CampaignsContactList: "/CRM/contacts",
};

export default URLS;
