/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import {
  Box,
  FormControl,
  Grid,
  IconButton,
  MenuItem,
  Paper,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TableRow,
  TableSortLabel,
  Tooltip,
  tooltipClasses,
  Typography,
} from "@mui/material";
import TextEditor from "../../../../../components/TextEditor";
import DeleteIcon from "@mui/icons-material/Delete";
import FileDropZone from "../../../../../components/FileDropZone";
import SingleDatePicker from "../../../../../components/SingleDatePicker";
import axiosInstance from "../../../../../axios";
import API from "../../../../../axios/api";
import moment from "moment";
import { useAlert } from "../../../../../hook/useAlert";
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import { convertDateFromFormat, getDurationFromDate, getFormattedDate, getFullName, getInputId, getReportingAuthority, isFutureYearDate, sortArrayByKey, titleCase } from "../../../../../utils";
import Button from "../../../../../components/Button";
import MultipleDatePickerWithButton from "../../../../../components/MultipleDatePickerWithButton";
import { useNavigate } from "react-router-dom";
import URLS from "../../../../../routes/urls";
import { useSelector } from "react-redux";
import InfoIcon from '@mui/icons-material/Info';
import { styled } from "@mui/styles";
import Breadcrumb from "../../../../../components/Breadcrumb";
import ScheduleIcon from '@mui/icons-material/Schedule';
import TimePickerClock from "../../../../../components/TimePickerClock";
import { getDefaultInOutTime, getTime, LEAVE_DURATION_TYPE } from "../../../../../constants/default-values";
import DatePanel from "react-multi-date-picker/plugins/date_panel";
import Toolbar from "react-multi-date-picker/plugins/toolbar";

const HtmlTooltip = styled(({ className, ...props }) => (
  <Tooltip arrow {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    padding: "16px",
    borderRadius: "5px",
    background: "#444444",
    maxWidth: 530,
  },
}));

const ApplyWFHRequestDetails = ({
  setIsAppliedLeave,
  setLeaveData,
  leaveData,
  userLeaveTypes,
  organizationUserList,
  currentUser,
  activeRequestType,
  leaveSnapshot,
  renewalSettingData,
  setHalfRequestData,
  halfRequestData
}) => {
  const showAlert = useAlert();
  const navigate = useNavigate();
  const { ignoreLeaveDates } = useSelector((state) => state?.leaveData);
  const RequestedToUserDetails = getReportingAuthority(organizationUserList, leaveData?.informed_authorities[0]);
  const AppliedLeaveType = userLeaveTypes?.find(
    (leaveType) =>
      leaveType?.id ===
      leaveData?.static_id
  );
  const [tempAddNewLeaves, setTempAddNewLeaves] = useState([]);
  const [isLoading, setIsLoading] = useState({
    formSubmitting: false,
    formButtonDisable: false,
    isTimeValid: false
  });
  const [sortType, setSortType] = useState("asc");

  const [errors, setErrors] = useState({
    description: '',
    leave_days: {}
  });

  useEffect(() => {
    if (!!leaveData) {
      let wfhDays = leaveData?.WfhDays;
      let updatedErrors = { ...errors.leave_days };

      let isOutTimeMore = false;
      wfhDays?.map((wfhDetails) => {
        let inTime = wfhDetails?.in_time;
        let outTime = wfhDetails?.out_time;
        let isDateExist = halfRequestData?.find(leaveData => leaveData?.leave_date === wfhDetails?.date)
        if (isDateExist) {
          if (isDateExist?.leave_duration === 'first_half') {
            if (new Date(inTime) < getTime(isDateExist?.leave_application_id ? isDateExist?.in_time : isDateExist?.out_time)) {
              let value = moment(getTime(isDateExist?.leave_application_id ? isDateExist?.in_time : isDateExist?.out_time)).format("hh:mm A");
              updatedErrors[`${wfhDetails?.date}`] = { 'in_time': `Please Enter check-in time after ${value}` };
              setIsLoading(prev => ({ ...prev, isTimeValid: true }))
            } else {
              delete updatedErrors[`${wfhDetails?.date}`];
              setIsLoading(prev => ({ ...prev, isTimeValid: false }))
            }
          } else if (isDateExist?.leave_duration === 'second_half') {
            if (new Date(outTime) > getTime(isDateExist?.leave_application_id ? isDateExist?.out_time : isDateExist?.in_time)) {
              let value = moment(getTime(isDateExist?.leave_application_id ? isDateExist?.out_time : isDateExist?.in_time)).format("hh:mm A");
              updatedErrors[`${wfhDetails?.date}`] = { 'out_time': `Please Enter check-out time before ${value}` };
              setIsLoading(prev => ({ ...prev, isTimeValid: true }))
            } else {
              delete updatedErrors[`${wfhDetails?.date}`];
              setIsLoading(prev => ({ ...prev, isTimeValid: false }))
            }
          }
          if (inTime >= outTime) {
            let value = moment(new Date(inTime)).format("hh:mm A");
            updatedErrors[`${wfhDetails?.date}`] = { 'out_time': `Please Enter check-out time after ${value}` };
            setIsLoading(prev => ({ ...prev, isTimeValid: true }))
          }
        } else {
          if (inTime >= outTime) {
            let value = moment(new Date(inTime)).format("hh:mm A");
            updatedErrors[`${wfhDetails?.date}`] = { 'out_time': `Please Enter check-out time after ${value}` };
            isOutTimeMore = true;
          }
        }
        return wfhDetails;
      })
      setIsLoading(prev => ({ ...prev, isTimeValid: isOutTimeMore }))
      setErrors((prev) => ({ ...prev, leave_days: updatedErrors, }));
    }
  }, [])

  useEffect(() => {
    let sortedArray = sortArrayByKey(leaveData?.WfhDays, sortType, 'date');
    setLeaveData((prev) => ({
      ...prev,
      WfhDays: sortedArray
    }));
  }, [sortType])

  const isCreditEnable = (leaveDetail) => {
    return !!activeRequestType?.find((request) => request?.spec_id === leaveData?.static_id);
  }

  const handleChangeAddRemoveLeaves = async (type, leaveIndex, leaveDetail) => {
    if (type === "remove") {
      let updatedLeaveDays = leaveData?.WfhDays?.filter((leave, index) => index !== leaveIndex)
      setLeaveData((prev) => ({
        ...prev,
        WfhDays: sortArrayByKey(updatedLeaveDays, "asc", "date"),
      }));

    } else {
      try {
        const addLeavesData = tempAddNewLeaves?.map((dates) => getFormattedDate(new Date(dates)));
        if (addLeavesData?.length > 0) {
          let { inTime, outTime } = getDefaultInOutTime();
          let isDateExist = (date) => halfRequestData?.find(leaveData => getFormattedDate(leaveData?.leave_date, "DD/MM/YYYY") === date)
          let updatedLeaveDays = [
            ...leaveData?.WfhDays,
            ...addLeavesData?.map((date) => ({
              date: date,
              leave_duration: !!isDateExist(date) ? isDateExist(date)?.leave_duration === 'first_half' ? 'second_half' : 'first_half' : "full",
              credit_used: isFutureYearDate(renewalSettingData, date) ? 0 : 1,
              in_time: !!isDateExist(date) ? isDateExist(date)?.leave_duration === 'first_half' ? getTime(isDateExist(date)?.leave_application_id ? isDateExist(date)?.in_time : isDateExist(date)?.out_time) : inTime : inTime,
              out_time: !!isDateExist(date) ? isDateExist(date)?.leave_duration === 'second_half' ? getTime(isDateExist(date)?.leave_application_id ? isDateExist(date)?.out_time : isDateExist(date)?.in_time) : outTime : outTime,
            }))];
          setLeaveData((prev) => ({
            ...prev,
            WfhDays: sortArrayByKey(updatedLeaveDays, "asc", "date"),
          }));
          setTempAddNewLeaves([]);

        }
      } catch (error) {
        console.error("Error:", error);
        showAlert(error?.response?.data?.message, "error");
      }
    }
  };

  const handleChangeSelectedDate = async (
    newSelectedDate,
    index,
    leaveDetail
  ) => {
    try {
      let isDateExist = () => {
        let existDateData = halfRequestData?.find(leaveData => getFormattedDate(leaveData?.leave_date, "YYYY-MM-DD") === getFormattedDate(new Date(newSelectedDate), "YYYY-MM-DD"));
        return existDateData;
      }

      let updatedLeaveDays = leaveData?.WfhDays?.map((item, i) => i === index ? {
        ...leaveDetail,
        leave_duration: !!isDateExist(item?.leave_date) ? isDateExist(item?.leave_date)?.leave_duration === 'first_half' ? 'second_half' : 'first_half' : leaveDetail?.leave_duration,
        in_time: !!isDateExist() ? isDateExist()?.leave_duration === 'first_half' ? getTime(isDateExist()?.leave_application_id ? isDateExist()?.in_time : isDateExist()?.out_time) : leaveDetail?.in_time : leaveDetail?.in_time ?? null,
        out_time: !!isDateExist() ? isDateExist()?.leave_duration === 'second_half' ? getTime(isDateExist()?.leave_application_id ? isDateExist()?.out_time : isDateExist()?.in_time) : leaveDetail?.out_time : leaveDetail?.out_time ?? null,
        date: getFormattedDate(new Date(newSelectedDate)),
        credit_used: isFutureYearDate(renewalSettingData, getFormattedDate(new Date(newSelectedDate))) ? 0 : 1
      } : {
        ...item,
      });
      setLeaveData((prev) => ({
        ...prev,
        WfhDays: sortArrayByKey(updatedLeaveDays, "asc", "date"),
      }));
      let updatedErrors = { ...errors?.leave_days };
      if (Object.keys(updatedErrors)?.includes(`${leaveDetail?.date}`)) setIsLoading(prev => ({ ...prev, isTimeValid: false }))
      delete updatedErrors[`${leaveDetail?.date}`];
      setErrors((prev) => ({ ...prev, leave_days: updatedErrors, }));
    } catch (error) {
      console.error(error);
    }
  };

  const getTotalCreditUsedCount = (leave_array) => {
    let totalCreditUsed = 0;
    leave_array?.forEach((leave) => {
      totalCreditUsed += leave?.credit_used || 0;
    });
    return totalCreditUsed || 0;
  };

  const getTotalRemainingCredit = () => {
    let wfhTypeData = activeRequestType?.find(data => data?.spec_id === leaveData?.static_id);
    let count = wfhTypeData?.remaining - getTotalCreditUsedCount(leaveData?.WfhDays);
    return count ? count : 0;
  }

  const getAlreadyRemainingCredit = () => {
    let wfhTypeData = activeRequestType?.find(data => data?.spec_id === leaveData?.static_id);
    return wfhTypeData?.remaining;
  }

  const getIgnoreDates = () => {
    let array = [];
    if (!!ignoreLeaveDates?.leaves?.length) {
      array.push(...ignoreLeaveDates?.leaves);
    }
    if (!!ignoreLeaveDates?.custom_holidays?.length) {
      array.push(...ignoreLeaveDates?.custom_holidays?.map(value => value?.date));
    }
    if (!!ignoreLeaveDates?.festival_holidays?.length) {
      array.push(...ignoreLeaveDates?.festival_holidays?.map(value => value?.date));
    }
    if (!!ignoreLeaveDates?.saturdayHolidays?.length) {
      array.push(...ignoreLeaveDates?.saturdayHolidays?.map(value => value?.date));
    }
    if (!!ignoreLeaveDates?.sundayHolidays?.length) {
      array.push(...ignoreLeaveDates?.sundayHolidays?.map(value => value?.date));
    }
    if (!!ignoreLeaveDates?.inactive_holidays?.length) {
      array.push(...ignoreLeaveDates?.inactive_holidays?.map(value => value?.date));
    }
    if (!!ignoreLeaveDates?.disabledDates?.length) {
      array.push(...ignoreLeaveDates?.disabledDates?.map(value => convertDateFromFormat(value)));
    }
    if (!!ignoreLeaveDates?.halfDayLeave?.length) {
      array = array.filter(date => !ignoreLeaveDates?.halfDayLeave?.find(leaveData => leaveData?.leave_date === date))
    }
    let updatedList = [...leaveData?.WfhDays?.map((value) => value?.date), ...array?.map(date => getFormattedDate(date))]
    if (ignoreLeaveDates?.length) {
      updatedList.push(...ignoreLeaveDates)
    }
    return Array.from(new Set(updatedList));
  }

  const isValidateData = () => {
    let isEmpty = !leaveData?.description || leaveData?.description
      ?.replace(/&nbsp;/g, ' ')
      ?.replace(/<(.|\n)*?>/g, '')
      ?.trim()?.length === '' || errors?.description?.length;

    if (!errors?.description?.length && isEmpty) {
      setErrors((prev) => ({
        ...prev,
        description: 'Reason Required'
      }))
    } else {
      let reasonLength = leaveData?.description
        ?.replace(/&nbsp;/g, ' ')
        ?.replace(/<(.|\n)*?>/g, '')
        ?.trim()?.length;

      isEmpty = reasonLength < 50 || reasonLength > 500;
      setErrors((prev) => ({
        ...prev,
        description: reasonLength ? (reasonLength < 50 || reasonLength > 500) ? 'Atleast 50 and up to 500 characters required' : ''
          : 'Reason Required'
      }))
    }
    setIsLoading((prev) => ({ ...prev, formButtonDisable: isEmpty }))
    return !isEmpty;
  }

  const handleSubmitLeaveApplication = async () => {
    try {
      setIsLoading((prev) => ({ ...prev, formSubmitting: true }))
      function convertObjectToFormData(obj) {
        const formData = new FormData();
        obj.attachments.forEach((attachment, index) => {
          formData.append(`attachments[${index}][name]`, attachment?.name);
          formData.append(`attachments[${index}][size]`, attachment?.size);
          formData.append(`attachments[${index}][type]`, attachment?.type);
          formData.append(`attachments[${index}][base64]`, attachment?.base64);
          formData.append(`attachments[${index}][unique_id]`, attachment?.unique_id);
          formData.append(`attachments[${index}][extension]`, `.${attachment?.name?.split('.').pop()}`);
        });
        formData.append(
          "static_id",
          obj.static_id
        );
        formData.append("user_req_spec_id", obj.user_req_spec_id);
        formData.append("description", obj.description);
        formData.append("apply_date", getFormattedDate(obj.apply_date, "YYYY-MM-DD"));
        formData.append(
          "org_year_id",
          currentUser?.organization?.work_type_settings?.[0]?.OrgYear?.id
        );
        formData.append(
          "setting_start_date",
          currentUser?.organization?.work_type_settings?.[0]?.OrgYear?.setting_start_date
        );
        formData.append(
          "setting_end_date",
          currentUser?.organization?.work_type_settings?.[0]?.OrgYear?.setting_end_date
        );
        obj.informed_authorities?.forEach((authority, index) => {
          formData.append(`informed_authorities[${index}]`, authority?.id);
        });
        obj?.WfhDays?.forEach((leaveDay, index) => {
          formData.append(
            `WfhDays[${index}][date]`,
            convertDateFromFormat(leaveDay?.date)
          );
          formData.append(
            `WfhDays[${index}][leave_duration]`,
            leaveDay?.leave_duration
          );
          formData.append(
            `WfhDays[${index}][credit_used]`,
            leaveDay?.credit_used
          );
          formData.append(
            `WfhDays[${index}][in_time]`,
            moment(leaveDay?.in_time).format("HH:mm")
          );
          formData.append(
            `WfhDays[${index}][out_time]`,
            moment(leaveDay?.out_time).format("HH:mm")
          );
        });

        return formData;
      }
      if (isValidateData()) {
        const formData = convertObjectToFormData(leaveData);

        const config = {
          headers: { "content-type": "multipart/form-data" },
        };
        const response = await axiosInstance.post(
          API.createWFHApplication(currentUser?.id),
          formData,
          config
        );
        if (response.status === 200) {
          showAlert(response?.data.message);
          navigate(`${URLS.WorkFromHome}?view=list&tabValue=1&groupBy=Status&status=active`);
        } else {
          showAlert(response?.response?.data?.message, "error");
        }
      }
      setIsLoading((prev) => ({ ...prev, formSubmitting: false }))
    } catch (error) {
      console.error("Error:", error);
      setIsLoading((prev) => ({ ...prev, formSubmitting: false }))
      showAlert(error?.response?.data?.message, "error");
    }
  };

  const handleChangeClick = () => {
    setTimeout(() => {
      setIsAppliedLeave(false);
    }, 0);
  }

  const handleClickBack = () => {
    navigate(-1);
  }

  const getRemainingCreditsData = () => {
    return leaveSnapshot?.find((data) => Object.keys(data)?.includes('specialCredits'))
  }

  const getUsedCredits = (total, used) => {
    if (used > total) return `${total}+${used - total}(Extra)`;
    else return used
  }

  const handleChangeDescription = (value) => {
    let tempVal = value;
    const isEmpty = !tempVal || tempVal?.replace(/&nbsp;/g, ' ')
      ?.replace(/<(.|\n)*?>/g, '')
      ?.trim() === '';

    let reasonLength = value
      ?.replace(/&nbsp;/g, ' ')
      ?.replace(/<(.|\n)*?>/g, '')
      ?.trim()?.length;

    setIsLoading((prev) => ({ ...prev, formButtonDisable: (reasonLength < 50 || reasonLength > 500) }))
    setLeaveData({
      ...leaveData,
      description: value,
    })
    setErrors((prev) => ({
      ...prev,
      description: !isEmpty ? '' : 'Reason Required'
    }))
  }

  const handleBlurDescription = (value) => {
    let reasonLength = leaveData?.description
      ?.replace(/&nbsp;/g, ' ')
      ?.replace(/<(.|\n)*?>/g, '')
      ?.trim()?.length;
    setIsLoading((prev) => ({ ...prev, formButtonDisable: (reasonLength < 50 || reasonLength > 500) }))

    setErrors((prev) => ({
      ...prev,
      description: reasonLength ? (reasonLength < 50 || reasonLength > 500) ? 'Atleast 50 and up to 500 characters required' : ''
        : 'Reason Required'
    }))
  }

  const getReasonLength = () => {
    return leaveData?.description
      ?.replace(/&nbsp;/g, ' ')
      ?.replace(/<(.|\n)*?>/g, '')
      ?.trim()?.length;
  }

  const handelTimeChange = (time, index, leaveDetail, field, date) => {
    let selectedTime = time;
    let updatedErrors = { ...errors?.leave_days };
    if (!selectedTime) {
      updatedErrors[`${date}`] = { [field]: `Required` };
    } else {
      let inTime = field === "in_time" ? selectedTime : leaveDetail?.in_time;
      let outTime = field === "out_time" ? selectedTime : leaveDetail?.out_time;
      let isDateExist = halfRequestData?.find(leaveData => getFormattedDate(leaveData?.leave_date, "DD/MM/YYYY") === date)
      if (isDateExist) {
        if (isDateExist?.leave_duration === 'first_half') {
          if (new Date(time) < getTime(isDateExist?.in_time)) {
            let value = moment(getTime(isDateExist?.in_time)).format("hh:mm A");
            updatedErrors[`${date}`] = { 'in_time': `Please Enter check-in time after ${value}` };
            setIsLoading(prev => ({ ...prev, isTimeValid: true }))
          } else {
            delete updatedErrors[`${date}`];
            setIsLoading(prev => ({ ...prev, isTimeValid: false }))
          }
        } else if (isDateExist?.leave_duration === 'second_half') {
          if (new Date(time) > getTime(isDateExist?.out_time)) {
            let value = moment(getTime(isDateExist?.out_time)).format("hh:mm A");
            updatedErrors[`${date}`] = { 'out_time': `Please Enter check-out time before ${value}` };
            setIsLoading(prev => ({ ...prev, isTimeValid: true }))
          } else {
            delete updatedErrors[`${date}`];
            setIsLoading(prev => ({ ...prev, isTimeValid: false }))
          }
        }
        if (inTime >= outTime) {
          let value = moment(new Date(inTime)).format("hh:mm A");
          updatedErrors[`${date}`] = { 'out_time': `Please Enter check-out time after ${value}` };
          setIsLoading(prev => ({ ...prev, isTimeValid: true }))
        }
      } else {
        if (inTime >= outTime) {
          let value = moment(new Date(inTime)).format("hh:mm A");
          updatedErrors[`${date}`] = { 'out_time': `Please Enter check-out time after ${value}` };
          setIsLoading(prev => ({ ...prev, isTimeValid: true }))
        } else {
          delete updatedErrors[`${date}`];
          setIsLoading(prev => ({ ...prev, isTimeValid: false }))
        }
      }
    }
    setErrors((prev) => ({ ...prev, leave_days: updatedErrors, }));
    const updatedLeaveDetails = {
      ...leaveData,
      WfhDays: leaveData?.WfhDays?.map(
        (item, i) => {
          if (i === index) {
            return { ...item, [field]: new Date(selectedTime) };
          } else {
            return item;
          }
        }
      )
    };
    setLeaveData(updatedLeaveDetails);
  }

  const handleChangeWfhDurationType = (event, index, leaveDetail, field, date) => {
    const updatedLeaveDetails = {
      ...leaveData,
      WfhDays: leaveData?.WfhDays?.map(
        (item, i) => {
          if (i === index) {
            return { ...item, [field]: event.target.value };
          } else {
            return item;
          }
        }
      )
    };
    setLeaveData(updatedLeaveDetails);
  }

  const isEnableDuratioOption = (leaveType, date) => {
    let isDateExist = halfRequestData?.find(leaveData => getFormattedDate(leaveData?.leave_date, "DD/MM/YYYY") === date)
    if (!!isDateExist) {
      return (isDateExist?.leave_duration === 'first_half' ? 'second_half' : 'first_half') !== leaveType
    }
    return false
  }

  const LeaveTypePlugin = () => {
    return (
      <Box display={"flex"} sx={{ padding: "8px 20px" }} gap={2}>
        <Box display={"flex"} alignItems={"center"}>
          <Box mr={1} width={10} height={10} minWidth={10} bgcolor={"#12B495"} borderRadius={20}></Box>
          <Typography color={"dark.800"} fontSize={14} fontWeight={400} lineHeight={"20px"} letterSpacing={"0.17px"}>Casual Leave</Typography>
        </Box>
        <Box display={"flex"} alignItems={"center"}>
          <Box mr={1} width={10} height={10} minWidth={10} bgcolor={"#D32F2F"} borderRadius={20}></Box>
          <Typography color={"dark.800"} fontSize={14} fontWeight={400} lineHeight={"20px"} letterSpacing={"0.17px"}>Sick Leave</Typography>
        </Box>
        <Box display={"flex"} alignItems={"center"}>
          <Box mr={1} width={10} height={10} minWidth={10} bgcolor={"#047FE0"} borderRadius={20}></Box>
          <Typography color={"dark.800"} fontSize={14} fontWeight={400} lineHeight={"20px"} letterSpacing={"0.17px"}>Work Remotely</Typography>
        </Box>
      </Box>)
  };

  return (
    <>
      <Breadcrumb isBack={true} title={"My WFH Request"}
        pageTitle={'New Request'}
        isPathShow={false}
        onBackClick={() => handleClickBack()} />
      <Box sx={{ padding: "26px !important" }}>
        <Grid display={"flex"} justifyContent={"space-between"} alignItems={"flex-end"}>
          <Box
            width={475}
            maxWidth="100%"
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            borderRadius={1}
            sx={{
              padding: "16px !important",
              background: "#F7F7F7",
              marginBottom: "16px",
              marginRight: "15px",
            }}
          >
            <Grid item>
              <Typography
                color="dark.800"
                fontSize={18}
                fontWeight={600}
                lineHeight="24px"
                letterSpacing="0.15px"
                sx={{ marginBottom: "6px" }}
              >
                {AppliedLeaveType?.name === "Come late" ? "Late Coming" : titleCase(AppliedLeaveType?.name)} Request
              </Typography>
              <Typography
                color="dark.800"
                fontSize={14}
                fontWeight={400}
                lineHeight="24px"
                letterSpacing="0.15px"
              >
                Request to:{" "}
                {`${getFullName(RequestedToUserDetails)} (${RequestedToUserDetails?.email})`}
              </Typography>
            </Grid>
            <Button
              variant="text"
              onClick={handleChangeClick}
              sx={{ textTransform: 'none', }}
              disabled={isLoading?.formSubmitting}
            >Change</Button>
          </Box>
          {isCreditEnable() ?
            <Box sx={{
              padding: "8px 16px",
              gap: "4px",
              borderRadius: "8px",
              backgroundColor: "#FFF6DF",
              marginBottom: "16px",
            }}>
              <Typography fontSize={12} color="secondary.main" fontWeight={500} sx={{ letterSpacing: '0.15px', lineHeight: '18px' }}>
                Available Special Credits
              </Typography>
              <Grid display={"flex"} justifyContent={"space-between"} alignItems={"center"}>
                <Typography fontSize={18} color="secondary.main" fontWeight={600} sx={{ letterSpacing: '0.15px', lineHeight: '27px' }}>
                  {getAlreadyRemainingCredit() > 0 ? getAlreadyRemainingCredit() : 0 ?? 0}
                </Typography>
                <Grid display={"flex"} alignItems={"center"}>
                  <Typography color={"secondary"} fontSize={12} fontWeight={500} lineHeight={"20px"} letterSpacing={"0.15px"} borderBottom="1px dashed" borderBottomColor="secondary">
                    See details
                  </Typography>
                  <HtmlTooltip
                    title={
                      <React.Fragment>
                        <Typography color={"white"} fontSize={16} lineHeight={"17px"} fontWeight={500} mb={1.5}><span style={{ fontWeight: 'bold' }}>{getRemainingCreditsData()?.specialCreditUsed > 0 ? getRemainingCreditsData()?.specialCreditUsed : 0 ?? 0}</span>/{getRemainingCreditsData()?.specialCredits ?? 0} Special Credits used</Typography>
                        <Typography variant="body1" color={"white"} fontSize={13} lineHeight={"19px"} fontWeight={500} mb={2}>Special Credits includes all currently applied leaves. If any leaves are rejected or canceled, the special credit will be adjusted accordingly.</Typography>
                        <Table >
                          <TableHead>
                            <TableRow
                              sx={{
                                'th': {
                                  padding: '10px 4px',
                                  color: 'white',
                                  fontSize: 12,
                                  lineHeight: '19px',
                                  fontWeight: 700,
                                  background: 'rgba(255,255,255,0.1)',
                                  border: 'none',
                                  '&:first-of-type': {
                                    padding: '10px',
                                  }
                                }
                              }}
                            >
                              <TableCell>Credits</TableCell>
                              <TableCell align="center" sx={{ width: '100px', maxWidth: '100px', minWidth: '100px' }}>Initial Credit</TableCell>
                              <TableCell align="center" sx={{ width: '70px', maxWidth: '70px', minWidth: '70px' }}>Used</TableCell>
                              <TableCell align="center" sx={{ width: '100px', maxWidth: '100px', minWidth: '100px' }}>Remaining</TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody
                            sx={{
                              'td': {
                                padding: '4px 10px',
                                color: 'white',
                                fontSize: 14,
                                lineHeight: '19px',
                                fontWeight: 500,
                                border: 'none',
                              },
                              'tr:first-of-type td': {
                                paddingTop: '10px',
                              }
                            }}
                          >
                            {getRemainingCreditsData()?.specialCreditsList?.length ?
                              getRemainingCreditsData()?.specialCreditsList?.map((creditData, index) => (
                                <TableRow key={index}>
                                  <TableCell>{titleCase(creditData?.name)}</TableCell>
                                  <TableCell align="center">{creditData?.allotment ?? 0}</TableCell>
                                  <TableCell align="center">{getUsedCredits(creditData?.allotment ?? 0, creditData?.used ?? 0)}</TableCell>
                                  <TableCell align="center">{creditData?.remaining > 0 ? creditData?.remaining : 0 ?? 0}</TableCell>
                                </TableRow>
                              )) : null}
                          </TableBody>
                        </Table>
                      </React.Fragment>
                    }
                  >
                    <InfoIcon color="secondary" sx={{ fontSize: "18px", marginLeft: "4px", marginTop: "2px" }} />
                  </HtmlTooltip>
                </Grid>
              </Grid>
            </Box>
            : null}
        </Grid>

        <TableContainer
          component={Paper}
          sx={{ border: "1px solid #E0E0E0", boxShadow: "none", mb: 3 }}
        >
          <Table aria-label="simple table">
            <TableHead sx={{ background: "rgba(0, 0, 0, 0.01)" }}>
              <TableRow
                sx={{
                  th: {
                    color: "dark.800",
                    fontWeight: 500,
                    fontSize: 14,
                    lineHeight: "24px",
                    letterSpacing: "0.15px",
                    padding: "6px 16px",
                  },
                }}
              >
                <TableCell sx={{ width: 260, maxWidth: 260, minWidth: 260 }}>
                  <TableSortLabel
                    active={true}
                    direction={sortType}
                    onClick={() => setSortType((prev) => prev === 'asc' ? 'desc' : 'asc')}
                  >
                    {titleCase(AppliedLeaveType?.name)} Days
                  </TableSortLabel>
                </TableCell>
                <TableCell>Day</TableCell>
                <TableCell>Type</TableCell>
                <TableCell sx={{ width: "30%", maxWidth: "30%", minWidth: 170 }} align="left">
                  Check-in Time
                </TableCell>
                <TableCell sx={{ width: "30%", maxWidth: "30%", minWidth: 170 }} align="left">
                  Check-out Time
                </TableCell>
                <TableCell sx={{ width: 150, maxWidth: 150, minWidth: 150 }} align="left">
                  Duration
                </TableCell>
                {isCreditEnable() ? <TableCell sx={{ width: 110, maxWidth: 110, minWidth: 110 }}>
                  Credit
                </TableCell> : null}
                <TableCell sx={{ width: 90, maxWidth: 90, minWidth: 90 }} align="center">
                  Remove
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {leaveData?.WfhDays?.map((leaveDetail, index, leaveArray) => (
                <TableRow
                  key={index}
                  sx={{
                    td: {
                      color: "dark.800",
                      fontWeight: 400,
                      fontSize: 14,
                      lineHeight: "24px",
                      letterSpacing: "0.15px",
                      padding: "7px 16px",
                      border: 0,
                      borderBottom: "1px solid rgba(0, 0, 0, 0.12)",
                    },
                  }}
                >
                  <TableCell
                    sx={{ width: 160, maxWidth: 160, minWidth: 160 }}
                  >
                    <Grid item className="calendar-date-picker"
                      sx={{
                        'input': {
                          width: '100%',
                          height: 30,
                          border: 'none !important',
                          boxShadow: 'none !important',
                          borderRadius: '0px',
                          paddingBottom: '6px',
                          paddingLeft: '28px',
                          borderBottom: '1px solid rgba(0, 0, 0, 0.42) !important',
                          margin: '0',
                          position: 'relative',
                          background: 'transparent',
                          color: 'dark.800',
                          fontSize: 14,
                          fontWeight: 400,
                          lineHeight: '24px',
                          letterSpacing: '0.15px'
                        },
                        'input:disabled': {
                          color: 'rgb(170, 170, 170)',
                          borderColor: 'rgba(118, 118, 118, 0.3) !important',
                        },
                        'input:disabled:hover': {
                          color: 'rgb(170, 170, 170)',
                          borderColor: 'rgba(118, 118, 118, 0.3) !important',
                        },
                        'input:hover': {
                          borderBottomColor: '#333333 !important',
                        },
                        'input:focus': {
                          borderBottomColor: '#047FE0 !important',
                        },
                        '.rmdp-container': {
                          minWidth: '100px',
                        },
                      }}
                    >
                      <div style={{ height: 1 }}>
                        <CalendarTodayIcon
                          sx={{
                            fontSize: 20,
                            marginBottom: '-5px',
                            marginLeft: '2px',
                            color: ((Object.keys(leaveDetail)?.includes('isAutoGenerated') && leaveDetail?.isAutoGenerated)) ? "rgb(170, 170, 170) !important" : "#3F5C76",
                          }}

                        />
                      </div>
                      <SingleDatePicker
                        value={leaveDetail?.date}
                        ignoreDates={getIgnoreDates()}
                        onChange={(value) =>
                          handleChangeSelectedDate(value, index, leaveDetail)
                        }
                        isMultiple={false}
                        disabled={((Object.keys(leaveDetail)?.includes('isAutoGenerated') && leaveDetail?.isAutoGenerated) || false)}
                        plugins={[
                          <LeaveTypePlugin position="bottom" />,
                        ]}
                      />
                    </Grid>
                  </TableCell>
                  <TableCell
                    color="dark.800"
                    fontWeight={400}
                    fontSize={14}
                    lineheight="24px"
                    letterSpacing="0.15px"
                  >
                    {new Date(moment(leaveDetail?.date, "DD/MM/YYYY").format()).toLocaleString(
                      "en-us",
                      { weekday: "long" }
                    )}
                  </TableCell>
                  <TableCell>
                    <Grid item maxWidth={{ xs: 130, xl: 170 }} width={{ xs: 130, xl: 170 }} mr={2}>
                      <FormControl
                        size="small"
                        variant="standard"
                        fullWidth
                        sx={{
                          height: "32px",
                          width: { xs: 130, xl: 170 },
                          ".MuiInputBase-root": {
                            height: "100%",
                          },
                        }}
                      >
                        <Select
                          inputProps={{ id: getInputId("leave_duration") }}
                          name={'leave_duration'}
                          id={getInputId("leave_duration", 0)}
                          value={leaveDetail?.leave_duration || ""}
                          onChange={(event) => {
                            handleChangeWfhDurationType(event, index, leaveDetail, 'leave_duration', leaveDetail?.date)
                          }}
                          height="44px"
                        >
                          {Object.entries(LEAVE_DURATION_TYPE).map(
                            (leaveType, index) => (
                              <MenuItem key={index} value={leaveType[0]} disabled={isEnableDuratioOption(leaveType[0], leaveDetail?.date)}>
                                {leaveType[1]}
                              </MenuItem>
                            )
                          )}
                        </Select>
                      </FormControl>
                    </Grid>
                  </TableCell>
                  <TableCell>
                    <Grid item
                      maxWidth={160}
                      width={"100%"}
                      sx={{
                        '.rmdp-container': {
                          minWidth: { xs: '130px !important', xl: '170px !important' },
                        },
                        'input': {
                          width: '100%',
                          height: 30,
                          border: 'none !important',
                          boxShadow: 'none !important',
                          borderRadius: '0px',
                          paddingBottom: '6px',
                          paddingLeft: '28px',
                          borderBottom: '1px solid rgba(0, 0, 0, 0.42) !important',
                          margin: '0',
                          position: 'relative',
                          background: 'transparent',
                          color: 'dark.800',
                          fontSize: 14,
                          fontWeight: 400,
                          lineHeight: '24px',
                          letterSpacing: '0.15px'
                        },
                        'input:hover': {
                          borderBottomColor: '#333333 !important',
                        },
                        'input:focus': {
                          borderBottomColor: '#047FE0 !important',
                        },
                      }}
                    >
                      <div style={{ height: 1 }}>
                        <ScheduleIcon
                          sx={{
                            fontSize: 20,
                            color: '#3F5C76',
                            marginBottom: '-9px',
                            marginLeft: '1px',
                          }}
                        />
                      </div>
                      <TimePickerClock
                        value={leaveDetail?.in_time || null}
                        // error={errors?.leave_days?.[index]?.in_time ?? ""}
                        onChange={(time) => {
                          handelTimeChange(time, index, leaveDetail, "in_time", leaveDetail?.date)
                        }}
                      />
                      {/* {!!errors?.leave_days[index] ? <div style={{ height: '1px', position: 'relative' }}><div style={{ position: 'absolute', top: '100%', right: '0', background: '#E87160', borderRadius: '3px', padding: '2px 5px', marginTop: '2px' }}><span className="error-arrow-top"></span><Typography sx={{ color: "white", fontSize: '11px', fontWeight: 400, }}>{errors?.leave_days[index][`time-${index}`]}</Typography></div></div> : null} */}
                      {!!errors?.leave_days?.[`${leaveDetail?.date}`]?.in_time ?
                        <div style={{ height: '1px', position: 'relative' }}>
                          <div style={{ position: 'absolute', top: '100%', right: '0', background: '#E87160', borderRadius: '3px', padding: '2px 5px', marginTop: '2px' }}>
                            <span className="error-arrow-top"></span>
                            <Typography sx={{ color: "white", fontSize: '11px', fontWeight: 400, }}>
                              {!!errors?.leave_days?.[`${leaveDetail?.date}`]?.in_time ? errors?.leave_days?.[`${leaveDetail?.date}`]?.in_time : ""}
                            </Typography>
                          </div>
                        </div>
                        : null}
                    </Grid>
                  </TableCell>
                  <TableCell>
                    <Grid item
                      maxWidth={160}
                      width={"100%"}
                      sx={{
                        '.rmdp-container': {
                          minWidth: { xs: '130px !important', xl: '170px !important' },
                        },
                        'input': {
                          width: '100%',
                          height: 30,
                          border: 'none !important',
                          boxShadow: 'none !important',
                          borderRadius: '0px',
                          paddingBottom: '6px',
                          paddingLeft: '28px',
                          borderBottom: '1px solid rgba(0, 0, 0, 0.42) !important',
                          margin: '0',
                          position: 'relative',
                          background: 'transparent',
                          color: 'dark.800',
                          fontSize: 14,
                          fontWeight: 400,
                          lineHeight: '24px',
                          letterSpacing: '0.15px'
                        },
                        'input:hover': {
                          borderBottomColor: '#333333 !important',
                        },
                        'input:focus': {
                          borderBottomColor: '#047FE0 !important',
                        },
                      }}
                    >
                      <div style={{ height: 1 }}>
                        <ScheduleIcon
                          sx={{
                            fontSize: 20,
                            color: '#3F5C76',
                            marginBottom: '-9px',
                            marginLeft: '1px',
                          }}
                        />
                      </div>
                      <TimePickerClock
                        value={leaveDetail?.out_time || null}
                        // error={errors?.leave_days?.[index]?.out_time ?? ""}
                        onChange={(time) => {
                          handelTimeChange(time, index, leaveDetail, "out_time", leaveDetail?.date)
                        }}
                      />
                      {/* {!!errors?.leave_days[index] ? <div style={{ height: '1px', position: 'relative' }}><div style={{ position: 'absolute', top: '100%', right: '0', background: '#E87160', borderRadius: '3px', padding: '2px 5px', marginTop: '2px' }}><span className="error-arrow-top"></span><Typography sx={{ color: "white", fontSize: '11px', fontWeight: 400, }}>{errors?.leave_days[index][`time-${index}`]}</Typography></div></div> : null} */}
                      {!!errors?.leave_days?.[`${leaveDetail?.date}`]?.out_time ?
                        <div style={{ height: '1px', position: 'relative' }}>
                          <div style={{ position: 'absolute', top: '100%', right: '0', background: '#E87160', borderRadius: '3px', padding: '2px 5px', marginTop: '2px' }}>
                            <span className="error-arrow-top"></span>
                            <Typography sx={{ color: "white", fontSize: '11px', fontWeight: 400, }}>
                              {!!errors?.leave_days?.[`${leaveDetail?.date}`]?.out_time ? errors?.leave_days?.[`${leaveDetail?.date}`]?.out_time : ""}
                            </Typography>
                          </div>
                        </div>
                        : null}
                    </Grid>
                  </TableCell>
                  <TableCell sx={{ width: 150, maxWidth: 150, minWidth: 150 }} align="left"
                    color="dark.800"
                    fontWeight={400}
                    fontSize={14}
                    lineheight="24px"
                    letterSpacing="0.15px"
                  >
                    <Typography>{getDurationFromDate(leaveDetail?.in_time, leaveDetail?.out_time)}</Typography>
                  </TableCell>
                  {isCreditEnable() ? <TableCell sx={{ width: 110, maxWidth: 110, minWidth: 110 }}>
                    <Typography>{leaveDetail?.credit_used > 0 ? leaveDetail?.credit_used : null}</Typography>
                  </TableCell> : null}
                  <TableCell sx={{ width: 90, maxWidth: 90, minWidth: 90 }} align="center">
                    <IconButton
                      aria-label="delete"
                      size="small"
                      color="secondary"
                      sx={{ borderRadius: "4px" }}
                      disabled={leaveData?.WfhDays?.length <= 1 || leaveDetail?.canDelete === false}
                      onClick={() =>
                        handleChangeAddRemoveLeaves(
                          "remove",
                          index,
                          leaveDetail
                        )
                      }
                    >
                      <DeleteIcon sx={{ fontSize: 20 }} />
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))}
              <TableRow
                sx={{
                  td: {
                    padding: "4px 10px",
                    border: 0,
                    borderBottom: "1px solid rgba(0, 0, 0, 0.12)",
                  },
                }}
              >
                <TableCell colSpan={8}>
                  <MultipleDatePickerWithButton
                    ignoreDates={getIgnoreDates()}
                    onChange={(dates) => setTempAddNewLeaves(dates)}
                    onClose={() => handleChangeAddRemoveLeaves("add")}
                    selectedDates={tempAddNewLeaves}
                    setSelectedDates={setTempAddNewLeaves}
                    plugins={[
                      <DatePanel markFocused />,
                      <LeaveTypePlugin position="bottom" />,
                      <Toolbar
                        position="bottom"
                        sort={["deselect", "today", "close"]}
                        names={{
                          deselect: "Deselect",
                          today: "Today",
                          close: "Select",
                        }}
                      />,
                    ]}
                  />
                </TableCell>
              </TableRow>
              {(isCreditEnable() && Number(getTotalCreditUsedCount(leaveData?.WfhDays)) > 0) ? <TableRow
                sx={{
                  td: {
                    padding: "10px 16px",
                    border: 0,
                    borderBottom: "1px solid rgba(0, 0, 0, 0.12)",
                  },
                }}
              >
                <TableCell colSpan={6}></TableCell>
                <TableCell colSpan={2}>
                  <Typography
                    color="error.main"
                    fontWeight={700}
                    sx={{ marginLeft: "0px" }}
                  >
                    {getTotalCreditUsedCount(leaveData?.WfhDays)}{" "}
                    Credit Applied
                  </Typography>
                </TableCell>
              </TableRow> : null}
            </TableBody>
            <TableFooter
              sx={{ 'td': { padding: 0, } }}
            >
              {(isCreditEnable() && Number(getTotalCreditUsedCount(leaveData?.WfhDays)) > 0) ? <TableRow>
                <TableCell colSpan={6}></TableCell>
                <TableCell colSpan={2}>
                  <Grid
                    item
                    width={200}
                    sx={{ background: "#FFF6DF", padding: "10px 16px" }}
                  >
                    <Typography
                      color="dark.800"
                      variant="body2"
                      lineHeight="24px"
                      letterSpacing="0.17px"
                      fontWeight={600}
                    >
                      Remaining Credits: {getTotalRemainingCredit() > 0 ? getTotalRemainingCredit() : 0}
                    </Typography>
                  </Grid>
                </TableCell>
              </TableRow> : null}
            </TableFooter>
          </Table>
        </TableContainer>
        <Box mb={3}>
          <Typography
            variant="body2"
            fontWeight={400}
            sx={{ lineHeight: "21px", letterSpacing: "0.15", mb: 0.5 }}
          >
            Reason*
          </Typography>
          <TextEditor
            value={leaveData.description}
            onChange={(value) =>
              handleChangeDescription(value)
            }
            onBlur={(value) => handleBlurDescription(value)}
          />
          <div style={{ height: '1px', position: 'relative' }}><div style={{ position: 'absolute', top: '100%', right: '0', background: '#FFF6DF', borderRadius: '3px', padding: '2px 5px', marginTop: '2px' }}><span className=""></span><Typography sx={{ color: "black", fontSize: '11px', fontWeight: 400, }}>{`${getReasonLength() ?? 0} / 500`}</Typography></div></div>
          {!!errors?.description?.length ?
            <Typography
              variant="body2"
              fontWeight={400}
              color={'error'}
              sx={{ lineHeight: "21px", letterSpacing: "0.15", mb: 0.5 }}
            >
              {errors?.description}
            </Typography>
            : null}
        </Box>
        <Box mb={3}>
          <Grid item display="flex" alignItems="center" mb={1}>
            <Typography
              variant="body1"
              color="dark.800"
              fontWeight={400}
              sx={{ lineHeight: "21px", letterSpacing: "0.15px" }}
              mr={1}
            >
              Attachments:
            </Typography>
            <Typography
              color="dark.600"
              fontSize={11}
              fontWeight={400}
              sx={{ lineHeight: "15px", letterSpacing: "0.17px" }}
            >
              (Upto 10MB, upto 5 attachments)
            </Typography>
          </Grid>
          <Box>
            <FileDropZone
              onDrop={(files) =>
                setLeaveData({
                  ...leaveData,
                  attachments: files,
                })
              }
              initialFiles={leaveData?.attachments}
              isConvertToBase64={true}
            />
          </Box>
        </Box>
        <Grid item>
          <Button
            variant="contained"
            color="primary"
            size="large"
            sx={{
              color: "white",
              textTransform: "none",
              marginRight: "20px",
              boxShadow: "none",
            }}
            type="button"
            onClick={handleSubmitLeaveApplication}
            isButtonLoading={isLoading.formSubmitting}
            disabled={!!errors?.description?.length || isLoading?.formButtonDisable || isLoading?.isTimeValid}
          >
            Apply
          </Button>
          <Button
            variant="text"
            color="secondary"
            size="large"
            sx={{ textTransform: "none" }}
            onClick={() => navigate(`${URLS.WorkFromHome}?view=list&tabValue=1&groupBy=Status&status=active`)}
            disabled={isLoading.formSubmitting}
          >
            Cancel
          </Button>
        </Grid>
      </Box>
    </>
  );
};

export default ApplyWFHRequestDetails;
