import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { Card, Container, FormControl, FormControlLabel, Grid, Link, MenuItem, Radio, RadioGroup, Stack } from "@mui/material";
import Button from "../../../components/Button";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import { Link as RouteLink, useNavigate } from "react-router-dom";
import URLS from "../../../routes/urls";
import { useFormik } from "formik";
import * as Yup from "yup";
import Input from "../../../components/Input";
import API from "../../../axios/api";
import axiosInstance from "../../../axios";
import { getInputId, sortArrayByKey, titleCase } from "../../../utils";
import { usePageTitle } from "../../../hook/useTitle";
import CircularLoader from "../../../components/CircularLoader";
import { useAlert } from "../../../hook/useAlert";

const AddModule = () => {
    const navigate = useNavigate();
    const { setPageTitle } = usePageTitle();
    const showAlert = useAlert();
    const [isLoading, setIsLoading] = useState({
        formSubmitting: false,
    });
    const [moduleList, setModuleList] = useState([]);
    const [loading, setLoading] = useState({
        pageLoading: true,
    })

    setPageTitle("Add Module");

    useEffect(() => {
        getAllModuleData();
    }, []);

    const getAllModuleData = async () => {
        try {
            setLoading((prev) => ({ ...prev, pageLoading: true }));
            const response = await axiosInstance.get(API.getAllModule);
            if (response.status === 200) {
                if (response.data?.data?.length) {
                    let sortedArray = sortArrayByKey(response.data?.data?.filter(moduleData => moduleData?.active), "asc", "name")
                    setModuleList(sortedArray);
                } else {
                    setModuleList([]);
                }
            }
            setLoading((prev) => ({ ...prev, pageLoading: false }));
        } catch (error) {
            console.error(error);
            setLoading((prev) => ({ ...prev, pageLoading: false }));
        }
    };

    const validationSchema = Yup.object({
        name: Yup.string().required("Required")
    });

    const formik = useFormik({
        initialValues: {
            name: "",
            active: true,
            module_type_id: "nill",
        },
        validationSchema,
        onSubmit: async ({ name, active, module_type_id }) => {
            setIsLoading((prev) => ({ ...prev, formSubmitting: true }));
            if (
                !(await handleValidate({ name }))
            ) {
                let body = {
                    name: name?.trim(),
                    active: active,
                    module_type_id: module_type_id !== "nill" ? module_type_id : null,
                };
                if (!body?.module_type_id) delete body.module_type_id
                try {
                    const response = await axiosInstance.post(API.createModule, body);
                    if (response.status === 200) {
                        showAlert(response.data.message);
                        setIsLoading((prev) => ({ ...prev, formSubmitting: false }));
                        navigate(URLS.AdminDashboardModule);
                    } else {
                        showAlert(response?.response?.data?.message, "error");
                        setIsLoading((prev) => ({ ...prev, formSubmitting: false }));
                    }
                } catch (error) {
                    console.error(error)
                    setIsLoading((prev) => ({ ...prev, formSubmitting: false }));
                }
            } else {
                setIsLoading((prev) => ({ ...prev, formSubmitting: false }));
            }
        },
    });

    const handleValidate = async ({
        name
    }) => {
        let isError;
        if (!name?.length) {
            formik.setErrors({
                ...formik.errors,
                "names": "Required",
            });
            isError = true;
        }
        return isError;
    };

    return (
        <Box sx={{ marginTop: "-190px", marginBottom: '40px' }} position="relative" zIndex={1}>
            <Container>
                <Link
                    component={RouteLink}
                    to={URLS.AdminDashboardModule}
                    display="inline-flex"
                    alignItems="center"
                    sx={{ textDecoration: "none", color: "white" }}
                    fontFamily={"fontFamily"}
                    p={0.5}
                    mb={1.5}
                >
                    <ChevronLeftIcon fontSize="small" sx={{ mr: 1 }} />
                    Back to Modules
                </Link>
                <Typography variant="h6" color="white" marginBottom="12px">
                    Create Module
                </Typography>
                <Card
                    sx={{ bgcolor: "bgColor", p: { xs: 2, sm: 3, md: 4 }, boxShadow: '0px 31px 95px rgba(0, 0, 0, 0.12)' }}
                    boxshadow={1}
                >
                    {loading.pageLoading ?
                        <CircularLoader /> :
                        <form onSubmit={formik.handleSubmit}>
                            <Grid container>
                                <Grid item xs={12} md={8}>
                                    <Stack spacing={4} width={380}>
                                        <Grid item xs={12}>
                                            <Input
                                                id="module-name"
                                                name="name"
                                                label="Module name"
                                                variant="standard"
                                                placeholder="Module Name"
                                                type="text"
                                                fullWidth
                                                onChange={formik.handleChange}
                                                onBlur={(e) => {
                                                    formik.handleBlur(e);
                                                }}
                                                value={titleCase(formik.values.name)}
                                                error={
                                                    formik.touched.name &&
                                                    Boolean(formik.errors.name)
                                                }
                                                helperText={
                                                    formik.touched.name &&
                                                    formik.errors.name
                                                }
                                                // isUpperCase={true}
                                                isCapitalizeFirstLatter={true}
                                            />
                                        </Grid>
                                        <Grid sx={{ marginTop: '0px' }} item xs={12}>
                                            <Typography
                                                color="text.dark"
                                                fontSize={14}
                                                sx={{ lineHeight: "24px" }}
                                            >
                                                Module Status
                                            </Typography>
                                            <FormControl component="fieldset">
                                                <RadioGroup
                                                    row
                                                    aria-labelledby="demo-row-radio-buttons-group-label"
                                                    name="active"
                                                    defaultValue="active"
                                                    value={formik?.values?.active}
                                                    onChange={formik.handleChange}
                                                >
                                                    <FormControlLabel
                                                        value={true}
                                                        control={<Radio size="small" />}
                                                        label="Active"
                                                        sx={{ color: "dark.800" }}
                                                    />
                                                    <FormControlLabel
                                                        value={false}
                                                        control={<Radio size="small" />}
                                                        label="Deactive"
                                                        sx={{ color: "dark.800" }}
                                                    />
                                                </RadioGroup>
                                            </FormControl>
                                        </Grid>

                                        <Grid item xs={12}>
                                            <Input
                                                id={getInputId("type", 0)}
                                                name={"module_type_id"}
                                                select
                                                type="select"
                                                label="Type"
                                                defaultValue={"nill"}
                                                variant="standard"
                                                sx={{ width: "100%" }}
                                                onChange={formik.handleChange}
                                                value={formik.values.module_type_id}
                                                required={false}
                                            >
                                                <MenuItem value={"nill"}>
                                                    Select
                                                </MenuItem>
                                                {moduleList?.map((option, index) => (
                                                    <MenuItem key={index} value={option?.id}>
                                                        {titleCase(option?.name)}
                                                    </MenuItem>
                                                ))}
                                            </Input>
                                        </Grid>

                                        <Grid item>
                                            <Button
                                                type="submit"
                                                color="primary"
                                                sx={{
                                                    boxshadow: 0,
                                                    color: "white",
                                                    fontSize: "15px",
                                                    textTransform: "none",
                                                    mr: 2.4,
                                                    "&:hover": { boxshadow: 0 },
                                                }}
                                                disabled={!(formik.values.name)}
                                                isButtonLoading={isLoading?.formSubmitting}
                                            >
                                                Create Module
                                            </Button>
                                            <Button
                                                variant="text"
                                                color="secondary"
                                                sx={{ textTransform: "none" }}
                                                onClick={() => navigate(URLS.AdminDashboardModule)}
                                                disabled={isLoading?.formSubmitting}
                                            >
                                                Cancel
                                            </Button>
                                        </Grid>
                                    </Stack>
                                </Grid>
                            </Grid>
                        </form>
                    }
                </Card>
            </Container>
        </Box>
    )
}

export default AddModule