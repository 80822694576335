import { Box } from '@mui/material';
import React, { useState } from 'react'
import { useFormik } from 'formik';
import * as Yup from 'yup'
import axiosInstance from '../../../../axios';
import Breadcrumb from "../../../../components/Breadcrumb";
import { useNavigate } from 'react-router-dom';
import API from '../../../../axios/api';
import URLS from '../../../../routes/urls';
import { useAlert } from '../../../../hook/useAlert';
import FormTemplate from './FormTemplate';

const supportedFormats = ['jpg', 'jpeg', 'png', 'gif'];
const validationSchema = Yup.object({
    title: Yup.string().required('Required'),
    sub_title: Yup.string().required('Required'),
    subject: Yup.string().required('Required'),
    body: Yup.string().max(1000, 'Maximum length is 1000 characters').required('Required'),
    read_more_link: Yup.string().url('Invalid URL').required('Required'),
    footer_title: Yup.string().required('Required'),
    footer_link: Yup.string().url('Invalid URL').required('Required'),
    other_blog_title: Yup.string().required('Required'),
    other_blog_intro: Yup.string().required('Required'),
    other_blog_link: Yup.string().url('Invalid URL').required('Required'),
    banner_img: Yup.mixed()
        .required('Required')
        .test('fileFormat', 'Only image files are allowed', value => {
            if (value) {
                return supportedFormats.includes(value?.name?.split('.')?.pop()?.toLowerCase());
            }
            return true;
        }),
    footer_banner: Yup.mixed()
        .required('Required')
        .test('fileFormat', 'Only image files are allowed', value => {
            if (value) {
                return supportedFormats.includes(value?.name?.split('.')?.pop()?.toLowerCase());
            }
            return true;
        }),
    other_blog_img: Yup.mixed()
        .required('Required')
        .test('fileFormat', 'Only image files are allowed', value => {
            if (value) {
                return supportedFormats.includes(value?.name?.split('.')?.pop()?.toLowerCase());
            }
            return true;
        }),
});

const initialValues = {
    banner_img: null,
    footer_banner: null,
    other_blog_img: null,
    title: '',
    sub_title: '',
    subject: '',
    body: '',
    read_more_link: '',
    footer_title: '',
    footer_link: '',
    other_blog_title: '',
    other_blog_intro: '',
    other_blog_link: ''
};

const CreateTemplate = () => {
    const navigate = useNavigate();
    const showAlert = useAlert();
    const [loading, setLoading] = useState({
        formSubmitting: false,
        pageLoading: false,
    });
    const formik = useFormik({
        initialValues,
        validationSchema,
        onSubmit: async (values) => {
            try {
                setLoading(prev => ({ ...prev, formSubmitting: true }));
                const formData = new FormData();
                Object.keys(values).forEach((key) => {
                    formData.append(key, values[key]);
                });
                const response = await axiosInstance.post(API.createEmailTemplate, formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data-1'
                    }
                });
                if (response?.status === 200) {
                    showAlert(response?.data?.message);
                    navigate(URLS.EmailTemplates);
                } else {
                    showAlert(response?.data?.message, "error");
                }
                setLoading(prev => ({ ...prev, formSubmitting: false }));
            } catch (error) {
                console.error(error);
                setLoading(prev => ({ ...prev, formSubmitting: false }));
            }
        }
    });

    return (<>
        <Box width="100%" bgcolor="white" display="flex" flexDirection="column">
            <React.Fragment>
                <Breadcrumb
                    isBack={true}
                    onBackClick={() => navigate(-1)}
                    pageTitle={"New Template"}
                    title={"Templates"}
                />
                <Box
                    p={{ xs: 3, lg: 4, xl: 4 }}
                    py={{ xs: 3, lg: 4, xl: 4 }}
                    sx={{ background: "#ffffff" }}
                    overflow="auto"
                    flex={1}
                >
                    <FormTemplate
                        formik={formik}
                        loading={loading}
                        isCreate={true}
                    />
                </Box>
            </React.Fragment>
        </Box>
    </>
    )
}

export default CreateTemplate;
