/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { Box, FormControl, FormHelperText, Grid, IconButton, InputLabel, MenuItem, Paper, Select, Stack, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TableSortLabel, Typography } from '@mui/material';
import Button from '../../../../components/Button';
import AutocompleteSelect from '../../../../components/AutocompleteSelect';
import { generateUniqueId, getFormattedDate, getInputId, sortArrayByKey, titleCase } from '../../../../utils';
import SingleDatePicker from '../../../../components/SingleDatePicker';
import DeleteIcon from "@mui/icons-material/Delete";
import CalendarTodayIcon from "@mui/icons-material/CalendarToday";
import { makeStyles } from "@mui/styles";
import useAuthentication from '../../../../hook/useAuthentication';
import axiosInstance from '../../../../axios';
import API from '../../../../axios/api';
import CircularLoader from '../../../../components/CircularLoader';
import { useParams } from 'react-router-dom';
import { useAlert } from '../../../../hook/useAlert';
import { getKeyByValue, PROJECT_STATUS } from '../../../../constants/default-values';
import EmptyPage from '../../../../components/EmptyPage';

const useStyles = makeStyles((theme) => ({
    root: {
        display: "flex",
        flexWrap: "wrap",
    },
    margin: {
        margin: theme.spacing(1),
    },
}));

const UserProjects = () => {
    const classes = useStyles();
    const { userId } = useParams();
    const showAlert = useAlert();
    const [isEditable, setIsEditable] = useState(false);
    const { getCurrentUser } = useAuthentication();
    const currentUser = getCurrentUser();
    const [isLoading, setIsLoading] = useState({
        pageLoading: true,
        allProjectsLoading: true,
        userProjectLoading: false
    });
    const [sortType, setSortType] = useState("desc");
    const [projectRolesData, setProjectRolesData] = useState([]);
    let initialProjectData = {
        id: null,
        projects: null,
        user_id: null,
        project_role_id: null,
        joining_date: "",
        is_active: true,
        unique_id: null,
    };
    const [errors, setErrors] = useState([]);
    const [projectData, setProjectData] = useState([initialProjectData]);
    const [optionalProjectData, setOptionalProjectData] = useState([initialProjectData]);
    const [projectList, setProjectList] = useState([]);
    const [userProjectList, setUserProjectList] = useState([])
    const [inActiveUserProjectList, setInActiveUserProjectList] = useState([])

    useEffect(() => {
        if (currentUser) {
            getProjectRolesData();
            getAllProjectList();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (Number(userId))
            getUserProjectData(Number(userId));
    }, [isEditable])

    const toggleEdit = () => {
        setIsEditable((prev) => !prev);
    };

    const getUserProjectData = async (userId) => {
        try {
            setIsLoading((prev) => ({ ...prev, userProjectLoading: true }));
            const response = await axiosInstance.get(
                API.getUserProjectList(userId)
            );
            if (response?.status === 200) {
                let sortedData = sortArrayByKey(response?.data?.data?.filter(userprojectData => userprojectData?.is_active && userprojectData?.projects?.status === PROJECT_STATUS["Working"]), "desc", "projects?.title");
                let inActiveProject = response?.data?.data?.filter(userprojectData => !userprojectData?.is_active && userprojectData?.projects?.status === PROJECT_STATUS["Working"]);
                setUserProjectList(sortedData);
                setInActiveUserProjectList(inActiveProject)
                let userProjectListData = [...sortedData]?.map((projectListData) => {
                    return { ...projectListData, unique_id: generateUniqueId() };
                });
                if (sortedData?.length < (projectList?.length - inActiveProject?.length)) {
                    userProjectListData.push({
                        ...initialProjectData,
                        unique_id: generateUniqueId(),
                    });
                }
                setErrors(
                    sortedData?.map((data) => {
                        return {
                            projects: "",
                            project_role_id: "",
                            joining_date: "",
                        };
                    })
                );
                setProjectData(userProjectListData)
                setOptionalProjectData(userProjectListData);
            } else {
                setUserProjectList([]);
            }
            setIsLoading((prev) => ({ ...prev, userProjectLoading: false }));
        } catch (error) {
            console.error(error);
            setUserProjectList([]);
            setIsLoading((prev) => ({ ...prev, userProjectLoading: false }));
        }
    }

    const getProjectRolesData = async () => {
        try {
            setIsLoading((prev) => ({ ...prev, pageLoading: true }));
            const response = await axiosInstance.get(
                API.organisationProjectRoles(currentUser?.organization_id)
            );
            if (response.status === 200) {
                let sortedData = sortArrayByKey(response?.data?.data, "asc", "title");
                setProjectRolesData(sortedData);
            }
            setIsLoading((prev) => ({ ...prev, pageLoading: false }));
        } catch (error) {
            console.error(error);
            setIsLoading((prev) => ({ ...prev, pageLoading: false }));
        }
    };

    const getAllProjectList = async () => {
        try {
            setIsLoading((prev) => ({ ...prev, allProjectsLoading: true }));
            const response = await axiosInstance.get(
                API.getAllProjectsList(currentUser?.organization_id)
            );
            if (response?.status === 200) {
                let sortedData = sortArrayByKey(response?.data?.data?.filter(project => project?.status === PROJECT_STATUS?.Working), "asc", "title");
                setProjectList(sortedData);
            } else {
                setProjectList([]);
            }
            setIsLoading((prev) => ({ ...prev, allProjectsLoading: false }));
        } catch (error) {
            console.error(error);
            setIsLoading((prev) => ({ ...prev, allProjectsLoading: false }));
        }
    };

    const validateProjectData = (event, memberIndex, array) => {
        const { name, value } = event.target;
        let updatedErrors = [...errors];

        setErrors(
            array?.map((userProjectData, index) => {
                if (index === memberIndex) {
                    let isLastData =
                        name === "projects" &&
                        !userProjectData?.projects &&
                        !userProjectData?.project_role_id &&
                        !userProjectData?.joining_date &&
                        index === projectData?.length - 1;
                    return {
                        ...updatedErrors?.[index],
                        [name]: value ? "" : isLastData ? "" : "Required",
                    };
                } else {
                    return {
                        ...updatedErrors?.[index],
                        [name]: updatedErrors?.[index]?.[name]
                            ? updatedErrors?.[index]?.[name]
                            : "",
                    };
                }
            })
        );
    };

    const handleChange = (event, memberIndex, array, uniqueId) => {
        let indexOfMember = memberIndex;
        let membersDataList = [...array];
        let inActiveMembersList = array?.filter((list) => !list?.is_active);
        const { name, value } = event.target;

        let leaveMemberDatalist = [];
        if (name === "projects") {
            if (inActiveMembersList?.find((list) => list?.project_id === value?.id)) {
                let existRecord = inActiveMembersList?.find(
                    (list) => list?.project_id === value?.id
                );

                if (existRecord) {
                    leaveMemberDatalist = membersDataList?.map((userProjectData, index) => {
                        if (uniqueId === userProjectData?.unique_id) {
                            return {
                                ...userProjectData,
                                project_role_id: existRecord?.project_role_id,
                                joining_date: existRecord?.joining_date,
                                [name]: value,
                                is_active: true,
                                id: existRecord?.id,
                                unique_id: generateUniqueId(),
                            };
                        }
                        return userProjectData;
                    });
                    setTimeout(() => {
                        leaveMemberDatalist = leaveMemberDatalist?.filter(
                            (list) => list?.project_id !== existRecord?.project_id
                        );
                    });
                }
            } else {
                leaveMemberDatalist = membersDataList?.map((userProjectData, index) => {
                    if (uniqueId === userProjectData?.unique_id) {
                        if (userProjectData?.id) {
                            let existRecord = membersDataList?.filter((data) => data?.id === userProjectData?.id);
                            return { ...userProjectData, id: existRecord?.length > 1 ? null : userProjectData?.id, [name]: value, is_active: true, is_changed: true };
                        }
                        return { ...userProjectData, [name]: value, is_active: true };
                    }
                    return userProjectData;
                });
            }
            validateProjectData(event, indexOfMember, membersDataList);
        } else {
            leaveMemberDatalist = membersDataList?.map((userProjectData, index) => {
                if (uniqueId === userProjectData?.unique_id) {
                    return { ...userProjectData, [name]: value, is_active: true };
                }
                return userProjectData;
            });

            let updatedErrors = [...errors];

            setErrors(
                array?.map((userProjectData, index) => {
                    if (index === memberIndex) {
                        return {
                            ...updatedErrors?.[index],
                            [name]: value ? "" : "Required",
                        };
                    } else {
                        return {
                            ...updatedErrors?.[index],
                            [name]: updatedErrors?.[index]?.[name]
                                ? updatedErrors?.[index]?.[name]
                                : "",
                        };
                    }
                })
            );
        }

        let lastMember = leaveMemberDatalist[leaveMemberDatalist?.length - 1];
        if (
            !!value &&
            name === "projects" &&
            (lastMember?.projects) &&
            !array[indexOfMember]?.[name] &&
            leaveMemberDatalist?.filter((list) => list?.is_active)?.length < (projectList?.length - inActiveUserProjectList?.length)
        ) {
            leaveMemberDatalist.push({
                ...initialProjectData,
                unique_id: generateUniqueId(),
            });
        }
        setProjectData(leaveMemberDatalist);
    };

    const handleDeleteProjectList = (userProjectData, memberIndex, uniqueId) => {
        let updatedTeamMembersData = [];
        if (userProjectData?.id) {
            updatedTeamMembersData = [
                ...projectData?.map((member, index) =>
                    member?.unique_id === uniqueId
                        ? {
                            ...member,
                            is_active: false,
                            is_deleted: true,
                            removed_date: getFormattedDate(new Date(), "YYYY-MM-DD"),
                        }
                        : { ...member }
                ),
            ];
        } else {
            updatedTeamMembersData = [
                ...projectData?.filter((member, index) => member?.unique_id !== uniqueId),
            ];
        }
        if (
            updatedTeamMembersData[updatedTeamMembersData?.length - 1]?.projects !== null
        ) {
            updatedTeamMembersData.push({
                ...initialProjectData,
                unique_id: generateUniqueId(),
            });
        }
        setProjectData(updatedTeamMembersData);
        setErrors((prev) => prev?.filter((member, index) => index !== memberIndex));
    };

    const handleChangeDate = (value, memberIndex) => {
        let err = [...errors];
        setProjectData((prev) =>
            prev?.map((userProjectData, index) => {
                if (memberIndex === index) {
                    err[index].joining_date = "";
                    return { ...userProjectData, joining_date: new Date(value) };
                }
                return userProjectData;
            })
        );
    };

    const validateTeamMemberData = () => {
        let error = projectData?.map((userProjectData, index) => {
            if (
                index === 0 ||
                index !== projectData?.length - 1 ||
                ((userProjectData?.projects ||
                    userProjectData?.project_role_id ||
                    userProjectData?.joining_date) &&
                    index === projectData?.length - 1)
            ) {
                return {
                    projects: userProjectData?.projects || !userProjectData?.is_active ? "" : "Required",
                    project_role_id: userProjectData?.project_role_id || !userProjectData?.is_active ? "" : "Required",
                    joining_date: userProjectData?.joining_date || !userProjectData?.is_active ? "" : "Required",
                };
            }
            return { projects: "", project_role_id: "", joining_date: "" };
        });
        setErrors(error);
        return error?.some(
            (errorData) =>
                errorData?.projects?.length ||
                errorData?.project_role_id?.length ||
                errorData?.joining_date?.length
        );
    };

    const handleSubmit = async () => {
        try {
            setIsLoading((prev) => ({ ...prev, formSubmitting: true }));
            if (!validateTeamMemberData()) {
                let updatedProjectData = projectData?.filter(list => {
                    let isTrue = !!list?.projects;
                    if (isTrue) {
                        let multipleIdData = projectData?.filter(listData => listData?.id === list?.id);
                        if (multipleIdData?.length > 1) {
                            return !list.User;
                        } else {
                            return true
                        }
                    }
                    return false;
                });

                let payload = {
                    user_id: Number(userId),
                    projects: updatedProjectData?.map((data) => {
                        let projectObj = {
                            "id": data?.id,
                            "user_id": data?.user_id ?? Number(userId),
                            "project_id": data?.projects?.id,
                            "project_role_id": data?.project_role_id ?? data?.project_role?.id,
                            "joining_date": getFormattedDate(data?.joining_date, "YYYY-MM-DD"),
                            "is_active": data?.is_active
                        }
                        if (data?.id) {
                            let previosData = optionalProjectData?.find(list => list?.id === data?.id);
                            if (projectObj?.project_id !== previosData?.projects?.id ||
                                projectObj?.joining_date !== previosData?.joining_date ||
                                projectObj?.project_role_id !== previosData?.project_role_id ||
                                projectObj?.is_active !== previosData?.is_active
                            ) {
                                projectObj['is_changed'] = true
                            }
                            if (!!data?.removed_date) {
                                projectObj['removed_date'] = data?.removed_date
                            }
                        }
                        return projectObj
                    })
                }
                const response = await axiosInstance.put(
                    API.updateProjectForUser, payload
                );
                if (response?.status === 200) {
                    showAlert(response?.data?.message);
                    setIsEditable(false);
                    setSortType('desc')
                }
            }
            setIsLoading((prev) => ({ ...prev, formSubmitting: false }));
        } catch (error) {
            console.error(error);
            setIsLoading((prev) => ({ ...prev, formSubmitting: false }));
        }
    }

    const handleCancel = () => {
        setIsEditable(false);
        setSortType('desc')
    }

    const handleSortClick = (sortTypeString) => {
        setSortType((prev) => (prev === "asc" ? "desc" : "asc"));
        setUserProjectList(prev => sortArrayByKey(prev, sortTypeString === "asc" ? "desc" : "asc", "projects?.title"))
    }

    return (
        <>
            {!isEditable ? (
                <React.Fragment>
                    <Grid
                        container
                        mb={2}
                        display={"flex"}
                        alignItems={"center"}
                        justifyContent={"space-between"}
                    >
                        <Grid item>
                            <Typography
                                color="dark.800"
                                fontSize={20}
                                fontWeight={400}
                                lineHeight="30px"
                                letterSpacing="0.15px"
                            >
                                {userProjectList?.length || 0} Assigned Projects
                            </Typography>
                        </Grid>
                        <Grid item>
                            <Button
                                variant="contained"
                                color="primary"
                                size="medium"
                                sx={{ color: "white", boxShadow: "none", textTransform: "none" }}
                                onClick={() => toggleEdit(isEditable)}
                            >
                                Edit
                            </Button>
                        </Grid>
                    </Grid>
                    <Paper
                        sx={{
                            border: "1px solid #E0E0E0",
                            borderRadius: "3px",
                            boxShadow: "none",
                        }}
                    >
                        <TableContainer>
                            <Table>
                                <TableHead
                                    sx={{
                                        'th': {
                                            color: "dark.800",
                                            fontWeight: 500,
                                            fontSize: 14,
                                            lineHeight: "24px",
                                            letterSpacing: "0.17px",
                                            padding: "5px 16px",
                                            textAlign: "left",
                                            background: "rgba(245, 248, 250, 1)",
                                        },
                                    }}
                                >
                                    <TableRow>
                                        <TableCell sx={{ minWidth: "300px" }}>
                                            <TableSortLabel
                                                active={true}
                                                direction={sortType}
                                                onClick={() => userProjectList?.length && handleSortClick(sortType)}
                                            >
                                                Projects
                                            </TableSortLabel>
                                        </TableCell>
                                        <TableCell sx={{ minWidth: "130px" }}>Projects Role</TableCell>
                                        <TableCell sx={{ minWidth: "140px" }}>Joining Date</TableCell>
                                        <TableCell sx={{ minWidth: "140px" }}>Status</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody
                                    sx={{
                                        "tr:hover .fav-project": {
                                            opacity: 1,
                                        },
                                    }}
                                >
                                    {(isLoading?.pageLoading || isLoading?.allProjectsLoading) ? (
                                        <CircularLoader variant="table" cols={6} rows={10} />
                                    ) : userProjectList?.length ? (
                                        userProjectList?.map((listData, index) => (
                                            <TableRow
                                                key={index}
                                                sx={{
                                                    td: {
                                                        color: "dark.800",
                                                        fontWeight: 400,
                                                        fontSize: 14,
                                                        lineHeight: "20px",
                                                        letterSpacing: "0.17px",
                                                        paddingTop: "12px",
                                                        paddingBottom: "12px",
                                                        textAlign: "left",
                                                        "&:first-of-type": {
                                                            paddingLeft: "16px",
                                                        },
                                                    },
                                                }}
                                            >
                                                <TableCell>
                                                    <Box display={"flex"} alignItems={"center"} gap={1}>
                                                        <Box
                                                            bgcolor={"#0063BF"}
                                                            height={36}
                                                            minWidth={36}
                                                            borderRadius={"6px"}
                                                            display={"flex"}
                                                            alignItems={"center"}
                                                            justifyContent={"center"}
                                                            sx={{ padding: "6px 8px" }}
                                                        >
                                                            <Typography
                                                                color={"white"}
                                                                fontSize={13}
                                                                lineHeight={"20px"}
                                                                fontWeight={600}
                                                            >
                                                                {listData?.projects?.short_code?.length > 6 ? listData?.projects?.short_code?.slice(0, 6)?.toUpperCase() : listData?.projects?.short_code?.toUpperCase()}
                                                            </Typography>
                                                        </Box>
                                                        <Box display={"flex"} alignItems={"center"} gap={"8px"}>
                                                            <Typography
                                                                variant="body1"
                                                                color="dark.800"
                                                                fontSize={14}
                                                                lineHeight={"20px"}
                                                                fontWeight={500}
                                                                letterSpacing={"0.17px"}
                                                            >
                                                                {listData?.projects?.title}
                                                            </Typography>
                                                        </Box>
                                                    </Box>
                                                </TableCell>
                                                <TableCell>
                                                    {titleCase(projectRolesData?.find((list) => list?.id === listData?.project_role_id)?.title)}
                                                </TableCell>
                                                <TableCell>
                                                    {getFormattedDate(listData?.joining_date, "DD/MM/YYYY")}
                                                </TableCell>
                                                <TableCell>
                                                    {titleCase(getKeyByValue(listData?.projects?.status, PROJECT_STATUS))}
                                                </TableCell>
                                            </TableRow>
                                        ))
                                    ) : (
                                        <TableRow>
                                            <TableCell
                                                colSpan={4}
                                                align="center"
                                                sx={{ padding: "12px 16px;" }}
                                            >
                                                <Typography
                                                    color={"secondary"}
                                                    display={"flex"}
                                                    alignItems={"center"}
                                                    justifyContent={"center"}
                                                    fontSize={13}
                                                    sx={{ opacity: "0.5", height: "200px" }}
                                                >
                                                    No project assigned.
                                                </Typography>
                                            </TableCell>
                                        </TableRow>
                                    )}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </Paper>
                </React.Fragment>
            ) :
                (isLoading?.pageLoading || isLoading?.allProjectsLoading) ? (
                    <Box>
                        <CircularLoader height="500px" />
                    </Box>
                ) : ((projectList?.length && projectData?.length) ?
                    <React.Fragment>
                        <Box p={3} bgcolor={"#F8F8F8"} mb={2}>
                            <Stack spacing={4} width={820} maxWidth={"100%"}>
                                {projectData?.map((userProjectData, index, array) => (
                                    <React.Fragment key={index}>
                                        {userProjectData?.is_active ||
                                            (!userProjectData?.projects && !userProjectData?.project_role_id && !userProjectData?.joining_date) ? (
                                            <Grid container>
                                                <>
                                                    <Grid
                                                        item
                                                        display="flex"
                                                        flexWrap={{ xs: "wrap", md: "nowrap" }}
                                                        width={780}
                                                    >
                                                        <Grid
                                                            item
                                                            pr={3}
                                                            mb={{ xs: 3, md: 0 }}
                                                            flex={{ xs: "0 0 50%", md: "0 0 37%" }}
                                                        >
                                                            <FormControl
                                                                className={classes.margin}
                                                                variant="standard"
                                                                sx={{ margin: "0px !important", width: "100%" }}
                                                            >
                                                                <AutocompleteSelect
                                                                    label="Select Project"
                                                                    name="projects"
                                                                    id={getInputId("projects")}
                                                                    onBlur={(event) =>
                                                                        validateProjectData(event, index, array, userProjectData?.unique_id)
                                                                    }
                                                                    onChange={(event) =>
                                                                        handleChange(event, index, array, userProjectData?.unique_id)
                                                                    }
                                                                    options={projectList?.filter((projectListData) => !projectData?.find(
                                                                        (member) =>
                                                                            member?.projects?.id === projectListData?.id && member?.is_active
                                                                    ) && !inActiveUserProjectList?.find(userData => userData?.project_id === projectListData?.id))}
                                                                    disabledOptions={projectList?.filter((data) =>
                                                                        projectData?.find(
                                                                            (member) => member?.projects?.id === data?.id && member?.is_active
                                                                        )
                                                                    )}
                                                                    isOptionEqualToValue={(option, value) => projectList?.find(user => user.id === value?.id) || {}}
                                                                    value={projectList?.find(user => user.id === userProjectData?.projects?.id) || ""}
                                                                    isProject={true}
                                                                    disableClearable={(!userProjectData?.projects && !userProjectData?.project_role_id && !userProjectData?.joining_date)}
                                                                />
                                                                {!!errors?.[index]?.projects ? (
                                                                    <FormHelperText sx={{ color: "red.main" }}>
                                                                        {errors?.[index]?.projects}
                                                                    </FormHelperText>
                                                                ) : null}
                                                            </FormControl>
                                                        </Grid>
                                                        {!userProjectData?.projects &&
                                                            !userProjectData?.project_role_id &&
                                                            !userProjectData?.joining_date &&
                                                            index !== 0 &&
                                                            projectData?.length - 1 === index ? null : (
                                                            <>
                                                                <Grid
                                                                    item
                                                                    pr={3}
                                                                    mb={{ xs: 3, md: 0 }}
                                                                    flex={{ xs: "0 0 50%", md: "0 0 32%" }}
                                                                    sx={{
                                                                        ".MuiTypography-body1": {
                                                                            fontSize: "12px",
                                                                            lineHeight: "19px",
                                                                            marginTop: "3px",
                                                                            color: "dark.800",
                                                                        },
                                                                    }}
                                                                >
                                                                    <FormControl
                                                                        variant="standard"
                                                                        sx={{ m: 0, width: "100%" }}
                                                                    >
                                                                        <InputLabel
                                                                            htmlFor={getInputId("role", index)}
                                                                            shrink={Boolean(userProjectData?.project_role_id)}
                                                                        >
                                                                            Projects Role
                                                                        </InputLabel>
                                                                        <Select
                                                                            name="project_role_id"
                                                                            label="Project Role"
                                                                            sx={{ width: "100%" }}
                                                                            value={userProjectData?.project_role_id ?? ""}
                                                                            onChange={(event) =>
                                                                                handleChange(event, index, array, userProjectData?.unique_id)
                                                                            }
                                                                            onBlur={(event) =>
                                                                                validateProjectData(event, index, array, userProjectData?.unique_id)
                                                                            }
                                                                            inputProps={{
                                                                                id: getInputId("role", index),
                                                                            }}
                                                                        >
                                                                            {projectRolesData?.map(({ id, title }, index) => (
                                                                                <MenuItem key={index} value={id} disabled={false}>
                                                                                    {titleCase(title)}
                                                                                </MenuItem>
                                                                            ))}
                                                                        </Select>
                                                                        {!!errors?.[index]?.project_role_id ? (
                                                                            <FormHelperText sx={{ color: "red.main" }}>
                                                                                {errors?.[index]?.project_role_id}
                                                                            </FormHelperText>
                                                                        ) : null}
                                                                    </FormControl>
                                                                </Grid>
                                                                <Grid
                                                                    item
                                                                    pr={3}
                                                                    mb={{ xs: 3, md: 0 }}
                                                                    flex={{ xs: "0 0 50%", md: "0 0 31%" }}
                                                                    sx={{
                                                                        input: {
                                                                            width: "100%",
                                                                            height: 30,
                                                                            border: "none !important",
                                                                            boxShadow: "none !important",
                                                                            borderRadius: "0px",
                                                                            paddingBottom: "6px",
                                                                            paddingLeft: "28px",
                                                                            borderBottom: "1px solid rgba(0, 0, 0, 0.42) !important",
                                                                            margin: "0",
                                                                            position: "relative",
                                                                            background: "transparent",
                                                                            color: "dark.800",
                                                                            fontSize: 14,
                                                                            fontWeight: 400,
                                                                            lineHeight: "24px",
                                                                            letterSpacing: "0.15px",
                                                                        },
                                                                        "input:hover": {
                                                                            borderBottomColor: "#333333 !important",
                                                                        },
                                                                        "input:focus": {
                                                                            borderBottomColor: "#047FE0 !important",
                                                                        },
                                                                        ".rmdp-container": {
                                                                            minWidth: "100px",
                                                                        },
                                                                    }}
                                                                >
                                                                    <Typography
                                                                        id={getInputId("joining_date")}
                                                                        fontSize={12}
                                                                        fontWeight={400}
                                                                        color="dark.600"
                                                                        sx={{
                                                                            width: "100%",
                                                                            letterSpacing: "0.15px",
                                                                            marginTop: "-3px",
                                                                            marginBottom: "2px",
                                                                        }}
                                                                    >
                                                                        Joining Date
                                                                    </Typography>
                                                                    <div style={{ height: 1 }}>
                                                                        <CalendarTodayIcon
                                                                            sx={{
                                                                                fontSize: 20,
                                                                                color: "#3F5C76",
                                                                                marginBottom: "-5px",
                                                                                marginLeft: "2px",
                                                                            }}
                                                                        />
                                                                    </div>
                                                                    <SingleDatePicker
                                                                        id={getInputId("joining_date")}
                                                                        value={new Date(userProjectData?.joining_date) || ""}
                                                                        name="joining_date"
                                                                        onChange={(event) =>
                                                                            handleChangeDate(event, index)
                                                                        }
                                                                        isMultiple={false}
                                                                        onOpen={() => { }}
                                                                    />
                                                                    {!!errors?.[index]?.joining_date ? (
                                                                        <FormHelperText sx={{ color: "red.main" }}>
                                                                            {errors?.[index]?.joining_date}
                                                                        </FormHelperText>
                                                                    ) : null}
                                                                </Grid>
                                                            </>
                                                        )}
                                                    </Grid>
                                                    {!userProjectData?.projects &&
                                                        !userProjectData?.project_role_id &&
                                                        !userProjectData?.joining_date &&
                                                        projectData?.length - 1 === index ? null : (
                                                        <Grid item mt={1} minWidth={40}>
                                                            <Box sx={{ minWidth: "40px" }}>
                                                                <IconButton
                                                                    size="small"
                                                                    sx={{ width: "40px", height: "40px" }}
                                                                    onClick={() =>
                                                                        handleDeleteProjectList(
                                                                            userProjectData,
                                                                            index,
                                                                            userProjectData?.unique_id
                                                                        )
                                                                    }
                                                                >
                                                                    <DeleteIcon fontSize="small" />
                                                                </IconButton>
                                                            </Box>
                                                        </Grid>
                                                    )}
                                                </>
                                            </Grid>
                                        ) : null}
                                    </React.Fragment>
                                ))}
                            </Stack>
                        </Box>
                        <Box
                            display="flex"
                            justifyContent="space-between"
                            alignItems="center"
                            mb={6}
                        >
                            <Box
                                display="flex"
                                justifyContent="flex-start"
                                alignItems="center"
                                flexBasis="100%"
                                gap={2}
                            >
                                <Button
                                    variant="contained"
                                    color="primary"
                                    size="large"
                                    sx={{
                                        boxShadow: "0",
                                        color: "white",
                                        fontSize: "14px",
                                        paddingTop: "6px",
                                        paddingBottom: "6px",
                                        textTransform: "none",
                                        "&:hover": { boxShadow: "0" },
                                    }}
                                    type="button"
                                    onClick={() => handleSubmit()}
                                    isButtonLoading={isLoading?.formSubmitting}
                                    disabled={isLoading?.pageLoading}
                                >
                                    Save
                                </Button>
                                <Button
                                    variant="text"
                                    color="secondary"
                                    size="large"
                                    sx={{
                                        boxShadow: "0",
                                        fontSize: "15px",
                                        textTransform: "none",
                                        "&:hover": { boxShadow: "0" },
                                    }}
                                    type="button"
                                    onClick={() => handleCancel()}
                                >
                                    Cancel
                                </Button>
                            </Box>
                        </Box>
                    </React.Fragment> :
                    <EmptyPage title="No project assigned." onClick={handleCancel} />
                )
            }
        </>

    )
}

export default UserProjects