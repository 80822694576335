import { Box, FormControl, Stack, TextField, Typography } from "@mui/material";
import DatePicker, { DateObject } from "react-multi-date-picker";
import { getFormattedDate, getStartDateBaseOnOnboarding } from "../../utils";
import moment from "moment";
import { WEEKDAYS } from "../../constants/default-values";
import useAuthentication from "../../hook/useAuthentication";
import Button from "../Button";
import "./index.css";
import React, { useRef, useState } from "react";

const MenuDateRangePicker = ({ ...props }) => {
    let { dateRange, onChange, onClose, maxDate = new DateObject().toDate(), error = "", width, ...rest } = props;
    const { getCurrentUser } = useAuthentication();
    const currentUser = getCurrentUser();
    const [filter, setFilter] = useState("");
    const pickerRef = useRef(null);
    let onboardingDate = new Date(currentUser?.organization?.createdAt);

    function getMonthNumber(monthName) {
        const months = [
            'January', 'February', 'March', 'April', 'May', 'June',
            'July', 'August', 'September', 'October', 'November', 'December'
        ];
        const monthIndex = months.indexOf(monthName);
        return monthIndex !== -1 ? monthIndex + 1 : null;
    }

    const getHeader = () => {
        const header = pickerRef.current?.querySelector(".rmdp-header-values");
        const [month, year] = header?.innerText.split(",");
        return { month, year: parseInt(year, 10) };
    };

    const navigateToMonthAndYear = async (targetMonthName, targetYear) => {
        const prevButton = pickerRef.current?.querySelector(".rmdp-left");
        const nextButton = pickerRef.current?.querySelector(".rmdp-right");

        while (true) {
            const { month: currentMonthName, year: currentYear } = getHeader();
            const currentMonthNumber = getMonthNumber(currentMonthName);
            const targetMonthNumber = getMonthNumber(targetMonthName);

            if (currentMonthNumber === null || targetMonthNumber === null) return;

            // Stop when both year and month match the target
            if (currentYear === targetYear && currentMonthNumber === targetMonthNumber) break;

            // Navigate based on year and month comparison
            if (currentYear < targetYear || (currentYear === targetYear && currentMonthNumber < targetMonthNumber)) {
                nextButton?.click();
            } else {
                prevButton?.click();
            }

            // Wait briefly for the UI to update
            await new Promise(resolve => setTimeout(resolve, 300));
        }
    };


    const handleClickFilter = (filter) => {
        if (filter !== "reset") {
            setFilter(filter);
        } else {
            setFilter("");
        }

        let targetMonth, targetYear;

        if (filter === "reset") {
            onChange([new DateObject(new DateObject().toDate()), new DateObject(new DateObject().toDate())]);
            targetMonth = moment().format("MMMM");
            targetYear = moment().year();
        }
        else if (filter === "this week") {
            let startOfWeek = new Date(moment().startOf("week").toDate());
            let startDate = getStartDateBaseOnOnboarding(startOfWeek, onboardingDate);
            let endDate = new DateObject(moment().toDate());
            onChange([startDate, endDate]);
            targetMonth = moment().format("MMMM");
            targetYear = moment().year();
        }
        else if (filter === "last week") {
            let startOfWeek = new Date(moment().subtract(1, "week").startOf("week").toDate());
            let startDate = getStartDateBaseOnOnboarding(startOfWeek, onboardingDate);
            let endDate = new DateObject(moment().subtract(1, "week").endOf("week").toDate());
            onChange([startDate, endDate]);
            targetMonth = moment(new Date(startDate)).format("MMMM");
            targetYear = moment(new Date(startDate)).year();
        }
        else if (filter === "last 7 days") {
            let startOfDays = new Date(moment().subtract(6, "days").toDate());
            let startDate = getStartDateBaseOnOnboarding(startOfDays, onboardingDate);
            let endDate = new DateObject(moment().toDate());
            onChange([startDate, endDate]);
            targetMonth = moment(new Date(startDate)).format("MMMM");
            targetYear = moment(new Date(startDate)).year();
        }
        else if (filter === "current month") {
            let startOfMonth = new Date(moment().startOf("month").toDate());
            let startDate = getStartDateBaseOnOnboarding(startOfMonth, onboardingDate);
            let endDate = new DateObject(moment().toDate());
            onChange([startDate, endDate]);
            targetMonth = moment(new Date(startDate)).format("MMMM");
            targetYear = moment(new Date(startDate)).year();
        }
        else if (filter === "last month") {
            let startOfMonth = new Date(moment().subtract(1, "month").startOf("month").toDate());
            let startDate = getStartDateBaseOnOnboarding(startOfMonth, onboardingDate);
            let endDate = new DateObject(moment().subtract(1, "month").endOf("month").toDate());
            onChange([startDate, endDate]);
            targetMonth = moment(new Date(startDate)).format("MMMM");
            targetYear = moment(new Date(startDate)).year();
        }

        // Navigate to the target month and year
        navigateToMonthAndYear(targetMonth, targetYear);
    };

    return <FormControl variant="standard">
        <DatePicker
            ref={pickerRef}
            id="date-range-with-menu"
            range
            value={dateRange}
            onChange={onChange}
            onClose={onClose}
            headerOrder={["MONTH_YEAR", "LEFT_BUTTON", "RIGHT_BUTTON"]}
            format="DD/MM/YYYY"
            weekDays={WEEKDAYS}
            highlightToday
            minDate={new Date(currentUser?.organization?.createdAt)}
            maxDate={maxDate}
            placeholder='Select Report Range'
            mapDays={({ date }) => {
                let isPastDate = moment(new Date(date)).isBefore(currentUser?.organization?.createdAt);
                if (isPastDate) {
                    return {
                        disabled: true,
                        style: { color: "#ccc" },
                    };
                }
            }}
            render={<TextField
                label={'Select Report Range'}
                autoComplete="off"
                onKeyDown={(event) => {
                    event.preventDefault();
                }}
                id="tags-standard"
                variant="standard"
                sx={{
                    minHeight: "30px",
                    width: { xs: 220, lg: 264 },
                    marginTop: "10px",
                    "label": {
                        top: "-10px",
                    },
                    ".MuiInputBase-formControl": {
                        minHeight: "30px",
                        flexWrap: "wrap",
                        paddingTop: "0px",
                        marginTop: '8px'
                    },
                    "p": {
                        fontSize: "14px",
                        fontWeight: "400",
                        color: "rgba(0, 0, 0, 0.87)",
                    },
                    ".MuiInputBase-input": {
                        opacity: 0,
                        height: "22px",
                        flex: '1',
                        border: "none",
                        top: 0,
                        bottom: "1px",
                        background: "transparent",
                        position: "absolute",
                    },
                    ".rmdp-input:focus": {
                        border: "none",
                        boxShadow: "none",
                    },
                    ".MuiChip-sizeSmall": {
                        marginLeft: '0px !important',
                        marginRight: '8px'
                    }
                }}
                InputProps={{
                    startAdornment: (
                        <Stack spacing={1} direction="row" flexWrap="wrap">
                            {dateRange?.map((date, index, array) => (
                                <React.Fragment key={index}>
                                    <Typography>{typeof date === "string" ? date : getFormattedDate(new Date(date), "DD/MM/YYYY", true)}</Typography>
                                    {array?.length !== 1 && index === 0 ? <Typography>{" - "}</Typography> : null}
                                </React.Fragment>
                            ))}
                        </Stack>
                    ),
                    readOnly: true
                }}
                {...rest}
                className="rmdp-input-with-menu"
            />
            }
            {...rest}
        >
            <Box sx={{ display: "flex", justifyContent: "space-between", width: "160px", minWidth: "160px" }}>
                <Stack direction="column" sx={{ width: "100%", alignItems: "flex-start" }}>
                    <Button
                        sx={{ color: filter === "this week" ? "white" : "primary", px: 1.5, }}
                        variant={filter === "this week" ? "contained" : "text"}
                        onClick={() => handleClickFilter("this week")}
                    >
                        this week
                    </Button>
                    <Button
                        sx={{ color: filter === "last week" ? "white" : "primary", px: 1.5, }}
                        variant={filter === "last week" ? "contained" : "text"}
                        onClick={() => handleClickFilter("last week")}
                    >
                        last week
                    </Button>
                    <Button
                        sx={{
                            color: filter === "last 7 days" ? "white" : "primary", px: 1.5,
                        }}
                        variant={filter === "last 7 days" ? "contained" : "text"}
                        onClick={() => handleClickFilter("last 7 days")}
                    >
                        last 7 days
                    </Button>
                    <Button
                        sx={{ color: filter === "current month" ? "white" : "primary", px: 1.5, }}
                        variant={filter === "current month" ? "contained" : "text"}
                        onClick={() => handleClickFilter("current month")}
                    >
                        current month
                    </Button>
                    <Button
                        sx={{ color: filter === "last month" ? "white" : "primary", px: 1.5, }}
                        variant={filter === "last month" ? "contained" : "text"}
                        onClick={() => handleClickFilter("last month")}
                        disabled={moment(moment().startOf("month").toDate()).isBefore(new Date(currentUser?.organization?.createdAt))}
                    >
                        last month
                    </Button>
                    <Button
                        variant={"text"}
                        onClick={() => handleClickFilter("reset")}
                    >
                        reset
                    </Button>
                </Stack>
            </Box>
        </DatePicker>
        {
            !!error ? <Typography

                sx={{
                    color: "red.main",
                    fontSize: "12px"
                }}
            >
                {error}
            </Typography> : null
        }
    </FormControl>
}

export default MenuDateRangePicker;