import React, { useEffect, useState } from 'react'
import { Box, Button, Collapse, FormControl, Grid, IconButton, InputLabel, MenuItem, Select, Stack, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@mui/material'
import { ReactComponent as ExpandIcon } from "../../../../assets/images/expand-icon.svg";
import { ReactComponent as CollapseIcon } from "../../../../assets/images/collapse-icon.svg";
import LaunchIcon from '@mui/icons-material/Launch';
import BackgroundLetterAvatars from '../../../../components/BackgroundLetterAvatars';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import axiosInstance from '../../../../axios';
import useAuthentication from '../../../../hook/useAuthentication';
import API from '../../../../axios/api';
import { useNavigate, useParams } from 'react-router-dom';
import { getApplicationNumber, getFormattedDate, getLeaveStatus, getYearObject, sortArrayByKey, specialRequestList, titleCase } from '../../../../utils';
import URLS from '../../../../routes/urls';
import CircularLoader from '../../../../components/CircularLoader';

const UserReports = () => {
    const navigate = useNavigate();
    const { getCurrentUser } = useAuthentication();
    const currentUser = getCurrentUser();
    const { userId } = useParams();

    const [selectedYearId, setSelectedYearId] = useState(null);
    const [selectedYearData, setSelectedYearData] = useState({});
    const [years, setYears] = useState([]);
    const [activeYear, setActiveYear] = useState({});
    const [specialCreditReportData, setSpecialCreditReportData] = useState([]);
    const [isReportShow, setIsReportShow] = useState(false);
    const [arrangedMonth, setArrangedMonth] = useState([]);
    const [isExpandAll, setIsExpandAll] = useState(false);
    const [openAccordians, setOpenAccordians] = useState([]);
    const [allSpecialReqNames, setAllSpecialReqNames] = useState([]);
    const [isLoading, setIsLoading] = useState({
        specialRequest: false,
        yearLoading: false
    });

    let months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"]
    const [selectedMonth, setSelectedMonth] = useState(months[new Date()?.getMonth()])
    const [selectedCell, setSelectedCell] = useState({
        month: months[new Date()?.getMonth()],
        id: null
    })
    const [currentMonthColumnName, setCurrentMonthName] = useState(months[new Date()?.getMonth()])

    useEffect(() => {
        if (currentUser?.organization_id)
            getAllHolidayYears();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        if (selectedYearData?.id && selectedYearId && userId && isReportShow) {
            getLeaveSpecialRequests(selectedYearId, selectedYearData)
            setIsExpandAll(false);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedYearData, isReportShow])

    useEffect(() => {
        if (isExpandAll) {
            setOpenAccordians(allSpecialReqNames);
        } else {
            setOpenAccordians([]);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isExpandAll])

    useEffect(() => {
        if ((openAccordians?.length === allSpecialReqNames?.length) && !isExpandAll && specialCreditReportData?.length) {
            setIsExpandAll(true)
        } else if ((openAccordians?.length === 0) && isExpandAll && specialCreditReportData?.length) {
            setIsExpandAll(false)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [openAccordians])

    const getMonthsByYear = (updatedStartDate, startDate, endDate) => {
        const date = new Date(updatedStartDate);
        const startMonthIndex = date.getMonth();
        let startYear = new Date(startDate)?.getFullYear();
        let endYear = new Date(endDate)?.getFullYear();
        let currentMonthIndex = new Date().getMonth();


        let allMonthData = [];
        let startYearChange = false;
        if (startYear === endYear && (new Date(endDate).getMonth() !== 11)) {
            startYear = startYear - 1;
            startYearChange = true
        }
        for (let monthIndex = startMonthIndex; monthIndex < months?.length; monthIndex++) {
            let isGreaterMonth = false;
            if (startYear < new Date().getFullYear()) {
                isGreaterMonth = false || startYearChange;
            } else if (startYear === new Date().getFullYear()) {
                isGreaterMonth = monthIndex > currentMonthIndex;
            } else {
                isGreaterMonth = true;
            }
            allMonthData.push({ month: months[monthIndex], year: startYear, isMonthGreater: isGreaterMonth })
        }
        if (startMonthIndex !== 0) {
            for (let monthIndex = 0; monthIndex < startMonthIndex; monthIndex++) {
                let isGreaterMonth = false;

                if (endYear < new Date().getFullYear()) {
                    isGreaterMonth = false;
                } else if (endYear === new Date().getFullYear()) {
                    isGreaterMonth = monthIndex > currentMonthIndex;
                } else {
                    isGreaterMonth = true;
                }
                allMonthData.push({ month: months[monthIndex], year: endYear, isMonthGreater: isGreaterMonth })
            }
        }
        return allMonthData;
    }

    const setReportDataMonthWise = (allCreditData, endDate, startDate) => {
        let activeYearMonthStartMonthdate = new Date(endDate);
        activeYearMonthStartMonthdate.setDate(1);
        if (new Date(endDate).getMonth() !== 11) {
            activeYearMonthStartMonthdate.setMonth(new Date(endDate).getMonth() + 1);
        } else {
            activeYearMonthStartMonthdate.setMonth(0);
        }

        let allArrangedMonths = getMonthsByYear(activeYearMonthStartMonthdate, startDate, endDate);
        setArrangedMonth(allArrangedMonths);
        let allNames = [];
        let finalData = allCreditData?.map((allCreditData, index) => {
            allNames.push(allCreditData?.id)
            let object = {
                name: allCreditData?.name,
                user_id: allCreditData?.user_id,
                allotment: allCreditData?.allotment,
                remaining: allCreditData?.remaining,
                active: allCreditData?.active,
                months: allArrangedMonths?.map((monthData) => { return { [monthData?.month]: { accordianData: [] }, year: monthData?.year, isGreaterMonth: monthData?.isMonthGreater } }),
                isLeave: false,
                id: allCreditData?.id,
            }

            if (allCreditData?.leaveData?.length) {
                object['isLeave'] = true;
                allCreditData?.leaveData?.map((leaveApplicationData) => {
                    leaveApplicationData?.leave_days?.map((leaveDayData) => {
                        let month = months[new Date(leaveDayData?.leave_date).getMonth()];
                        let monthIndex = object?.months?.findIndex(monthName => Object.keys(monthName)?.includes(month));
                        object?.months[monthIndex][month]['accordianData'].push({
                            ...leaveDayData,
                            appId: leaveApplicationData?.id,
                            type: leaveApplicationData?.leave_type?.split(" ")?.[0],
                            processed_by: leaveApplicationData?.processed_by,
                            process_date: leaveApplicationData?.process_date,
                            user_id: leaveApplicationData?.user_id,
                            status: leaveApplicationData?.status,
                            days: leaveApplicationData?.days,
                            application_num: leaveApplicationData?.application_num,
                            reqDate: leaveDayData?.leave_date,
                            isLeave: true
                        })
                        return leaveDayData;
                    })
                    return leaveApplicationData;
                })
            } else {
                if (allCreditData?.wfhData?.length) {
                    object['isLeave'] = false;
                }
                allCreditData?.wfhData?.map((wfhApplication) => {
                    wfhApplication?.WfhDays?.map((wfhDayData) => {
                        let month = months[new Date(wfhDayData?.date).getMonth()];
                        let monthIndex = object?.months?.findIndex(monthName => Object.keys(monthName)?.includes(month));
                        object?.months[monthIndex][month]['accordianData'].push({
                            ...wfhDayData,
                            appId: wfhApplication?.id,
                            type: specialRequestList?.find(requestData => requestData?.id === wfhApplication?.spec_id)?.name ?? titleCase(allCreditData?.name),
                            processed_by: wfhApplication?.processed_by,
                            process_date: wfhApplication?.process_date,
                            user_id: wfhApplication?.user_id,
                            status: wfhApplication?.status,
                            days: wfhApplication?.days,
                            application_num: wfhApplication?.application_num,
                            reqDate: wfhDayData?.date,
                            isLeave: false
                        })
                        return wfhDayData;
                    })
                    return wfhApplication;
                })
            }

            return object
        });
        setSelectedMonth(months[new Date().getMonth()]);
        setSelectedCell({
            month: months[new Date()?.getMonth()],
            id: null
        })
        setAllSpecialReqNames(allNames)
        setSpecialCreditReportData(finalData)
    }

    const getLeaveSpecialRequests = async (yearId, yearData = selectedYearData) => {
        setIsLoading((prev) => ({ ...prev, specialRequest: true }));
        try {
            let response = await axiosInstance.get(API.getUserCreditReport(userId, yearId));
            if (response.status === 200) {
                setReportDataMonthWise(response?.data?.data, yearData?.setting_end_date, yearData?.setting_start_date);
                setIsLoading((prev) => ({ ...prev, specialRequest: false }));
            }
            else {
                setIsLoading((prev) => ({ ...prev, specialRequest: false }));
            }
        } catch (error) {
            console.error(error);
            setIsLoading((prev) => ({ ...prev, specialRequest: false }));
        }
    }

    const getAllHolidayYears = async () => {
        try {
            setIsLoading((prev) => ({ ...prev, yearLoading: true }));
            const response = await axiosInstance.get(
                API.getAllHolidaysYear(currentUser.organization_id)
            );
            if (response.status === 200) {
                let sortedYear = response?.data?.result?.sort((a, b) =>
                    a?.year?.toString()?.localeCompare(b?.year?.toString())
                );

                let removeFutureYear = sortedYear?.filter((yearData) => {
                    return yearData?.year <= new Date().getFullYear() || yearData?.active;
                });
                setYears(removeFutureYear);
                let activeYear = sortedYear?.find(yearData => yearData?.active);
                setSelectedYearId(activeYear?.id);
                setSelectedYearData(activeYear);
                setActiveYear(activeYear);

                let activeYearMonthStartMonthdate = new Date(activeYear?.setting_end_date);
                activeYearMonthStartMonthdate.setDate(1);
                if (new Date(activeYear?.setting_end_date).getMonth() !== 11) {
                    activeYearMonthStartMonthdate.setMonth(new Date(activeYear?.setting_end_date).getMonth() + 1);
                } else {
                    activeYearMonthStartMonthdate.setMonth(0);
                }
                let allArrangedMonths = getMonthsByYear(activeYearMonthStartMonthdate, activeYear?.setting_start_date, activeYear?.setting_end_date);
                setArrangedMonth(allArrangedMonths)
            }
            setIsLoading((prev) => ({ ...prev, yearLoading: false }));
        } catch (error) {
            console.error("Error fetching data:", error);
            setIsLoading((prev) => ({ ...prev, yearLoading: false }));
        }
    };

    const handleYearChange = (event) => {
        setSelectedYearId(event.target.value);
        setSelectedYearData(years?.find(yearData => yearData?.id?.toString() === event.target.value?.toString()));
    };

    const getSelectedMonthData = (creditData = {}, index, isLength = true) => {
        let monthData = creditData?.months?.[index];
        if (!isLength) return monthData;
        if (monthData?.isGreaterMonth) {
            return Object.values(monthData)?.[0]?.accordianData?.length ? Object.values(monthData)?.[0]?.accordianData?.length : "-"
        } else {
            return Object.values(monthData)?.[0]?.accordianData?.length ?? 0
        }
    }

    const getSelectedMonth = (creditData, index) => {
        return Object.keys(creditData?.months?.[index])[0]
    }

    const getSelectedMonthRequestData = (creditData, isYear = false) => {
        let isSelectedCellIdExist = creditData?.id === selectedCell?.id;

        let monthData = creditData?.months?.find(monthData => Object.keys(monthData)?.includes(isSelectedCellIdExist ? selectedCell?.month : currentMonthColumnName));
        if (isYear) return monthData?.year;
        else return monthData[isSelectedCellIdExist ? selectedCell?.month : currentMonthColumnName]?.accordianData
    }

    const getLeaveCount = (reqDayData) => {
        if (reqDayData?.leave_duration === "full") {
            return 1
        } else {
            return (<>
                0.5 (
                {reqDayData?.leave_duration === "first_half" ? (
                    <>
                        1
                        <Typography fontSize={"70%"} display={"inline-block"} sx={{ verticalAlign: "top" }}>st</Typography>{" "}
                        half
                    </>
                ) : reqDayData?.leave_duration === "second_half" ? (
                    <>
                        2
                        <Typography fontSize={"70%"} display={"inline-block"} sx={{ verticalAlign: "top" }}>nd</Typography>{" "}
                        half
                    </>
                ) : null}
                )
            </>)
        }
    }

    const getLeaveSetOff = (reqDayData) => {
        if (reqDayData?.isLeave) return "Fully Paid"
        else {
            if (reqDayData?.leave_duration === "full") {
                return reqDayData?.is_paid === 1 ? "Fully Paid" : reqDayData?.is_paid === 0.5 ? "Half Paid / Half Unpaid" : "Fully Unpaid"
            } else {
                return reqDayData?.is_paid === 0.5 ? "Fully Paid" : "Fully Unpaid"
            }
        }
    }

    const handleRequestClick = (requestData) => {
        let { user_id, appId } = requestData;
        navigate(`${requestData?.isLeave ? URLS.OtherLeaveDetails : URLS.OtherWfhApplicationDetails}/${user_id}/${appId}`);
    };

    const handleClickCell = (creditData, index) => {
        let monthName = getSelectedMonth(creditData, index);
        setSelectedMonth(monthName);
        setSelectedCell({
            month: monthName,
            id: creditData?.id
        })
    }

    const handleClickMonthHeader = (month) => {
        setCurrentMonthName(month)
        setSelectedMonth(month);
        setSelectedCell({
            month: month,
            id: null
        })
    }

    const handleClickBack = () => {
        setIsReportShow(false);
        setSelectedCell({
            month: months[new Date().getMonth()],
            id: null
        });
        setCurrentMonthName(months[new Date().getMonth()])
        setSelectedYearId(activeYear?.id);
        setSelectedYearData(activeYear);
    }

    return (
        <>
            {!isReportShow ? <Box display={"flex"} sx={{ width: '100%', flex: '1', overflow: 'hidden', }} pb={4}>
                <Box display={"flex"} flexDirection={"column"} sx={{ width: '100%', flex: '1', overflow: 'hidden', }}>
                    <Box bgcolor={"white"} display={'flex'} gap={"10px"} color={"#3F5C76"} width={300} p={2}
                        sx={{
                            border: '1px solid #E0E0E0',
                            cursor: " pointer",
                            borderRadius: '4px',
                            '&:hover': {
                                boxShadow: 'shadow'
                            }
                        }}
                        onClick={() => setIsReportShow(true)}
                    >
                        <ExitToAppIcon sx={{ fontSize: "1.5rem", verticalAlign: "middle" }} />
                        <Typography color="secondary.main" fontSize={14} fontWeight={600} lineHeight="21px" letterSpacing="0.15px">Leave / Request Credits Report</Typography>
                    </Box>
                </Box>
            </Box> : null}

            {isReportShow ? <Box display={"flex"} sx={{ width: '100%', flex: '1', }}>
                <Box display={"flex"} flexDirection={"column"} sx={{ width: '100%', flex: '1', }}>
                    <Box>
                        <Button
                            variant="contained"
                            color="secondary"
                            size="medium"
                            sx={{ boxShadow: 'none', textTransform: 'none' }}
                            onClick={() => handleClickBack()}
                        >
                            Back to Reports
                        </Button>
                    </Box>
                    <Stack spacing={4} py={3} display="flex" flexDirection={"row"} justifyContent="space-between" alignItems="center">
                        <Grid item width={265} maxWidth={265}>
                            <FormControl variant="standard" sx={{ width: "100%" }}>
                                <InputLabel htmlFor="year_label-1">
                                    Select Year
                                </InputLabel>
                                <Select
                                    id='year-range'
                                    name='year'
                                    inputProps={{ id: "year_label-1" }}
                                    value={selectedYearId}
                                    onChange={(e) => handleYearChange(e)}
                                    label="Select Year"
                                    sx={{
                                        ".MuiSelect-select": {
                                            fontSize: 14,
                                            fontWeight: 400,
                                            color: "dark.800",
                                        },
                                    }}
                                    disabled={isLoading?.specialRequest}
                                >
                                    {years?.map((yearData, index) => (
                                        <MenuItem value={yearData?.id} key={index}>
                                            {getYearObject(yearData)?.yearDashedFormat}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item display={"flex"} justifyContent={"flex-end"} gap={2}>
                            <Button
                                variant="outlined"
                                color="secondary"
                                size="medium"
                                startIcon={!isExpandAll ? <ExpandIcon /> : <CollapseIcon />}
                                sx={{ color: "black", BorderColor: '##ddd', boxShadow: "none", textTransform: "none", minWidth: '130px' }}
                                onClick={() => setIsExpandAll(prev => !prev)}
                            >
                                {!isExpandAll ? "Expand All" : "Collapse All"}
                            </Button>
                        </Grid>
                    </Stack>
                    <Box flex={1} overflow={"hidden"} display={"flex"}>
                        <TableContainer sx={{ border: '1px solid #ddd', borderRadius: '3px', overflow: 'overlay' }}>
                            <Table
                                aria-labelledby="tableTitle"
                                size={'medium'}
                                aria-label="collapsible table">
                                <TableHead>
                                    <TableRow fontSize={14} fontWeight={500} lineheight={'24px'} letterSpacing={'0.17px'} alignitems={'center'} sx={{ backgroundColor: '#F5F8FA', textAlign: 'center', height: '36px', maxHeight: '36px' }}>
                                        <TableCell key={'100'} sx={{ minWidth: '225px', padding: '6px 16px 6px 16px', boxShadow: 'inset -1px -1px 0px #ddd', borderBottom: 'none', textAlign: 'left', color: 'dark.800' }}>Requests</TableCell>
                                        <TableCell key={'200'} sx={{ minWidth: '110px', maxWidth: '110px', width: '110px', padding: '6px 5px 6px 5px', boxShadow: 'inset -1px -1px 0px #ddd', borderBottom: 'none', textAlign: 'center', color: 'dark.800' }}>Credits Given</TableCell>
                                        {arrangedMonth?.length ?
                                            arrangedMonth?.map((monthData, index) => (
                                                <TableCell key={monthData?.month} sx={{ minWidth: '52px', maxWidth: '52px', width: '52px', padding: '6px 5px 6px 5px', boxShadow: 'inset -1px -1px 0px #ddd', borderBottom: 'none', textAlign: 'center', color: 'dark.800' }}
                                                    onClick={() => handleClickMonthHeader(monthData?.month)}
                                                >
                                                    {monthData?.month}
                                                </TableCell>
                                            ))
                                            : null
                                        }
                                        <TableCell key={'300'} sx={{ minWidth: '140px', maxWidth: '140px', width: '140px', padding: '6px 5px 6px 5px', boxShadow: 'inset 0px -1px 0px #ddd', borderBottom: 'none', textAlign: 'center', color: 'dark.800' }}>Current Balance</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {
                                        isLoading?.specialRequest ?
                                            <CircularLoader variant="table" cols={15} rows={10} /> :
                                            specialCreditReportData?.length ?
                                                specialCreditReportData?.map((creditData, index) => (
                                                    <React.Fragment key={index}>
                                                        <TableRow hover
                                                            sx={{
                                                                "td": {
                                                                    fontSize: 14,
                                                                    fontWeight: 400,
                                                                    lineHeight: "24px",
                                                                    letterSpacing: "0.17px",
                                                                    color: "dark.800",
                                                                    textAlign: "center",
                                                                    padding: "8px 5px",
                                                                    boxShadow: "inset -1px 0px 0px #ddd",
                                                                    '&:last-child': {
                                                                        boxShadow: 'none',
                                                                    },
                                                                },
                                                            }}>
                                                            <TableCell sx={{ padding: "10px 16px !important" }} onClick={() => setOpenAccordians(prev => prev?.includes(creditData?.id) ? prev?.filter(list => list !== creditData?.id) : [...prev, creditData?.id])}>
                                                                <Box display={"flex"} alignItems={"center"} gap={1}>
                                                                    <IconButton size="small" sx={{ padding: '2px' }}>
                                                                        {/* <ExpandIcon /> <CollapseIcon /> */}
                                                                        <ExpandIcon />
                                                                    </IconButton>
                                                                    <Typography component="span" color={"secondary.main"} fontSize={14} fontWeight={openAccordians?.includes(creditData?.id) ? 700 : 400} lineHeight={"20px"} letterSpacing={"0.17px"}>{titleCase(creditData?.name)}</Typography>
                                                                </Box>
                                                            </TableCell>
                                                            <TableCell sx={{ fontWeight: "700 !important", }}>
                                                                {creditData?.allotment ?? 0}
                                                            </TableCell>
                                                            <TableCell sx={{ bgcolor: (selectedCell?.month === getSelectedMonth(creditData, 0) && selectedCell?.id === creditData?.id) ? 'rgba(18, 180, 149, 0.25)' : currentMonthColumnName === getSelectedMonth(creditData, 0) ? 'rgba(18, 180, 149, 0.05)' : 'transparent' }} onClick={() => handleClickCell(creditData, 0)}>{getSelectedMonthData(creditData, 0)}</TableCell>
                                                            <TableCell sx={{ bgcolor: (selectedCell?.month === getSelectedMonth(creditData, 1) && selectedCell?.id === creditData?.id) ? 'rgba(18, 180, 149, 0.25)' : currentMonthColumnName === getSelectedMonth(creditData, 1) ? 'rgba(18, 180, 149, 0.05)' : 'transparent' }} onClick={() => handleClickCell(creditData, 1)}>{getSelectedMonthData(creditData, 1)}</TableCell>
                                                            <TableCell sx={{ bgcolor: (selectedCell?.month === getSelectedMonth(creditData, 2) && selectedCell?.id === creditData?.id) ? 'rgba(18, 180, 149, 0.25)' : currentMonthColumnName === getSelectedMonth(creditData, 2) ? 'rgba(18, 180, 149, 0.05)' : 'transparent' }} onClick={() => handleClickCell(creditData, 2)}>{getSelectedMonthData(creditData, 2)}</TableCell>
                                                            <TableCell sx={{ bgcolor: (selectedCell?.month === getSelectedMonth(creditData, 3) && selectedCell?.id === creditData?.id) ? 'rgba(18, 180, 149, 0.25)' : currentMonthColumnName === getSelectedMonth(creditData, 3) ? 'rgba(18, 180, 149, 0.05)' : 'transparent' }} onClick={() => handleClickCell(creditData, 3)}>{getSelectedMonthData(creditData, 3)}</TableCell>
                                                            <TableCell sx={{ bgcolor: (selectedCell?.month === getSelectedMonth(creditData, 4) && selectedCell?.id === creditData?.id) ? 'rgba(18, 180, 149, 0.25)' : currentMonthColumnName === getSelectedMonth(creditData, 4) ? 'rgba(18, 180, 149, 0.05)' : 'transparent' }} onClick={() => handleClickCell(creditData, 4)}>{getSelectedMonthData(creditData, 4)}</TableCell>
                                                            <TableCell sx={{ bgcolor: (selectedCell?.month === getSelectedMonth(creditData, 5) && selectedCell?.id === creditData?.id) ? 'rgba(18, 180, 149, 0.25)' : currentMonthColumnName === getSelectedMonth(creditData, 5) ? 'rgba(18, 180, 149, 0.05)' : 'transparent' }} onClick={() => handleClickCell(creditData, 5)}>{getSelectedMonthData(creditData, 5)}</TableCell>
                                                            <TableCell sx={{ bgcolor: (selectedCell?.month === getSelectedMonth(creditData, 6) && selectedCell?.id === creditData?.id) ? 'rgba(18, 180, 149, 0.25)' : currentMonthColumnName === getSelectedMonth(creditData, 6) ? 'rgba(18, 180, 149, 0.05)' : 'transparent' }} onClick={() => handleClickCell(creditData, 6)}>{getSelectedMonthData(creditData, 6)}</TableCell>
                                                            <TableCell sx={{ bgcolor: (selectedCell?.month === getSelectedMonth(creditData, 7) && selectedCell?.id === creditData?.id) ? 'rgba(18, 180, 149, 0.25)' : currentMonthColumnName === getSelectedMonth(creditData, 7) ? 'rgba(18, 180, 149, 0.05)' : 'transparent' }} onClick={() => handleClickCell(creditData, 7)}>{getSelectedMonthData(creditData, 7)}</TableCell>
                                                            <TableCell sx={{ bgcolor: (selectedCell?.month === getSelectedMonth(creditData, 8) && selectedCell?.id === creditData?.id) ? 'rgba(18, 180, 149, 0.25)' : currentMonthColumnName === getSelectedMonth(creditData, 8) ? 'rgba(18, 180, 149, 0.05)' : 'transparent' }} onClick={() => handleClickCell(creditData, 8)}>{getSelectedMonthData(creditData, 8)}</TableCell>
                                                            <TableCell sx={{ bgcolor: (selectedCell?.month === getSelectedMonth(creditData, 9) && selectedCell?.id === creditData?.id) ? 'rgba(18, 180, 149, 0.25)' : currentMonthColumnName === getSelectedMonth(creditData, 9) ? 'rgba(18, 180, 149, 0.05)' : 'transparent' }} onClick={() => handleClickCell(creditData, 9)}>{getSelectedMonthData(creditData, 9)}</TableCell>
                                                            <TableCell sx={{ bgcolor: (selectedCell?.month === getSelectedMonth(creditData, 10) && selectedCell?.id === creditData?.id) ? 'rgba(18, 180, 149, 0.25)' : currentMonthColumnName === getSelectedMonth(creditData, 10) ? 'rgba(18, 180, 149, 0.05)' : 'transparent' }} onClick={() => handleClickCell(creditData, 10)}>{getSelectedMonthData(creditData, 10)}</TableCell>
                                                            <TableCell sx={{ bgcolor: (selectedCell?.month === getSelectedMonth(creditData, 11) && selectedCell?.id === creditData?.id) ? 'rgba(18, 180, 149, 0.25)' : currentMonthColumnName === getSelectedMonth(creditData, 11) ? 'rgba(18, 180, 149, 0.05)' : 'transparent' }} onClick={() => handleClickCell(creditData, 11)}>{getSelectedMonthData(creditData, 11)}</TableCell>                                                        <TableCell sx={{ fontWeight: "700 !important", color: (creditData?.remaining ?? 0) < 0 ? "#D32F2F !important" : "" }}>
                                                                {creditData?.remaining ?? 0}
                                                            </TableCell>
                                                        </TableRow>
                                                        <TableRow>
                                                            <TableCell sx={{ padding: "0px !important", border: "none" }} colSpan={15}>
                                                                <Collapse in={openAccordians?.includes(creditData?.id)} sx={{ borderLeft: "4px solid #3F5C76", }}>
                                                                    <Box p={3} sx={{ borderTop: 'none', borderBottom: '1px solid lightgray' }}>
                                                                        {getSelectedMonthRequestData(creditData)?.length ?
                                                                            <>
                                                                                <Typography fontSize={14} fontWeight={500} color={"dark.800"} lineHeight={"24px"} letterSpacing={"0.17px"} mb={1}><b>{titleCase(creditData?.name)}</b> details of <b>{selectedMonth} {getSelectedMonthRequestData(creditData, true)}</b></Typography>
                                                                                <TableContainer sx={{ border: '1px solid #ddd', borderRadius: '3px', overflow: 'overlay' }}>
                                                                                    <Table>
                                                                                        <TableHead>
                                                                                            <TableRow fontSize={14} fontWeight={500} lineheight={'24px'} letterSpacing={'0.17px'} alignitems={'center'} sx={{
                                                                                                backgroundColor: '#F5F8FA', textAlign: 'center', height: '36px', maxHeight: '36px',
                                                                                                "th": { padding: '6px 16px 6px 16px' },
                                                                                            }}>
                                                                                                <TableCell sx={{ minWidth: 130 }}>Date</TableCell>
                                                                                                <TableCell sx={{ minWidth: 120, maxWidth: 120, width: 120 }}>Leave</TableCell>
                                                                                                <TableCell sx={{ minWidth: 90, maxWidth: 190, }}>Type</TableCell>
                                                                                                <TableCell sx={{ minWidth: 120, maxWidth: 120, width: 120 }}>Full / Half</TableCell>
                                                                                                <TableCell sx={{ minWidth: 195 }}>Set Off</TableCell>
                                                                                                <TableCell sx={{ minWidth: 82, maxWidth: 82, width: 82 }}>Credits</TableCell>
                                                                                                <TableCell sx={{ minWidth: 120, maxWidth: 120, width: 120 }}>Status</TableCell>
                                                                                                <TableCell sx={{ minWidth: 224 }}>By</TableCell>
                                                                                            </TableRow>
                                                                                        </TableHead>
                                                                                        <TableBody>
                                                                                            {sortArrayByKey(getSelectedMonthRequestData(creditData), "asc", "reqDate")?.map((requestData, reqIndex) => (
                                                                                                <TableRow key={reqIndex} sx={{
                                                                                                    "td": {
                                                                                                        fontSize: 14,
                                                                                                        fontWeight: 400,
                                                                                                        color: "dark.800",
                                                                                                        lineheight: "20px",
                                                                                                        letterSpacing: "0.17px",
                                                                                                        padding: "10px 16px"
                                                                                                    },
                                                                                                    "&:hover": {
                                                                                                        background: "#F7F7F7",
                                                                                                        '.launchIcon': {
                                                                                                            display: 'inline-flex',
                                                                                                        },
                                                                                                        '.leaveId': {
                                                                                                            color: '#047FE0',
                                                                                                            textDecoration: 'underline',
                                                                                                        },
                                                                                                        '.leave-details': {
                                                                                                            opacity: 1,
                                                                                                            display: 'flex',
                                                                                                        },
                                                                                                    },
                                                                                                }}>
                                                                                                    <TableCell>{getFormattedDate(requestData?.reqDate, "DD/MM/YYYY")}</TableCell>
                                                                                                    <TableCell>
                                                                                                        <Box
                                                                                                            sx={{
                                                                                                                display: 'flex',
                                                                                                                alignItems: 'center',
                                                                                                                color: 'black',
                                                                                                                cursor: 'pointer',
                                                                                                                '&:hover .launchIcon': {
                                                                                                                    display: 'inline-flex',
                                                                                                                },
                                                                                                                '&:hover .leaveId': {
                                                                                                                    color: '#047FE0',
                                                                                                                    textDecoration: 'underline',
                                                                                                                },
                                                                                                            }}

                                                                                                        >
                                                                                                            <Typography
                                                                                                                fontSize={14}
                                                                                                                fontWeight={500}
                                                                                                                lineHeight={"14px"}
                                                                                                                letterSpacing={"0.17px"}
                                                                                                                className="leaveId"
                                                                                                                sx={{
                                                                                                                    textDecoration: 'none',
                                                                                                                }}
                                                                                                                onClick={() => handleRequestClick(requestData)}
                                                                                                            >
                                                                                                                {getApplicationNumber(requestData?.application_num)}
                                                                                                            </Typography>
                                                                                                            <LaunchIcon
                                                                                                                className="launchIcon"
                                                                                                                sx={{
                                                                                                                    fontSize: 16,
                                                                                                                    marginLeft: '4px',
                                                                                                                    display: 'none',
                                                                                                                    color: '#047FE0',
                                                                                                                }}
                                                                                                            />
                                                                                                        </Box>
                                                                                                    </TableCell>
                                                                                                    <TableCell>{titleCase(requestData?.type)}</TableCell>
                                                                                                    <TableCell>{getLeaveCount(requestData)}</TableCell>
                                                                                                    <TableCell>{getLeaveSetOff(requestData)}</TableCell>
                                                                                                    <TableCell>1</TableCell>
                                                                                                    <TableCell>
                                                                                                        {getLeaveStatus(requestData?.status, "chip")}
                                                                                                    </TableCell>
                                                                                                    <TableCell>
                                                                                                        {!!requestData?.processed_by ? <Box display={"flex"} alignItems={"center"} gap={"6px"} whiteSpace={"nowrap"}>
                                                                                                            <BackgroundLetterAvatars sx={{ width: 24, height: 24, minWidth: 24, fontSize: "13px", fontWeight: '500' }}
                                                                                                                user={requestData?.processed_by?.[0]}
                                                                                                            />
                                                                                                            <Typography fontSize={14} fontWeight={400} color={"dark.800"} lineHeight={"20px"} letterSpacing={"0.17px"}>{titleCase(requestData?.processed_by?.[0]?.name)}</Typography>
                                                                                                            <Typography fontSize={12} fontWeight={400} color={"dark.800"} lineHeight={"17px"} letterSpacing={"0.17px"}>at {getFormattedDate(requestData?.process_date, "DD/MM/YYYY")}</Typography>
                                                                                                        </Box> : <Box>-</Box>}
                                                                                                    </TableCell>
                                                                                                </TableRow>
                                                                                            ))}
                                                                                            {/* <TableRow sx={{
                                                                                            "td": {
                                                                                                fontSize: 14,
                                                                                                fontWeight: 400,
                                                                                                color: "dark.800",
                                                                                                lineheight: "20px",
                                                                                                letterSpacing: "0.17px",
                                                                                                padding: "10px 16px"
                                                                                            },
                                                                                            "&:hover": {
                                                                                                background: "#F7F7F7",
                                                                                                '.launchIcon': {
                                                                                                    display: 'inline-flex',
                                                                                                },
                                                                                                '.leaveId': {
                                                                                                    color: '#047FE0',
                                                                                                    textDecoration: 'underline',
                                                                                                },
                                                                                                '.leave-details': {
                                                                                                    opacity: 1,
                                                                                                    display: 'flex',
                                                                                                },
                                                                                            },
                                                                                        }}>
                                                                                            <TableCell>03/03/2024</TableCell>
                                                                                            <TableCell>
                                                                                                <Box
                                                                                                    sx={{
                                                                                                        display: 'flex',
                                                                                                        alignItems: 'center',
                                                                                                        color: 'black',
                                                                                                        cursor: 'pointer',
                                                                                                        '&:hover .launchIcon': {
                                                                                                            display: 'inline-flex',
                                                                                                        },
                                                                                                        '&:hover .leaveId': {
                                                                                                            color: '#047FE0',
                                                                                                            textDecoration: 'underline',
                                                                                                        },
                                                                                                    }}

                                                                                                >
                                                                                                    <Typography
                                                                                                        fontSize={14}
                                                                                                        fontWeight={500}
                                                                                                        lineHeight={"14px"}
                                                                                                        letterSpacing={"0.17px"}
                                                                                                        className="leaveId"
                                                                                                        sx={{
                                                                                                            textDecoration: 'none',
                                                                                                        }}
                                                                                                    >
                                                                                                        #0002
                                                                                                    </Typography>
                                                                                                    <LaunchIcon
                                                                                                        className="launchIcon"
                                                                                                        sx={{
                                                                                                            fontSize: 16,
                                                                                                            marginLeft: '4px',
                                                                                                            display: 'none',
                                                                                                            color: '#047FE0',
                                                                                                        }}
                                                                                                    />
                                                                                                </Box>
                                                                                            </TableCell>
                                                                                            <TableCell>#0002</TableCell>
                                                                                            <TableCell>1</TableCell>
                                                                                            <TableCell>Half Paid / Half Unpaid</TableCell>
                                                                                            <TableCell>1</TableCell>
                                                                                            <TableCell>
                                                                                                <Chip
                                                                                                    label="Approved"
                                                                                                    color="greentc"
                                                                                                    size="small"
                                                                                                    variant="outlined"
                                                                                                />
                                                                                            </TableCell>
                                                                                            <TableCell>
                                                                                                <Box display={"flex"} alignItems={"center"} gap={"6px"} whiteSpace={"nowrap"}>
                                                                                                    <BackgroundLetterAvatars sx={{ width: 24, height: 24, minWidth: 24, fontSize: "13px", fontWeight: '500' }}
                                                                                                    />
                                                                                                    <Typography fontSize={14} fontWeight={400} color={"dark.800"} lineHeight={"20px"} letterSpacing={"0.17px"}>John Doe</Typography>
                                                                                                    <Typography fontSize={12} fontWeight={400} color={"dark.800"} lineHeight={"17px"} letterSpacing={"0.17px"}>at 02/05/2024</Typography>
                                                                                                </Box>
                                                                                            </TableCell>
                                                                                        </TableRow> */}
                                                                                        </TableBody>
                                                                                    </Table>
                                                                                </TableContainer>
                                                                            </>
                                                                            :
                                                                            <Typography fontSize={14} fontWeight={400} lineHeight={"14px"} letterSpacing={"0.24px"} sx={{ textAlign: 'center' }}>
                                                                                No data found
                                                                            </Typography>}
                                                                    </Box>
                                                                </Collapse>
                                                            </TableCell>
                                                        </TableRow>
                                                    </React.Fragment>
                                                )) :
                                                <TableRow>
                                                    <TableCell
                                                        colSpan={15}
                                                        align="center"
                                                        sx={{ padding: "12px 16px;" }}>
                                                        <Typography
                                                            color={"secondary"}
                                                            display={"flex"}
                                                            alignItems={"center"}
                                                            justifyContent={"center"}
                                                            fontSize={13}
                                                            sx={{ opacity: "0.5", height: "200px" }}
                                                        >
                                                            No credit data found.
                                                        </Typography>
                                                    </TableCell>
                                                </TableRow>
                                    }
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </Box>
                </Box>
            </Box> : null}
        </>
    )
}

export default UserReports