import { Box, Typography } from "@mui/material";
import React from "react";
import DatePicker from "react-multi-date-picker";
import "react-multi-date-picker/styles/colors/purple.css";
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';

const MonthSelector = ({ label = "Month", value, onChange }) => {

    const handleDateChange = (date) => {
        if (date) {
            onChange(date);
        }
    };

    return (
        <div style={{ width: "100%" }}>
            <Typography fontSize={12} color={"dark.600"} fontWeight={400} letterSpacing={"0.15px"} display={"block"}>
                {label}
            </Typography>
            <Box position={"relative"} sx={{
                'svg': {
                    position: "absolute",
                    right: 0,
                    top: '4px',
                    color: 'rgba(0, 0, 0, 0.56)'
                }
            }}>
                <DatePicker
                    value={value}
                    onChange={handleDateChange}
                    onlyMonthPicker
                    portal
                    format="MMMM YYYY"
                    style={{
                        fontSize: "14px",
                        fontWeight: '400',
                        width: "100%",
                        border: "none",
                        borderBottom: "1px solid rgba(81, 95, 124, 0.25)",
                        borderRadius: "0px",
                        margin: '0px',
                        padding: '4px 30px 5px 0px',
                        boxShadow: 'none',
                        height: '32px',
                    }}
                />
                <ArrowDropDownIcon />
            </Box>
        </div>
    );
};

export default MonthSelector;
