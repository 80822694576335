/* eslint-disable react-hooks/exhaustive-deps */
import React, { forwardRef, useEffect, useImperativeHandle, useState } from "react";
import {
  Box,
  Card,
  Checkbox,
  FormControlLabel,
  Grid,
  IconButton,
  MenuItem,
  Stack,
  Typography,
} from "@mui/material";
import Button from "../../../components/Button";
import DeleteIcon from "@mui/icons-material/Delete";
import { useNavigate } from "react-router-dom";
import { useAlert } from "../../../hook/useAlert";
import API from "../../../axios/api";
import axiosInstance from "../../../axios";
import URLS from "../../../routes/urls";
import { ON_BOARDING_STEPS } from "../../../constants/default-values";
import Input from "../../../components/Input";
import { clearAllCookies, generateUniqueId, getFormattedDate, getFullName, getInputId, getMissingKeys } from "../../../utils";
import { titleCase, validateEmail } from "../../../utils/validation";
import CircularLoader from "../../../components/CircularLoader";
import { handleCheckIsEmailUnique } from "../../../axios/service";
import { usePageTitle } from "../../../hook/useTitle";
import { setUserOnBoardingStep } from "../../../redux/slices/userSlice";
import { useDispatch } from "react-redux";
import secureLocalStorage from "react-secure-storage";
import SingleDatePicker from "../../../components/SingleDatePicker";
import CalendarTodayIcon from "@mui/icons-material/CalendarToday";

const initialUserObjectData = {
  // id: null,
  email: "",
  name: "",
  active: true,
  current_user_role_id: null,
  current_user_type_id: null,
  org_work_type_setting_id: null,
  is_admin: true,
  unique_id: generateUniqueId(),
  enable_sys_generated_leave: false,
  joining_date: null,
  external: false
};

const initialErrorObject = {
  email: "",
  name: "",
  current_user_role_id: "",
  current_user_type_id: "",
  org_work_type_setting_id: "",
  joining_date: ""
};

const UserSettingStep = forwardRef(({ setOnBoardingStep, currentUser, isEditField, setIsEditField, handleShowSaveModal }, ref) => {
  const showAlert = useAlert();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { setPageTitle } = usePageTitle();
  const [formData, setFormData] = useState([]);
  const [optionalformData, setOptionalFormData] = useState([]);
  const [loading, setLoading] = useState({
    formSubmitting: false,
    pageLoading: true,
  });
  const [errors, setErrors] = useState([]);
  const [constantValues, setConstantValues] = useState({
    userRolesData: [],
    workTypeData: [],
    userTypeData: [],
  });
  const [leaveSettingData, setLeaveSettingData] = useState({});
  const [externalWorkTypeIds, setExternalWorkTypeIds] = useState([]);
  const [externalRoles, setExternalRoles] = useState([]);

  setPageTitle("Add your Users");
  const findIndexWithUid = (id) =>
    formData.findIndex((data) => data.unique_id === id);

  const fetchData = async () => {
    try {
      const [
        leaveSettingsResponse,
        userRolesResponse,
        workTypesResponse,
        userTypesResponse,
        userSettingResponse,
      ] = await Promise.all([
        axiosInstance.get(
          API.organisationLeaveSettings(currentUser.organization_id)
        ),
        axiosInstance.get(API.organisationUserRoles(currentUser?.organization_id)),
        axiosInstance.get(API.getWorkTypesByOrganisationId(currentUser?.organization_id)),
        axiosInstance.get(API.getUserTypes),
        axiosInstance.get(API.organisationUserSetting(currentUser?.organization_id)),
      ]);
      let isSystemGeneratedLeaveEnabled = false;
      if (leaveSettingsResponse.status === 200) {
        setLeaveSettingData(leaveSettingsResponse?.data?.data);
        isSystemGeneratedLeaveEnabled = leaveSettingsResponse?.data?.data?.enable_sys_generated_leave;
      }

      if (userRolesResponse.status === 200) {
        setConstantValues((prev) => ({
          ...prev,
          userRolesData: userRolesResponse.data.data.map(item => ({
            ...item,
            isVisible: true,
            isSelected: true,
          })),
        }));
        setExternalRoles(userRolesResponse?.data?.data?.filter(roleData => roleData?.external)?.map((data => data?.id)))
      }

      if (workTypesResponse.status === 200) {
        setConstantValues((prev) => ({
          ...prev,
          workTypeData: workTypesResponse.data.data,
        }));
      }

      if (userTypesResponse.status === 200) {
        setConstantValues((prev) => ({
          ...prev,
          userTypeData: userTypesResponse.data.data.map(item => ({
            ...item,
            isVisible: true,
          })),
        }));
      }

      if (userSettingResponse.status === 200) {
        let newResponse = userSettingResponse.data.data.map((userData, index) => ({
          ...userData,
          name: getFullName(userData).trim(),
          unique_id: generateUniqueId(),
          enable_sys_generated_leave: isSystemGeneratedLeaveEnabled ? true : userData.enable_sys_generated_leave,
        }));
        newResponse.sort((a, b) => {
          if (a.is_primary_admin === b.is_primary_admin) {
            return 0;
          }
          return a.is_primary_admin ? -1 : 1;
        });
        initialUserObjectData.enable_sys_generated_leave = isSystemGeneratedLeaveEnabled;
        setFormData([...newResponse, initialUserObjectData]);
        setOptionalFormData([...newResponse, initialUserObjectData]);
      }

      setExternalWorkTypeIds(workTypesResponse.data.data?.filter(workTypeData => workTypeData?.external)?.map((data => data?.id)))
      setLoading((prev) => ({ ...prev, pageLoading: false }));

    } catch (error) {
      console.error(error);
      setLoading((prev) => ({ ...prev, pageLoading: false }));
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  useImperativeHandle(ref, () => ({
    handleSubmitUserSettings: () => handleSubmitUserSettings(ON_BOARDING_STEPS.step4)
  }));

  const handleChangeFunction = (unique_id, event, index) => {
    setIsEditField(true);
    // handleBlurFunction(event, index);
    const { name, value } = event.target;
    const findIndex = findIndexWithUid(unique_id);
    const updatedUserData = [...formData];
    const updatedOptionalUserData = [...optionalformData]
    let updatedObject = {
      ...updatedUserData[findIndex],
      [name]: value,
    };
    let updatedOptionalObject = {
      ...updatedOptionalUserData[findIndex],
    };
    let adminType = constantValues?.userTypeData?.find(item => item?.name?.toLowerCase() === "admin");
    if (name === "current_user_type_id") {
      let generalType = constantValues?.userTypeData?.find(item => item?.name?.toLowerCase() === "general");
      let adminRole = constantValues?.userRolesData?.find((value) => value?.name?.toLowerCase() === "administrator");
      if (adminType?.id === value) {
        updatedObject['current_user_role_id'] = adminRole?.id;
        updatedObject['is_admin'] = true;
        updatedObject['is_admin_user'] = true;
        updatedObject['is_general_user'] = false;
        if (externalWorkTypeIds?.includes(updatedObject?.org_work_type_setting_id)) updatedObject['org_work_type_setting_id'] = null;
        setConstantValues((prev) => ({
          ...prev,
          userRolesData: prev?.userRolesData?.map(item => item?.id === adminRole?.id ? { ...item, isVisible: true, isSelected: true, } : { ...item, isVisible: false })
        }));
        let updatedError = [...errors];
        updatedError[index] = { ...updatedError[index], current_user_role_id: "" };
        setErrors(updatedError);
      } else if (generalType?.id === value) {
        updatedObject['is_admin'] = false;
        updatedObject['is_admin_user'] = false;
        updatedObject['is_general_user'] = true;
        updatedObject['current_user_role_id'] = updatedObject?.current_user_role_id === adminRole?.id ? null : updatedObject?.current_user_role_id;
        setConstantValues((prev) => ({
          ...prev,
          userRolesData: prev?.userRolesData?.map(item => item?.id !== adminRole?.id ? { ...item, isVisible: true } : { ...item, isVisible: false })
        }));
      } else {
        setConstantValues((prev) => ({
          ...prev,
          userRolesData: prev?.userRolesData?.map(item => item?.id !== adminRole?.id ? { ...item, isVisible: true } : { ...item, isVisible: false })
        }));
        updatedObject['is_admin'] = true;
        updatedObject['is_admin_user'] = false;
        updatedObject['is_general_user'] = false;
        updatedObject['current_user_role_id'] = updatedObject?.current_user_role_id === adminRole?.id ? null : updatedObject?.current_user_role_id;
      }
    }
    if (name === "org_work_type_setting_id") {
      let updatedErrors = [...errors];
      const error = { ...(updatedErrors[findIndex] || initialErrorObject) };
      if (externalWorkTypeIds?.includes(value)) {
        const updatedRoleId = () => {
          let currentSelectedRoleId = updatedObject?.current_user_role_id;
          if (currentSelectedRoleId && updatedObject?.id) {
            return currentSelectedRoleId ? currentSelectedRoleId : (updatedOptionalObject?.org_work_type_setting_id === value) ? updatedOptionalObject?.current_user_role_id : currentSelectedRoleId
          } else if (currentSelectedRoleId && !updatedObject?.id) {
            return externalRoles?.includes(currentSelectedRoleId) ? currentSelectedRoleId : null;
          } else {
            return (updatedOptionalObject?.org_work_type_setting_id === value && updatedObject?.id) ? updatedOptionalObject?.current_user_role_id : currentSelectedRoleId
          }
        }

        if (adminType?.id === updatedObject?.current_user_type_id) {
          updatedObject = {
            ...updatedObject,
            current_user_type_id: null,
            current_user_role_id: null,
            is_admin_user: false,
            is_admin: false
          }
        }
        updatedObject = {
          ...updatedObject,
          current_user_role_id: updatedRoleId(),
          external: true,
        }
        if (!externalRoles?.includes(updatedObject?.current_user_role_id)) {
          updatedObject['current_user_role_id'] = null
        }

        if (!externalRoles?.length) {
          error['current_user_role_id'] = 'No external user role exist'
        }

        if (updatedObject?.current_user_role_id && error['current_user_role_id']?.includes("Required")) {
          error['current_user_role_id'] = "";
        }
      } else {
        if (error['current_user_role_id']?.includes('No external user role exist')) {
          error['current_user_role_id'] = null
        }
        const updatedRoleId = () => {
          let currentSelectedRoleId = updatedObject?.current_user_role_id;
          if (currentSelectedRoleId && updatedObject?.id) {
            return currentSelectedRoleId ? currentSelectedRoleId : (updatedOptionalObject?.org_work_type_setting_id === value) ? updatedOptionalObject?.current_user_role_id : currentSelectedRoleId
          } else if (currentSelectedRoleId && !updatedObject?.id) {
            return externalRoles?.includes(currentSelectedRoleId) ? null : currentSelectedRoleId;
          } else {
            return (updatedOptionalObject?.org_work_type_setting_id === value && updatedObject?.id) ? updatedOptionalObject?.current_user_role_id : currentSelectedRoleId
          }
        }
        updatedObject = {
          ...updatedObject,
          current_user_role_id: updatedRoleId(),
          external: false,
        }
        if (externalRoles?.includes(updatedObject?.current_user_role_id)) {
          updatedObject['current_user_role_id'] = null
        }
        if (updatedObject?.current_user_role_id && error['current_user_role_id']?.includes("Required")) {
          error['current_user_role_id'] = "";
        }
      }
      updatedErrors[findIndex] = error
      setErrors(updatedErrors);
    }

    updatedUserData[findIndex] = updatedObject;

    if (value !== "" && index === updatedUserData.length - 1) {
      initialUserObjectData.unique_id = generateUniqueId();
      initialUserObjectData.enable_sys_generated_leave = leaveSettingData?.enable_sys_generated_leave;
      updatedUserData.push(initialUserObjectData);
      updatedOptionalUserData.push(initialUserObjectData);
    }

    setFormData(updatedUserData);
    setOptionalFormData(updatedOptionalUserData);
  };

  const handleBlurFunction = async (e, index) => {
    let { name, value } = e.target;
    let updatedErrors = [...errors];
    const error = { ...(updatedErrors[index] || initialErrorObject) };
    if (!value) {
      if (name === "current_user_role_id") {
        error[name] = error[name]?.length > 1 ? error[name] : "Required"
      } else {
        error[name] = "Required";
      }
    } else if (name === "email") {
      const isEmailUnique = await handleCheckIsEmailUnique(value);
      const checkExistUser = formData.filter(
        (obj) => value && obj.email && obj.email === value
      );
      const findExistEmail =
        index === 0
          ? formData.slice(1).find((data) => data.email === value)
            ?.unique_id
          : formData[index]?.unique_id;

      const isInvalidEmail =
        value?.trim() && !validateEmail(value?.trim());

      if (isInvalidEmail) {
        error[name] = "Invalid email address";
      } else if (isEmailUnique || checkExistUser.length > 1) {
        error[name] = "Email is already exist";
      } else if (!findExistEmail) {
        error[name] = null;
      } else {
        error[name] = null;
      }
    } else {
      error[name] = null;
    }
    let isShowError =
      Object?.values(getMissingKeys(formData[index]))?.filter(
        (value) => !!value
      )?.length !==
      [
        "email",
        "name",
        "current_user_role_id",
        "current_user_type_id",
        "org_work_type_setting_id",
        "joining_date"
      ].length;
    updatedErrors[index] = isShowError ? error : initialErrorObject;
    setErrors(updatedErrors);
  };

  const handleRemoveUser = (userData, index) => {
    const updatedUserData = [...formData];
    if (userData?.id) {
      const changeDeleteStatus = updatedUserData?.map((user) => {
        return user?.unique_id === userData?.unique_id
          ? { ...user, active: false }
          : { ...user };
      });
      setFormData(changeDeleteStatus);
      setOptionalFormData(prev => prev?.map((user) => {
        return user?.unique_id === userData?.unique_id
          ? { ...user, active: false }
          : { ...user };
      }));
      setIsEditField(true)
    } else {
      const removedObject = updatedUserData?.filter(
        (data) => data.unique_id !== userData?.unique_id
      );
      setFormData(removedObject);
      setOptionalFormData(prev => prev?.filter(
        (data) => data.unique_id !== userData?.unique_id
      ));
    }
    setErrors((prev) => prev?.filter((_, _index) => _index !== index));
  };

  const validateFormData = (formData) => {
    let validationMessage = "Required";
    let isValid = true;
    const newErrors = [];

    formData.forEach((userData, index, arrayData) => {
      const errors = {};
      const checkExistEmail = arrayData?.filter(
        (data) => !!data?.email && data?.email === userData?.email
      );

      const isInvalidEmail =
        userData?.email?.trim() && !validateEmail(userData?.email?.trim());
      if (
        userData?.current_user_role_id ||
        userData?.name?.trim() ||
        userData?.email?.trim() ||
        userData?.org_work_type_setting_id ||
        userData?.current_user_type_id ||
        userData?.joining_date
      ) {
        if (!userData?.current_user_role_id) {
          errors.current_user_role_id = validationMessage;
          if (externalWorkTypeIds?.includes(userData?.org_work_type_setting_id) && !externalRoles?.length) {
            errors.current_user_role_id = 'No external user role exist'
          }
          isValid = false;
        }

        if (!userData?.name?.trim()) {
          errors.name = validationMessage;
          isValid = false;
        }

        if (!userData?.email?.trim()) {
          errors.email = validationMessage;
          isValid = false;
        } else if (isInvalidEmail) {
          errors.email = "Invalid email address";
        } else if (checkExistEmail.length > 1) {
          if (index !== 0) {
            errors.email = "Email is already exist";
          }
        }

        if (!userData?.org_work_type_setting_id) {
          errors.org_work_type_setting_id = validationMessage;
          isValid = false;
        }

        if (!userData?.current_user_type_id) {
          errors.current_user_type_id = validationMessage;
          isValid = false;
        }
        if (!userData?.joining_date) {
          errors.joining_date = validationMessage;
          isValid = false;
        }
      }
      newErrors[index] = errors;
    });

    setErrors(newErrors);
    return isValid;
  };

  const handleSubmitUserSettings = async (redirectStep) => {
    try {
      setLoading((prev) => ({ ...prev, formSubmitting: true }));
      if (validateFormData(formData)) {
        let filterData = formData?.filter(
          (data) => !!data?.current_user_type_id
        );
        let updatedUser = filterData?.map((userData => {
          return {
            ...userData,
            joining_date: getFormattedDate(userData?.joining_date, "YYYY-MM-DD"),
            name: userData?.name?.trim()
          }
        }))
        let body = { org_users: updatedUser, is_previous: redirectStep === ON_BOARDING_STEPS.step4 };
        const response = await axiosInstance.put(
          API.organisationUserSetting(currentUser?.organization_id),
          body
        );
        if (response.status === 200) {
          if (redirectStep === ON_BOARDING_STEPS.step4) {
            setLoading((prev) => ({ ...prev, formSubmitting: false }));
            showAlert(response.data?.message);
            navigate(`${URLS.onBoarding}/${redirectStep}`, {
              replace: true,
            });
            setOnBoardingStep(redirectStep);
            dispatch(setUserOnBoardingStep(redirectStep));
            return true;
          } else {
            showAlert("Onboarding completed successfully, please login again to start using the app.");
            secureLocalStorage.clear();
            clearAllCookies();
            navigate(URLS.Login, {
              replace: true,
            });
          }
        }
        setLoading((prev) => ({ ...prev, formSubmitting: false }));
        return false;
      }
      setLoading((prev) => ({ ...prev, formSubmitting: false }));
    } catch (error) {
      console.error(error);
      setLoading((prev) => ({ ...prev, formSubmitting: false }));
    }
  };

  const handlePreviousStep = () => {
    if (isEditField) {
      handleShowSaveModal();
    } else {
      navigate(`${URLS.onBoarding}/${ON_BOARDING_STEPS.step4}`, {
        replace: true,
      });
      setOnBoardingStep(ON_BOARDING_STEPS.step4);
    }
  };

  const handleChangeJoiningDate = (unique_id, date, index) => {
    setIsEditField(true)
    const findIndex = findIndexWithUid(unique_id);
    const updatedUserData = [...formData];
    const updatedObject = {
      ...updatedUserData[findIndex],
      joining_date: new Date(date),
    };
    updatedUserData[findIndex] = updatedObject;
    if (date !== null && index === updatedUserData.length - 1) {
      initialUserObjectData.unique_id = generateUniqueId();
      initialUserObjectData.enable_sys_generated_leave = leaveSettingData?.enable_sys_generated_leave;
      updatedUserData.push(initialUserObjectData);
    }
    setFormData(updatedUserData);
    setErrors((prev) => ({
      ...prev,
      joining_date: "",
    }))
    let updatedError = [...errors];
    updatedError[index] = { ...updatedError[index], joining_date: "" };
    setErrors(updatedError);
  }

  const getFilteredWorkTypeList = (index) => {
    let adminTypeId = constantValues?.userTypeData?.find(item => item?.name?.toLowerCase() === "admin")?.id;

    if (adminTypeId === formData[index]?.current_user_type_id) {
      return constantValues?.workTypeData?.filter((workData) => !workData?.external)
    } else {
      return constantValues?.workTypeData
    }
  }

  const getFilteredRoleList = (isExternal, userData) => {
    let adminTypeId = constantValues?.userTypeData?.find(item => item?.name?.toLowerCase() === "admin")?.id;
    let adminRoleId = constantValues?.userRolesData?.find((value) => value?.name?.toLowerCase() === "administrator")?.id;

    return constantValues?.userRolesData?.filter((userDetails) => {
      if (adminTypeId !== userData?.current_user_type_id && userDetails?.id === adminRoleId) {
        return false;
      } else {
        return userDetails?.external === isExternal
      }
    });
  }

  const getFilteredUserTypeList = (isExternal) => {
    if (isExternal) {
      return constantValues?.userTypeData?.filter((useData) => useData?.name?.toLowerCase() !== "admin")
    }
    return constantValues?.userTypeData;
  }

  return (
    <Box display="flex" flexDirection="column" width={"100%"}>
      <Stack spacing={4} sx={{ flexBasis: "100%" }}>
        <Typography color="secondary.800" fontSize={20}>
          Create a Organisation (Step 5 of 5)
        </Typography>
        <Grid item>
          <Typography
            color="secondary"
            fontSize={32}
            lineHeight={"44px"}
            mb={2}
          >
            Add your Users
          </Typography>
          <Typography
            variant="body2"
            color="dark.800"
            width={600}
            maxWidth={"100%"}
          >
            Your User will have access based on respective roles. Users will be
            invited to the organisation. You can still add/manage from the User
            settings later.
          </Typography>
        </Grid>
        {loading.pageLoading ? (
          <CircularLoader />
        ) : (
          <Box
            sx={{
              display: "flex",
              flexWrap: "wrap",
              width: "100%",
            }}
          >
            <Stack spacing={1}>
              {formData?.map((userData, index, array) =>
                userData?.active ? (
                  <Card key={index} sx={{ boxShadow: "none", display: "flex", overflow: "visible" }}>
                    <Typography
                      textAlign={"center"}
                      fontSize={14}
                      sx={{ minWidth: "30px", paddingTop: "10px" }}
                    >
                      {index + 1}
                    </Typography>
                    <Card
                      sx={{
                        padding: "24px 24px 0px 24px",
                        bgcolor: "offWhite",
                        boxShadow: "none",
                        flex: "1",
                        overflow: 'visible'
                      }}
                    >
                      <Grid container flexWrap={"nowrap"}>
                        <Grid item container>
                          <Grid
                            item
                            pr={3}
                            xs={6}
                            md={4}
                            sx={{
                              // flex: "0 0 33.33%",
                              // maxWidth: "33.33%",
                              marginBottom: "30px",
                            }}
                          >
                            <Input
                              id={getInputId("user-type", index)}
                              name={"current_user_type_id"}
                              select
                              type="select"
                              label="User Type"
                              defaultValue="Admin"
                              variant="standard"
                              sx={{ width: "100%" }}
                              onChange={(event) =>
                                handleChangeFunction(
                                  userData?.unique_id,
                                  event,
                                  index
                                )
                              }
                              onBlur={(e) => handleBlurFunction(e, index)}
                              value={userData?.current_user_type_id}
                              error={!!errors[index]?.current_user_type_id}
                              helperText={errors[index]?.current_user_type_id}
                              required={index !== array?.length - 1}
                              disabled={userData?.is_primary_admin}
                            >
                              {getFilteredUserTypeList(userData?.external)?.map((option) => (
                                <MenuItem key={option.id} value={option.id}>
                                  {titleCase(option.name)}
                                </MenuItem>
                              ))}
                            </Input>
                          </Grid>
                          <Grid
                            item
                            pr={3}
                            xs={6}
                            md={4}
                            sx={{
                              // flex: "0 0 33.33%",
                              // maxWidth: "33.33%",
                              marginBottom: "30px",
                            }}
                          >
                            <Input
                              id={getInputId("name", index)}
                              name={"name"}
                              label="Full Name"
                              variant="standard"
                              sx={{ width: "100%" }}
                              onChange={(event) =>
                                handleChangeFunction(
                                  userData?.unique_id,
                                  event,
                                  index
                                )
                              }
                              onBlur={(e) => handleBlurFunction(e, index)}
                              value={getFullName(userData)}
                              error={!!errors[index]?.name}
                              helperText={errors[index]?.name}
                              required={index !== array?.length - 1}
                            />
                          </Grid>
                          <Grid
                            item
                            pr={3}
                            xs={6}
                            md={4}
                            sx={{
                              // flex: "0 0 33.33%",
                              // maxWidth: "33.33%",
                              marginBottom: "30px",
                            }}
                          >
                            <Input
                              id={getInputId("email", index)}
                              name={"email"}
                              type="email"
                              label="Email Address"
                              variant="standard"
                              sx={{ width: "100%" }}
                              onChange={(event) =>
                                handleChangeFunction(
                                  userData?.unique_id,
                                  event,
                                  index
                                )
                              }
                              onBlur={(e) => handleBlurFunction(e, index)}
                              value={userData?.email}
                              error={!!errors[index]?.email}
                              helperText={errors[index]?.email}
                              required={index !== array?.length - 1}
                              disabled={userData?.is_primary_admin}
                            />
                          </Grid>
                          <Grid
                            item
                            pr={3}
                            xs={6}
                            md={4}
                            sx={{
                              // flex: "0 0 33.33%",
                              // maxWidth: "33.33%",
                              marginBottom: "30px",
                            }}
                          >
                            <Input
                              id={getInputId("work-type", index)}
                              name={"org_work_type_setting_id"}
                              select
                              type="select"
                              label="Work Type"
                              defaultValue="In-Office"
                              variant="standard"
                              sx={{ width: "100%" }}
                              onChange={(event) =>
                                handleChangeFunction(
                                  userData?.unique_id,
                                  event,
                                  index
                                )
                              }
                              onBlur={(e) => handleBlurFunction(e, index)}
                              value={userData?.org_work_type_setting_id}
                              error={!!errors[index]?.org_work_type_setting_id}
                              helperText={
                                errors[index]?.org_work_type_setting_id
                              }
                              required={index !== array?.length - 1}
                            >
                              {getFilteredWorkTypeList(index)?.map((option) => (
                                <MenuItem key={option.id} value={option.id}>
                                  {option.name}
                                </MenuItem>
                              ))}
                            </Input>
                          </Grid>
                          <Grid
                            item
                            pr={3}
                            xs={6}
                            md={4}
                            sx={{
                              // flex: "0 0 33.33%",
                              // maxWidth: "33.33%",
                              marginBottom: "30px",
                            }}
                          >
                            <Input
                              select
                              type="select"
                              label="User Role"
                              variant="standard"
                              id={getInputId("user-role", index)}
                              name={"current_user_role_id"}
                              sx={{
                                width: "100%",
                                "& .MuiPopover-paper": {
                                  boxShadow:
                                    "0px 2px 4px -1px rgba(0, 0, 0, 0.20), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12)",
                                },
                              }}
                              onChange={(event) =>
                                handleChangeFunction(
                                  userData?.unique_id,
                                  event,
                                  index
                                )
                              }
                              onBlur={(e) => handleBlurFunction(e, index)}
                              value={userData?.current_user_role_id}
                              error={!!errors[index]?.current_user_role_id}
                              helperText={errors[index]?.current_user_role_id}
                              required={index !== array?.length - 1}
                              disabled={(userData?.is_admin_user || userData?.is_primary_admin) || false}
                              InputLabelProps={{
                                shrink: userData?.is_admin_user || !!userData?.current_user_role_id,
                                htmlFor: getInputId("user-role", index),
                              }}
                            >
                              {getFilteredRoleList(userData?.external, userData)?.map((option) => (
                                <MenuItem
                                  selected={option?.isSelected || false}
                                  disabled={option.id === userData?.current_user_role_id}
                                  key={option.id}
                                  value={option.id}
                                  sx={{
                                    display: !option?.isVisible ? "none" : "block",
                                    "&:hover": {
                                      background: "rgba(4, 127, 224, 0.1)",
                                    },
                                  }}
                                >
                                  {titleCase(option.name)}
                                </MenuItem>
                              ))}
                            </Input>
                          </Grid>
                          <Grid item xs={6} pr={3}
                            md={4}
                            maxWidth={"100%"}
                            flex={1}
                            sx={{
                              input: {
                                width: "100%",
                                height: 30,
                                border: "none !important",
                                boxShadow: "none !important",
                                borderRadius: "0px",
                                paddingBottom: "6px",
                                paddingRight: "28px",
                                borderBottom:
                                  "1px solid rgba(0, 0, 0, 0.42) !important",
                                margin: "0",
                                position: "relative",
                                background: "transparent",
                                color: "dark.800",
                                fontSize: 16,
                                fontWeight: 400,
                                lineHeight: "24px",
                                letterSpacing: "0.15px",
                              },
                              "input:hover": {
                                borderBottomColor:
                                  "#333333 !important",
                              },
                              "input:focus": {
                                borderBottomColor:
                                  "#047FE0 !important",
                              },
                              ".rmdp-container": {
                                minWidth: "100px",
                              },
                            }}
                          >
                            <Typography
                              id="demo-simple-select-standard-label"
                              fontSize={12}
                              fontWeight={400}
                              color={errors[index]?.joining_date ? "error.main" : "dark.600"}
                              sx={{
                                width: "100%",
                                letterSpacing: "0.15px",
                                marginTop: "-3px",
                                marginBottom: "2px",
                              }}
                            >
                              Joining Date *
                            </Typography>
                            <Box sx={{ position: 'relative' }}>
                              <CalendarTodayIcon
                                sx={{
                                  fontSize: 20,
                                  color: "rgba(0, 0, 0, 0.56)",
                                  position: 'absolute',
                                  right: '0px',
                                  top: '3px'
                                }}
                              />
                              <SingleDatePicker
                                value={userData?.joining_date !== null ? new Date(userData?.joining_date) : ''}
                                onChange={(value) => {
                                  handleChangeJoiningDate(
                                    userData?.unique_id,
                                    value,
                                    index)
                                }}
                                name={`joining_date`}
                                placeholder='Select Date'
                                isMultiple={false}
                                isEnableAllDate={true}
                              />
                            </Box>
                            {!!errors[index]?.joining_date ? (
                              <Typography sx={{ color: "red.main", fontSize: "12px" }}>{errors[index]?.joining_date}</Typography>
                            ) : null}
                          </Grid>
                          <Grid
                            item
                            pr={3}
                            xs={6}
                            md={4}
                            sx={{
                              // flex: "0 0 33.33%",
                              // maxWidth: "33.33%",
                              marginBottom: "30px",
                            }}
                          >
                            <FormControlLabel
                              value="end"
                              name="is_admin"
                              id={getInputId("is_admin", index)}
                              control={
                                <Checkbox checked={userData?.is_admin} />
                              }
                              label="Access to Admin Panel"
                              labelPlacement="end"
                              sx={{
                                padding: "0px 9px 9px",
                                '.MuiCheckbox-sizeMedium': {
                                  minWidth: '42px'
                                }
                              }}
                              disabled={(userData?.is_primary_admin || userData?.is_general_user || userData?.is_admin_user) || false}
                              onChange={(event, checked) =>
                                handleChangeFunction(
                                  userData?.unique_id,
                                  {
                                    target: {
                                      name: "is_admin",
                                      value: checked,
                                    },
                                  },
                                  index
                                )
                              }
                            />
                          </Grid>

                          {leaveSettingData?.enable_sys_generated_leave ?
                            <Grid
                              item
                              pr={3}
                              xs={6}
                              md={8}
                              sx={{
                                // flex: "0 0 33.33%",
                                // maxWidth: "33.33%",
                                marginBottom: "30px",
                              }}
                            >
                              <FormControlLabel
                                name="enable_sys_generated_leave"
                                id={getInputId("enable_sys_generated_leave", index)}
                                value="end"
                                control={
                                  <Checkbox checked={userData?.enable_sys_generated_leave} />
                                }
                                label="Enable System Generated Leaves for this user"
                                labelPlacement="end"
                                sx={{
                                  padding: "0px 9px 9px",
                                  '.MuiCheckbox-sizeMedium': {
                                    minWidth: '42px'
                                  }
                                }}
                                onChange={(event, checked) =>
                                  handleChangeFunction(
                                    userData?.unique_id,
                                    {
                                      target: {
                                        name: "enable_sys_generated_leave",
                                        value: checked,
                                      },
                                    },
                                    index
                                  )
                                }
                              />
                            </Grid>
                            : null}
                          {/* {!userData?.is_primary_admin ? <Grid
                            item
                            // pr={3}
                            xs={6}
                            sm={12}
                            sx={{
                              // flex: "0 0 33.33%",
                              // maxWidth: "33.33%",
                              marginBottom: "30px",
                            }}
                          >
                            <FormControlLabel
                              name="external"
                              id={getInputId("external", index)}
                              value="end"
                              control={
                                <Checkbox checked={userData?.external} />
                              }
                              label="External Work Type"
                              labelPlacement="end"
                              sx={{
                                padding: "0px 9px 9px",
                                '.MuiCheckbox-sizeMedium': {
                                  minWidth: '42px'
                                }
                              }}
                              onChange={(event, checked) =>
                                handleChangeFunction(
                                  userData?.unique_id,
                                  {
                                    target: {
                                      name: "external",
                                      value: checked,
                                    },
                                  },
                                  index
                                )
                              }
                              disabled={!showExternal}
                            />
                          </Grid> : null} */}
                        </Grid>
                        <Grid item>
                          <Box sx={{ minWidth: "40px" }}>
                            {index !== formData.length - 1 ? (
                              <IconButton
                                size="small"
                                sx={{ width: "40px", height: "40px" }}
                                onClick={() =>
                                  handleRemoveUser(userData, index)
                                }
                                disabled={
                                  userData?.is_primary_admin ||
                                  userData?.id === currentUser?.id
                                }
                              >
                                <DeleteIcon fontSize="small" />
                              </IconButton>
                            ) : null}
                          </Box>
                        </Grid>
                      </Grid>
                    </Card>
                  </Card>
                ) : null
              )}
            </Stack>
          </Box>
        )}
      </Stack>
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        mt={4}
      >
        <Button
          variant="text"
          color="secondary"
          sx={{ fontSize: "15px", textTransform: "none" }}
          onClick={handlePreviousStep}
        >
          Previous
        </Button>
        <Box
          display="flex"
          justifyContent="flex-end"
          alignItems="center"
          flexBasis="100%"
        >
          <Button
            variant="contained"
            color="primary"
            size="large"
            type="button"
            sx={{
              boxShadow: "0",
              color: "white",
              fontSize: "15px",
              textTransform: "none",
              marginLeft: "20px",
              "&:hover": { boxShadow: "0" },
            }}
            onClick={() => handleSubmitUserSettings(ON_BOARDING_STEPS.completed)}
            isButtonLoading={loading?.formSubmitting}
          >
            Save
          </Button>
        </Box>
      </Box>
    </Box>
  );
});

export default UserSettingStep;
