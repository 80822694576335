/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import {
    Box,
    Button,
    ButtonGroup,
    Divider,
    FormControl,
    Grid,
    InputLabel,
    MenuItem,
    Select,
    Stack,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    Tooltip,
    tooltipClasses,
    Typography,
} from "@mui/material";
// import ListAltRoundedIcon from "@mui/icons-material/ListAltRounded";
// import CalendarTodayRoundedIcon from "@mui/icons-material/CalendarTodayRounded";
import {
    LEAVE_GROUP_BY_STATUS,
    LEAVE_STATUS,
    MY_WFH_GROUP_FILTER,
    REQUEST_TYPE,
    STATUS_BY_FILTER,
} from "../../../../constants/default-values";
import useAuthentication from "../../../../hook/useAuthentication";
import axiosInstance from "../../../../axios";
import API from "../../../../axios/api";
import CircularLoader from "../../../../components/CircularLoader";
import CustomModal from "../../../../components/CustomModal";
import moment from "moment";
import { getInputId, getLeaveDateArray, isInPastDays, isMoreThan30Days, isWithinNext30Days, isWithinNext7Days, sortRequestArrayByDate, specialRequestList, titleCase } from "../../../../utils";
import { useAlert } from "../../../../hook/useAlert";
import { useNavigate } from "react-router-dom";
import URLS from "../../../../routes/urls";
import useQuery from "../../../../hook/useQuery";
import WFHCalendarView from "./WHFListing/WFHCalendarView";
import WHFListTable from "./WHFListing/WHFListTable";
import InfoIcon from '@mui/icons-material/Info';
import { styled } from "@mui/styles";

const HtmlTooltip = styled(({ className, ...props }) => (
    <Tooltip arrow {...props} classes={{ popper: className }} />
))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
        padding: "16px",
        borderRadius: "5px",
        background: "#444444",
        maxWidth: 530,
    },
}));

const MyWFHRequests = () => {
    const { getCurrentUser } = useAuthentication();
    const { getQueryParams, setQueryParams, deleteQueryParams } = useQuery();
    const currentUser = getCurrentUser();
    const navigate = useNavigate();
    let view = getQueryParams("view");
    let groupBy = getQueryParams("groupBy") && Object.keys(MY_WFH_GROUP_FILTER)?.includes(getQueryParams("groupBy")) ? getQueryParams("groupBy") : Object.keys(MY_WFH_GROUP_FILTER)[0];
    let status = getQueryParams("status") && Object.keys(LEAVE_GROUP_BY_STATUS)?.includes(getQueryParams("status")) ? getQueryParams("status") : Object.keys(LEAVE_GROUP_BY_STATUS)[0];
    let filterStatus = getQueryParams("filterStatus") && Object.values(STATUS_BY_FILTER)?.includes(getQueryParams("filterStatus")) ? getQueryParams("filterStatus") : Object.values(STATUS_BY_FILTER)[0];
    const [leaveDataList, setLeaveDataList] = useState({});
    const [othersLeaveDataForCalendar, setOthersLeaveDataForCalendar] = useState([]);
    const [anchorEl, setAnchorEl] = useState(null);
    const [openElem, setOpenElem] = useState(null);
    const open = Boolean(anchorEl);
    const [isLoading, setIsLoading] = useState({
        dataLoading: true,
    });
    const initialModalDetails = {
        isShow: false,
        type: null,
        comment: "",
        leaveDetails: null,
        isLoading: false,
    };
    const [modalDetails, setModalDetails] = useState(initialModalDetails);
    const [openAccordianList, setOpenAccordianList] = useState([]);
    const [optionalWfhData, setOptionalWfhData] = useState([])
    const showAlert = useAlert();
    const [activeRequestType, setActiveRequestType] = useState([]);
    const [leaveSnapshot, setLeaveSnapshot] = useState(null);

    useEffect(() => {
        fetchMyLeavesForUser();
        getUserLeaveTypeData();
    }, [status]);

    useEffect(() => {
        getUserLeaveBalance();
    }, [])

    useEffect(() => {
        getDataAccordingToGroupByFilters(optionalWfhData, groupBy);
    }, [filterStatus])

    const getUserLeaveTypeData = async () => {
        try {
            setIsLoading((prev) => ({ ...prev, leaveType: true }))
            const response = await axiosInstance.get(
                API.getUserSpecialCredits(currentUser?.id)
            );
            if (response.status === 200) {
                setActiveRequestType(response?.data?.result)
                setIsLoading((prev) => ({ ...prev, leaveType: false }))
            } else {
                setIsLoading((prev) => ({ ...prev, leaveType: false }))
            }
        } catch (error) {
            console.error(error);
            setIsLoading((prev) => ({ ...prev, leaveType: false }))
        }
    };

    const getUserLeaveBalance = async () => {
        try {
            setIsLoading((prev) => ({ ...prev, spanLoading: true }))
            const response = await axiosInstance.get(API.getLeaveBalance(currentUser?.id));
            if (response.status === 200) {
                setLeaveSnapshot(response?.data?.result);
                setIsLoading((prev) => ({ ...prev, spanLoading: false }))
            } else {
                setIsLoading((prev) => ({ ...prev, spanLoading: false }))
            }
        } catch (error) {
            console.error(error);
            setIsLoading((prev) => ({ ...prev, spanLoading: false }))
        }
    };

    const fetchMyLeavesForUser = async () => {
        try {
            if (!!status) {
                setIsLoading((prev) => ({ ...prev, dataLoading: true }));
                let queryParams = {
                    // page: 1,
                    // limit: 100,
                    status: status,
                };
                const response = await axiosInstance.get(
                    API.getUserWfhApplicationList(currentUser.id),
                    {
                        params: queryParams,
                    }
                );
                if (response.status === 200) {
                    if (response?.data?.data?.length) {
                        let sortedArray = sortRequestArrayByDate(response?.data?.data, "WfhDays", "date", status === Object.keys(LEAVE_GROUP_BY_STATUS)[0] ? "asc" : "desc");
                        setOthersLeaveDataForCalendar(sortedArray)
                        getDataAccordingToGroupByFilters(sortedArray, groupBy === MY_WFH_GROUP_FILTER["Status"] ? MY_WFH_GROUP_FILTER["Status"] : groupBy);
                        setOptionalWfhData(sortedArray);
                    } else {
                        setLeaveDataList({});
                        setOptionalWfhData([]);
                    }
                    setIsLoading((prev) => ({ ...prev, dataLoading: false }))
                } else {
                    setLeaveDataList({});
                    setIsLoading((prev) => ({ ...prev, dataLoading: false }))
                    setOptionalWfhData([]);
                }
            }
        } catch (error) {
            console.error(error);
            setIsLoading((prev) => ({ ...prev, dataLoading: false }));
            setLeaveDataList({});
            setOptionalWfhData([]);
        }

    };

    const getDataAccordingToGroupByFilters = (optionalWfhData, group) => {
        if (group === MY_WFH_GROUP_FILTER["Status"]) {
            if (optionalWfhData?.length) {
                let statusData = REQUEST_TYPE?.reduce((result, type) => {
                    let filteredTypeData = optionalWfhData?.filter((leave) => leave?.status === type);
                    result[type] = filteredTypeData;
                    return result;
                }, {});

                let accordian = []
                if (Object.values(statusData)?.some(data => data?.length)) {
                    for (let key = 0; key < REQUEST_TYPE?.length; key++) {
                        if (statusData[REQUEST_TYPE[key]]?.length) {
                            accordian.push(REQUEST_TYPE[key])
                            break;
                        }
                    }
                }
                setOpenAccordianList(accordian)
                setLeaveDataList(statusData);
                setTimeout(() => {
                    setIsLoading((prev) => ({ ...prev, dataLoading: false }))
                }, 500)
            } else {
                setLeaveDataList({});
                setOpenAccordianList([])
                setIsLoading((prev) => ({ ...prev, dataLoading: false }))
            }
        } else if (group === MY_WFH_GROUP_FILTER["WFH Date"]) {
            let leaveDateArray = [];
            let organizedLeaves = {};

            if (status === Object.keys(LEAVE_GROUP_BY_STATUS)[0]) {
                leaveDateArray = [
                    "Next 7 days",
                    "Next 30 days",
                    "More than 30 days",
                ];
                organizedLeaves = {
                    "Next 7 days": [],
                    "Next 30 days": [],
                    "More than 30 days": [],
                };
            } else {
                leaveDateArray = [
                    "Past WFH",
                ];
                organizedLeaves = {
                    "Past WFH": [],
                };
            }

            let filteredData = [...optionalWfhData]
            if (filterStatus !== Object.values(STATUS_BY_FILTER)[0])
                filteredData = optionalWfhData?.filter((leaveData) => leaveData?.status === filterStatus.toLocaleLowerCase())

            leaveDateArray?.forEach((key) => {
                filteredData?.forEach((leaveData) => {
                    const filteredLeaves = leaveData?.WfhDays?.filter((leave) => {
                        if (key === "Past WFH") {
                            return isInPastDays(leave?.date);
                        } else if (key === "Next 7 days") {
                            return isWithinNext7Days(leave?.date);
                        } else if (key === "Next 30 days") {
                            return isWithinNext30Days(leave?.date);
                        } else if (key === "More than 30 days") {
                            return isMoreThan30Days(leave?.date);
                        }
                    });
                    if (filteredLeaves?.length > 0) {
                        organizedLeaves[key].push({
                            ...leaveData,
                            leaveData: filteredLeaves,
                        });
                    }
                });
            });

            if (Object.values(organizedLeaves)?.some(data => data?.length)) {
                let keyArray = [
                    "Next 7 days",
                    "Next 30 days",
                    "More than 30 days",
                ];
                let accordian = [];
                if (status === Object.keys(LEAVE_GROUP_BY_STATUS)[0]) {
                    for (let key = 0; key < keyArray?.length; key++) {
                        if (!organizedLeaves[keyArray[key]]?.length) {
                            accordian.push(keyArray[key])
                        } else {
                            accordian.push(keyArray[key])
                            break;
                        }
                    }
                } else {
                    accordian = ['Past WFH'];
                }

                setOpenAccordianList(accordian);
                setLeaveDataList(organizedLeaves);
            } else {
                setOpenAccordianList([]);
                setLeaveDataList({});
            }
            setTimeout(() => {
                setIsLoading((prev) => ({ ...prev, dataLoading: false }))
            }, 500)
        }
    };

    const handleCancelRequestModal = (leave) => {
        handleClose();
        setModalDetails({
            isShow: true,
            type: LEAVE_STATUS.Cancelled,
            leaveDetails: leave
        });
    };

    const handleDeleteRequestModal = (leave) => {
        handleClose();
        setModalDetails({
            isShow: true,
            type: LEAVE_STATUS.Delete,
            leaveDetails: leave
        });
    };

    const handleEditLeave = (leave) => {
        handleClose();
        navigate(`${URLS.EditMyWfhApplication}/${currentUser?.id}/${leave?.id}`)
    };

    const handleViewLeave = (leave) => {
        handleClose();
        navigate(`${URLS.ViewMyWfhApplication}/${currentUser?.id}/${leave?.id}`)
    };

    const handleClick = (elem) => (event) => {
        setAnchorEl(event.currentTarget);
        setOpenElem(elem);
    };

    const handleClose = () => {
        setAnchorEl(null);
        setOpenElem(null);
    };

    const handleChange = (event) => {
        setQueryParams("groupBy", event.target.value)
        if (event.target.value === MY_WFH_GROUP_FILTER?.Status)
            deleteQueryParams("filterStatus")
        else
            setQueryParams("filterStatus", filterStatus);
        getDataAccordingToGroupByFilters(optionalWfhData, event.target.value);
    };

    const handleStatusChange = (e) => {
        setQueryParams("filterStatus", e.target.value);
    }

    const getApplyDays = (leave_days) => {
        // let count = leave_days?.reduce((previousValue, currentValue, currentIndex, array) => {
        //     let paidCount = currentValue?.is_paid;
        //     let unPaidCount = !currentValue?.is_paid ? currentValue.leave_duration !== "full" ? 0.5 : 1 : 0
        //     previousValue.paidCount = previousValue.paidCount + paidCount;
        //     previousValue.unPaidCount = previousValue.unPaidCount + unPaidCount;
        //     previousValue.totalCount = previousValue.paidCount + previousValue.unPaidCount
        //     return previousValue;
        // }, { paidCount: 0, unPaidCount: 0, totalCount: 0, });
        return leave_days?.length;
    }

    const handleCancelRequest = () => {
        handleCancelRequestApplication('cancelled', modalDetails?.comment);
    }

    const handleCancelModal = () => {
        handleClose();
        setModalDetails(initialModalDetails);
    }

    const handleCancelRequestApplication = async (status, comment) => {
        try {
            if (comment?.trim()?.length) {
                setModalDetails((prev) => ({ ...prev, isLoading: true }));
                let updatedComment = comment?.trim()?.replace(/\s{2,}/g, " ");
                let payload = {
                    process_description: updatedComment,
                    action: status,
                    static_id: modalDetails?.leaveDetails?.static_id,
                    allow_special_credits: currentUser?.organization?.allow_special_credits,
                    org_year_id: currentUser?.organization?.work_type_settings?.[0]?.OrgYear?.id,
                };

                const response = await axiosInstance.put(
                    API.updateWfhApplicationStatus(currentUser?.id, modalDetails?.leaveDetails?.id),
                    payload,
                );
                if (response.status === 200) {
                    showAlert(response?.data.message);
                    setModalDetails(initialModalDetails);
                    handleClose();
                    fetchMyLeavesForUser();
                    getUserLeaveBalance();
                    handleCancelModal();
                } else {
                    showAlert(response?.response?.data.message, "error");
                    setModalDetails(initialModalDetails);
                    handleClose();
                    handleCancelModal();
                }
                setModalDetails((prev) => ({ ...prev, isLoading: false }));
            } else {
                setModalDetails((prev) => ({ ...prev, error: "Required" }));
            }
        } catch (error) {
            console.error(error)
            showAlert(error?.response?.data.message);
            setModalDetails(initialModalDetails);
            handleClose();
            setModalDetails((prev) => ({ ...prev, isLoading: false }));
            handleCancelModal();
        }
    }

    const handleDeleteRequestApplication = async () => {
        try {
            setModalDetails((prev) => ({ ...prev, isLoading: true }));
            const formData = new FormData();
            formData.append("is_deleted", true);

            const response = await axiosInstance.delete(
                API.deleteWfhApplicationById(modalDetails?.leaveDetails?.id, modalDetails?.leaveDetails?.static_id),
                formData,
                {
                    headers: {
                        "Content-Type": "multipart/form-data",
                    },
                }
            );
            if (response.status === 200) {
                showAlert(response?.data.message);
                setModalDetails(initialModalDetails);
                handleClose();
                fetchMyLeavesForUser();
                getUserLeaveBalance();
                handleCancelModal();
            } else {
                showAlert(response?.response?.data.message, "error");
                setModalDetails(initialModalDetails);
                handleClose();
                handleCancelModal();
            }
            setModalDetails((prev) => ({ ...prev, isLoading: false }));
        } catch (error) {
            console.error(error)
            showAlert(error?.response?.data.message);
            setModalDetails(initialModalDetails);
            handleClose();
            setModalDetails((prev) => ({ ...prev, isLoading: false }));
            handleCancelModal();
        }
    }

    const getRemainingCreditsData = () => {
        return leaveSnapshot?.length ? leaveSnapshot?.find((data) => Object.keys(data)?.includes('specialCredits')) : false;
    }

    const getUsedCredits = (total, used) => {
        if (used > total) return `${total}+${used - total}(Extra)`;
        else return used
    }

    return ((isLoading?.spanLoading) ?
        <CircularLoader height="500px" /> :
        <>
            {getRemainingCreditsData() ? <Box>
                <Stack gap={1} direction="row" width="100%" p={2} flexWrap={"wrap"}>
                    {leaveSnapshot?.map((snap, index) => {
                        if (Object.keys(snap).includes('specialCredits')) {
                            return (
                                <Grid
                                    item
                                    p={{ xs: 1, xl: 2 }}
                                    sx={{ borderRadius: "8px", flex: ' 0 0 calc(25% - 6px)', minWidth: '200px' }}
                                    bgcolor="#FFF6DF"
                                    key={index}
                                >
                                    <Typography
                                        variant="caption"
                                        color="secondary"
                                        fontWeight={500}
                                        display="block"
                                        mb={0.5}
                                    >
                                        Available Special Credits
                                    </Typography>
                                    <Grid display={"flex"} alignItems={"center"}>
                                        <Typography
                                            fontSize={{ xs: 16, xl: 18 }}
                                            fontWeight={600}
                                            color="secondary"
                                            display="block"
                                            mr={0.5}
                                        >
                                            {snap?.specialCreditUsed > 0 ? snap?.specialCreditUsed : 0}
                                        </Typography>
                                        <Typography color={"secondary"} fontSize={12} lineHeight={"18px"} letterSpacing={"0.15px"} fontWeight={500} pt={0.5}>credits used out of <b>{snap?.specialCredits ?? 0}</b></Typography>
                                        <HtmlTooltip
                                            title={
                                                <React.Fragment>
                                                    <Typography color={"white"} fontSize={16} lineHeight={"17px"} fontWeight={500} mb={1.5}><span style={{ fontWeight: 'bold' }}>{snap?.specialCreditUsed > 0 ? snap?.specialCreditUsed : 0}</span>/{snap?.specialCredits ?? 0} Special Credits used</Typography>
                                                    <Typography variant="body1" color={"white"} fontSize={13} lineHeight={"19px"} fontWeight={500} mb={2}>Special Credits includes all currently applied leaves. If any leaves are rejected or canceled, the special credit will be adjusted accordingly.</Typography>
                                                    <Table>
                                                        <TableHead>
                                                            <TableRow
                                                                sx={{
                                                                    'th': {
                                                                        padding: '10px 4px',
                                                                        color: 'white',
                                                                        fontSize: 12,
                                                                        lineHeight: '19px',
                                                                        fontWeight: 700,
                                                                        background: 'rgba(255,255,255,0.1)',
                                                                        border: 'none',
                                                                        '&:first-of-type': {
                                                                            padding: '10px',
                                                                        }
                                                                    }
                                                                }}
                                                            >
                                                                <TableCell>Credits</TableCell>
                                                                <TableCell align="center" sx={{ width: '100px', maxWidth: '100px', minWidth: '100px' }}>Initial Credit</TableCell>
                                                                <TableCell align="center" sx={{ width: '70px', maxWidth: '70px', minWidth: '70px' }}>Used</TableCell>
                                                                <TableCell align="center" sx={{ width: '100px', maxWidth: '100px', minWidth: '100px' }}>Remaining</TableCell>
                                                            </TableRow>
                                                        </TableHead>
                                                        <TableBody
                                                            sx={{
                                                                'td': {
                                                                    padding: '4px 10px',
                                                                    color: 'white',
                                                                    fontSize: 14,
                                                                    lineHeight: '19px',
                                                                    fontWeight: 500,
                                                                    border: 'none',
                                                                },
                                                                'tr:first-of-type td': {
                                                                    paddingTop: '10px',
                                                                }
                                                            }}
                                                        >
                                                            {snap?.specialCreditsList?.length ?
                                                                snap?.specialCreditsList?.map((creditData, index) => (
                                                                    <TableRow key={index}>
                                                                        <TableCell>{titleCase(creditData?.name)}</TableCell>
                                                                        <TableCell align="center">{creditData?.allotment ?? 0}</TableCell>
                                                                        <TableCell align="center">{getUsedCredits(creditData?.allotment ?? 0, creditData?.used ?? 0)}</TableCell>
                                                                        <TableCell align="center">{creditData?.remaining > 0 ? creditData?.remaining : 0 ?? 0}</TableCell>
                                                                    </TableRow>
                                                                )) : null}
                                                        </TableBody>
                                                    </Table>
                                                </React.Fragment>
                                            }
                                        >
                                            <InfoIcon color="secondary" sx={{ fontSize: "18px", marginLeft: "4px", marginTop: "2px" }} />
                                        </HtmlTooltip>
                                    </Grid>
                                </Grid>
                            );
                        }
                    })}
                </Stack>
                <Divider component={"div"} />
            </Box> : null}
            <Box sx={{ padding: { sm: '16px !important', lg: '24px !important', xl: '32px !important' }, width: '100%' }}>
                <Stack spacing={{ xs: 2, lg: 3 }}>
                    <Grid container justifyContent="space-between" alignItems="flex-end">
                        <Grid item display="flex" alignItems="flex-end">
                            <FormControl
                                variant="standard"
                                sx={{ mr: { xs: 2, lg: 3, xl: 4 }, minWidth: { xs: 160, md: 180 }, maxWidth: { xs: 160, md: 180 } }}>
                                <InputLabel htmlFor={getInputId("group-by", 0)}>
                                    Group By
                                </InputLabel>
                                <Select
                                    id="group-by"
                                    inputProps={{ id: getInputId("group-by", 0) }}
                                    value={groupBy || ""}
                                    onChange={(e) => handleChange(e)}
                                    label="Group By"
                                >
                                    {Object.values(MY_WFH_GROUP_FILTER)?.map(
                                        (name, index) => (
                                            <MenuItem key={index} value={name}>
                                                {name}
                                            </MenuItem>
                                        )
                                    )}
                                </Select>
                            </FormControl>
                            {groupBy !== MY_WFH_GROUP_FILTER?.Status ? <FormControl
                                variant="standard"
                                sx={{ mr: { xs: 2, lg: 3, xl: 4 }, minWidth: { xs: 160, md: 180 }, maxWidth: { xs: 160, md: 180 } }}
                            >
                                <InputLabel htmlFor={getInputId("filter-by", 0)}>
                                    Filter By Status
                                </InputLabel>
                                <Select
                                    id="filter-by"
                                    inputProps={{ id: getInputId("filter-by", 0) }}
                                    value={filterStatus || ""}
                                    onChange={(e) => handleStatusChange(e)}
                                    label="Filter By Status"
                                >
                                    {Object.values(STATUS_BY_FILTER)?.map((name, index) => (
                                        <MenuItem key={index} value={name}>
                                            {name}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl> : null}
                        </Grid>
                        <Grid item>
                        </Grid>
                        <Grid item display="flex" justifyContent={"flex-end"}>
                            <ButtonGroup
                                variant="outlined"
                                aria-label="Basic button group"
                                color="secondary"
                                fontSize={14}
                                sx={{ mr: { xs: 1, lg: 2, xl: 3 } }}
                            >
                                {Object.entries(LEAVE_GROUP_BY_STATUS).map(
                                    ([key, value], index) => (
                                        <Button
                                            variant="outlined"
                                            key={index}
                                            sx={{
                                                color: status === key ? "primary.main" : "",
                                                textTransform: "none",
                                                background: status === key ? "rgba(4, 127, 224, 0.1)" : "",
                                                borderColor: status === key ? "#047FE0 !important" : "",
                                                position: 'relative',
                                                zIndex: status === key ? "1" : "",
                                                paddingLeft: { xs: '10px', md: '15px' },
                                                paddingRight: { xs: '10px', md: '15px' }
                                            }}
                                            onClick={() => setQueryParams("status", key)}
                                        >
                                            {value}
                                        </Button>
                                    )
                                )}
                            </ButtonGroup>
                            <Stack spacing={2} direction="row">
                                {/* <ButtonGroup
                                    variant="outlined"
                                    aria-label="Basic button group"
                                    color="secondary"
                                    fontSize={14}
                                >
                                    <Button
                                        sx={{
                                            textTransform: "none",
                                            background: view === "list" ? "rgba(4, 127, 224, 0.1)" : "",
                                            borderColor: view === "list" ? "#047FE0 !important" : "",
                                            color: view === "list" ? "primary.main" : "",
                                            zIndex: view === "list" ? "1" : "",
                                            position: 'relative',
                                            paddingLeft: { xs: '8px', md: '15px' },
                                            paddingRight: { xs: '8px', md: '15px' }
                                        }}
                                        onClick={() => setQueryParams("view", "list")}
                                    >
                                        <i style={{ height: 20, width: 20 }}>
                                            <ListAltRoundedIcon sx={{ fontSize: 20 }} />
                                        </i>
                                    </Button>
                                    <Button
                                        sx={{
                                            textTransform: "none",
                                            background: view === "calendar" ? "rgba(4, 127, 224, 0.1)" : "",
                                            borderColor: view === "calendar" ? "#047FE0 !important" : "",
                                            color: view === "calendar" ? "primary.main" : "",
                                            zIndex: view === "calendar" ? "1" : "",
                                            position: 'relative',
                                            paddingLeft: { xs: '8px', md: '15px' },
                                            paddingRight: { xs: '8px', md: '15px' }
                                        }}
                                        disabled={true}
                                        onClick={() => setQueryParams("view", "calendar")}
                                    >
                                        <i style={{ height: 20, width: 20 }}>
                                            <CalendarTodayRoundedIcon sx={{ fontSize: 20 }} />
                                        </i>
                                    </Button>
                                </ButtonGroup> */}
                                <Button
                                    variant="contained"
                                    color="secondary"
                                    sx={{ textTransform: "none", boxShadow: "none" }}
                                    onClick={() => navigate(URLS.ApplyWorkFromHome)}
                                >
                                    Apply WFH Request
                                </Button>
                            </Stack>
                        </Grid>
                    </Grid>
                    {(isLoading?.dataLoading || isLoading?.leaveType) ?
                        <CircularLoader height="500px" /> :
                        view === "calendar" ? (
                            <WFHCalendarView leaveDataList={othersLeaveDataForCalendar} userId={currentUser?.id} />
                        ) : (
                            <WHFListTable
                                modalDetails={modalDetails}
                                leaveStatus={status}
                                leaveDataList={leaveDataList}
                                handleViewLeave={(openElem) => handleViewLeave(openElem)}
                                handleCancelRequestModal={(openElem) => handleCancelRequestModal(openElem)}
                                handleDeleteRequestModal={(openElem) => handleDeleteRequestModal(openElem)}
                                handleEditLeave={(openElem) => handleEditLeave(openElem)}
                                handleClose={handleClose}
                                handleClick={(data) => handleClick(data)}
                                anchorEl={anchorEl}
                                openElem={openElem}
                                open={open}
                                openAccordianList={openAccordianList}
                                setOpenAccordianList={setOpenAccordianList}
                                activeRequestType={activeRequestType}
                                optionalWfhData={optionalWfhData}
                            />
                        )}
                </Stack>
            </Box>
            {modalDetails?.type === LEAVE_STATUS.Cancelled ? <CustomModal
                actionType={modalDetails?.type}
                title={"Confirm Cancellation"}
                leaveType={specialRequestList?.find(request => request?.id === modalDetails?.leaveDetails?.static_id)?.name || 'Work From Home'}
                date={getLeaveDateArray(modalDetails?.leaveDetails?.WfhDays || [], 'date')?.map((date) => moment(date).format("Do MMMM (dddd)"))}
                userData={{
                    profileImage: currentUser?.profile_img,
                    role: titleCase(currentUser?.user_role),
                    is_dedicated_developer: currentUser?.User?.is_dedicated_developer,
                    ...currentUser,
                }}
                applyDays={getApplyDays(modalDetails?.leaveDetails?.WfhDays)}
                comment={modalDetails?.comment}
                commentError={modalDetails?.error}
                handleComment={(e) => {
                    setModalDetails((prev) => ({ ...prev, comment: e.target.value, error: e.target.value ? "" : "Required" }))
                }}
                onApprove={handleCancelRequest}
                onCancel={handleCancelModal}
                isButtonLoading={modalDetails?.isLoading}
                isWfh={true}
                submitButtonContent={'Cancel Request'}
                isLeave={false}
            /> : null}

            {modalDetails?.type === LEAVE_STATUS.Delete ? <CustomModal
                actionType={modalDetails?.type}
                title={"Confirm Deletion"}
                leaveType={specialRequestList?.find(request => request?.id === modalDetails?.leaveDetails?.static_id)?.name || 'Work From Home'}
                date={getLeaveDateArray(modalDetails?.leaveDetails?.WfhDays || [], 'date')?.map((date) => moment(date).format("Do MMMM (dddd)"))}
                userData={{
                    profileImage: currentUser?.profile_img,
                    role: titleCase(currentUser?.user_role),
                    is_dedicated_developer: currentUser?.User?.is_dedicated_developer,
                    ...currentUser,
                }}
                applyDays={getApplyDays(modalDetails?.leaveDetails?.WfhDays)}
                comment={modalDetails?.comment}
                commentError={modalDetails?.error}
                handleComment={(e) => {
                    setModalDetails((prev) => ({ ...prev, comment: e.target.value, error: e.target.value ? "" : "Required" }))
                }}
                onApprove={handleDeleteRequestApplication}
                onCancel={handleCancelModal}
                isButtonLoading={modalDetails?.isLoading}
                isWfh={true}
                submitButtonContent={'Delete Request'}
                isLeave={false}
            /> : null}
        </>
    );
};

export default MyWFHRequests;
