/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import API from "../../../../../axios/api";
import axiosInstance from "../../../../../axios";
import useAuthentication from "../../../../../hook/useAuthentication";
import CircularLoader from "../../../../../components/CircularLoader";
import { useNavigate, useParams } from "react-router-dom";
import { convertTimeToDate, sortArrayByKey, specialRequestList } from "../../../../../utils";
import EditWFHRequestDetails from "./EditWFHRequestDetails";
import EditWFHRequest from "./EditWFHRequest";
import { usePageTitle } from "../../../../../hook/useTitle";
import { getAllHolidayData } from "../../../../../redux/slices/leaveDataSlice";
import { useDispatch, useSelector } from "react-redux";
import URLS from "../../../../../routes/urls";
import { LEAVE_STATUS } from "../../../../../constants/default-values";
import PageNotFound from "../../../../PageNotFound";

const EditWfhApplication = () => {

  const { getCurrentUser } = useAuthentication();
  const { holidaySettingData } = useSelector((state) => state?.leaveData);
  const currentUser = getCurrentUser();
  const params = useParams();
  const dispatch = useDispatch();
  const { setPageTitle } = usePageTitle();
  const navigate = useNavigate();
  let initialLeaveData = {
    static_id: null,
    informed_authorities: [],
    attachments: [],
    attachment_references: [],
    description: "",
    apply_date: new Date(),
    WfhDays: [],
    is_special_request_changed: false,
  };
  const [isAppliedLeave, setIsAppliedLeave] = useState(true);
  const [leaveData, setLeaveData] = useState(initialLeaveData);
  const [optionalLeaveData, setOptionalLeaveData] = useState(null);
  const [deletedDays, setDeletedDays] = useState([]);
  const [deletedImages, setDeletedImages] = useState([]);
  const [errors, setErrors] = useState({});
  const [organizationUserList, setOrganizationUserList] = useState([]);
  const [isLoading, setIsLoading] = useState({
    users: true,
    holiday: true,
    leaveDetails: true,
    wfhDates: true
  });
  const [isFirstRender, setIsFirstRender] = useState(true);
  const [remainingCount, setRemainingCount] = useState(0);
  const [activeRequestType, setActiveRequestType] = useState([]);
  const [renewalSettingData, setRenewalSettingData] = useState({
    setting_end_date: null,
    setting_start_date: null,
    year: null
  })
  const [halfRequestData, setHalfRequestData] = useState([]);

  useEffect(() => {
    if (!!holidaySettingData?.halfDayLeave?.length || !!holidaySettingData?.halfDayDates?.length) {
      setHalfRequestData([...holidaySettingData?.halfDayLeave, ...holidaySettingData?.halfDayDates?.map(wfhData => {
        return {
          ...wfhData,
          leave_date: wfhData?.date
        }
      })])
    }
  }, [holidaySettingData])

  setPageTitle("Edit WFH Application");

  const getUserLeave = async (user_id, id) => {
    try {
      const response = await axiosInstance.get(API.getWfhApplicationById(user_id, id));
      if (response.status === 200) {
        if (response?.data?.data?.status !== LEAVE_STATUS.Pending) {
          navigate(`${URLS.WorkFromHome}?view=list&tabValue=1&groupBy=Status&status=active`)
        }
        let res = response?.data?.data;
        setLeaveData({
          ...res,
          WfhDays: sortArrayByKey(res?.WfhDays?.map(value => ({
            ...value,
            is_changed: false,
            in_time: convertTimeToDate(value?.in_time),
            out_time: convertTimeToDate(value?.out_time)
          })), 'asc', 'date'),
          is_special_request_changed: false,
          attachment_references: res?.attachment_references?.filter(value => value != null)
        });
        setOptionalLeaveData({
          ...res,
          WfhDays: sortArrayByKey(res?.WfhDays?.map(value => ({
            ...value,
            is_changed: false,
            in_time: convertTimeToDate(value?.in_time),
            out_time: convertTimeToDate(value?.out_time)
          })), 'asc', 'date'),
          is_special_request_changed: false,
        })
        setIsLoading((prev) => ({ ...prev, leaveDetails: false }));
      } else {
        setLeaveData({});
        setIsLoading((prev) => ({ ...prev, leaveDetails: false }));
      }
    } catch (error) {
      console.error(error);
      setLeaveData({});
      setIsLoading((prev) => ({ ...prev, leaveDetails: false }));
    }
  };

  const getUserLeaveTypeData = async () => {
    try {
      setIsLoading((prev) => ({ ...prev, leaveType: true }))
      const response = await axiosInstance.get(
        API.getUserSpecialCredits(params?.userId)
      );
      if (response.status === 200) {
        setActiveRequestType(response?.data.result);
        setRenewalSettingData({
          setting_end_date: response?.data?.orgRenewalSetting?.setting_end_date,
          setting_start_date: response?.data?.orgRenewalSetting?.setting_start_date,
          year: response?.data?.orgRenewalSetting?.year
        });
        setIsLoading((prev) => ({ ...prev, leaveType: false }))
      } else {
        setIsLoading((prev) => ({ ...prev, leaveType: false }))
      }
    } catch (error) {
      console.error(error);
      setIsLoading((prev) => ({ ...prev, leaveType: false }))
    }
  };

  const getUserSettingData = async () => {
    try {
      setIsLoading((prev) => ({ ...prev, users: true }))
      const response = await axiosInstance.get(API.organisationUserWithInformationAuthority);
      if (response.status === 200) {
        let reporting_authorities = response?.data?.data?.reporting_authorities;
        let second_reporting_authorities = response?.data?.data?.second_reporting_authorities;
        let defaultUsers = response?.data?.data?.defaultUsers;
        let users = [];
        if (reporting_authorities && reporting_authorities?.length > 0) {
          users.push(...reporting_authorities);
        }
        if (second_reporting_authorities && second_reporting_authorities?.length > 0) {
          users.push(...second_reporting_authorities);
        }
        if (users?.length <= 0) {
          users.push(...defaultUsers);
        }
        setOrganizationUserList(users || []);
        getUserLeave(params?.userId, params?.appId);
        setIsLoading((prev) => ({ ...prev, users: false }))
      } else {
        setIsLoading((prev) => ({ ...prev, users: false }))
      }
    } catch (error) {
      console.error(error);
      setIsLoading((prev) => ({ ...prev, users: false }))
    }
  };

  const getYears = async () => {
    try {
      setIsLoading((prev) => ({ ...prev, holiday: true }))
      const response = await axiosInstance.get(
        `${API.getAllHolidaysYear(currentUser?.organization_id)}`
      );
      if (response.status === 200) {
        let sortedYear = response?.data?.result?.sort((a, b) =>
          a?.year?.toString()?.localeCompare(b?.year?.toString())
        );
        let activeYear = sortedYear?.find(yearData => yearData?.active);
        dispatch(getAllHolidayData({
          organization_id: currentUser?.organization_id,
          year_Id: activeYear?.id || sortedYear[0]?.id,
          user_id: currentUser?.id
        }))
        setIsLoading((prev) => ({ ...prev, holiday: false }))
      }
    } catch (error) {
      console.error(error);
      setIsLoading((prev) => ({ ...prev, holiday: false }))
    }
  }

  useEffect(() => {
    if (params?.userId) {
      if (Number(currentUser?.id) === Number(params?.userId)) {
        getUserSettingData();
        getUserLeaveTypeData();
        getYears();
      } else {
        navigate(URLS.AccessDenied)
      }
    }
  }, []);

  useEffect(() => {
    if (isFirstRender) {
      if (leaveData?.static_id) {
        let count = activeRequestType?.find((data) => data?.spec_req_id === leaveData?.static_id)?.remaining || 0;
        setRemainingCount(count)
        setIsFirstRender(false)
      }
    }
  }, [leaveData])

  return ((isLoading?.users || isLoading?.leaveDetails) ?
    <CircularLoader /> :
    leaveData && Object.keys(leaveData)?.length === 0 ?
      <PageNotFound showSideBar={false} /> :
      isAppliedLeave ? (
        <EditWFHRequestDetails
          setIsAppliedLeave={setIsAppliedLeave}
          setLeaveData={setLeaveData}
          leaveData={leaveData}
          deletedDays={deletedDays}
          deletedImages={deletedImages}
          setDeletedImages={setDeletedImages}
          setDeletedDays={setDeletedDays}
          setErrors={setErrors}
          userLeaveTypes={specialRequestList}
          organizationUserList={organizationUserList}
          errors={errors}
          currentUser={currentUser}
          remainingCount={remainingCount}
          setRemainingCount={setRemainingCount}
          activeRequestType={activeRequestType}
          renewalSettingData={renewalSettingData}
          setHalfRequestData={setHalfRequestData}
          halfRequestData={halfRequestData}
        />
      ) : (
        <EditWFHRequest
          setIsAppliedLeave={setIsAppliedLeave}
          setLeaveData={setLeaveData}
          leaveData={leaveData}
          deletedDays={deletedDays}
          setDeletedDays={setDeletedDays}
          setErrors={setErrors}
          errors={errors}
          userLeaveTypes={specialRequestList}
          organizationUserList={organizationUserList}
          currentUser={currentUser}
          setRemainingCount={setRemainingCount}
          optionalLeaveData={optionalLeaveData}
          activeRequestType={activeRequestType}
          renewalSettingData={renewalSettingData}
          setHalfRequestData={setHalfRequestData}
          halfRequestData={halfRequestData}
        />
      )
  );
};

export default EditWfhApplication;
