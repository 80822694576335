/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useRef, useState } from "react";
import MultiDatePicker, { DateObject } from "react-multi-date-picker";
import DatePanel from "react-multi-date-picker/plugins/date_panel";
import Chip from "@mui/material/Chip";
import Stack from "@mui/material/Stack";
import { Box, IconButton, styled, TextField, Typography } from "@mui/material";
import { getFormattedDate } from "../../utils";
import moment from "moment";
import ClearIcon from '@mui/icons-material/Clear';
import { LEAVE_DURATION_TYPE, WEEKDAYS } from "../../constants/default-values";
import { useSelector } from "react-redux";
import useAuthentication from "../../hook/useAuthentication";
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import { titleCase } from "../../utils/validation";

const HtmlTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: '#444444',
    maxWidth: 220,
    padding: "7px 12px",
  },
}));

const MultipleDatePicker = ({
  name,
  value,
  onChange,
  ignoreDates = [],
  onConfirm,
  onCancel,
  onClose = () => { },
  onBlur,
  isAutoDeleted = false,
  setIsAutoDeleted,
  extraDisableDates = [],
  ...restDateProps
}) => {
  const { getCurrentUser } = useAuthentication();
  const { ignoreLeaveDates, requestDays } = useSelector((state) => state?.leaveData);
  const currentUser = getCurrentUser();

  const [selectedDates, setSelectedDates] = useState(value || []);
  // const [dataTooltip, setDataTooltip] = useState({});
  const datePickerRef = useRef();

  useEffect(() => {
    if (isAutoDeleted) {
      setSelectedDates(value);
      setIsAutoDeleted(false);
    }
  }, [isAutoDeleted])

  const handleDateChange = (newDates) => {
    setSelectedDates(newDates);
    onChange(newDates);
  };

  const getIgnoreDatesArray = () => {
    let array = [];
    if (ignoreDates?.length) {
      array = ignoreDates;
    } else {
      array = ignoreLeaveDates;
    }
    if (extraDisableDates?.length) {
      array = Array.from(new Set([...extraDisableDates, ...ignoreLeaveDates]));
    }
    return array;
  }

  const handleDelete = (deletedIndex) => () => {
    const newDates = selectedDates?.filter((date, index) => index !== deletedIndex);
    handleDateChange(newDates);
    // onChange(newDates);
  };

  const handleClear = () => {
    const newDates = [];
    handleDateChange(newDates);
    // onChange(newDates);
  };

  const handleOnClose = (...rest) => {
    if (selectedDates?.length === 0) {
      let ignoreDatesArray = getIgnoreDatesArray();
      if (ignoreDatesArray?.length) {
        let isExists = ignoreDatesArray?.some((ignoreDate) => {
          let date1 = moment(ignoreDate, 'DD/MM/YYYY').format("DD/MM/YYYY");
          let date2 = moment(new DateObject()).format("DD/MM/YYYY");
          return date1 === date2;
        });
        if (!isExists) {
          setSelectedDates([new DateObject()]);
          onChange([new DateObject()]);
        }
      } else {
        setSelectedDates([new DateObject()]);
        onChange([new DateObject()]);
      }
      onClose(...rest);
    } else {
      onClose(...rest);
    }
  };

  const getLeaveType = (leaveDetails) => {
    // Leave has leave_date key and WFH / timing request has date key
    if (leaveDetails?.leave_duration !== Object.keys(LEAVE_DURATION_TYPE)[2]) {
      return `${Object.keys(leaveDetails)?.includes('leave_date') ? titleCase(leaveDetails?.type?.split(" ")?.[0]) : titleCase(leaveDetails?.type)} ${LEAVE_DURATION_TYPE[leaveDetails?.leave_duration]} ${Object.keys(leaveDetails)?.includes('leave_date') ? 'Leave' : ''}`
    } else {
      return `${titleCase(leaveDetails?.type)}`
    }
  }

  const isdotType = (requestDate, key) => {
    return requestDate?.find(data => Object.keys(data)?.includes(key))
  }

  const isIncludeTwoRequest = (requestDate) => {
    return requestDate?.length > 1;
  }

  return (
    <MultiDatePicker
      ref={datePickerRef}
      onClose={handleOnClose}
      value={selectedDates}
      onChange={handleDateChange}
      plugins={[<DatePanel markFocused />]}
      sort
      highlightToday
      weekDays={WEEKDAYS}
      headerOrder={["MONTH_YEAR", "LEFT_BUTTON", "RIGHT_BUTTON"]}
      format="DD/MM/YYYY"
      mapDays={({ date }) => {
        // let isPastDate = moment(new Date(date)).add(1, 'd').isBefore(new Date());
        let isPastDate = moment(new Date(date)).isBefore(currentUser?.organization?.createdAt || `01/01/${new Date().getFullYear()}`);
        let isIgnoredDate = getIgnoreDatesArray()?.some((ignoreDate) => {
          let date1 = moment(ignoreDate, 'DD/MM/YYYY').format("DD/MM/YYYY");
          let date2 = moment(new Date(date)).format("DD/MM/YYYY");
          return date1 === date2;
        });
        let isRequestDate = requestDays?.filter((requestDetails) => {
          let date1 = moment(requestDetails?.leave_date ?? requestDetails?.date).format("DD/MM/YYYY");
          let date2 = moment(new Date(date)).format("DD/MM/YYYY");

          return date1 === date2;
        });

        // if (isPastDate || isIgnoredDate) {
        //   return {            
        //     // onMouseOver: (e) => {
        //     //   let spanRect = e.target.getBoundingClientRect();
        //     //   let calendarRect = datePickerRef.current
        //     //     .querySelector(".rmdp-wrapper")
        //     //     .getBoundingClientRect();

        //     //   setDataTooltip({
        //     //     ...dataTooltip,
        //     //     left: spanRect.left - calendarRect.left,
        //     //     top: spanRect.top - calendarRect.top,
        //     //     visible: true,
        //     //     title: "Sick Leave",
        //     //   });
        //     // },
        //     // onMouseLeave: () => {
        //     //   setDataTooltip({
        //     //     ...dataTooltip,
        //     //     visible: false,
        //     //     title: "",
        //     //   });
        //     // },
        //   };
        // }

        if (isPastDate || isIgnoredDate || !!isRequestDate?.length) {
          return {
            disabled: (isPastDate || isIgnoredDate) ? true : (!!isRequestDate?.length && isRequestDate?.leave_duration === "full") ? true : false,
            style: { color: (isPastDate || isIgnoredDate) ? "#ccc" : (!!isRequestDate?.length && isRequestDate?.leave_duration === "full") ? "#ccc" : "#000" },
            children: (
              <>
                {!!isRequestDate?.length ? <HtmlTooltip arrow
                  title={
                    <React.Fragment>
                      <Box display={'flex'} flexDirection={'column'} justifyContent={'space-between'} alignItems={'center'}>
                        {isdotType(isRequestDate, "leave_date") ? <Typography variant="body1" color={"white"} fontSize={14} fontWeight={500} lineHeight={"21px"}>{getLeaveType(isRequestDate?.find(data => Object.keys(data)?.includes('leave_date')))}</Typography> : null}
                        {isdotType(isRequestDate, "date") ? <Typography variant="body1" color={"white"} fontSize={14} fontWeight={500} lineHeight={"21px"}>{getLeaveType(isRequestDate?.find(data => Object.keys(data)?.includes('date')))}</Typography> : null}
                      </Box>
                    </React.Fragment>
                  }
                >
                  <Box>
                    <Typography variant="caption" className="sd leave-type">{new Date(isRequestDate[0]?.leave_date ?? isRequestDate[0]?.date)?.getDate()}</Typography>
                    <Box display={'flex'} justifyContent={'space-between'} alignItems={'center'} mx={0}>
                      {isdotType(isRequestDate, "leave_date") ? <Box mr={1} width={6} height={6} minWidth={6} bgcolor={isRequestDate?.find(data => Object.keys(data)?.includes('leave_date'))?.type?.toLowerCase() === "casual leave" ? "#12B495" : "#D32F2F"} borderRadius={5} margin={"auto"} position={"absolute"} bottom={"1.5px"} left={isIncludeTwoRequest(isRequestDate) ? -7 : 0} right={0}></Box> : null}
                      {isdotType(isRequestDate, "date") ? <Box mr={1} width={6} height={6} minWidth={6} bgcolor={"#047FE0"} borderRadius={5} margin={"auto"} position={"absolute"} bottom={"1.5px"} left={isIncludeTwoRequest(isRequestDate) ? 7 : 0} right={0}></Box> : null}
                    </Box>
                  </Box>
                </HtmlTooltip> : <Typography variant="caption" className="sd leave-type">{new Date(date)?.getDate()}</Typography>}
              </>
            ),
          };
        }
      }}
      render={
        <TextField
          autoComplete="off"
          onKeyDown={(event) => {
            event.preventDefault();
          }}
          name={name}
          id="tags-standard"
          variant="standard"
          onBlur={onBlur}
          sx={{
            minHeight: "30px",
            width: "100%",
            ".MuiInputBase-formControl": {
              minHeight: "30px",
              flexWrap: "wrap",
              paddingTop: "0px",
              marginTop: '8px'
            },
            ".MuiInputBase-input": {
              opacity: 0,
              height: "22px",
              minWidth: "150px",
              flex: '1',
              border: "none",
              top: 0,
              bottom: "1px",
              background: "transparent",
            },
            ".rmdp-input:focus": {
              border: "none",
              boxShadow: "none",
            },
            ".MuiChip-sizeSmall": {
              marginLeft: '0px !important',
              marginRight: '8px'
            }
          }}
          InputProps={{
            startAdornment: (
              <Stack spacing={1} direction="row" flexWrap="wrap">
                {selectedDates?.map((date, index) => (
                  <Chip
                    key={date}
                    label={typeof date === "string" ? date : date ? getFormattedDate(new Date(date), "DD/MM/YYYY", true) : ""}
                    onDelete={handleDelete(index)}
                    color="secondary"
                    sx={{ marginBottom: "3px !important" }}
                    size="small"
                  >
                  </Chip>
                ))}
              </Stack>
            ),
            endAdornment: (
              !!selectedDates?.length ?
                <IconButton
                  size="small"
                  edge="start"
                  color="inherit"
                  aria-label="menu"
                  onClick={handleClear}
                >
                  <i
                    style={{
                      height: "20px",
                      width: "20px",
                      transition: 'all 0.25s ease-in-out',
                    }}><ClearIcon /></i>
                </IconButton>
                : null),
            readOnly: true
          }}
        />
      }

      {...restDateProps}
    >
      {/* <span
        style={{
          position: "absolute",
          left: 0,
          top: 0,
          backgroundColor: "white",
          border: "1px solid #ccc",
          boxShadow: "0 0 5px #ccc",
          borderRadius: "5px",
          padding: "3px 5px",
          fontSize: "14px",
          transform: `translate(${dataTooltip?.left}px, ${dataTooltip?.top + 30}px)`,
          visibility: dataTooltip?.visible ? "visible" : "hidden",
        }}
      >
        {dataTooltip?.title}
      </span> */}
    </MultiDatePicker>
  );
};

export default MultipleDatePicker;
