/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react'
import { usePageTitle } from '../../../../hook/useTitle';
import {
  Box, Grid, IconButton,
  Stack, Typography, FormControlLabel,
  Checkbox, FormControl, RadioGroup, Radio,
  FormLabel, TableContainer,
  Table, TableBody, TableRow, TableCell,
  Card, CardActionArea,
  Menu,
  FormGroup,
  Switch
} from '@mui/material'
import { useNavigate, useParams } from 'react-router-dom';
import URLS from '../../../../routes/urls';
import API from '../../../../axios/api';
import axiosInstance from '../../../../axios';
import CircularLoader from '../../../../components/CircularLoader';
import { toast } from 'react-toastify';
import useAuthentication from '../../../../hook/useAuthentication';
import Button from '../../../../components/Button'
import Input from '../../../../components/Input'
import { titleCase } from '../../../../utils'
import DeleteIcon from "@mui/icons-material/Delete";
import ConfirmationModal from '../../../../components/ConfirmationModal';
import { checkActivityUniqueName } from '../../../../axios/service';
import * as Muicon from "@mui/icons-material";
import { ICON_LIST } from '../../../../constants/default-values'
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import Breadcrumb from '../../../../components/Breadcrumb';

const initialActivityData = {
  "title": "",
  "types": "working",
  "have_notes": false,
  'org_work_type_ids': [],
  "notion": [],
  "activity_logo": ""
}

const UpdateActivity = () => {
  const { activityId } = useParams();
  const navigate = useNavigate();
  const { getCurrentUser } = useAuthentication();
  const currentUser = getCurrentUser();
  const { setPageTitle } = usePageTitle();
  const [activityData, setActivityData] = useState(initialActivityData);
  const [optionalActivityData, setOptionalActivityData] = useState(initialActivityData);
  const [workTypeData, setWorkTypeData] = useState([]);
  const [loading, setLoading] = useState({
    pageLoading: true,
    formSubmitting: false,
    isDelete: false
  });
  const [notionText, setNotionText] = useState("");
  const [errors, setErrors] = useState({
    "title": "",
    'notion': "",
    "org_work_type_ids": "",
    "activity_logo": ""
  });
  const [isDeleteIsOpen, setIsDeleteIsOpen] = useState(false)

  setPageTitle("Update Activity");

  useEffect(() => {
    getWorkTypesByOrganizationIdData();
  }, [])

  useEffect(() => {
  }, [activityData])

  useEffect(() => {
    if (activityId) {
      getActivityDataById();
    }
  }, [activityId]);

  const getWorkTypesByOrganizationIdData = async () => {
    try {
      const response = await axiosInstance.get(
        API.getWorkTypesByOrganisationId(currentUser?.organization_id)
      );
      if (response.status === 200) {
        setWorkTypeData(response?.data?.data);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const getActivityDataById = async () => {
    try {
      let response = await axiosInstance.get(
        API.getActivityById(activityId)
      );
      if (response.status === 200) {
        setActivityData(response?.data?.data);
        setOptionalActivityData(response?.data?.data);
      } else {
        toast.error(response?.data?.message)
      }
      setLoading((prev) => ({ ...prev, pageLoading: false }))
    } catch (error) {
      console.error(error);
      setLoading((prev) => ({ ...prev, pageLoading: false }))
    }
  }

  const handleChangeActivityIcon = (iconData) => {
    setActivityData((prev) => ({
      ...prev,
      activity_logo: iconData?.iconName,
      is_changes: true
    }))
    setErrors(prev => ({
      ...prev,
      activity_logo: ""
    }))
  }

  const handleChange = (e) => {
    handleBlur(e);
    let { name, value } = e.target;
    setActivityData(prev => ({
      ...prev,
      [name]: value,
    }))
  }

  const handleBlur = (e) => {
    let { name, value } = e.target;
    setErrors(prev => ({
      ...prev,
      [name]: !value ? "Required" : "",
    }))
  }

  const handleChangeNoteCheckBox = (e) => {
    setActivityData((prev) => ({
      ...prev,
      [e.target.name]: e.target.checked,
      notion: e.target.checked ? optionalActivityData?.notion : prev?.notion?.filter((data) => data?.id)?.map(data => {
        return { ...data, is_deleted: true }
      })
    }))
  }

  const handleChangeWorkType = (e) => {
    setActivityData((prev) => ({
      ...prev,
      'org_work_type_ids': e.target.checked ? [...prev?.org_work_type_ids, parseInt(e.target.name)] : prev?.org_work_type_ids?.filter(ids => ids !== parseInt(e.target.name))
    }))
    setErrors((prev) => ({
      ...prev,
      'org_work_type_ids': e.target.checked ? "" : activityData?.org_work_type_ids?.length <= 1 ? "Required" : ""
    }))
  }

  const handleChangeNotionName = (e) => {
    handleBlurNotion(e)
    setNotionText(e.target.value)
  }

  const handleBlurNotion = (e) => {
    setErrors((prev) => ({
      ...prev,
      'notion': ''
    }))
  }

  const handleAddClick = (e) => {
    e.preventDefault();
    if (activityData?.notion?.find(notionData => notionData?.title?.toLowerCase() === notionText?.toLowerCase())) {
      setErrors((prev) => ({
        ...prev,
        'notion': 'Notion name already exist'
      }))
    } else {
      setActivityData((prev) => ({
        ...prev,
        'notion': [...prev?.notion, { title: notionText }]
      }))
      setOptionalActivityData((prev) => ({
        ...prev,
        'notion': [...prev?.notion, { title: notionText }]
      }))
      setNotionText("");
    }
  }

  const handleCheckUniqueActivityName = async (title, isSubmit = false) => {
    try {
      if ((title?.trim() !== optionalActivityData?.title?.trim()) || isSubmit) {
        const { isExist } = await checkActivityUniqueName(title, currentUser?.organization_id, activityData?.id);
        if (isExist) {
          setErrors((prev) => ({
            ...prev,
            title: "Activity title already exists. Please choose a unique title.",
          }))
        }
        return isExist;
      }
      return false;
    } catch (error) {
      console.error(error);
    }
  };

  const validateActivityData = (isExistTitle = false) => {
    let error = { ...errors };
    if (!activityData?.title?.trim()?.length) {
      error['title'] = "Required"
    } else if (isExistTitle) {
      error['title'] = "Activity title already exists. Please choose a unique title.";
    } else if (errors?.title?.trim()?.length) {
      error['title'] = errors?.title;
    }
    if (!activityData?.org_work_type_ids?.length) {
      error['org_work_type_ids'] = "Required"
    }
    if (!activityData?.activity_logo) {
      error['activity_logo'] = "Required"
    }
    setErrors(error);
    return Object?.values(error)?.filter(errorData => errorData?.length)?.length;
  }

  const handleSubmit = async () => {
    try {
      let isExistTitle = activityData?.title?.length ? await handleCheckUniqueActivityName(activityData?.title, true) : false;
      if (!validateActivityData(isExistTitle)) {
        setLoading((prev) => ({ ...prev, formSubmitting: true }))
        let response = await axiosInstance.put(API.updateActivity, activityData);
        if (response?.status === 200) {
          setActivityData(initialActivityData);
          navigate(URLS.AdministratorActivity)
          toast.success(response?.data?.message);
        } else {
          toast.error(response?.data?.message);
        }
        setLoading((prev) => ({ ...prev, formSubmitting: false }))
      }
    } catch (error) {
      console.error(error);
      setLoading((prev) => ({ ...prev, formSubmitting: false }))
    }
  }

  const handleSubmitDelete = async () => {
    try {
      setLoading((prev) => ({ ...prev, isDelete: true }))
      let response = await axiosInstance.delete(API.deleteActivityById(activityData?.id));
      if (response?.status === 200) {
        toast.success(response?.data?.message);
        navigate(URLS.AdministratorActivity);
      } else {
        toast.error(response?.data?.message);
      }
      setIsDeleteIsOpen(false);
      setLoading((prev) => ({ ...prev, isDelete: false }));
    } catch (error) {
      console.error(error);
      setLoading((prev) => ({ ...prev, isDelete: false }))
    }
  }

  const handleDeleteNotion = (notionData) => {
    if (notionData?.id) {
      setActivityData((prev) => ({
        ...prev,
        notion: prev?.notion?.map((data) => data?.id === notionData?.id ? { ...data, is_deleted: true } : data)
      }))
      setOptionalActivityData((prev) => ({
        ...prev,
        notion: prev?.notion?.map((data) => data?.id === notionData?.id ? { ...data, is_deleted: true } : data)
      }))
    } else {
      setActivityData((prev) => ({
        ...prev,
        notion: prev?.notion?.filter(name => name?.title !== notionData?.title)
      }))
      setOptionalActivityData((prev) => ({
        ...prev,
        notion: prev?.notion?.filter(name => name?.title !== notionData?.title)
      }))
    }
  }

  const ActionCard = ({ icon, disabled, isOption = false }) => {
    const Icon = Muicon?.[icon];
    return (
      <Card sx={{
        boxShadow: "none !important",
        minWidth: isOption ? 60 : 90,
        maxWidth: 90,
        minHeight: 90,
        maxHeight: 90,
        alignContent: 'center',
        backgroundColor: "rgba(165, 179, 193, 0.1)",
      }}>
        <CardActionArea sx={{ textAlign: "center", px: 2, py: isOption ? "15px" : "5px" }} disabled={disabled}>
          {Icon ? <Icon color={(activityData?.activity_logo === icon && isOption) ? "primary" : "secondary"} sx={{ px: 1, fontSize: isOption ? 48 : 68 }} /> : null}
        </CardActionArea>
      </Card>
    );
  };

  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const getIcon = (icon, isOption = false) => {
    const Icon = Muicon?.[icon];
    return Icon ? <Icon color={(activityData?.activity_logo === icon && isOption) ? "primary" : "secondary"} /> : null
  }

  return (
    <Box sx={{ width: '100%', overflow: 'hidden', display: 'flex', flexDirection: 'column' }}>
      <Breadcrumb isBack={true} title={"Activities"}
        pageTitle={'Update Activity'}
        isPathShow={false}
        onBackClick={() => navigate(-1)} />
      {
        loading?.pageLoading ? <CircularLoader height="500px" /> :
          <Box p={{ xs: 2, lg: 3, xl: 4 }} py={{ xs: 3, lg: 3, xl: 4 }} flex={1} overflow={"auto"}>
            <Stack spacing={3}>
              <Box justifyContent="space-between" alignItems="center">
                <Stack spacing={4} sx={{ flexBasis: "100%" }}>
                  <Grid item>
                    <Typography variant='label' color={"dark.800"} fontSize={16} fontWeight={400} lineHeight={"24px"} letterSpacing={"0.15px"} mb={1} display={"block"}>Activity Symbol</Typography>
                    <Grid display="flex" alignItems="center">
                      {!activityData?.activity_logo?.length ? <Box
                        display={"flex"}
                        alignItems={"center"}
                        justifyContent={"center"}
                        sx={{
                          height: "90px",
                          width: "90px",
                          background: "rgba(165, 179, 193, 0.1)",
                          borderRadius: "6px",
                          overflow: "hidden",
                          position: "relative",
                          marginRight: "20px"
                        }}
                      >
                        <Typography color="#A5B3C1" fontSize={15} fontWeight={700} lineHeight={"18px"} letterSpacing={"0.4px"} display={"flex"} alignItems={"center"} justifyContent="center" position={"absolute"} sx={{ inset: '0', margin: 'auto' }}>Icon</Typography>
                      </Box> :
                        <ActionCard icon={activityData?.activity_logo} />
                      }
                      <div>
                        <Button
                          id="basic-button"
                          aria-controls={open ? 'basic-menu' : undefined}
                          aria-haspopup="true"
                          aria-expanded={open ? 'true' : undefined}
                          onClick={handleClick}
                          p={0.5}
                          variant="text"
                          color="primary"
                          size="small"
                          sx={{ textTransform: 'none' }}
                        >
                          Choose
                          <ArrowDropDownIcon />
                        </Button>
                        <Menu
                          id="basic-menu"
                          anchorEl={anchorEl}
                          open={open}
                          onClose={handleClose}
                          MenuListProps={{
                            'aria-labelledby': 'basic-button',
                          }}
                        >
                          <Typography variant='span' color="rgba(38, 38, 38, 0.87)" fontSize={13} fontWeight={500} letterSpacing={"0.15px"} lineHeight={"16px"} mb="10px" display={"block"} px={"10px"}>Select symbol</Typography>
                          <Box display={"flex"} flexWrap={"wrap"} width={172} px={"10px"}
                            sx={{
                              'svg': {
                                width: '50%', height: '50%'
                              },
                              'button': {
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                                width: '38px',
                                maxWidth: '38px',
                                flex: '38px',
                                height: '38px',
                                p: 0,
                              }
                            }}
                          >
                            {
                              ICON_LIST?.map((iconData, index) => (
                                <IconButton onClick={() => handleChangeActivityIcon(iconData)} key={index}>
                                  {getIcon(iconData?.iconName, true)}
                                </IconButton>
                              ))
                            }
                          </Box>
                        </Menu>
                      </div>

                    </Grid>
                    <Typography fontSize={12} color={'red.main'} sx={{ paddingTop: '5px' }}>
                      {!!errors?.activity_logo ? errors?.activity_logo : null}
                    </Typography>
                  </Grid>
                  <Grid width={"398px"} maxWidth={"100%"}>
                    <Input
                      id="title"
                      label="Name*"
                      variant="standard"
                      placeholder="Name"
                      type="text"
                      name={"title"}
                      fullWidth
                      required={false}
                      value={titleCase(activityData?.title)}
                      onBlur={(e) => {
                        handleBlur(e);
                        handleCheckUniqueActivityName(e?.target?.value)
                      }}
                      onChange={(e) => handleChange(e)}
                      error={!!errors?.title}
                      helperText={errors?.title}
                    />
                  </Grid>
                  <Grid maxWidth={"100%"}>
                    <FormControl component="fieldset">
                      <FormLabel
                        component="legend"
                        sx={{
                          color: 'rgba(0, 0, 0, 0.87)',
                          fontSize: 16,
                          fontWeight: 400,
                          lineHeight: '24px',
                          letterSpacing: '0.15px',
                          display: 'block',
                          mb: "8px"
                        }}
                      >
                        Type
                      </FormLabel>
                      <RadioGroup
                        name="types"
                        value={activityData?.types || ''}
                        onChange={handleChange}
                        row
                        aria-label="type-selection"
                      >
                        <FormControlLabel
                          value="working"
                          control={<Radio />}
                          label="Working"
                        />
                        <FormControlLabel
                          value="non-working"
                          control={<Radio />}
                          label="Non-Working"
                        />
                      </RadioGroup>
                    </FormControl>
                  </Grid>
                  <Grid maxWidth={"100%"}>
                    <FormControl component="fieldset">
                      <FormLabel
                        component="legend"
                        sx={{
                          color: 'rgba(0, 0, 0, 0.87)',
                          fontSize: 16,
                          fontWeight: 400,
                          lineHeight: '24px',
                          letterSpacing: '0.15px',
                          display: 'block',
                          mb: "8px"
                        }}
                      >
                        Working Type
                      </FormLabel>
                      <FormGroup
                        aria-labelledby="workType-chip-label"
                        row
                        sx={{
                          flexDirection: 'row',
                        }}
                      >
                        {workTypeData?.map((option, index) => (
                          <FormControlLabel
                            key={index}
                            control={<Checkbox />}
                            name={(option?.id)?.toString()}
                            checked={activityData?.org_work_type_ids?.includes(option?.id)}
                            onChange={handleChangeWorkType}
                            label={option?.name}
                          />
                        ))}
                      </FormGroup>
                    </FormControl>
                    <Typography fontSize={12} fontWeight={400} color="red.main">
                      {!!errors?.org_work_type_ids ? errors?.org_work_type_ids : ""}
                    </Typography>
                  </Grid>
                  <Grid maxWidth={"100%"} sx={{ marginTop: '24px !important' }}>
                    <Typography variant='label' color={"dark.800"} fontSize={16} fontWeight={400} lineHeight={"24px"} letterSpacing={"0.15px"} display={"block"}>Need Comment?</Typography>
                    <Switch
                      id='notes'
                      name='have_notes'
                      checked={activityData?.have_notes}
                      onChange={(e) =>
                        handleChangeNoteCheckBox(e)
                      }
                    />
                    {
                      activityData?.have_notes ?
                        <Box sx={{ width: '395px' }}>
                          <form onSubmit={handleAddClick} className="step-form-1">
                            <TableContainer>
                              <Table>
                                <TableBody
                                  sx={{
                                    'td': {
                                      padding: "5px 4px",
                                      color: "dark.800",
                                      fontWeight: 500,
                                      fontSize: 14,
                                      lineHeight: "24px",
                                      letterSpacing: "0.15px",
                                      border: "none",
                                    },
                                    "td:first-of-type": {
                                      paddingLeft: 0,
                                    },
                                    "td:last-child": {
                                      padding: 0,
                                    },
                                  }}
                                >
                                  <TableRow>
                                    <TableCell sx={{ paddingRight: '32px !important', width: '252px', maxWidth: '252px', minWidth: '252px' }}>
                                      <Input
                                        label={"Notions"}
                                        variant="standard"
                                        sx={{ width: "100%", alignSelf: "center" }}
                                        name="notion"
                                        id="name"
                                        value={notionText}
                                        onChange={(e) => handleChangeNotionName(e)}
                                        onBlur={(e) => handleChangeNotionName(e)}
                                        required={false}
                                        isOnlyCapitalFirstLatter={true}
                                        error={!!errors?.notion}
                                        helperText={errors?.notion}
                                      />
                                    </TableCell>
                                    <TableCell sx={{ width: '64px', maxWidth: '64px', minWidth: '64px', textAlign: 'center' }}>
                                      <Button
                                        variant="contained"
                                        color="secondary"
                                        size="medium"
                                        type="submit"
                                        sx={{
                                          boxShadow: "none !important",
                                          marginTop: "6px",
                                          textTransform: "none",
                                        }}
                                        disabled={
                                          errors?.notion?.length ||
                                          !notionText?.length
                                        }
                                      >
                                        Add
                                      </Button>
                                    </TableCell>
                                  </TableRow>
                                </TableBody>
                              </Table>
                              {
                                activityData?.notion?.length ?
                                  <Table sx={{ marginTop: '10px !important' }
                                  } >
                                    <TableBody
                                      sx={{
                                        td: {
                                          padding: "5px 4px",
                                          color: "dark.800",
                                          fontWeight: 500,
                                          fontSize: 14,
                                          lineHeight: "24px",
                                          letterSpacing: "0.15px",
                                          border: "none",
                                        },
                                        "td:first-of-type": {
                                          paddingLeft: 0,
                                        },
                                        "td:last-child": {
                                          padding: 0,
                                        },
                                      }}
                                    >
                                      {
                                        activityData?.notion?.filter(notionData => !notionData?.is_deleted)
                                          ?.map((notionData, index) => (
                                            <TableRow key={index}>
                                              <TableCell sx={{ paddingRight: '0px !important', width: '28px', maxWidth: '28px', minWidth: '28px', textAlign: 'right' }}>
                                                {index + 1}.
                                              </TableCell>
                                              <TableCell sx={{ paddingRight: '32px !important', width: '252px', maxWidth: '252px', minWidth: '252px' }}>
                                                {notionData?.title}
                                              </TableCell>
                                              <TableCell sx={{ width: '64px', maxWidth: '64px', minWidth: '64px', textAlign: 'center' }}>
                                                <IconButton
                                                  aria-label="delete"
                                                  size="small"
                                                  color="secondary"
                                                  onClick={() => handleDeleteNotion(notionData)}
                                                >
                                                  <DeleteIcon fontSize="small" />
                                                </IconButton>
                                              </TableCell>
                                            </TableRow>
                                          ))
                                      }
                                    </TableBody>
                                  </Table>
                                  : null
                              }
                            </TableContainer>
                          </form>
                        </Box>
                        : null}
                  </Grid>
                </Stack>
                <Box
                  display="flex"
                  justifyContent="space-between"
                  alignItems="center"
                  mt={5}
                >
                  <Box
                    display="flex"
                    justifyContent="flex-start"
                    alignItems="center"
                    flexBasis="100%"
                    gap={2}
                  >
                    <Button
                      variant="text"
                      color="secondary"
                      size="large"
                      sx={{
                        boxShadow: "0",
                        fontSize: "15px",
                        textTransform: "none",
                        "&:hover": { boxShadow: "0" },
                      }}
                      disabled={loading.formSubmitting || loading.isDelete}
                      onClick={() => navigate(URLS.AdministratorActivity)}
                    >
                      Cancel
                    </Button>
                    <Button
                      variant="contained"
                      size="large"
                      // type="submit"
                      sx={{
                        boxShadow: "0",
                        fontSize: "15px",
                        textTransform: "none",
                        color: "white",
                        "&:hover": { boxShadow: "0" },
                      }}
                      disabled={loading.formSubmitting || loading.isDelete}
                      isButtonLoading={loading.formSubmitting}
                      onClick={handleSubmit}
                    >
                      Update
                    </Button>
                    <Button
                      variant="contained"
                      color="error"
                      size="medium"
                      fontWeight="500"
                      sx={{
                        color: "white",
                        fontSize: "15px",
                        textTransform: "none",
                        boxShadow: 'none !important',
                        padding: '8px 22px'
                      }}
                      onClick={() => setIsDeleteIsOpen(true)}
                      isButtonLoading={loading.isDelete}
                      disabled={loading.isDelete || loading.formSubmitting}
                    >
                      Delete
                    </Button>
                  </Box>
                </Box>
              </Box>
            </Stack>
          </Box>
      }
      <ConfirmationModal
        isOpen={isDeleteIsOpen}
        title={'Activity'}
        deletableDataName={activityData?.title}
        dataContentName={'activity'}
        handleClose={() => setIsDeleteIsOpen(false)}
        onConfirm={() => handleSubmitDelete()}
        onCancel={() => setIsDeleteIsOpen(false)}
        isButtonLoading={loading.isDelete}
        subList={<span>This will remove <b>{activityData?.title}</b> from your organization's activity list.</span>}
      />
    </Box>
  )
}

export default UpdateActivity